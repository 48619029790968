import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withRouter, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import { getRole } from './state/selectors/login'

// Layout
import Logo from './components/layout/logo'
import SubscriptionNavigation from './components/navigation/subscription'
import AdminNavigation from './components/navigation/admin'
import ProfileNavigation from './components/navigation/profile'
import LoginNavigation from './components/navigation/login'
import StyledHeader from './components/layout/header.styled'
import Footer from './components/layout/footer'
// Routing
import PrivateRoute from './components/routing/private'
// Pages
import LoginPage from './pages/login'
import RegisterPage from './pages/register'
import TeamPage from './pages/team'
import MonitorPage from './pages/monitor'
import NewPlayPage from './pages/newplay'
import DashboardPage from './pages/dashboard'
import RecoverPage from './pages/recover'
import PlayCodePage from './pages/playcode/details'
import CreatePlayCodePage from './pages/playcode/create'
import GamesPage from './pages/game/games'
import GamePage from './pages/game/game'
import PlayPage from './pages/play/play'
import FeedbackPage from './pages/feedback'
import ReportPage from './pages/report'
import DemoPage from './pages/demo/demo'
import MyGamesPage from './pages/profile/games'
import MyPlayCodesPage from './pages/profile/playcodes'
import MyProfilePage from './pages/profile/profile'
import EditGamePage from './pages/game/edit'
import DuplicateGamePage from './pages/game/duplicate'
import DeleteGamePage from './pages/game/delete'
import MySubscriptionPage from './pages/subscription/subscription'
import StatsPage from './pages/stats/stats'
import PlayedGamesPage from './pages/playedgame/playedgames';
import PlayedGamePage from './pages/playedgame/playedgame';
import CompratePlayedGamesPage from './pages/playedgame/compareplayedgames';
import SuccessPage from './pages/success'
import CancelPage from './pages/cancel'
import PendingPage from './pages/pending'


class App extends Component {
  
  componentWillMount() {
    if (window.location.pathname != "/play" && window.location.pathname != "/demo") {
      document.title = process.theme.title;
    }
  }
  
  render() {
    var { role } = this.props;
    return (
      <div>
        <div>
          {window.location.pathname != "/report" && window.location.pathname != "/feedback" && window.location.pathname != "/play" && window.location.pathname != "/demo" &&
          <StyledHeader theme={process.theme}>
            <Logo />
            {role == "Subscription" && <SubscriptionNavigation />}
            {role == "Administrator" && <AdminNavigation />}
            {role == "Profile" && <ProfileNavigation />}  
            {role != "Profile" && role != "Subscription" && role != "Administrator" && <LoginNavigation />}
          </StyledHeader>
          }
          {window.location.pathname != "/compareplayedgames" && window.location.pathname != "/report" && window.location.pathname != "/feedback" && window.location.pathname != "/dashboard" && window.location.pathname != "/monitor" && window.location.pathname != "/play" && window.location.pathname != "/demo" && (role == "Subscription" || role == "Profile" || role == "Administrator") ?
          <div>
            {role == "Subscription" && <PrivateRoute exact path='/' component={MySubscriptionPage} />}
            {role == "Administrator" && <PrivateRoute exact path='/' component={StatsPage} />}
            {role == "Profile" && <PrivateRoute exact path='/' component={MyPlayCodesPage} />}
            {role == "Profile" && <PrivateRoute exact path='/editgame' component={EditGamePage} />}
            {role == "Profile" && <PrivateRoute exact path='/creategame' component={EditGamePage} />}          
            {role == "Profile" && <PrivateRoute exact path='/createplaycode' component={CreatePlayCodePage} />}
            {role == "Profile" && <PrivateRoute exact path='/playcode' component={PlayCodePage} />}
            {role == "Profile" && <PrivateRoute exact path='/games' component={GamesPage} />}
            {role == "Profile" && <PrivateRoute exact path='/game' component={GamePage} />}
            {role == "Profile" && <PrivateRoute exact path='/register' component={RegisterPage} />}
            {role == "Profile" && <PrivateRoute exact path='/team' component={TeamPage} />}
            {role == "Profile" && <PrivateRoute exact path='/mygames' component={MyGamesPage} />}
            {role == "Profile" && <PrivateRoute exact path='/newplay' component={NewPlayPage} />}
            {role == "Profile" && <PrivateRoute exact path='/profile' component={MyProfilePage} />}
            {role == "Profile" && <PrivateRoute exact path='/playedgames' component={PlayedGamesPage} />}
            {role == "Profile" && <PrivateRoute exact path='/playedgame' component={PlayedGamePage} />}
            {role == "Profile" && <PrivateRoute exact path='/duplicate' component={DuplicateGamePage} />}
            {role == "Profile" && <PrivateRoute exact path='/delete' component={DeleteGamePage} />}
            {role == "Profile" && <PrivateRoute exact path='/success' component={SuccessPage} />}
            {role == "Profile" && <PrivateRoute exact path='/pending' component={PendingPage} />}
            {role == "Profile" && <PrivateRoute exact path='/cancel' component={CancelPage} />}
          </div>
          :
          <div>
            {process.theme.publicgames &&
              <Route exact path='/games' component={GamesPage} />
            }
            {process.theme.publicgame &&
              <Route exact path='/game' component={GamePage} />
            }
            <Route exact path='/report' component={ReportPage} />
            <Route exact path='/feedback' component={FeedbackPage} />
            <Route exact path='/play' component={PlayPage} />
            <Route exact path='/compareplayedgames' component={CompratePlayedGamesPage} />            
            <Route exact path='/demo' component={DemoPage} />
            <Route exact path='/register' component={RegisterPage} />
            <Route exact path='/team' component={TeamPage} />
            <Route exact path='/monitor' component={MonitorPage} />
            <Route exact path='/dashboard' component={DashboardPage} />
            <Route exact path='/recover' component={RecoverPage} />    
            <Route exact path='/success' component={SuccessPage} />     
            <Route exact path='/cancel' component={CancelPage} />  
            <Route exact path='/pending' component={PendingPage} />
            {window.location.pathname != "/compareplayedgames" && window.location.pathname != "/report" && window.location.pathname != "/feedback" && window.location.pathname != "/dashboard" && window.location.pathname != "/monitor" && window.location.pathname != "/register" && window.location.pathname != "/team" && (!process.theme.publicgame || window.location.pathname != "/game") && (!process.theme.publicgames || window.location.pathname != "/games") && window.location.pathname != "/recover" && window.location.pathname != "/register" && window.location.pathname != "/play" &&  window.location.pathname != "/demo" && window.location.pathname != "/install" && window.location.pathname != "/success" && window.location.pathname != "/cancel" && window.location.pathname != "/pending" && 
              <Route exact path='/*' component={LoginPage} />
            }
          </div>
          }
          {window.location.pathname != "/report" && window.location.pathname != "/feedback" && window.location.pathname != "/play" && window.location.pathname != "/dashboard" && window.location.pathname != "/monitor" && window.location.pathname != "/newplay" && window.location.pathname != "/demo" &&
            <Footer/>
          }
        </div>
      </div>
    )
  }
}


App.propTypes = {
  role: PropTypes.string.isRequired,
}

App.defaultProps = {
  role: "",
}

const mapStateToProps = (state) => {
  return {
    role: getRole(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
