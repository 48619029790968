import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import StyledEditLevelRole from './rolelevel.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import { addGameRoleLevel, updateGameRoleLevel, deleteGameRoleLevel } from '../../../state/actions/game'
  

export class EditRoleLevel extends Component {
  
    constructor(props) {
      super()

      this.state = {
        isUpdate: false,
        isChanged: false,
        isDeleted: false,
        isAdded: false,      }

    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const id = target.id
        this.setState({
          [ id ]: value,
          isChanged: true,
        });
    }

    submitRoleLevel = e => {
        e.preventDefault()
        if (!this.state.isUpdate) {
            this.props.fetchAddRoleLevel(this.props.levelId, this.state.roleId, this.state.individualOn, this.state.individualInstruction, this.state.groupOn, this.state.groupInstruction, this.state.introduction);
            this.props.deleteNewRoleLevel(this.props.sequence);
        } else {
            const roleLevelId = this.props.roleLevel.getIn(['Id'], null);  
            this.props.fetchUpdateRoleLevel(roleLevelId, this.state.individualOn, this.state.individualInstruction, this.state.groupOn, this.state.groupInstruction, this.state.introduction);
            this.setState({
                isChanged: false,
                isDeleted: false,
                isAdded: false,
            });   
        }
    }

    submitDeleteExistingRoleLevel = e => {
        e.preventDefault();
        const roleLevelId = this.props.roleLevel.getIn(['Id'], null);  
        this.props.fetchDeleteRoleLevel(roleLevelId);
    }

    submitDeleteNewRoleLevel = e => {
        e.preventDefault()
        this.props.deleteNewRoleLevel(this.props.sequence);
    }

    render() {
        let { roles, notOwned, roleLevel, levelId, hasIndividual, hasGroup } = this.props;
        if (roleLevel != null && roleLevel != undefined) {
            this.state.isUpdate = true;
        }
        const roleId = (this.state.isUpdate) ? roleLevel.getIn(['RoleId'], "") : "";
        if (this.state.roleId == null) {
            this.state.roleId = roleId;
        }
        const individualOn = (this.state.isUpdate) ? roleLevel.getIn(['IndividualOn'], false) : false;
        if (this.state.individualOn == null) {
            this.state.individualOn = individualOn;
        }
        const individualInstruction = (this.state.isUpdate) ? roleLevel.getIn(['IndividualInstruction'], "") : "";
        if (this.state.individualInstruction == null) {
            this.state.individualInstruction = individualInstruction;
        }
        const groupOn = (this.state.isUpdate) ? roleLevel.getIn(['GroupOn'], false) : false;
        if (this.state.groupOn == null) {
            this.state.groupOn = groupOn;
        }
        const groupInstruction = (this.state.isUpdate) ? roleLevel.getIn(['GroupInstruction'], "") : "";
        if (this.state.groupInstruction == null) {
            this.state.groupInstruction = groupInstruction;
        }
        const introduction = (this.state.isUpdate) ? roleLevel.getIn(['Introduction'], "") : "";
        if (this.state.introduction == null) {
            this.state.introduction = introduction;
        }
        return (  
            <StyledEditLevelRole>    
                <div class="roleLevelForm">
                    <form onSubmit={this.submitRoleLevel}>
                    <div class="inputs">
                        <div class="row">
                        <div>
                            <select {...notOwned ? {disabled:"true"} : {}} value={this.state.roleId} id='roleId' required onChange={this.handleInputChange}>
                                <option value="" disabled selected>Изберете роля*</option>
                                {
                                    roles.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx)=> {
                                    var roleId = val.getIn(["Id"], null);
                                    var roleName = val.getIn(["Name"], null);
                                    return (
                                        <option value={roleId}>{roleName}</option>
                                    )
                                })
                                }
                            </select>
                        </div>
                        <div>
                            <textarea rows="5" value={this.state.introduction} {...notOwned ? {disabled:"true"} : {}} class="textarea" id='introduction' placeholder="Специфични инструкции за тази роля" onChange={this.handleInputChange}></textarea>
                        </div>
                        <div>
                            {hasIndividual &&
                            <div>
                                <div class="checkbox">
                                    <input {...(this.state.individualOn ? {checked: "checked"} : {})} {...notOwned ? {disabled:"true"} : {}} type="checkbox" id="individualOn" name="individualOn" onChange={this.handleInputChange}/>
                                    <label for="individualOn">Ще участва в индивидуалната задача.</label>
                                </div>
                                {this.state.individualOn &&
                                    <textarea value={this.state.individualInstruction} {...notOwned ? {disabled:"true"} : {}} class="textarea" id='individualInstruction' placeholder="Специфични инструкции за индивидуалната задача" onChange={this.handleInputChange}></textarea>
                                }
                            </div>
                            }
                            {hasGroup &&
                                <div>
                                    <div class="checkbox">
                                        <input {...(this.state.groupOn ? {checked: "checked"} : {})} {...notOwned ? {disabled:"true"} : {}} type="checkbox" id="groupOn" name="groupOn" onChange={this.handleInputChange}/>
                                        <label {...notOwned ? {disabled:"true"} : {}} for="groupOn">Ще участва в груповата задача.</label>
                                    </div>
                                    {this.state.groupOn &&
                                        <textarea value={this.state.groupInstruction} class="textarea" id='groupInstruction' placeholder="Специфични инструкции за груповата задача" onChange={this.handleInputChange}></textarea>
                                    }
                                </div>
                            }
                        </div>
                        <div class="btns">
                            <div class="addbtn">
                                <FixedButton>
                                    <StandardButton disabled={notOwned} isSubmit text={(!this.state.isUpdate) ? "Добавете" : "Актуализирайте"} width="100%"/>
                                </FixedButton>
                            </div>
                            <div class="deletebtn">
                                <FixedButton>
                                    <StandardButton disabled={notOwned} click={(this.state.isUpdate) ? this.submitDeleteExistingRoleLevel : this.submitDeleteNewRoleLevel} text="Изтрийте" width="100%" type="delete"/>
                                </FixedButton>
                            </div>
                        </div>
                        </div> 
                    </div>              
                    </form>
            </div>
          </StyledEditLevelRole>
        )
    }
}


EditRoleLevel.propTypes = {
    idx: PropTypes.number,
    game: PropTypes.object,
    level: PropTypes.object,
  }
  
  const mapStateToProps = (state) => {
    return {
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
        fetchAddRoleLevel: (levelId, roleId, individualOn, individualInstruction, groupOn, groupInstruction, introduction) => dispatch(addGameRoleLevel(levelId, roleId, individualOn, individualInstruction, groupOn, groupInstruction, introduction)),
        fetchUpdateRoleLevel: (roleLevelId, individualOn, individualInstruction, groupOn, groupInstruction, introduction) => dispatch(updateGameRoleLevel(roleLevelId, individualOn, individualInstruction, groupOn, groupInstruction, introduction)),
        fetchDeleteRoleLevel: (roleLevelId) => dispatch(deleteGameRoleLevel(roleLevelId)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditRoleLevel)