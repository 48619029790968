
import styled from 'styled-components'

const StyledPlayerStats = styled.section`
.player {
  width: 150px;
  background: #e8ebe2;
  border-radius: 10px;
  padding: 20px 0;
  margin: 0 5px;
  margin-top: 10px;
}

.playername {
  padding-bottom: 10px;
  font-weight: bold;
}

.playerpoints {
  font-weight: bold;
  color: 000;
}

.playerscorelabel {
  font-size: 1.2em;
  color: 000;
}

.playerscore {
  color: #000;
  font-size: 1.5em;
  font-weight: bold;
}

.playerpoints span {
  color: #000;
}
`
export default StyledPlayerStats