import * as types from './types'

export function logInAsSubscription(subscriptionId, activationCode) {
  return {     
    type: types.READ_SUBSCRIPTION_REQUEST,
    payload: { subscriptionId, activationCode }
  }
}

export function logInAsProfile(email, password) {
  return { 
    type: types.READ_PROFILE_REQUEST,
    payload: { email, password }
  }
}

export function logInAsAdmin(username, password) {
  return { 
    type: types.READ_ADMIN_REQUEST,
    payload: { username, password }
  }
}

export function logOut() {
  return {
    type: types.AUTH_LOGOUT
  }
}