import { fromJS, Map } from 'immutable'


export function getReport(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['report'], null)
}

export function setReport(state, newState) {
  return state.merge(fromJS(newState.report))
}

export function resetReport(state) {
  return new Map();
}

export function setError(state, error) {
  return state.merge({
    Error: fromJS(error)
  })
}

