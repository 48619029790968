import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledProfile from './subscription.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import { updateSubscription } from '../../../state/actions/subscription'
import {  getSubscription } from '../../../state/selectors/subscription'

export class Subscription extends Component {
  constructor(props) {
    super()
    this.state = {
      isUpdate: false,
      isChanged: false,
    }
    this.startUpdate = this.startUpdate.bind(this);
  }

  startUpdate = () => {
    this.setState(state => ({
      isUpdate: true
    }));
  }

  stopUpdate = () => {
    this.setState(state => ({
      isUpdate: false
    }));
  }

  clearChanges = () => {
    this.setState(state => ({
      isChanged: false
    }));
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    
   this.setState({
      [ id ]: value,
      isChanged: true
    });
  }

  submitSubsriptionDetails = e => {
    e.preventDefault();

    const { subscription } = this.props
    const subscriptionId = subscription.getIn(['Subscription', 'Id'], null);
    this.props.fetchUpdateSubscription(subscriptionId, this.state.organization, this.state.name, this.state.email, this.state.phone);
    this.setState(state => ({
      isUpdate: false,
      isChanged: false,
      organization: undefined,
      name: undefined,
      email: undefined,
      phone: undefined
    }));
  }

  render() {

    const { subscription } = this.props
    const organization = subscription.getIn(['Subscription', 'Organization'], null);
    const name = subscription.getIn(['Subscription', 'Name'], null);
    const email = subscription.getIn(['Subscription', 'Email'], null);
    const phone = subscription.getIn(['Subscription', 'Phone'], null);
    var expirationDate = new Date(Date.parse(subscription.getIn(['Subscription', 'ExpirationDate'], null)));
    var isExpired = subscription.getIn(['Subscription', 'IsExpired'], null);
    var isEnabled = subscription.getIn(['Subscription', 'IsEnabled'], null);
    var status = (isEnabled && !isExpired) ? "Активен" : "Неактивен";
    var playCodesCount = subscription.getIn(['Subscription', 'PlayCodesCount'], null);
    var playCodesLeft = subscription.getIn(['Subscription', 'PlayCodesLeft'], null);
    return (
        <StyledProfile>
         <div id="subscription">
          <div>
            {!this.state.isUpdate &&
            <div>
              <div class="details_section">
                <div class="detail_item">
                    <div class="detail_attribute">Организация: </div>
                    <div class="detail_value">{organization}</div>
                </div>
                <div class="detail_item">
                    <div class="detail_attribute">Лице за контакт: </div>
                    <div class="detail_value">{name}</div>
                </div>
                <div class="detail_item">
                    <div class="detail_attribute">Email: </div>
                    <div class="detail_value"><a href={"mailto:" + email}>{email}</a></div>
                </div>
                <div class="detail_item">
                    <div class="detail_attribute">Телефон: </div>
                    <div class="detail_value">{phone}</div>
                </div>
                <div class="detail_item">
                    <div class="detail_attribute">Изтича на:</div>
                    <div class="detail_value">{expirationDate.toLocaleString()}</div>
                </div>
                <div class="detail_item">
                    <div class="detail_attribute">Статус:</div>
                    <div class="detail_value"><span class={(isEnabled && !isExpired) ? "active" : "inactive"}>{status}</span></div>
                </div>
                <div class="detail_item">
                    <div class="detail_attribute">Генерирани кодове:</div>
                    <div class="detail_value">{playCodesCount}</div>
                </div>
                <div class="detail_item">
                    <div class="detail_attribute">Оставащи кодове:</div>
                    <div class="detail_value">{playCodesLeft}</div>
                </div>
              </div>
              <div class="detail_button">
                <FixedButton>
                  <StandardButton click={this.startUpdate} text="Актуализирайте" width="100%" />
                </FixedButton>
              </div>
            </div>
            }

            {this.state.isUpdate &&
              <form onSubmit={this.submitSubsriptionDetails}>
                <h2>Моля въведете детайли на абонамента:</h2>
                <div class="inputs">
                  <div class="row">
                    <input id='organization' {...(!this.state.isChanged ? {value: organization} : {})} required placeholder="Име на организацията*" onChange={this.handleInputChange}/>
                  </div>
                  <div class="row">
                    <input id='name' {...(!this.state.isChanged ? {value: name} : {})} required placeholder="Лице за контакт*" onChange={this.handleInputChange}/>
                  </div>
                  <div class="row">
                    <input id='email' {...(!this.state.isChanged ? {value: email} : {})} required placeholder="Email*" onChange={this.handleInputChange}/>
                  </div>
                  <div class="row">
                    <input id='phone' {...(!this.state.isChanged ? {value: phone} : {})} required placeholder="Телефон*" onChange={this.handleInputChange}/>
                  </div>  
                  <div class="btn_buttons">
                      <div class="btn_edit">
                        <FixedButton>
                          <StandardButton disabled={this.state.isUpdate && !this.state.isChanged} isSubmit text="Актуализирайте" width="100%" />
                        </FixedButton>
                      </div>
                      {this.state.isUpdate &&
                        <div class="btn_cancel">
                          <FixedButton>
                            <StandardButton disabled={!this.state.isChanged} click={this.clearChanges} text="Анулирайте промените" width="100%" type="cancel"/>
                          </FixedButton>
                        </div>
                      }                  
                      <div class="btn_back">
                          <FixedButton>
                            <StandardButton click={this.stopUpdate} text="Откажете актуализацията" width="100%" type="back"/>
                          </FixedButton>
                        </div>
                  </div>
                </div>              
              </form>
              }
          </div>
        </div>
      </StyledProfile>
    )
  }
}

Subscription.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    subscription: getSubscription(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchUpdateSubscription: (subscriptionId, title, name, email, phone) => dispatch(updateSubscription(subscriptionId, title, name, email, phone))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
