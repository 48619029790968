import styled from 'styled-components'

const StyledTeam = styled.section`

.accessLink {
    text-align: center;
}

.accessLink a {
    font-size: 2em;
    color: #000;
}

.teamName {
    font-size: 2em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
}

.players {
    width: 50%;
    margin: 0 auto;
    margin-top: 50px;
}

.playersTitle {
    font-size: 1.2em;
    text-align: center;
    margin-bottom: 10px;
    font-weight: bold;
}

.players .refresh {
    margin-top: 50px;
}

.note {
    text-align: center;
}

.refresh {
    margin-top: 10px;
}
`

export default StyledTeam
