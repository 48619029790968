import styled from 'styled-components'

const StyledGame = styled.section`

.playedgamesloading, .noplayedgames {
  text-align: center;
  padding: 50px;
}

.playedgames {
  display: flex;
  align-items: top;
  justify-content: space-evenly;
}

.playedgame {
  width: 100%;
}

.buttons {
  margin-top: 70px;
  margin-bottom: 70px;
}

.comparison {
  padding-top: 0px;
}

.comparison .title {
  padding-bottom: 10px !important;
}

.results {
  background: #E8EBE2;
  padding-bottom: 50px;
  padding-top: 50px;
}
`

export default StyledGame
