import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledNewPlay from './monitor.styled'
import queryString from 'query-string'

import PageTitle from '../../ui/title'
import SubTitle from '../../ui/subtitle'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'
import StandardSection from '../../ui/section'
import Ranking from '../../ui/ranking'

import socketIOClient from "socket.io-client";

import {  getGame } from '../../../state/selectors/game'
import {  getProfile } from '../../../state/selectors/profile'
import {  getTeam } from '../../../state/selectors/team'
import { readGameByAccessKey, resetGame } from '../../../state/actions/game'
import { createPlayedGame } from '../../../state/actions/playedgame'
import { readTeamByAccessKey } from '../../../state/actions/team'

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Level from '../game/level'

const internalSocketURL = "https://socket.agify.me/socket.io/";
const externalSocketURL = "https://socket.agify.me/";
const socketURL = externalSocketURL;

var parse = require('html-react-parser');

export class NewPlay extends Component {
 
  state = {
    accessKey: -1,
    isConnected: false,
    isInitialized: false,
    nickname: "",
    player: null,
    epochTimeDiffWithServer: 0,
  }

  componentDidMount() {
    let url =  window.location.href;
    if (this.props.accessKey) {
      this.accessKey = this.props.accessKey;
    } else {
      const parsed = queryString.parseUrl(url);
      // Check for access key
      this.accessKey = (parsed.query.id) ? parsed.query.id : -1;
      if (this.accessKey == -1) {
        this.accessKey = (parsed.query.id) ? parsed.query.id : -1;
      }
    }
    const { profile } = this.props;
    var playerId = (profile != null) ? profile.getIn(['Profile', 'Id'], null) : Math.floor(100000 + Math.random() * 900000);
    this.setState({
      playerId: playerId,
    });

    this.initialize();
  }

  constructor(props) {
    super()
    // Handlers    
    this.handleInputChange = this.handleInputChange.bind(this);
    this.setNickname = this.setNickname.bind(this);
    this.setInitialEmotion = this.setInitialEmotion.bind(this);
    this.onTeamNameChange = this.onTeamNameChange.bind(this);
    this.setTeamName = this.setTeamName.bind(this);
    this.setIntroduction = this.setIntroduction.bind(this);
    this.setGroupAnswer = this.setGroupAnswer.bind(this);
    this.setFinalEmotion = this.setFinalEmotion.bind(this);
    this.setResults = this.setResults.bind(this);
    this.storeResults = this.storeResults.bind(this);
    this.getServerEpochTime = this.getServerEpochTime.bind(this);
    this.getPlayedTime = this.getPlayedTime.bind(this);
    this.formatPlayedTime = this.formatPlayedTime.bind(this);
    this.getActivePlayers = this.getActivePlayers.bind(this);
    // Socket
    this.initialize = this.initialize.bind(this);
    this.onInitComplete = this.onInitComplete.bind(this);
    this.onSocketConnect = this.onSocketConnect.bind(this);
    this.onSocketDisconnect = this.onSocketDisconnect.bind(this);
    this.onSocketConnectError = this.onSocketConnectError.bind(this);
    this.onSocketError = this.onSocketError.bind(this);
    this.onSetState = this.onSetState.bind(this);
    this.onHelpAddressed = this.onHelpAddressed.bind(this);
  }

  setNickname = (event) => {
    event.preventDefault();
    this.state.state.players.map((player) => {
      if (player.id == this.state.playerId) {
        player.nickname = this.state.nickname;
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  startPlay = (event) => {
    event.preventDefault();
    this.state.state.players.map((player) => {
      if (player.id == this.state.playerId) {
        player.hasAgreedToStartPlay = true;
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  setInitialEmotion(emotionCode) {
    this.state.state.players.map((player) => {
      if (player.id == this.state.playerId) {
        player.initialEmotion = emotionCode;
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  onTeamNameChange = (event) => {
    const value = event.target.value;
    if (value != this.state.state.team.name) {
      this.state.state.team.name = value;
    }
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  setTeamName(event) {
    event.preventDefault();
    this.state.state.players.map((player) => {
      if (player.id == this.state.playerId) {
        player.hasAgreedOnTeamName = true;
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  setIntroduction(event) {
    event.preventDefault();
    this.state.state.players.map((player) => {
      if (player.id == this.state.playerId) {
        player.hasAgreedOnIntroduction = true;
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  setGroupAnswer(levelId, answerId) {
    this.state.state.players.map((player) => {
      if (player.id == this.state.playerId) {
        var playerGroupLevel = null;
        player.groupLevels.map((groulLevel) => {
          if (groulLevel.id == levelId) {
            playerGroupLevel = groulLevel;
          }
        });
        if (playerGroupLevel == null) {
          // Create player group level
          player.groupLevels.push({
            id: levelId,
            answer: answerId,
          })
        } else {
          // Update player group level
          playerGroupLevel.answer = answerId;
        }
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  setFinalEmotion(emotionCode) {
    this.state.state.players.map((player) => {
      if (player.id == this.state.playerId) {
        player.finalEmotion = emotionCode;
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  setResults = (event) => {
    event.preventDefault();
    this.state.state.players.map((player) => {
      if (player.id == this.state.playerId) {
        player.hasAgreedOnResults = true;
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  getActivePlayers = (stateAsJSON) => {
    var activePlayers = [];
    stateAsJSON.players.map((player) => {
      if (player.isOnline && player.isActive) {
        activePlayers.push(player);
      }
    });
  }
  

  getGameResults = () => {
    const { game, profile } = this.props;
    let levels = game.getIn(['Game', 'Levels', 'Level'], []);
    var numberOfPlayedLevels = 0;
    var teamPoints = 0;
    var playedLevels = [];    
    this.state.state.play.levels.map((level) => {
      if (level.isPlayed) {
        numberOfPlayedLevels++;
        levels.map((levelData) => {
          if (levelData.getIn(['Id'], null) == level.id) {
            var levelAsnwers = levelData.getIn(['GroupQuestion','Answers'], []);
            levelAsnwers.map((levelAnswer) => {
              if (levelAnswer.getIn(['Id'], null) == level.answer) {
                levelData.givenGroupAnswer = levelAnswer.getIn(['Id'], null);
                levelData.wonGroupPoints = parseInt(levelAnswer.getIn(['Points'], null))
                teamPoints += levelData.wonGroupPoints;
              }
            })
            playedLevels.push(levelData);
          }
        });
      }
    })
    return {
      numberOfPlayedLevels: numberOfPlayedLevels,
      teamPoints: teamPoints,
      playedLevels: playedLevels,
    }
  }

  getServerEpochTime = () =>
  {
      var serverEpochTime = (new Date().getTime() / 1000) - this.state.epochTimeDiffWithServer;
      return serverEpochTime;
  }

  getPlayedTime = () =>
  {
      var playedTime = 0;
      var currentLevel = null;
      this.state.state.play.levels.map((level) => {
        playedTime += level.totalPlayedTime;
        if (level.id == this.state.state.play.currentLevelId) {
          currentLevel = level;
        }
      })
      if (this.state.state.play.phase == "play" && currentLevel != null)
      {
        playedTime += this.getServerEpochTime() - currentLevel.startPlayServerEpochTime;
      }      
      return Math.floor(playedTime);
  }

  formatPlayedTime = () => {
    var playedTime = this.getPlayedTime();
    if (playedTime <= 0) {
      return "-";
    }
    var minutes = Math.floor((playedTime / 60) % 60);
    var hours = Math.floor((playedTime / (60 * 60)) % 24);
    var seconds = Math.floor(playedTime % 60),
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;
    return hours + ":" + minutes + ":" + seconds;
  }

  storeResults = () => {
    // Store results
    var gameResults = this.getGameResults();
    this.props.fetchStoreResults(this.accessKey, gameResults.teamPoints, this.state.state.team.name, this.getPlayedTime(), JSON.stringify(this.state.state), true);
  }

  getRankings = () => {
    // Get ranking
    this.props.fetchReadTeamByAccessKey(this.accessKey);
  }

  refreshRankings = () => {
    this.props.fetchReadTeamByAccessKey(this.accessKey);
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    this.setState({
        [ id ]: value,
        isChanged: true
      });
  }

  initialize = () => {
    // Reset game
    this.props.fetchResetGame();
    // Read game
    this.props.fetchReadGameByAccessKey(this.accessKey);
    // Initialize socket
    this.socket = socketIOClient(socketURL, {transports: ["websocket"], upgrade: false});
    this.socket.on("connect", this.onSocketConnect);
    this.socket.on('disconnect', this.onSocketDisconnect);
    this.socket.on('connect_error', this.onSocketConnectError);
    this.socket.on('error', this.onSocketError);
    this.socket.on("SetState", this.onSetState);
  }

  onSocketConnect = () => {
    console.log("[Socket] Socket connected.");
    this.state.isConnected = true;
    const { game } = this.props;
    if (this.state.isInitialized && this.state.state != null) {
      // Connection reestablished
      var channel = this.accessKey;
      this.socket.emit('GetState', channel, JSON.stringify(this.state.state));
    } else if (game != null) {
      // Fist connection established & game already loaded 
      this.onInitComplete();
    }
  }

  onInitComplete = () => {
    if (this.state.isInitialized) {
      return;
    }
    this.state.isInitialized = true;
    var channel = this.accessKey;
    // Initialize initial player
    var player = {
      id: this.state.playerId,
      nickname: "",
      initialEmotion: -1,
      finalEmotion: -1,
      hasAgreedToStartPlay: false,
      hasAgreedOnTeamName: false,
      hasAgreedOnIntroduction: false,
      hasAgreedOnBacklog: false,
      hasAgreedOnPostPlayText: false,
      groupLevels: [],
      isOnline: true,
      isActive: true,
    }
    // Initialize initial state
    var state = {
      phase: "Start",
      currentPlayerId: this.state.playerId,
      team: {
        name: "",
      },
      players: [player],
    }
    this.socket.emit('GetState', channel, JSON.stringify(state));
  }

  onSocketDisconnect = () => {
    console.log("[Socket] Socket disconnected.");
  }

  onSocketConnectError = (error) => {
    console.log("[Socket] Socket error: " + error);
  }

  onSocketError = (error) => {
    console.log("[Socket] Socket connect error: " + error);
  }

  onSetState = (state) => {
    console.log("[Socket] Set state.");
    var stateAsJSON = JSON.parse(state);

    // In case of Start phase
    if (stateAsJSON.phase == "Start") {
      // Check if all players have agreed to start the play
      var allPlayersHaveAgreedToStartPlay = true;
      var activePlayers = this.getActivePlayers();
      activePlayers.map((player) => {
        if (!player.hasAgreedToStartPlay) {
          allPlayersHaveAgreedToStartPlay = false;
        }
      });
      if (allPlayersHaveAgreedToStartPlay) {
        stateAsJSON.phase = "Play";
        stateAsJSON.play = {
          phase: "initialemotions"
        }
        this.socket.emit('SetState', JSON.stringify(stateAsJSON));
        return;
      }
    } else
    // In case of Play phase
    if (stateAsJSON.phase == "Play") {
      // In case of initial emotions
      if (stateAsJSON.play.phase == "initialemotions") {
        // Check if all players have given their initial emotion
        var allPlayersHaveGivenTheirInitialEmotion = true;
        var activePlayers = this.getActivePlayers();
        activePlayers.map((player) => {
          if (player.initialEmotion < 0) {
            allPlayersHaveGivenTheirInitialEmotion = false;
          }
        });
        if (allPlayersHaveGivenTheirInitialEmotion) {
          stateAsJSON.play.phase = "teamname";
          this.socket.emit('SetState', JSON.stringify(stateAsJSON));
          return;
        }
      } else if (stateAsJSON.play.phase == "teamname") {
        // Check if all players have agreed on team name
        var allPlayersHaveAgreedOnTeamName = true;
        var activePlayers = this.getActivePlayers();
        activePlayers.players.map((player) => {
          if (!player.hasAgreedOnTeamName) {
            allPlayersHaveAgreedOnTeamName = false;
          }
        });
        if (allPlayersHaveAgreedOnTeamName) {
          stateAsJSON.play.phase = "introduction";
          this.socket.emit('SetState', JSON.stringify(stateAsJSON));
          return;
        }
      } else if (stateAsJSON.play.phase == "introduction") {
        // Check if all players have agreed on introduction
        var allPlayersHaveAgreedOnIntroduction = true;
        var activePlayers = this.getActivePlayers();
        activePlayers.map((player) => {
          if (!player.hasAgreedOnIntroduction) {
            allPlayersHaveAgreedOnIntroduction = false;
          }
        });
        if (allPlayersHaveAgreedOnIntroduction) {
          stateAsJSON.play.phase = "play";
          stateAsJSON.play.levels = [];
          const { game } = this.props;
          let levels = game.getIn(['Game', 'Levels', 'Level'], []);
          var counter = 0;
          levels.sort((a, b) => a.getIn(['SequenceNumber'], null) - b.getIn(['SequenceNumber'], null)).map((level) => {
            stateAsJSON.play.levels.push({
              id: level.getIn(['Id'], null),
              sequence: level.getIn(['SequenceNumber'], null),
              isPlayed: (counter == 0),
              isDone: false,    
              startPlayServerEpochTime: (counter ==0 ) ? this.getServerEpochTime() : 0,
              totalPlayedTime: 0,          
            });
            if (counter == 0) {
              // Set first level as current level
              stateAsJSON.play.currentLevelId = level.getIn(['Id'], null);
            }
            counter++;
          });
          this.socket.emit('SetState', JSON.stringify(stateAsJSON));
          return;
        }
      } else if (stateAsJSON.play.phase == "play") {
        // Check if all players have given their group answer
        var allPlayersHaveGivenTheSameAnswer = true;
        var groupAnswer = null;
        var activePlayers = this.getActivePlayers();
        activePlayers.map((player) => {
          var playerGroupLevel = null;
          player.groupLevels.map((playerLevel) => {
            if (playerLevel.id == stateAsJSON.play.currentLevelId) {
              playerGroupLevel = playerLevel;
            }
          })
          if (playerGroupLevel == null) {
            allPlayersHaveGivenTheSameAnswer = false;
          } else {
            if (groupAnswer == null) {
              groupAnswer = playerGroupLevel.answer;
            } else if (groupAnswer != playerGroupLevel.answer) {
              allPlayersHaveGivenTheSameAnswer = false;
            }
          }
        });
        if (allPlayersHaveGivenTheSameAnswer) {
          // Mark level as played
          var currentLevelSequence = -1;
          stateAsJSON.play.levels.map((level) => {
            if (level.id == stateAsJSON.play.currentLevelId) {
              level.isDone = true;
              level.answer = groupAnswer;
              currentLevelSequence = level.sequence;
              level.totalPlayedTime += this.getServerEpochTime() - level.startPlayServerEpochTime;
              level.startPlayServerEpochTime = 0;
            }
          })
          // Define next level
          var nextLevel = null;
          stateAsJSON.play.levels.map((level) => {
            if (level.sequence == currentLevelSequence + 1) {
              nextLevel = level;
            }
          })
          if (nextLevel == null) {
            stateAsJSON.play.phase = "finalemotions";
          } else {
            nextLevel.isPlayed = true;
            nextLevel.startPlayServerEpochTime = this.getServerEpochTime();
            stateAsJSON.play.currentLevelId = nextLevel.id;
          }
          this.socket.emit('SetState', JSON.stringify(stateAsJSON));
          return;
        }
      } else if (stateAsJSON.play.phase == "finalemotions") {
        // Check if all players have given their final emotion
        var allPlayersHaveGivenTheirFinalEmotion = true;
        var activePlayers = this.getActivePlayers();
        activePlayers.players.map((player) => {
          if (player.finalEmotion < 0) {
            allPlayersHaveGivenTheirFinalEmotion = false;
          }
        });
        if (allPlayersHaveGivenTheirFinalEmotion) {
          stateAsJSON.play.phase = "results";
          stateAsJSON.phase = "End";
          this.socket.emit('SetState', JSON.stringify(stateAsJSON));
          this.state.state.play.phase = "results";
          this.state.state.phase = "End";
          this.storeResults();
          return;
        }
      }
    } else if (stateAsJSON.phase == "End") {
      if (stateAsJSON.play.phase == "results") {
        // Check if all players have agreed on results
        var allPlayersHaveAgreedOnResults = true;
        var activePlayers = this.getActivePlayers();
        activePlayers.players.map((player) => {
          if (!player.hasAgreedOnResults) {
            allPlayersHaveAgreedOnResults = false;
          }
        });
        if (allPlayersHaveAgreedOnResults) {
          stateAsJSON.play.phase = "rankings";
          this.socket.emit('SetState', JSON.stringify(stateAsJSON));
          return;
        }
      }
    }
    
    var thisPlayer = null;
    stateAsJSON.players.map((player) => {
      if (player.id == this.state.playerId) {
        thisPlayer = player;
      }
    });

    if (stateAsJSON.phase == "End" && stateAsJSON.play.phase == "rankings") {
      this.getRankings();
    }

    var epochTimeDiffWithServer = (new Date().getTime() / 1000) - stateAsJSON.serverEpochTime;
    this.setState({
      state: stateAsJSON,
      player: thisPlayer,
      epochTimeDiffWithServer: epochTimeDiffWithServer,
    });

    if (stateAsJSON.phase == "Play" && stateAsJSON.play.phase == "play") {
      if (this.interval == null) {
        this.interval = setInterval(() => this.setState({ playedTime: this.formatPlayedTime() }), 1000);
      }
    }
    
  }

  onHelpRequest = () => {
    this.state.state.helpRequested = true;
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  render() {
      const { game, profile, team } = this.props;
      if (game != null && this.state.isConnected && !this.state.isInitialized) {
        this.onInitComplete();
      }
      const gameId = game.getIn(['Game', 'Id'], null);
      const gameDescription = game.getIn(['Game', 'GameDescription'], null);
      const gameIntroduction = game.getIn(['Game', 'Introduction'], null);
      let levels = game.getIn(['Game', 'Levels', 'Level'], []);
      var currentLevel = null;
      if (levels != null && this.state.state && this.state.state.play && this.state.state.play.phase == "play") {
        levels.map((level) => {
          if (level.getIn(['Id'], null) == this.state.state.play.currentLevelId) {
            currentLevel = level;
          }
        });
      }
      var currentAnswer = null;
      if (currentLevel != null) {
        var currentLevelId = currentLevel.getIn(['Id'], null);
        this.state.player.groupLevels.map((playerLevel) => {
          if (currentLevelId == playerLevel.id) {
            currentAnswer = playerLevel.answer;
          }
        })
      }
      var gameResults = null;
      if (this.state.state && this.state.state.phase == "End" && this.state.state.play.phase == "results") {
        gameResults = this.getGameResults();
      }

      var rankings = null;
      if (this.state.state && this.state.state.play && this.state.state.play.phase == "rankings" && team != null) {
        rankings = {
          concurrentTeams: team.getIn(['PlayCodeTeam', 'ConcurrentTeams'], null),
          allTeams: team.getIn(['PlayCodeTeam', 'AllTeams'], null),
          showRanking: team.getIn(['PlayCodeTeam', 'ShowRanking'], null),
        }
      }
      return (
        <StyledNewPlay theme={process.theme}>
          <PageTitle title={"Нова игра!"}/>
          {this.state.state && this.state.player && !this.state.player.nickname != "" &&
          <StandardSection bgr="#FFF">
            <div class="screen">
              <h2>Моля въведете вашия псевдоним:</h2>
              <form onSubmit={this.setNickname}>              
                <div class="inputs">
                  <div class="row">
                    <input id='nickname' required placeholder="Псевдоним*" onChange={this.handleInputChange}/>
                  </div>
                  <div class='buttons'>
                    <div class="btn_edit">
                      <FixedButton>
                        <StandardButton isSubmit text="Готово" width="100%"/>
                      </FixedButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </StandardSection>
          }
          {this.state.state && this.state.player && this.state.player.nickname != "" && this.state.state.phase == "Start" &&
          <StandardSection bgr="#FFF">
            <div class="gameDescription">{gameDescription}</div>
            <form onSubmit={this.startPlay}>
              <SubTitle text="Списък с играчи"/>
              <div class={["table", "players"].join(' ')}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Играч</TableCell>
                        <TableCell align="center">Онлайн</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                      return (
                      <TableRow>
                        <TableCell align="center">{player.nickname}</TableCell>
                        <TableCell align="center">{(player.isOnline) ? <span class='green'>Онлайн</span> : <span class='red'>Офлайн</span>}</TableCell>
                      </TableRow>
                      );}
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div class='buttons'>
                  <div class="btn_edit">
                    <FixedButton>
                      <StandardButton disabled={this.state.player.hasAgreedToStartPlay} isSubmit text="Готово" width="100%"/>
                    </FixedButton>
                  </div>
                </div>
              </div>
            </form>
          </StandardSection>         
          }
          {this.state.state && this.state.state.phase == "Play" && this.state.state.play.phase == "initialemotions" &&
            <StandardSection>
              <div class="screen">
                <h2>Моля изберете вашата емоция в началото на играта:</h2>
                <div class="emotions">
                  <div class="emotion">
                    <div class="emotionImage"><a onClick={() => this.setInitialEmotion(0)} ><img src={"/images/results/sad.png"} /></a></div>
                    <div class="emotionText">Не се чувствам добре.</div>
                  </div>
                  <div class="emotion">
                    <div class="emotionImage"><a onClick={() => this.setInitialEmotion(1)} ><img src={"/images/results/happy.png"} /></a></div>
                    <div class="emotionText">Чувствам се добре.</div>
                  </div>
                </div>
              </div>
            </StandardSection>
          }
          {this.state.state && this.state.state.phase == "Play" && this.state.state.play.phase == "teamname" &&
            <StandardSection bgr="#FFF">
            <div class="screen">
              <h2>Моля въведете името на вашия отбор:</h2>
              <form onSubmit={this.setTeamName}>              
                <div class="inputs">
                  <div class="row">
                    <input id='teamname' value={this.state.state.team.name} required placeholder="Име на отбора*" onChange={this.onTeamNameChange}/>
                  </div>
                  <div class='buttons'>
                    <div class="btn_edit">
                      <FixedButton>
                        <StandardButton disabled={this.state.player.hasAgreedOnTeamName} isSubmit text="Готово" width="100%"/>
                      </FixedButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </StandardSection>
          }          
          {this.state.state && this.state.state.phase == "Play" && this.state.state.play.phase == "introduction" &&
            <StandardSection bgr="#FFF">
            <div class="screen">
              <h2>Моля запознайте се с инструкции за играта:</h2>
              <div class="gameIntroduction">
                {gameIntroduction}
              </div>
              <form onSubmit={this.setIntroduction}>
                <div class="inputs">
                  <div class='buttons'>
                    <div class="btn_edit">
                      <FixedButton>
                        <StandardButton disabled={this.state.player.hasAgreedOnIntroduction} isSubmit text="Готово" width="100%"/>
                      </FixedButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </StandardSection>
          }
          {this.state.state && this.state.state.phase == "Play" && this.state.state.play.phase == "play" && currentLevel != null &&
            <StandardSection bgr="#FFF">
            <div class="screen">
              <h2>Моля изберете своя отговор:</h2>
              <div class="gameLevel">
                <div class="groupInstruction">{currentLevel.getIn(['GroupInstruction'], null)}</div>
                <div class="groupAnswers">
                  {currentLevel.getIn(['GroupQuestion','Answers'], []).sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((question) => {
                    const levelId = currentLevel.getIn(['Id'], null);
                    const answerId = question.getIn(['Id'], null);
                    const answerValue = question.getIn(['Value'], null);
                    return(
                      <div class="groupAnswer">
                        <FixedButton>
                          <StandardButton disabled={(currentAnswer == answerId) ? true : false} click={() => this.setGroupAnswer(levelId, answerId)} text={answerValue} width="100%"/>
                        </FixedButton>
                      </div>
                    )
                  })
                  }
                </div>
              </div>
            </div>
            <div class="playedTime">Изиграно време: <span>{this.state.playedTime}</span></div>
          </StandardSection>
          }
          {this.state.state && this.state.state.phase == "Play" && this.state.state.play.phase == "finalemotions" &&
            <StandardSection>
              <div class="screen">
                <h2>Моля изберете вашата емоция в края на играта:</h2>
                <div class="emotions">
                  <div class="emotion">
                    <div class="emotionImage"><a onClick={() => this.setFinalEmotion(0)} ><img src={"/images/results/sad.png"} /></a></div>
                    <div class="emotionText">Не се чувствам добре.</div>
                  </div>
                  <div class="emotion">
                    <div class="emotionImage"><a onClick={() => this.setFinalEmotion(1)} ><img src={"/images/results/happy.png"} /></a></div>
                    <div class="emotionText">Чувствам се добре.</div>
                  </div>
                </div>
              </div>
            </StandardSection>
          }
          {this.state.state && this.state.state.phase == "End" && this.state.state.play.phase == "results" && gameResults != null &&
            <div>
              <div class="generalResults">
                <div>Изграни нива: <span>{gameResults.numberOfPlayedLevels}</span></div>
                <div>Точки: <span>{gameResults.teamPoints}</span></div>
                <div>Време за игра: <span>{this.formatPlayedTime()}</span></div>
              </div> 
              {gameResults.playedLevels &&
                gameResults.playedLevels.map((val, idx)=> {
                var details = val;
                var answerId = val.givenGroupAnswer;
                var isMaxPoints = true;
                var levelAsnwers = val.getIn(['GroupQuestion','Answers'], []);
                levelAsnwers.map((levelAnswer) => {
                  if (parseInt(levelAnswer.getIn(['Points'], null)) > val.wonGroupPoints) {
                    isMaxPoints = false;
                  }
                })
                return (
                  <div class="level_component">
                    <Level isPlayedLevel={true} isMaxPoints={isMaxPoints} hasGroupResults={true} gameId={gameId} markAnswerId={answerId} level={details} id={idx} />
                  </div>
                )
              })
            }
            <form onSubmit={this.setResults}>
                <div class="inputs">
                  <div class='buttons'>
                    <div class="btn_edit">
                      <FixedButton>
                        <StandardButton disabled={this.state.player.hasAgreedOnResults} isSubmit text="Готово" width="100%"/>
                      </FixedButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
          {rankings != null &&
          <div>
            <Ranking bgColor="#E8EBE2" title="Класация за всички времена" teams={rankings.allTeams} refreshCallback={this.refreshRankings} />
          </div>
          }
        </StyledNewPlay>
      )
  }
}

NewPlay.propTypes = {
  profile: PropTypes.object,
  game: PropTypes.object,
  team: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    game: getGame(state),
    profile: getProfile(state),
    team: getTeam(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchReadGameByAccessKey: (accessKey) => dispatch(readGameByAccessKey(accessKey)), 
    fetchResetGame: () => dispatch(resetGame()),
    fetchStoreResults: (accessKey, score, teamName, playTime, state, isVirtual) => dispatch(createPlayedGame(accessKey, score, teamName, playTime, state, isVirtual)),
    fetchReadTeamByAccessKey: (accessKey) => dispatch(readTeamByAccessKey(accessKey)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPlay)
