
import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTitle from '../../components/ui/title'
import StandardSection from '../../components/ui/section'
import Content from '../../components/ui/content'

import PlayedGames from '../../components/pages/playedgame/playedgames'

export class PlayedGamesPage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <div>
        <PageTitle title="Списък с всички регистрирани разигравания"/>
        <StandardSection bgr="#FFF">
          <Content>
            <PlayedGames />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

PlayedGamesPage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayedGamesPage)
