import React, { Component, PureComponent}  from 'react'
import { connect } from 'react-redux'
import { readSubscriptionCount } from '../../../state/actions/subscription'
import {  getSubscriptionCount } from '../../../state/selectors/subscription'
import StyledStats from './stats.styled'
import {
  LineChart,
  CartesianGrid,
  XAxis,
  Line,
  Legend
} from "recharts";


export class AdminDashboardPage extends Component {
  constructor(props) {
    super()
    props.fetchSubscriptionCount()
  }

  render() {
      const { subscriptionCount } = this.props
      let count = subscriptionCount.getIn(['SubscriptionCountData', 'Total'], 0);
      let notExpired = subscriptionCount.getIn(['SubscriptionCountData', 'NotExpired'], 0);
      let notExpiredAndPaid = subscriptionCount.getIn(['SubscriptionCountData', 'NotExpiredAndPaid'], 0);
      const subscriptionCountPerMonthList = subscriptionCount.getIn(['SubscriptionCountData', 'SubscriptionCountPerMonthsData', 'SubscriptionCountPerMonthData'], null);
      const dataCount = [];
      if (subscriptionCountPerMonthList) {
            subscriptionCountPerMonthList.sort((a, b) => a.get("Date").localeCompare(b.get("Date"))).map((subscriptionsPerMonth) => {
              let obj = { };
              obj.name = subscriptionsPerMonth.get("Date");
              obj.count = subscriptionsPerMonth.get("Count");
              dataCount.push(obj);
        });
      }
      
        return (
        <StyledStats>
         <div>
          <div class="count">
            <div class="data">
              <div class="item">              
                <div class="number">{count}</div>
                <div class="label">всички абонаменти</div>
              </div>
              <div class="item">
                <div class="number">{notExpired} <span class="percentage">({Math.round((notExpired/count) * 100)}%)</span></div>
                <div class="label">активни абонаменти</div>
              </div>
              <div class="item">
                <div class="number">{notExpiredAndPaid} <span class="percentage">({Math.round((notExpiredAndPaid/notExpired) * 100)}%)</span></div>
                <div class="label">платени абонаменти</div>
              </div>
            </div>
            <div class="chart">
              <LineChart width={1200} height={400} data={dataCount} margin={{ top: 50, right: 50, left: 10, bottom: 0 }} >
                <XAxis dataKey="name" />
                <CartesianGrid stroke="#f5f5f5" />
                <Line isAnimationActive={false} type="monotone" name="Брой нови абонаменти на месец" dataKey="count" stroke="#a62324" yAxisId={0} label={<CustomizedLabel stroke="#a62324" />}  />
                <Legend wrapperStyle={{paddingTop: "30px"}}/>
              </LineChart>
            </div>
          </div>
        </div>
      </StyledStats>
    )
  }
}

class CustomizedLabel extends PureComponent {
  render() {
    const {
      x, y, stroke, value,
    } = this.props;

    return <text x={x} y={y} dy={-15} fill={stroke} fontSize={15} textAnchor="middle">{value}</text>;
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptionCount: getSubscriptionCount(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubscriptionCount: () => dispatch(readSubscriptionCount())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardPage)
