import React from 'react'
import StyledPlayer from './player.styled'
import Unity, { UnityContext } from "react-unity-webgl";
import queryString from 'query-string';
import socketIOClient from "socket.io-client";
import PageVisibility from 'react-page-visibility';

const apiProtocol = "https";
const apiURL = "api.agify.me/platform";
const internalSocketURL = "https://socket.agify.me/socket.io/";
const externalSocketURL = "https://socket.agify.me/";
const socketURL = externalSocketURL;
const isExternalSocket = true;

class Player extends React.Component {

  state = {
    progression : 0,
    isLoaded : false,
    isGameover: false,
    channelPrefix: "",
    accessKey: -1,
    playerId: -1,
    nickname: "",
    isConference: false,
    isVirtual: false,
    actionsExpanded: false,
    autoToggleAudio: false,
    demoPlayers: 0,
  }

  componentWillMount() {
    let url =  window.location.href;
    window.alert = console.log;
    const parsed = queryString.parseUrl(url);
    // Check for access key
    this.accessKey = (parsed.query.key) ? parsed.query.key : -1;
    if (this.accessKey == -1) {
      this.accessKey = (parsed.query.Key) ? parsed.query.Key : -1;
    }
    this.demoPlayers = (parsed.query.players) ? parsed.query.players : 0;
    this.setState({
      accessKey: this.accessKey,
      innerHeight: window.innerHeight,
      demoPlayers: this.demoPlayers,
    })
  }

  componentDidUpdate() {
    if (this.state.teamName != undefined) {
      document.title = this.state.teamName; 
      if (this.state.nickname != "") {
        document.title += " | " + this.state.nickname;
      } else if (this.state.displayName != undefined) {
        document.title += " | " + this.state.displayName;
      }
    }
  }

  constructor(props) {
    super(props);

    this.unityLang = "bg";

    this.unityContext = new UnityContext({
      loaderUrl: "../unity/Build/Build.loader.js",
      dataUrl: "../unity/Build/Build.data",
      frameworkUrl: "../unity/Build/Build.framework.js",
      codeUrl: "../unity/Build/Build.wasm",
      streamingAssetsUrl: "../unity/StreamingAssets/"
    });
    
    this.unityContext.on("progress", progress => {
      this.setState(
        {
          progression : Math.floor(progress * 100),
        }
      );
    });
    
    this.unityContext.on("loaded", () => {
      if (this.props.isHub) {
        setTimeout(function() {
            this.unityContext.send(
              "Web", 
              "StartGameByHub",
              this.unityLang + "|" + apiProtocol + "|" + apiURL + "|" +  this.props.gameId + "|" + localStorage.getItem('token'),
            );
            this.setState(
            {
              isLoaded : true
            }
            );
          }
        .bind(this), 0);
      } if (this.props.isDemo) {
        setTimeout(function() {
            this.unityContext.send(
              "Web", 
              "StartDemo",
              this.unityLang + "|" + this.state.demoPlayers
            );
            this.setState(
            {
              isLoaded : true
            }
            );
          }
        .bind(this), 0);
      } else if (this.state.accessKey != -1 || (this.props.accessKey && this.props.accessKey != "-")) {
        setTimeout(function() {
          var accessKey = (this.state.accessKey != -1) ? this.state.accessKey : this.props.accessKey;
          this.unityContext.send(
            "Web", 
            "StartGameByWeb",
            this.unityLang + "|" + apiProtocol + "|" + apiURL + "|" + socketURL + "|" + isExternalSocket + "|" + accessKey
          );
          this.setState(
            {
              isLoaded : true
            }
          );
        }
      .bind(this), 0);
      } else {
        this.setState(
          {
            isLoaded : true
          });
      } 
    });

    // Init external socket
    this.unityContext.on("InitExternalSocket", params => {
      var paramsAsList = params.split("|");
      // Socket
      var socketURL = paramsAsList[0];
      // Conference
      var isConference = paramsAsList[1] == "True" || paramsAsList[1] == "true" || paramsAsList[1] == "1";
      var conferencePos = paramsAsList[2];
      var confHeight = (conferencePos == "right") ? this.state.innerHeight : "600px";
      var confWidth = (conferencePos == "right") ? "400px" : "100%";
      var confMaxCols = (conferencePos == "right") ? 1 : 20;
      var confLayoutFit = (conferencePos == "right") ? "height" : "both";
      var confFilmStrip = (conferencePos == "right") ? true : true;
      var confToolbar = ['microphone', 'camera', 'closedcaptions', 'fullscreen', 'fodeviceselection', 'chat', 'settings', 'raisehand', 'videoquality', 'filmstrip', 'stats', 'tileview', 'videobackgroundblur'];
      var channel = paramsAsList[3];
      // Player & team names
      var playerNickname = paramsAsList[4];
      var teamName = paramsAsList[5];
      // Initialize socket
      this.socket = socketIOClient(socketURL, {transports: ["websocket"], upgrade: false});
      this.socket.on("connect", this.onExternalSocketConnect);
      this.socket.on('disconnect', this.onExternalSocketDisconnect);
      this.socket.on('connect_error', this.onExternalSocketConnectError);
      this.socket.on('error', this.onExternalSocketError);
      this.socket.on("SetState", this.onExternalSetState);
      // Update state
      return this.setState({
        isConference: isConference,
        conferencePos: conferencePos,
        confHeight: confHeight,
        confWidth: confWidth,
        confMaxCols: confMaxCols,
        confLayoutFit: confLayoutFit,
        confFilmStrip: confFilmStrip,
        confToolbar: confToolbar,
        channelName: channel,
        displayName: playerNickname,
        nickname: playerNickname,
        teamName: teamName,
        isVirtual: true,
      });
    });

    // Get state
    this.unityContext.on("ExternalGetState", params => {
      var paramsAsList = params.split("|");
        var channel = paramsAsList[0];
        var state = paramsAsList[1];
        this.socket.emit('GetState', channel, state);
    });

    // Get state
    this.unityContext.on("ExternalSetState", params => {
      this.socket.emit('SetState', params);
    });
    // Socket
    this.onExternalSocketConnect = this.onExternalSocketConnect.bind(this);
    this.onExternalSocketDisconnect = this.onExternalSocketDisconnect.bind(this);
    this.onExternalSocketConnectError = this.onExternalSocketConnectError.bind(this);
    this.onExternalSocketError = this.onExternalSocketError.bind(this);
    this.onExternalSetState = this.onExternalSetState.bind(this);
  }

  isWebGLAvailable = () => {
    return this.isWebGL1Available() || this.isWebGL2Available();
  }

  isWebGL1Available = () => {
		try {
			var canvas = document.createElement( 'canvas' );
			return !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );
		} catch ( e ) {
			return false;
		}
  }
  
	isWebGL2Available = () => {
		try {
			var canvas = document.createElement( 'canvas' );
			return !! ( window.WebGL2RenderingContext && canvas.getContext( 'webgl2' ) );
		} catch ( e ) {
			return false;
		}
  }

  // Socket -> Unity

  onExternalSocketConnect = () => {
    console.log("[Socket] External socket connected.");
    this.unityContext.send(
      "VirtualGame", 
      "OnExternalSocketConnect",
      ""
    );
  }

  onExternalSocketDisconnect = () => {
    console.log("[Socket] External socket disconnected.");
    this.unityContext.send(
      "VirtualGame", 
      "OnExternalSocketDisonnect",
      ""
    );
  }

  onExternalSocketError = (error) => {
    console.log("[Socket] External socket error: " + error);
    this.unityContext.send(
      "VirtualGame", 
      "OnExternalSocketError",
      error + ""
    );
  }

  onExternalSocketConnectError = (error) => {
    console.log("[Socket] External socket connect error: " + error);
    this.unityContext.send(
      "VirtualGame", 
      "OnExternalSocketError",
      error + ""
    );
  }

  onExternalSetState = (state) => {
    console.log("[Socket] External set state.");
    var stateAsJSON = JSON.parse(state);
    var isHelpRequested = stateAsJSON.helpRequested == "True" || stateAsJSON.helpRequested == "true" || stateAsJSON.helpRequested == "1" || stateAsJSON.helpRequested == true;
    this.unityContext.send(
      "VirtualGame", 
      "OnExternalSetState",
      state
    );
    this.setState(
      {
        isHelpRequested: isHelpRequested,
      }
    );
  }

  render() {
    this.unityLang = "bg-BG";


    return (
      <StyledPlayer>
        <PageVisibility onChange={this.handleVisibilityChange}>
        <div class="player">
          {!this.isWebGLAvailable && <div class="nounity">За съжаление Вашият браузър или устройство <b>не поддържа WebGL</b>, който е нужен, за да играете с Пук.бг онлайн. Опитайте с друг браузър или разгледайте игрите ни офлайн.</div>}
          {this.isWebGLAvailable &&
          <div>
            <div {...(this.state.conferencePos == "right" ? {class: "plot"} : {})}>
              <div {...(this.state.conferencePos == "right" ? {class: "rightunity"} : {class: "topunity"})}>
                {this.state.progression < 100 && <div class="lprogress">{this.state.progression}%</div>}
                {<Unity unityContext={this.unityContext} 
                style={{
                  height: "100%",
                  width: "100%",
                }}
                
                />}
              </div>
            </div>
          </div>
          }
        </div>     
        </PageVisibility>
      </StyledPlayer>
      )
    }
}
export default Player