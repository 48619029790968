import { put, call } from 'redux-saga/effects'
import { createGame, readGame, readGameByAccessKey, deleteGame, duplicateGame, updateGame, deleteLevel, addLevel, updateLevel, readGames, addGameType, deleteGameType, addGameTopic, deleteGameTopic, addGameRole, deleteGameRole, updateGameRole, addGameRoleLevel, deleteGameRoleLevel, updateGameRoleLevel } from '../../server/api'
import * as types from '../actions/types'

export function* createGameSaga({ payload }) {
  try {
    const game = yield call(createGame, payload)
    yield put({ type: types.CREATE_GAME_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.CREATE_GAME_ERROR, error })
  }
}

export function* readGameSaga({ payload }) {
  try {
    const game = yield call(readGame, payload)
    yield put({ type: types.READ_GAME_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.READ_GAME_ERROR, error })
  }
}

export function* readGameByAccessKeySaga({ payload }) {
  try {
    const game = yield call(readGameByAccessKey, payload)
    yield put({ type: types.READ_GAME_BY_ACCESS_KEY_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.READ_GAME_BY_ACCESS_KEY_ERROR, error })
  }
}

export function* deleteGameSaga({ payload }) {
  try {
    const game = yield call(deleteGame, payload)
    yield put({ type: types.DELETE_GAME_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.DELETE_GAME_ERROR, error })
  }
}

export function* duplicateGameSaga({ payload }) {
  try {
    const game = yield call(duplicateGame, payload)
    yield put({ type: types.DUPLICATE_GAME_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.DUPLICATE_GAME_ERROR, error })
  }
}

export function* updateGameSaga({ payload }) {
  try {
    const game = yield call(updateGame, payload)
    yield put({ type: types.UPDATE_GAME_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.UPDATE_GAME_ERROR, error })
  }
}

export function* addLevelSaga({ payload }) {
  try {
    const game = yield call(addLevel, payload)
    yield put({ type: types.ADD_LEVEL_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.ADD_LEVEL_ERROR, error })
  }
}

export function* updateLevelSaga({ payload }) {
  try {
    const game = yield call(updateLevel, payload)
    yield put({ type: types.UPDATE_LEVEL_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.UPDATE_LEVEL_RESPONSE, error })
  }
}

export function* deleteLevelSaga({ payload }) {
  try {
    const game = yield call(deleteLevel, payload)
    yield put({ type: types.DELETE_LEVEL_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.DELETE_LEVEL_ERROR, error })
  }
}

export function* readGamesSaga({ payload }) {
  try {
    const games = yield call(readGames, payload)
    yield put({ type: types.READ_GAMES_RESPONSE, games })    
  } catch (error) {
    yield put({ type: types.READ_GAMES_ERROR, error })
  }
}

export function* addGameTypeSaga({ payload }) {
  try {
    const game = yield call(addGameType, payload)
    yield put({ type: types.ADD_GAME_TYPE_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.ADD_GAME_TYPE_ERROR, error })
  }
}

export function* deleteGameTypeSaga({ payload }) {
  try {
    const game = yield call(deleteGameType, payload)
    yield put({ type: types.DELETE_GAME_TYPE_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.DELETE_GAME_TYPE_ERROR, error })
  }
}

export function* addGameTopicSaga({ payload }) {
  try {
    const game = yield call(addGameTopic, payload)
    yield put({ type: types.ADD_GAME_TOPIC_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.ADD_GAME_TOPIC_ERROR, error })
  }
}

export function* deleteGameTopicSaga({ payload }) {
  try {
    const game = yield call(deleteGameTopic, payload)
    yield put({ type: types.DELETE_GAME_TOPIC_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.DELETE_GAME_TOPIC_ERROR, error })
  }
}

export function* addGameRoleSaga({ payload }) {
  try {
    const game = yield call(addGameRole, payload)
    yield put({ type: types.ADD_GAME_ROLE_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.ADD_GAME_ROLE_ERROR, error })
  }
}

export function* updateGameRoleSaga({ payload }) {
  try {
    const game = yield call(updateGameRole, payload)
    yield put({ type: types.UPDATE_GAME_ROLE_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.UPDATE_GAME_ROLE_ERROR, error })
  }
}

export function* deleteGameRoleSaga({ payload }) {
  try {
    const game = yield call(deleteGameRole, payload)
    yield put({ type: types.DELETE_GAME_ROLE_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.DELETE_GAME_ROLE_ERROR, error })
  }
}

export function* addGameRoleLevelSaga({ payload }) {
  try {
    const game = yield call(addGameRoleLevel, payload)
    yield put({ type: types.ADD_GAME_ROLE_LEVEL_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.ADD_GAME_ROLE_LEVEL_ERROR, error })
  }
}

export function* updateGameRoleLevelSaga({ payload }) {
  try {
    const game = yield call(updateGameRoleLevel, payload)
    yield put({ type: types.UPDATE_GAME_ROLE_LEVEL_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.UPDATE_GAME_ROLE_LEVEL_ERROR, error })
  }
}

export function* deleteGameRoleLevelSaga({ payload }) {
  try {
    const game = yield call(deleteGameRoleLevel, payload)
    yield put({ type: types.DELETE_GAME_ROLE_LEVEL_RESPONSE, game })    
  } catch (error) {
    yield put({ type: types.DELETE_GAME_ROLE_LEVEL_ERROR, error })
  }
}