import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StyledLoginForm from './form.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

export class LoginForm extends Component {

  state = {
    formSelected: 1,
    subscriptionId: localStorage.getItem('subscriptionId'),
    activationCode: localStorage.getItem('activationCode'),
    profileEmail: localStorage.getItem('profileEmail'),
    profilePassword: localStorage.getItem('profilePassword')
  }

  constructor(props) {
    super();
    if (this.state.profileEmail != null && this.state.profileEmail != "") {
      this.state.formSelected = 1;
      var pwd = (this.state.profilePassword != null && this.state.profilePassword.length > 0) ? this.state.profilePassword : "";
      props.submitLoginAsProfile(
        this.state.profileEmail,
        pwd
      )
    }
    
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id

    this.setState({
      [ id ]: value
    });
  }


  schoolProfileSelected = e => {
    this.setState({
      formSelected: 0
    })
  }

  teacherProfileSelected = e => {
    this.setState({
      formSelected: 1
    })
  }

  adminProfileSelected = e => {
    this.setState({
      formSelected: 2
    })
  }

  submitLoginAsSubscription = e => {
    e.preventDefault()
    this.props.submitLoginAsSubscription(
      this.state.subscriptionId,
      this.state.activationCode
    )
  }

  submitLoginAsProfile = e => {
    e.preventDefault()
    this.props.submitLoginAsProfile(
      this.state.profileEmail,
      this.state.profilePassword
    )
  }

  submitLoginAsAdmin = e => {
    e.preventDefault()
    this.props.submitLoginAsAdmin(
      this.state.adminUsername,
      this.state.adminPassword
    )
  }

  render() {
    return (
      <StyledLoginForm>
        <div id="login">
          { this.state.formSelected == 0 ?
          <form onSubmit={this.submitLoginAsSubscription}>
            <h2>Проверете своя абонамент</h2>
            <div class="inputs">
              <div class="row">
                <input tabIndex={1} id='subscriptionId' value={this.state.subscriptionId} required placeholder="Абонаментен номер*" onChange={this.handleInputChange}/>
              </div>
              <div class="row">
                <input tabIndex={2} type='password' value={this.state.activationCode} id='activationCode' required placeholder="Код за активиране*" onChange={this.handleInputChange}/>
              </div>
            </div>
            <div class='required'>* задължително поле</div>
            <div class='submit'>
            <FixedButton>
              <StandardButton isSubmit text="Влезте" width="100%"/>
            </FixedButton>
            </div>
            {(!this.props.isAuthenticated && this.props.isApiError) &&
            <div className='error'>Нещо се обърка. Извинете ни за неудобството. Опитайте отново по-късно.</div>}
            {(!this.props.isAuthenticated && this.props.isAuthError) &&
            <div className='error'>Грешен абонаментен номер или код за активиране.</div>}
          </form>
          : "" }
            
          { this.state.formSelected == 1 ?
          <form onSubmit={this.submitLoginAsProfile}>
            <h2>Влезте във вашия акаунт:</h2>
            <div class="inputs">
              <div class="row">
                <input tabIndex={1} id='profileEmail' value={this.state.profileEmail} required placeholder="Email*" onChange={this.handleInputChange}/>
              </div>
              <div class="row">
                <input tabIndex={2} type='password' value={this.state.profilePassword} id='profilePassword' placeholder="Парола" onChange={this.handleInputChange}/>
              </div>
            </div>
            <div class='required'>* задължително поле</div>
            <div class='submit'>
            <FixedButton>
              <StandardButton isSubmit text="Влезте" width="100%"/>
            </FixedButton>
            </div>
            {(!this.props.isAuthenticated && this.props.isApiError) &&
            <div className='error'>Нещо се обърка. Извинете ни за неудобството. Опитайте отново по-късно.</div>}
            {(!this.props.isAuthenticated && this.props.isAuthError) &&
            <div className='error'>Грешен email или парола.</div>}
          </form>
          : "" }

          { this.state.formSelected == 2 ?
          <form onSubmit={this.submitLoginAsAdmin}>
            <h2>Влезте като администратор:</h2>
            <div class="inputs">
              <div class="row">
                <input tabIndex={1} id='adminUsername' value={this.state.adminUsername} required placeholder="Потребителско име*" onChange={this.handleInputChange}/>
              </div>
              <div class="row">
                <input tabIndex={2} type='password' value={this.state.adminPassword} id='adminPassword' placeholder="Парола*" onChange={this.handleInputChange}/>
              </div>
            </div>
            <div class='required'>* задължително поле</div>
            <div class='submit'>
            <FixedButton>
              <StandardButton isSubmit text="Влезте" width="100%"/>
            </FixedButton>
            </div>
            {(!this.props.isAuthenticated && this.props.isApiError) &&
            <div className='error'>Нещо се обърка. Извинете ни за неудобството. Опитайте отново по-късно.</div>}
            {(!this.props.isAuthenticated && this.props.isAuthError) &&
            <div className='error'>Грешно потребителско име или парола.</div>}            
          </form>
          : "" }
          <div class="links">
            {this.state.formSelected == 1 && <div><a href="/recover">Възстановете паролата си?</a></div>}
            {this.state.formSelected == 1 && <div><a href="/register">Регистрирайте се?</a></div>}
            {/** <div class="change">
              {this.state.formSelected != 0 && <div><a href="#" onClick={() => this.schoolProfileSelected()}>Проверете своя абоанемнт</a></div>}
              {this.state.formSelected != 1 && <div><a href="#" onClick={() => this.teacherProfileSelected()}>Влезте във вашия акаунт</a></div>}
              {this.state.formSelected != 2 && <div><a href="#" onClick={() => this.adminProfileSelected()}>Влезте като администратор</a></div>}
            </div>
            */}
          </div> 
      </div>
      </StyledLoginForm>
    )
  }
}

LoginForm.propTypes = {
  isAuthenticated: PropTypes.bool,
  isAuthError: PropTypes.bool,
  isApiError: PropTypes.bool,
  submitLoginAsSubscription: PropTypes.func,
  submitLoginAsProfile: PropTypes.func,
  submitLoginAsAdmin: PropTypes.func
}

export default LoginForm
