import { takeLatest } from 'redux-saga/effects'
import { readSubscriptionSaga, updateSubscriptionSaga, readSubscriptionCountSaga, readSubscriptionIncomeSaga } from './sagas/subscription'
import { createProfileSaga, readProfileSaga, readProfileGamesSaga, updateProfileSaga, cancelProfileSubscriptionSaga, reactivateProfileSubscriptionSaga, upgradeProfileSubscriptionSaga, repurchaseProfileSubscriptionSaga, forgottenPasswordSaga, createProfileSubscriptionSaga } from './sagas/profile'
import { readAdminSaga } from './sagas/admin'
import { createGameSaga, readGameSaga, readGameByAccessKeySaga, deleteGameSaga, updateGameSaga, addLevelSaga, updateLevelSaga, deleteLevelSaga, readGamesSaga, addGameTypeSaga, deleteGameTypeSaga, addGameRoleSaga, updateGameRoleSaga, deleteGameRoleSaga, duplicateGameSaga, addGameTopicSaga, deleteGameTopicSaga, addGameRoleLevelSaga, updateGameRoleLevelSaga, deleteGameRoleLevelSaga } from './sagas/game'
import { createPlayCodeSaga, readPlayCodeSaga, readLastPlayCodeSaga, readPlayCodesSaga, addPlayCodeTeamSaga, deletePlayCodeTeamSaga } from './sagas/playcode'
import { readPlayedGameSaga, createPlayedGameSaga, readPlayedGamesSaga, readDetailedPlayedGamesSaga } from './sagas/playedgame'
import { readTeamByPublicKeySaga, readTeamByAccessKeySaga } from './sagas/team'
import { sendEmotionFeedbackSaga, sendFullFeedbackSaga } from './sagas/feedback'
import { readReportSaga } from './sagas/report'

import * as types from './actions/types'

export function* watchSubscription() {
  yield takeLatest(types.READ_SUBSCRIPTION_REQUEST, readSubscriptionSaga)
}

export function* watchUpdateSubscription() {
  yield takeLatest(types.UPDATE_SUBSCRIPTION_REQUEST, updateSubscriptionSaga)
}


export function* watchCreateProfile() {
  yield takeLatest(types.CREATE_PROFILE_REQUEST, createProfileSaga)
}

export function* watchProfile() {
  yield takeLatest(types.READ_PROFILE_REQUEST, readProfileSaga)
}

export function* watchUpdateProfile() {
  yield takeLatest(types.UPDATE_PROFILE_REQUEST, updateProfileSaga)
}

export function* watchCancelProfileSubscription() {
  yield takeLatest(types.CANCEL_PROFILE_SUBSCRIPTION_REQUEST, cancelProfileSubscriptionSaga)
}

export function* watchReactivateProfileSubscription() {
  yield takeLatest(types.REACTIVATE_PROFILE_SUBSCRIPTION_REQUEST, reactivateProfileSubscriptionSaga)
}

export function* watchUpgradeProfileSubscription() {
  yield takeLatest(types.UPGRADE_PROFILE_SUBSCRIPTION_REQUEST, upgradeProfileSubscriptionSaga)
}

export function* watchRepurchaseProfileSubscription() {
  yield takeLatest(types.REPURCHASE_PROFILE_SUBSCRIPTION_REQUEST, repurchaseProfileSubscriptionSaga)
}

export function* watchAdmin() {
  yield takeLatest(types.READ_ADMIN_REQUEST, readAdminSaga)
}

export function* watchCreateGame() {
  yield takeLatest(types.CREATE_GAME_REQUEST, createGameSaga)
}

export function* watchProfileGames() {
  yield takeLatest(types.READ_PROFILE_GAMES_REQUEST, readProfileGamesSaga)
}

export function* watchReadGame() {
  yield takeLatest(types.READ_GAME_REQUEST, readGameSaga)
}

export function* watchReadGameByAccessKey() {
  yield takeLatest(types.READ_GAME_BY_ACCESS_KEY_REQUEST, readGameByAccessKeySaga)
}

export function* watchDeleteGame() {
  yield takeLatest(types.DELETE_GAME_REQUEST, deleteGameSaga)
}

export function* watchDuplicateGame() {
  yield takeLatest(types.DUPLICATE_GAME_REQUEST, duplicateGameSaga)
}

export function* watchAddGameType() {
  yield takeLatest(types.ADD_GAME_TYPE_REQUEST, addGameTypeSaga)
}

export function* watchDeleteGameType() {
  yield takeLatest(types.DELETE_GAME_TYPE_REQUEST, deleteGameTypeSaga)
}

export function* watchAddGameTopic() {
  yield takeLatest(types.ADD_GAME_TOPIC_REQUEST, addGameTopicSaga)
}

export function* watchDeleteGameTopic() {
  yield takeLatest(types.DELETE_GAME_TOPIC_REQUEST, deleteGameTopicSaga)
}

export function* watchAddGameRole() {
  yield takeLatest(types.ADD_GAME_ROLE_REQUEST, addGameRoleSaga)
}

export function* watchUpdateGameRole() {
  yield takeLatest(types.UPDATE_GAME_ROLE_REQUEST, updateGameRoleSaga)
}

export function* watchDeleteGameRole() {
  yield takeLatest(types.DELETE_GAME_ROLE_REQUEST, deleteGameRoleSaga)
}

export function* watchUpdateGame() {
  yield takeLatest(types.UPDATE_GAME_REQUEST, updateGameSaga)
}

export function* watchAddLevel() {
  yield takeLatest(types.ADD_LEVEL_REQUEST, addLevelSaga)
}

export function* watchUpdateLevel() {
  yield takeLatest(types.UPDATE_LEVEL_REQUEST, updateLevelSaga)
}

export function* watchDeleteLevel() {
  yield takeLatest(types.DELETE_LEVEL_REQUEST, deleteLevelSaga)
}

export function* watchReadGames() {
  yield takeLatest(types.READ_GAMES_REQUEST, readGamesSaga)
}

export function* watchForgottenPassword() {
  yield takeLatest(types.FORGOTTEN_PASSWORD_REQUEST, forgottenPasswordSaga)
}

export function* watchSubscriptionCount() {
  yield takeLatest(types.READ_SUBSCRIPTION_COUNT_REQUEST, readSubscriptionCountSaga)
}

export function* watchSubscriptionIncome() {
  yield takeLatest(types.READ_SUBSCRIPTION_INCOME_REQUEST, readSubscriptionIncomeSaga)
}

export function* watchCreatePlayCode() {
  yield takeLatest(types.CREATE_PLAYCODE_REQUEST, createPlayCodeSaga)
}

export function* watchReadPlayCode() {
  yield takeLatest(types.READ_PLAYCODE_REQUEST, readPlayCodeSaga)
}

export function* watchReadPlayCodes() {
  yield takeLatest(types.READ_PLAYCODES_REQUEST, readPlayCodesSaga)
}

export function* watchReadLastPlayCode() {
  yield takeLatest(types.READ_LAST_PLAYCODE_REQUEST, readLastPlayCodeSaga)
}

export function* watchAddPlayCodeTeam() {
  yield takeLatest(types.ADD_PLAYCODETEAM_REQUEST, addPlayCodeTeamSaga)
}

export function* watchDeletePlayCodeTeam() {
  yield takeLatest(types.DELETE_PLAYCODETEAM_REQUEST, deletePlayCodeTeamSaga)
}

export function* watchCreateProfileSubscription() {
  yield takeLatest(types.CREATE_PROFILE_SUBSCRIPTION_REQUEST, createProfileSubscriptionSaga)
}

export function* watchReadPlayedGames() {
  yield takeLatest(types.READ_PLAYEDGAMES_REQUEST, readPlayedGamesSaga)
}

export function* watchReadDetailedPlayedGames() {
  yield takeLatest(types.READ_DETAILEDPLAYEDGAMES_REQUEST, readDetailedPlayedGamesSaga)
}

export function* watchCreatePlayedGame() {
  yield takeLatest(types.CREATE_PLAYEDGAME_REQUEST, createPlayedGameSaga)
}

export function* watchReadPlayedGame() {
  yield takeLatest(types.READ_PLAYEDGAME_REQUEST, readPlayedGameSaga)
}

export function* watchReadTeamByPublicKey() {
  yield takeLatest(types.READ_TEAMBY_PUBLICKEY_REQUEST, readTeamByPublicKeySaga)
}

export function* watchReadTeamByAccessKey() {
  yield takeLatest(types.READ_TEAMBY_ACCESSKEY_REQUEST, readTeamByAccessKeySaga)
}

export function* watchAddGameRoleLevel() {
  yield takeLatest(types.ADD_GAME_ROLE_LEVEL_REQUEST, addGameRoleLevelSaga)
}

export function* watchUpdateGameRoleLevel() {
  yield takeLatest(types.UPDATE_GAME_ROLE_LEVEL_REQUEST, updateGameRoleLevelSaga)
}

export function* watchDeleteGameRoleLevel() {
  yield takeLatest(types.DELETE_GAME_ROLE_LEVEL_REQUEST, deleteGameRoleLevelSaga)
}

export function* watchSendEmotionFeedback() {
  yield takeLatest(types.SEND_EMOTION_FEEDBACK_REQUEST, sendEmotionFeedbackSaga)
}

export function* watchSendFullFeedback() {
  yield takeLatest(types.SEND_FULL_FEEDBACK_REQUEST, sendFullFeedbackSaga)
}

export function* watchReadReport() {
  yield takeLatest(types.GET_REPORT_REQUEST, readReportSaga)
}

