import styled from 'styled-components'

const StyledProfiles = styled.section`
.profile {
    padding-top: 40px;
}

.buttons {
    padding-top: 40px;
}

.message {
    padding-bottom: 40px;
}

.associate {
    padding-bottom: 10px;
}

.error {
    text-align: center;
    padding-bottom: 40px;
    color: #a62324;
    font-size: 1.1em;
}
`

export default StyledProfiles
