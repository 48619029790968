import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledRanking from './ranking.styled'
import StandardSection from './section'
import SubTitle from './subtitle'
import StandardButton from './button'
import FixedButton from './fixedbutton'

export class Ranking extends Component {
 
  constructor(props) {
    super()
    
    this.refresh = this.refresh.bind(this);
  }


  refresh = () => {
    this.props.refreshCallback();
  }

  render() {
      const { teams, title } = this.props;
      var bgColor = (this.props.bgColor) ? this.props.bgColor : "#fff";
      return (
        <StyledRanking theme={process.theme}>
         {teams &&
          <StandardSection bgr={bgColor}>
            <SubTitle text={title}/>
            {teams.map((team, index) => {
              var place = team.getIn(['Place'], null);
              var name = team.getIn(['Name'], null);
              var score = team.getIn(['Score'], null);
              var playedTime = team.getIn(['PlayedTime'], null);
              var isWinner = place == 1;
              return (
                <div class="team" {...(isWinner ? {class: ["team", "winner"].join(" ")} : {})}>
                  {isWinner && <div class="winnerplace">{place}</div>}
                  {!isWinner && <div class="teamplace"><div class="teamplacenumber">{place}</div></div>}
                  <div class="teamname" {...(isWinner ? {class: ["teamname", "winnerdesc"].join(" ")} : {})}>
                    <div {...(isWinner ? {class: "winnername"} : {})}>{name}</div>
                    {isWinner &&
                      <div class={["teampoints", "winnerpoints"].join(" ")}>
                        <div>{score >= 0 ? score : "-"}<span class="pointslabel"> резултат</span></div>
                        <div class="teamtime">{playedTime > 0 ? playedTime : "-"} сек. игрално време</div>
                      </div>
                    }
                  </div>
                  {(!isWinner) &&
                    <div class="teampoints">
                      <div>{score >= 0 ? score : "-"}<span class="pointslabel"> резултат</span></div>
                      <div class="teamtime">{playedTime > 0 ? playedTime : "-"} сек. игрално време</div>
                    </div>
                  }
                </div>
              )
            }
            )}
            <div class="refresh">
              <FixedButton>
                <StandardButton click={this.refresh} text="Опресни информацията" width="100%"/>
              </FixedButton>
            </div>
          </StandardSection>
          }
        </StyledRanking>
    )
  }
}

Ranking.propTypes = {
  title: PropTypes.string,
  teams: PropTypes.object,
  refreshCallback: PropTypes.func,
}

const mapStateToProps = (state) => {
  return { 
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Ranking)
