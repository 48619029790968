import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import StyledPlans from './plans.styled'

export class Plans extends Component {

  state = {
    planType: "",
    preselectedPlan: "",
  }

  constructor(props) {
    super();
    
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id

    this.setState({
      planType: value
    });
    this.props.onPlanChanged(value);
  }

  render() {
    var freeacountclasses = "sub freewithaccount column";
    var paidaccountmonthly = "sub paidaccount column";
    var paidaccountyearly = "sub paidaccountyearly column";
    if (this.state.planType == "" && this.props.preselectedPlan) {
      this.state.planType = this.props.preselectedPlan;
    }
    var preselectedPlan = this.props.preselectedPlan;
    var showFreeAccount = true;
    var showMonthlyAccount = true;
    if (this.props.isRepurchase) {
      showFreeAccount = false;
      this.state.planType = "";
      preselectedPlan = "";
    } else if (this.props.isUpgrade) {
      if (this.props.minPlan == "0") {
        showFreeAccount = false;
        this.state.planType = "";
      } else if (this.props.minPlan == "1") {
        showFreeAccount = false;
        showMonthlyAccount = false;
        this.state.planType = "";
      }
    }
    if (this.state.planType == "0") {
      paidaccountmonthly += " grayout";
      paidaccountyearly += " grayout";
    } else if (this.state.planType == "1") {
      freeacountclasses += " grayout";
      paidaccountyearly += " grayout";
    } else if (this.state.planType == "2") {
      freeacountclasses += " grayout";
      paidaccountmonthly += " grayout";
    }

    return (
      <StyledPlans>
      <div class="pricelist">
          <div class="pricetable">
            {showFreeAccount &&
              <div class={freeacountclasses}>
                <div class="height">
                  <div class="title">Безплатен достъп с акаунт</div>
                  <div class="line">Ще можете да разгледате <span>всички игри</span>.</div>          
                  <div class="line">Ще може да играете неограничено само <span>3 игри</span>.</div>
                  <div class="line">Ще може да играете в <span>индивидуален и групов режим</span>.</div>          
                  <div class="line">Ще имате достъп до <span>подробни статистики</span> за всяка проведена игра.</div>
                  <div class="line">Ще имате достъп до <span>история</span> на проведените игри.</div>
                </div>
                <div class={["price"].join(' ')}>
                  <span>0</span> лв.
                </div>
                <div class="notes">Създаването на акаунт не ви обвързва със закупуването на абонамент.</div>
              </div>
            }
            {showMonthlyAccount &&
              <div class={paidaccountmonthly}>
                <div class="title">Месечен платен абонамент</div>
                <div class="line">Ще можете да разгледате <span>всички игри</span>.</div>          
                <div class="line">Ще може да играете неограничено <span>всички игри</span>.</div>
                <div class="line">Ще може да играете в <span>индивидуален и групов режим</span>.</div>          
                <div class="line">Ще имате достъп до <span>подробни статистики</span> за всяка проведена игра.</div>
                <div class="line">Ще имате достъп до <span>история</span> на проведените игри.</div>          
                <div class="line">Ще можете да <span>създавате собствени игри</span>.</div>
                <div class={["price", "paid"].join(' ')}>
                  <div class="promotion">990 лв. на месец</div>
                  <span>290</span> лв. на месец*
                </div>
                <div class="notes">*Плащa се автоматично всеки месец като може да прекратите абонамента по всяко време.</div>
              </div>
            }
            <div  class={paidaccountyearly}>
              <div class="title">Годишен платен абонамент</div>
              <div class="line">Ще можете да разгледате <span>всички игри</span>.</div>          
              <div class="line">Ще може да играете неограничено <span>всички игри</span>.</div>
              <div class="line">Ще може да играете в <span>индивидуален и групов режим</span>.</div>          
              <div class="line">Ще имате достъп до <span>подробни статистики</span> за всяка проведена игра.</div>
              <div class="line">Ще имате достъп до <span>история</span> на проведените игри.</div>          
              <div class="line">Ще можете да <span>създавате собствени игри</span>.</div>
              <div class={["price", "paid"].join(' ')}>
                <div class="promotion">4990 лв. на година</div>
                  <span>2490</span> лв. на година*
              </div>
              <div class="notes">*Плащa се автоматично всяка година като може да прекратите абонамента по всяко време.</div>
            </div>
          </div>
          <div class="radios">
            {showFreeAccount &&
              <div class="radio">
                <input type="radio" name="planType" value="0" onChange={this.handleInputChange} {...(preselectedPlan == "0" ? {defaultChecked : "defaultChecked "} : {})}/>
                <label class="freelabel" for="isConference">Безплатен достъп с акаунт</label>
              </div>
            }
            {showMonthlyAccount &&
              <div class="radio">
                <input type="radio" name="planType" value="1" onChange={this.handleInputChange} {...(preselectedPlan == "1" ? {defaultChecked : "defaultChecked "} : {})}/>
                <label class="monthlypaidlabel" for="isConference">Месечен платен абонамент</label>
              </div>
            }
            <div class="radio">
              <input type="radio" name="planType" value="2" onChange={this.handleInputChange} {...(preselectedPlan == "2" ? {defaultChecked : "defaultChecked "} : {})}/>
              <label class="yearlypaidlabel" for="isConference">Годишен платен абонамент</label>
            </div>
          </div>

        </div>
      </StyledPlans>
    )
  }
}

Plans.propTypes = {
  onPlanChanged: PropTypes.func,
  preselectedPlan: PropTypes.string
}

const mapStateToProps = (state) => {
  return {
    }
}


const mapDispatchToProps = (dispatch) => {
  return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans)
