import { Map } from 'immutable'
import { bool } from 'prop-types';

export default {
  game: Map(),
  profile: Map(),
  subscription: Map(),
  games: Map(),
  admin: Map(),
  subscriptionCount: Map(),
  subscriptionIncome: Map(),
  playCode: Map(),
  playCodes: Map(),
  playedGames: Map(),
  playedGame: Map(),
  detailedPlayedGames: Map(),
  team: Map(),
  feedback: Map(),
  report: Map(),
}
