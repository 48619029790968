
import { REHYDRATE } from 'redux-persist/lib/constants'

export const REDUX_PERSIST_REHYDRATE = REHYDRATE

export const READ_SUBSCRIPTION_REQUEST = 'READ_SUBSCRIPTION_REQUEST'
export const READ_SUBSCRIPTION_RESPONSE = 'READ_SUBSCRIPTION_RESPONSE'
export const READ_SUBSCRIPTION_ERROR = 'READ_SUBSCRIPTION_ERROR'

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST'
export const UPDATE_SUBSCRIPTION_RESPONSE = 'UPDATE_SUBSCRIPTION_RESPONSE'
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR'

export const CREATE_PROFILE_REQUEST = 'CREATE_PROFILE_REQUEST'
export const CREATE_PROFILE_RESPONSE = 'CREATE_PROFILE_RESPONSE'
export const CREATE_PROFILE_ERROR = 'CREATE_PROFILE_ERROR'

export const READ_PROFILE_REQUEST = 'READ_PROFILE_REQUEST'
export const READ_PROFILE_RESPONSE = 'READ_PROFILE_RESPONSE'
export const READ_PROFILE_ERROR = 'READ_PROFILE_ERROR'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_RESPONSE = 'UPDATE_PROFILE_RESPONSE'
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR'

export const CANCEL_PROFILE_SUBSCRIPTION_REQUEST = 'CANCEL_PROFILE_SUBSCRIPTION_REQUEST'
export const CANCEL_PROFILE_SUBSCRIPTION_RESPONSE = 'CANCEL_PROFILE_SUBSCRIPTION_RESPONSE'
export const CANCEL_PROFILE_SUBSCRIPTION_ERROR = 'CANCEL_PROFILE_SUBSCRIPTION_ERROR'

export const REACTIVATE_PROFILE_SUBSCRIPTION_REQUEST = 'REACTIVATE_PROFILE_SUBSCRIPTION_REQUEST'
export const REACTIVATE_PROFILE_SUBSCRIPTION_RESPONSE = 'REACTIVATE_PROFILE_SUBSCRIPTION_RESPONSE'
export const REACTIVATE_PROFILE_SUBSCRIPTION_ERROR = 'REACTIVATE_PROFILE_SUBSCRIPTION_ERROR'

export const UPGRADE_PROFILE_SUBSCRIPTION_REQUEST = 'UPGRADE_PROFILE_SUBSCRIPTION_REQUEST'
export const UPGRADE_PROFILE_SUBSCRIPTION_RESPONSE = 'UPGRADE_PROFILE_SUBSCRIPTION_RESPONSE'
export const UPGRADE_PROFILE_SUBSCRIPTION_ERROR = 'UPGRADE_PROFILE_SUBSCRIPTION_ERROR'

export const REPURCHASE_PROFILE_SUBSCRIPTION_REQUEST = 'REPURCHASE_PROFILE_SUBSCRIPTION_REQUEST'
export const REPURCHASE_PROFILE_SUBSCRIPTION_RESPONSE = 'REPURCHASE_PROFILE_SUBSCRIPTION_RESPONSE'
export const REPURCHASE_PROFILE_SUBSCRIPTION_ERROR = 'REPURCHASE_PROFILE_SUBSCRIPTION_ERROR'

export const READ_ADMIN_REQUEST = 'READ_ADMIN_REQUEST'
export const READ_ADMIN_RESPONSE = 'READ_ADMIN_RESPONSE'
export const READ_ADMIN_ERROR = 'READ_ADMIN_ERROR'

export const CREATE_GAME_REQUEST = 'CREATE_GAME_REQUEST'
export const CREATE_GAME_RESPONSE = 'CREATE_GAME_RESPONSE'
export const CREATE_GAME_ERROR = 'CREATE_GAME_ERROR'

export const READ_PROFILE_GAMES_REQUEST = 'READ_PROFILE_GAMES_REQUEST'
export const READ_PROFILE_GAMES_RESPONSE = 'READ_PROFILE_GAMES_RESPONSE'
export const READ_PROFILE_GAMES_ERROR = 'READ_PROFILE_GAMES_ERROR'

export const READ_GAME_REQUEST = 'READ_GAME_REQUEST'
export const READ_GAME_RESPONSE = 'READ_GAME_RESPONSE'
export const READ_GAME_ERROR = 'READ_GAME_ERROR'

export const READ_GAME_BY_ACCESS_KEY_REQUEST = 'READ_GAME_BY_ACCESS_KEY_REQUEST'
export const READ_GAME_BY_ACCESS_KEY_RESPONSE = 'READ_GAME_BY_ACCESS_KEY_RESPONSE'
export const READ_GAME_BY_ACCESS_KEY_ERROR = 'READ_GAME_BY_ACCESS_KEY_ERROR'

export const UPDATE_GAME_REQUEST = 'UPDATE_GAME_REQUEST'
export const UPDATE_GAME_RESPONSE = 'UPDATE_GAME_RESPONSE'
export const UPDATE_GAME_ERROR = 'UPDATE_GAME_ERROR'

export const DELETE_GAME_REQUEST = 'DELETE_GAME_REQUEST'
export const DELETE_GAME_RESPONSE = 'DELETE_GAME_RESPONSE'
export const DELETE_GAME_ERROR = 'DELETE_GAME_ERROR'

export const DUPLICATE_GAME_REQUEST = 'DUPLICATE_GAME_REQUEST'
export const DUPLICATE_GAME_RESPONSE = 'DUPLICATE_GAME_RESPONSE'
export const DUPLICATE_GAME_ERROR = 'DUPLICATE_GAME_ERROR'

export const RESET_GAME = 'RESET_GAME'

export const ADD_GAME_TOPIC_REQUEST = 'ADD_GAME_TOPIC_REQUEST'
export const ADD_GAME_TOPIC_RESPONSE = 'ADD_GAME_TOPIC_RESPONSE'
export const ADD_GAME_TOPIC_ERROR = 'ADD_GAME_TOPIC_ERROR'

export const DELETE_GAME_TOPIC_REQUEST = 'DELETE_GAME_TOPIC_REQUEST'
export const DELETE_GAME_TOPIC_RESPONSE = 'DELETE_GAME_TOPIC_RESPONSE'
export const DELETE_GAME_TOPIC_ERROR = 'DELETE_GAME_TOPIC_ERROR'

export const ADD_GAME_TYPE_REQUEST = 'ADD_GAME_TYPE_REQUEST'
export const ADD_GAME_TYPE_RESPONSE = 'ADD_GAME_TYPE_RESPONSE'
export const ADD_GAME_TYPE_ERROR = 'ADD_GAME_TYPE_ERROR'

export const DELETE_GAME_TYPE_REQUEST = 'DELETE_GAME_TYPE_REQUEST'
export const DELETE_GAME_TYPE_RESPONSE = 'DELETE_GAME_TYPE_RESPONSE'
export const DELETE_GAME_TYPE_ERROR = 'DELETE_GAME_TYPE_ERROR'

export const ADD_GAME_ROLE_REQUEST = 'ADD_GAME_ROLE_REQUEST'
export const ADD_GAME_ROLE_RESPONSE = 'ADD_GAME_ROLE_RESPONSE'
export const ADD_GAME_ROLE_ERROR = 'ADD_GAME_ROLE_ERROR'

export const UPDATE_GAME_ROLE_REQUEST = 'UPDATE_GAME_ROLE_REQUEST'
export const UPDATE_GAME_ROLE_RESPONSE = 'UPDATE_GAME_ROLE_RESPONSE'
export const UPDATE_GAME_ROLE_ERROR = 'UPDATE_GAME_ROLE_ERROR'

export const DELETE_GAME_ROLE_REQUEST = 'DELETE_GAME_ROLE_REQUEST'
export const DELETE_GAME_ROLE_RESPONSE = 'DELETE_GAME_ROLE_RESPONSE'
export const DELETE_GAME_ROLE_ERROR = 'DELETE_GAME_ROLE_ERROR'

export const ADD_GAME_ROLE_LEVEL_REQUEST = 'ADD_GAME_ROLE_LEVEL_REQUEST'
export const ADD_GAME_ROLE_LEVEL_RESPONSE = 'ADD_GAME_ROLE_LEVEL_RESPONSE'
export const ADD_GAME_ROLE_LEVEL_ERROR = 'ADD_GAME_ROLE_LEVEL_ERROR'

export const UPDATE_GAME_ROLE_LEVEL_REQUEST = 'UPDATE_GAME_ROLE_LEVEL_REQUEST'
export const UPDATE_GAME_ROLE_LEVEL_RESPONSE = 'UPDATE_GAME_ROLE_LEVEL_RESPONSE'
export const UPDATE_GAME_ROLE_LEVEL_ERROR = 'UPDATE_GAME_ROLE_LEVEL_ERROR'

export const DELETE_GAME_ROLE_LEVEL_REQUEST = 'DELETE_GAME_ROLE_LEVEL_REQUEST'
export const DELETE_GAME_ROLE_LEVEL_RESPONSE = 'DELETE_GAME_ROLE_LEVEL_RESPONSE'
export const DELETE_GAME_ROLE_LEVEL_ERROR = 'DELETE_GAME_ROLE_LEVEL_ERROR'

export const ADD_LEVEL_REQUEST = 'ADD_LEVEL_REQUEST'
export const ADD_LEVEL_RESPONSE = 'ADD_LEVEL_RESPONSE'
export const ADD_LEVEL_ERROR = 'ADD_LEVEL_ERROR'

export const UPDATE_LEVEL_REQUEST = 'UPDATE_LEVEL_REQUEST'
export const UPDATE_LEVEL_RESPONSE = 'UPDATE_LEVEL_RESPONSE'
export const UPDATE_LEVEL_ERROR = 'UPDATE_LEVEL_ERROR'

export const DELETE_LEVEL_REQUEST = 'DELETE_LEVEL_REQUEST'
export const DELETE_LEVEL_RESPONSE = 'DELETE_LEVEL_RESPONSE'
export const DELETE_LEVEL_ERROR = 'DELETE_LEVEL_ERROR'

export const READ_GAMES_REQUEST = 'READ_GAMES_REQUEST'
export const READ_GAMES_RESPONSE = 'READ_GAMES_RESPONSE'
export const READ_GAMES_ERROR = 'READ_GAMES_ERROR'

export const FORGOTTEN_PASSWORD_REQUEST = 'FORGOTTEN_PASSWORD_REQUEST'
export const FORGOTTEN_PASSWORD_RESPONSE = 'FORGOTTEN_PASSWORD_RESPONSE'
export const FORGOTTEN_PASSWORD_ERROR = 'FORGOTTEN_PASSWORD_ERROR'

export const READ_SUBSCRIPTION_COUNT_REQUEST = 'READ_SUBSCRIPTION_COUNT_REQUEST'
export const READ_SUBSCRIPTION_COUNT_RESPONSE = 'READ_SUBSCRIPTION_COUNT_RESPONSE'
export const READ_SUBSCRIPTION_COUNT_ERROR = 'READ_SUBSCRIPTION_COUNT_ERROR'

export const READ_SUBSCRIPTION_INCOME_REQUEST = 'READ_SUBSCRIPTION_INCOME_REQUEST'
export const READ_SUBSCRIPTION_INCOME_RESPONSE = 'READ_SUBSCRIPTION_INCOME_RESPONSE'
export const READ_SUBSCRIPTION_INCOME_ERROR = 'READ_SUBSCRIPTION_INCOME_ERROR'

export const CREATE_PLAYCODE_REQUEST = 'CREATE_PLAYCODE_REQUEST'
export const CREATE_PLAYCODE_RESPONSE = 'CREATE_PLAYCODE_RESPONSE'
export const CREATE_PLAYCODE_ERROR = 'CREATE_PLAYCODE_ERROR'

export const READ_PLAYCODE_REQUEST = 'READ_PLAYCODE_REQUEST'
export const READ_PLAYCODE_RESPONSE = 'READ_PLAYCODE_RESPONSE'
export const READ_PLAYCODE_ERROR = 'READ_PLAYCODE_ERROR'

export const READ_PLAYCODES_REQUEST = 'READ_PLAYCODES_REQUEST'
export const READ_PLAYCODES_RESPONSE = 'READ_PLAYCODES_RESPONSE'
export const READ_PLAYCODES_ERROR = 'READ_PLAYCODES_ERROR'

export const CREATE_PROFILE_SUBSCRIPTION_REQUEST = 'CREATE_PROFILE_SUBSCRIPTION_REQUEST'
export const CREATE_PROFILE_SUBSCRIPTION_RESPONSE = 'CREATE_PROFILE_SUBSCRIPTION_RESPONSE'
export const CREATE_PROFILE_SUBSCRIPTION_ERROR = 'CREATE_PROFILE_SUBSCRIPTION_ERROR'

export const RESET_PLAYCODE = 'RESET_PLAYCODE'

export const READ_LAST_PLAYCODE_REQUEST = 'READ_LAST_PLAYCODE_REQUEST'
export const READ_LAST_PLAYCODE_RESPONSE = 'READ_LAST_PLAYCODE_RESPONSE'
export const READ_LAST_PLAYCODE_ERROR = 'READ_LAST_PLAYCODE_ERROR'

export const ADD_PLAYCODETEAM_REQUEST = 'ADD_PLAYCODETEAM_REQUEST'
export const ADD_PLAYCODETEAM_RESPONSE = 'ADD_PLAYCODETEAM_RESPONSE'
export const ADD_PLAYCODETEAM_ERROR = 'ADD_PLAYCODETEAM_ERROR'

export const DELETE_PLAYCODETEAM_REQUEST = 'DELETE_PLAYCODETEAM_REQUEST'
export const DELETE_PLAYCODETEAM_RESPONSE = 'DELETE_PLAYCODETEAM_RESPONSE'
export const DELETE_PLAYCODETEAM_ERROR = 'DELETE_PLAYCODETEAM_ERROR'

export const CREATE_PLAYEDGAME_REQUEST = 'CREATE_PLAYEDGAME_REQUEST'
export const CREATE_PLAYEDGAME_RESPONSE = 'CREATE_PLAYEDGAME_RESPONSE'
export const CREATE_PLAYEDGAME_ERROR = 'CREATE_PLAYEDGAME_ERROR'

export const READ_PLAYEDGAME_REQUEST = 'READ_PLAYGAME_REQUEST'
export const READ_PLAYEDGAME_RESPONSE = 'READ_PLAYGAME_RESPONSE'
export const READ_PLAYEDGAME_ERROR = 'READ_PLAYEDGAME_ERROR'

export const RESET_PLAYEDGAME = 'RESET_PLAYEDGAME'

export const READ_PLAYEDGAMES_REQUEST = 'READ_PLAYGAMES_REQUEST'
export const READ_PLAYEDGAMES_RESPONSE = 'READ_PLAYGAMES_RESPONSE'
export const READ_PLAYEDGAMES_ERROR = 'READ_PLAYEDGAMES_ERROR'

export const READ_DETAILEDPLAYEDGAMES_REQUEST = 'READ_DETAILEDPLAYEDGAMES_REQUEST'
export const READ_DETAILEDPLAYEDGAMES_RESPONSE = 'READ_DETAILEDPLAYEDGAMES_RESPONSE'
export const READ_DETAILEDPLAYEDGAMES_ERROR = 'READ_DETAILEDPLAYEDGAMES_ERROR'

export const RESET_DETAILEDPLAYEDGAMES = 'RESET_DETAILEDPLAYEDGAMES'

export const AUTH_LOGOUT = 'AUTH_LOGOUT'

export const READ_TEAMBY_PUBLICKEY_REQUEST = 'READ_TEAMBY_PUBLICKEY_REQUEST'
export const READ_TEAMBY_PUBLICKEY_RESPONSE = 'READ_TEAMBY_PUBLICKEY_RESPONSE'
export const READ_TEAMBY_PUBLICKEY_ERROR = 'READ_TEAMBY_PUBLICKEY_ERROR'

export const READ_TEAMBY_ACCESSKEY_REQUEST = 'READ_TEAMBY_ACCESSKEY_REQUEST'
export const READ_TEAMBY_ACCESSKEY_RESPONSE = 'READ_TEAMBY_ACCESSKEY_RESPONSE'
export const READ_TEAMBY_ACCESSKEY_ERROR = 'READ_TEAMBY_ACCESSKEY_ERROR'


export const RESET_FEEDBACK = 'RESET_FEEDBACK_REQUEST'

export const SEND_EMOTION_FEEDBACK_REQUEST = 'SEND_EMOTION_FEEDBACK_REQUEST'
export const SEND_EMOTION_FEEDBACK_RESPONSE = 'SEND_EMOTION_FEEDBACK_RESPONSE'
export const SEND_EMOTION_FEEDBACK_ERROR = 'SEND_EMOTION_FEEDBACK_ERROR'

export const SEND_FULL_FEEDBACK_REQUEST = 'SEND_FULL_FEEDBACK_REQUEST'
export const SEND_FULL_FEEDBACK_RESPONSE = 'SEND_FULL_FEEDBACK_RESPONSE'
export const SEND_FULL_FEEDBACK_ERROR = 'SEND_FULL_FEEDBACK_ERROR'

export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST'
export const GET_REPORT_RESPONSE = 'GET_REPORT_RESPONSE'
export const GET_REPORT_ERROR = 'GET_REPORT_ERROR'