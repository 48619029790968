import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTitle from '../../components/ui/title'
import StandardSection from '../../components/ui/section'
import Content from '../../components/ui/content'
import SectionTitle from '../../components/ui/subtitle'
import Subscription from '../../components/pages/subscription/subscription'
import Profiles from '../../components/pages/profile/profiles'

export class ProfilePage extends Component {

render() {
    return (
      <div>
        <PageTitle title="Моят абонамент"/>
        <StandardSection bgr="#FFF">
          <Content>
            <SectionTitle text="Моят абонамент"/>
            <Subscription />
          </Content>
        </StandardSection>
        <StandardSection bgr="#e8e8e8">
          <Content>
            <SectionTitle text="Асоциирани акаунти"/>
            <Profiles />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

ProfilePage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
