import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import StyledRole from './role.styled'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
  } from 'react-accessible-accordion';

export class Role extends Component {
  
    constructor(props) {
      super()
    }

    render() {        
        let {game, role, id} = this.props;
        const name = role.getIn(['Name'], null);
        const description = role.getIn(['Description'], null);
        const toDoList = role.getIn(['ToDoList'], null);
        const openPlanning = role.getIn(['OpenPlanning'], null);
        const closePlanning = role.getIn(['OpenPlanning'], null);
        const tasksInfo = role.getIn(['TasksInfo'], null);
        const reorderTasks = role.getIn(['ReorderTasks'], null);
        const selectTasks = role.getIn(['SelectTasks'], null);
        const estimateTasks = role.getIn(['EstimateTasks'], null);
        const togglePlay = role.getIn(['TogglePlay'], null);
        const introduction = role.getIn(['Introduction'], "");
        const doPlay = role.getIn(['DoPlay'], null);
        const toggleDefaultPlay = role.getIn(['ToggleDefaultPlay'], "");
        return (
            <Accordion allowZeroExpanded allowMultipleExpanded>
                <AccordionItem uuid={this.props.id}>
                    <StyledRole theme={process.theme}>
                    <div class={(this.props.id % 2 == 0) ? "accordion__heading_1" : "accordion__heading_2"}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Роля {name}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                        <div id="info" class="roleinfo">
                            <div class="detail">
                                <div class="title">Име на ролята: </div>
                                <div class="value"><b>{name}</b></div>
                            </div>
                            <div class="detail">
                                <div class="title">Описание на ролята: </div>
                                <div class="value"><b>{description}</b></div>
                            </div>
                            {introduction && introduction != "" &&
                                <div class="detail">
                                    <div class="title">Въведение в началото на играта за тази роля: </div>
                                    <div class="value"><b>{introduction}</b></div>
                                </div>
                            }
                            <div class="detail">
                                <div class="title">Да може да достъпва списъка със задачи: </div>
                                <div class="value"><b>{toDoList ? "Да" : "Не"}</b></div>
                            </div>
                            <div class="detail">
                                <div class="title">Да може да инициира процеса на планиране: </div>
                                <div class="value"><b>{openPlanning ? "Да" : "Не"}</b></div>
                            </div>
                            <div class="detail">
                                <div class="title">Да може да прекратява процеса на планиране: </div>
                                <div class="value"><b>{closePlanning ? "Да" : "Не"}</b></div>
                            </div>
                            <div class="detail">
                                <div class="title">Да достъпва допълнителна информация за задачите: </div>
                                <div class="value"><b>{tasksInfo ? "Да" : "Не"}</b></div>
                            </div>
                            <div class="detail">
                                <div class="title">Да може да променя подредбата на задачите: </div>
                                <div class="value"><b>{reorderTasks ? "Да" : "Не"}</b></div>
                            </div>
                            <div class="detail">
                                <div class="title">Да може да избира задачите, по които да се работи: </div>
                                <div class="value"><b>{selectTasks ? "Да" : "Не"}</b></div>
                            </div>
                            <div class="detail">
                                <div class="title">Да може да оценява сложността на задачите: </div>
                                <div class="value"><b>{estimateTasks ? "Да" : "Не"}</b></div>
                            </div>
                            <div class="detail">
                                <div class="title">Да участва в решаването на задачи: </div>
                                <div class="value"><b>{doPlay ? "Да" : "Не"}</b></div>
                            </div>
                            {doPlay &&
                                <div class="detail">
                                    <div class="title">Да може да се изключва от решаването на задачи: </div>
                                    <div class="value"><b>{togglePlay ? "Да" : "Не"}</b></div>
                                </div>
                            }
                            {doPlay && togglePlay &&
                                <div class="detail">
                                <div class="title">Първоначално да бъде изключен от решаването на задачите: </div>
                                <div class="value"><b>{toggleDefaultPlay ? "Да" : "Не"}</b></div>
                            </div>
                            }
                        </div>
                        </AccordionItemPanel>
                    </div>
                    </StyledRole>
                </AccordionItem>
            </Accordion>
        )
    }
}

Role.propTypes = {
    game: PropTypes.object,
    id: PropTypes.string,
    sequence: PropTypes.string,
    role: PropTypes.object,
  }
  
  const mapStateToProps = (state) => {
    return {
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Role)