import styled from 'styled-components'

const StyledDashboard = styled.section`
font-size: 1.2em;
text-align: center;

.count {
  
  .chart {
    padding: 20px 0 0 0; 
    width: 95%;
    margin: 0 auto;

    
  }

  .data {
    

    .item {
      text-align: center;
      margin: 0 auto;
  
      .number {
        color: ${props => props.theme.defaultColor};
        font-weight: bold;
        font-size: 3.0em;
      }
      
      .percentage {
        font-weight: normal;
        font-size: 0.3em;
      }
  
      .label {
        font-size: 0.8em;
      }
    }

    @media (min-width: 1200px) {
      display: flex;
      justify-content: center;
      align-items: center;      
    }
  }

`

export default StyledDashboard
