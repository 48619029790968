import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import StyledLogo from './logo.styled'


export class Logo extends Component {
  constructor(props) {
    super()
  }

  render() {
  return (
    <StyledLogo>
      <div>
        <Link to={'/'}><img src={"/images/logo.png"} alt={'Хъбът на agify.me'} title={'Хъбът на agify.me'}/></Link>
      </div>
    </StyledLogo>
    )
  }
}

export default Logo
