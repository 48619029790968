import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import StyledRoleLevel from './rolelevel.styled'

export class RoleLevel extends Component {
  
    constructor(props) {
      super()
    }

    render() {        
        let {roleLevel, hasIndividual, hasGroup} = this.props;
        const roleName = roleLevel.getIn(['RoleName'], "");
        const individualOn = roleLevel.getIn(['IndividualOn'], null);
        const individualInstruction = roleLevel.getIn(['IndividualInstruction'], null);
        const groupOn = roleLevel.getIn(['GroupOn'], null);
        const groupInstruction = roleLevel.getIn(['GroupInstruction'], null);
        const introduction = roleLevel.getIn(['Introduction'], "");
        return (
            <StyledRoleLevel>
                <div class="rolelevelinfo">
                    <div class="rolelevel_detail">
                        <div>Име на ролята: </div>
                        <div><b>{roleName}</b></div>
                    </div>
                    {introduction != "" &&
                    <div class="rolelevel_detail">
                        <div><b>Специфични инструкции за тази роля:</b></div>
                        <div>
                            <div><i>{introduction}</i></div>
                        </div>
                    </div>
                    }
                    {hasIndividual &&
                    <div class="rolelevel_detail">
                        <div>{(individualOn) ? <b>Ще участва в индивидуалната задача.</b> : <b>Няма да участва в индивидуалната задача.</b>}</div>
                        {individualOn && individualInstruction &&
                        <div>
                            <div>Специфични инструкции за индивидуалната задача: </div>
                            <div><i>{individualInstruction}</i></div>
                        </div>
                        }
                    </div>
                    }
                    {hasGroup &&
                    <div class="rolelevel_detail">
                        <div>{(groupOn) ? <b>Ще участва в груповата задача.</b> : <b>Няма да участва в груповата задача.</b>}</div>
                        {groupOn && groupInstruction &&
                        <div>
                            <div>Специфични инструкции за груповата задача: </div>
                            <div><i>{groupInstruction}</i></div>
                        </div>
                        }
                    </div>
                    }
                </div>
            </StyledRoleLevel>
        )
    }
}

RoleLevel.propTypes = {
    roleLevel: PropTypes.object,
  }
  
  const mapStateToProps = (state) => {
    return {
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(RoleLevel)