import { put, call } from 'redux-saga/effects'
import { createPlayedGame, readPlayedGame, readPlayedGames, readDetailedPlayedGames } from '../../server/api'
import * as types from '../actions/types'

export function* readPlayedGameSaga({ payload }) {
  try {
    const playedGame = yield call(readPlayedGame, payload)
    yield put({ type: types.READ_PLAYEDGAME_RESPONSE, playedGame })    
  } catch (error) {
    yield put({ type: types.READ_PLAYEDGAME_ERROR, error })
  }
}

export function* createPlayedGameSaga({ payload }) {
  try {
    const playedGame = yield call(createPlayedGame, payload)
    yield put({ type: types.CREATE_PLAYEDGAME_RESPONSE, playedGame })    
  } catch (error) {
    yield put({ type: types.CREATE_PLAYEDGAME_ERROR, error })
  }
}

export function* readPlayedGamesSaga({ payload }) {
  try {
    const playedGames = yield call(readPlayedGames, payload)
    yield put({ type: types.READ_PLAYEDGAMES_RESPONSE, playedGames })    
  } catch (error) {
    yield put({ type: types.READ_PLAYEDGAMES_ERROR, error })
  }
}

export function* readDetailedPlayedGamesSaga({ payload }) {
  try {
    const detailedPlayedGames = yield call(readDetailedPlayedGames, payload)
    yield put({ type: types.READ_DETAILEDPLAYEDGAMES_RESPONSE, detailedPlayedGames })    
  } catch (error) {
    yield put({ type: types.READ_DETAILEDPLAYEDGAMES_ERROR, error })
  }
}


