
import * as types from '../actions/types'
import initial from './initial'
import { setDetailedPlayedGames, setDetailedPlayedGamesError, resetDetailedPlayedGames } from '../selectors/playedgame'

export default function (state = initial.detailedPlayedGames, action) {
  switch (action.type) {
    case types.READ_DETAILEDPLAYEDGAMES_RESPONSE:
      return setDetailedPlayedGames(state, action)
    case types.READ_DETAILEDPLAYEDGAMES_ERROR:
      return setDetailedPlayedGamesError(state, action.error)
    case types.RESET_DETAILEDPLAYEDGAMES:
    return resetDetailedPlayedGames(state)
    default:
      return state
  }
}
