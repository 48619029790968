import styled from 'styled-components'

const StyledTeam = styled.section`

.screen h2 {
    text-align: center;
}

.gameDescription {
    text-align: center;
    font-size: 1.2em;
    margin-bottom: 30px;
}

.gameIntroduction {
    width: 80%;
    margin: 40px auto 0px auto;
    font-size: 1.3em;
}

.emotions {
    display: flex;
    justify-content: center;
    align-items: top;
    margin-top: 50px;
}

.emotion {
    padding: 0px 10px;
}

.emotionImage img {
    width: 100px;
}

.emotionText {
    padding-top: 10px;
    width: 100px;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
}

.playedTime {
    text-align: center;
    font-size: 1.2em;
    margin-top: 40px;
}

.playedTime span {
    font-weight: bold;
}

.generalResults {
    font-size: 1.2em;
    text-align: center;
    margin: 50px auto;
}

.generalResults span {
    font-weight: bold;
}

.gameLevel {
    width: 80%;
    margin: 0 auto;
}

.groupInstruction {
    font-size: 1.3em;
    margin: 40px auto;
}

.groupAnswer {
    margin-bottom: 10px;
}

.buttons {
    margin-top: 50px !important;
}

.note {
    text-align: center;
}

.emphasize {
    background-color: ${props => props.bgrColor};
}

.emphasize .section {
    background-color: ${props => props.bgrColor} !important;
}

.embededTeamName {
    padding-top: 50px;
    font-size: 1.5em;
    text-align: center;
}

.embededTeamName span {
    font-weight: bold;
    color: 000;
}

.embededTeamLink {
    margin-top: 10px;
    text-align: center;
}

.teamActions {
    text-align: center;
    padding-top: 50px;
}

.pause {
    margin-top: 10px;
}

.pause button {
    width: 50% !important;
    margin: 0 auto;
}

.teamActions button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}
.teamActions img {
    width: 100px;
}

.players {
    width: 90%;
    margin: 0 auto;
}

.green {
    color: green;
}

.red {
    color: red;
}

.phase {
    margin: 0 auto;
    width: 90%;
    border-radius: 25px;
    margin-bottom: 50px;
    font-size: 1.8em;
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 10px 50px;
}

.subphase {
    margin: 0 auto;
    width: 90%;
    border-radius: 25px;
    margin-bottom: 50px;
    font-size: 1.5em;
    text-align: center;
    background-color: #fff;
    padding: 10px 50px;

}

  .gameId {
      font-size: 1.5em;
      text-align: center;
      margin-bottom: 50px;
  }

  .levelInfo {
      font-size: 1.2em;
      font-weight: bold;
      margin-bottom: 50px;
      text-align: center;
  }

  .postPlay {
      text-align: center;
      margin-bottom: 50px;
  }

  .gameStats {
      margin-top: 50px;
  } 

  .details {
    font-size: 1.2em;
    margin-top: 20px;
    }

    .detail {
        padding-top: 20px;

        @media print {
            padding-top: 10px;
            display: flex;
            justify-content: center;
            align-items: top;
        }
        
        @media (min-width: 1200px) {
            padding-top: 10px;
            display: flex;
            justify-content: center;
            align-items: top;
        }
    }

    .param {
        color: ${props => props.theme.defaultColor};
        
        @media (min-width: 1200px) {
            width: 400px;
            text-align: right;
        }
    
        @media print {
            width: 400px;
            text-align: right;
        }
    }
    
    .value {
        padding-left: 10px;
        
        @media (min-width: 1200px) {
            width: 400px;
            text-align: left;
        }
    
        @media print {
            width: 400px;
            text-align: left;
        }
    }
`

export default StyledTeam
