import styled from 'styled-components'

const StyledRecover = styled.div`
  
  #recover {
    margin-bottom: 0px;
  }

  form {
    margin-bottom: 50px;
  }

  a {
    font-size: 1.1em;
  }

  .links {
    text-align: center;
  }

  .recover a {
    color: #a62324;
    font-size: 1em;
    padding-top: 20px;
  }
  
`

export default StyledRecover
