import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import StyledLevel from './level.styled'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
  } from 'react-accessible-accordion';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import RoleLevel from './rolelevel'

export class Level extends Component {
  
    constructor(props) {
      super()
    }

    render() {
        let {level, markAnswerId, penalty, hasPlanning, allowReplanning, givenAnswers, orderedItems, isCommited, isDeadlineMissed, distribution, isPlayedLevel, isTimeboxOver, isDone } = this.props;
        const id = (level.getIn(['Id'], null) == null) ? "-" : level.getIn(['Id'], null);
        const resultsType = level.getIn(['ResultsType'], null);
        const gPoints = level.getIn(['GPoints'], null);
        const iPoints = level.getIn(['IPoints'], null);
        const complexity = level.getIn(['Complexity'], null);
        const color = level.getIn(['Color'], null);
        const deadline = level.getIn(['Deadline'], null);
        const deadlinePenalty = level.getIn(['DeadlinePenalty'], null);
        const commitPenalty = level.getIn(['CommitPenalty'], null);
        const activation = level.getIn(['Activation'], null);
        const timebox = level.getIn(['Timebox'], null);
        const description = level.getIn(['Description'], null);
        const individualInstruction = level.getIn(['IndividualInstruction'], null);
        const individualType = level.getIn(['IndividualType'], "");
        const groupInstruction = level.getIn(['GroupInstruction'], null);
        const groupType = level.getIn(['GroupType'], "");
        const groupQuestionAnswers = level.getIn(['GroupQuestion','Answers'], []);
        const individualQuestions = level.getIn(['IndividualQuestions','IndividualQuestion'], []);
        const individualInputs = [];
        if (individualType == "texts") {
            individualQuestions.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx) => {
                const individualQuestionsAnswers = val.getIn(['Answers'], null);
                individualQuestionsAnswers.map((val, idy) => {
                    const answerValue = val.getIn(['Value'], null);
                    individualInputs.push(<div class="answer_detail"><div class="answer_title">Индивидуален текст {idx + 1}:</div><div class="answer_value">{answerValue}</div></div>);     
                });
            });
        } else if (individualType == "singleoption") {
            individualQuestions.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx) => {
                const individualQuestionsAnswers = val.getIn(['Answers'], null);
                individualQuestionsAnswers.map((val, idy) => {
                    const answerValue = val.getIn(['Value'], null);
                    const answerPoints = val.getIn(['Points'], null);
                    const answerPointsClass = (answerPoints > 0) ? "positive_points" : "negative_points"; 
                    individualInputs.push(
                        <div class="answer_detail">
                            <div class="answer_title">Oтговор {idy + 1}: </div>
                            <div class="answer_value">{answerValue}</div>
                            {resultsType != "no" && answerPoints &&
                                <div class="answer_points"><span class={answerPointsClass}>({answerPoints} точки)</span></div>
                            }
                        </div>
                    );     
                });
            });
        }        
        const items = level.getIn(['List','Items'], []);
        let resultsTypeLabel = "";
        if (resultsType == "no") {
            resultsTypeLabel = "Няма да има точкуване";
        } else if (resultsType == "points") {
            resultsTypeLabel = "Спрямо точките, които отговорите носят";
        } else if (resultsType == "sort") {
            resultsTypeLabel = "Спрямо разликата в позицията на елементите в подреден списък";
        }
        
        let headingclass = "";
        let penaltyText = ""
        if (isDeadlineMissed) {
            headingclass = "accordion__heading_penalty_2";
            penaltyText = "Отнети точки за изпуснат краен срок на ниво:"
        } else if (isTimeboxOver) {
            headingclass = "accordion__heading_penalty_2";
            penaltyText = "Отнети точки за изтекъл timebox:"
        } else if (isCommited && !isDone) {
            headingclass = "accordion__heading_penalty_2";
            penaltyText = "Отнети точки за планирано, но неизиграно ниво:"
        } else if (this.props.id % 2 == 0) {
            penalty = 0;
            headingclass = "accordion__heading_1";
        } else {
            penalty = 0;
            headingclass = "accordion__heading_2";
        }

        var levelId = "(" + id;
        if (description != null && description.length > 0) {
            levelId += ": " + description;
        }
        levelId += ")"
        const roleLevels = (level.getIn(['Roles'], null) == null) ? "" : level.getIn(['Roles'], null);
        var hasIndividual = individualType != "none";
        var hasGroup = groupType != "none";
        var preExpanded = [];
        if (this.props.preExpanded) {
            preExpanded = [this.props.id]
        }
        return (
            <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={preExpanded}>
                <AccordionItem uuid={this.props.id} dangerouslySetExpanded={true}>
                    <StyledLevel theme={process.theme}>
                    <div class={headingclass}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            {this.props.isPlayedLevel && ((resultsType != "no" && this.props.isMaxPoints) || (resultsType == "diff" && penalty == 0) || (groupType == "list" && resultsType != "sort")) &&
                                <span>
        Ниво {this.props.id + 1} <span class={["level_id", "green"].join(' ')}>{levelId}</span> {color && <span class="level_color" style={{color: color}}>⬤</span>} {distribution && <span class="distribution">({distribution})</span>}
                                </span>
                            }
                            {this.props.isPlayedLevel && (resultsType == "no" || !this.props.isMaxPoints) && (resultsType != "diff" || penalty > 0) && (groupType != "list" || (groupType == "list" && resultsType == "sort")) && 
                                <span>
        Ниво {this.props.id + 1} <span class={["level_id", "red"].join(' ')}>{levelId}</span> {color && <span class="level_color" style={{color: color}}>⬤</span>} {distribution && <span class="distribution">({distribution})</span>}
                                </span>
                            }
                            {!this.props.isPlayedLevel &&
                            <span>
        Ниво {this.props.id + 1} <span class={["level_id"].join(' ')}>{levelId}</span> {color && <span class="level_color" style={{color: color}}>⬤</span>} {distribution && <span class="distribution">({distribution})</span>}
                            </span>
                            }
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {penalty > 0 &&
                                <div class="level_inputs">
                                 <div>
                                    <div class="level_inputs_title">{penaltyText}</div>
                                    <div key={this.props.id}>
                                        -{penalty}
                                    </div>
                                 </div>
                                </div>
                            }
                            {penalty == 0 &&
                                <div class="level_inputs">
                                    <div class="level_instruction">
                                        <div class="level_inputs_title">Система за точкуване:</div>
                                        <div>
                                            {resultsTypeLabel}
                                        </div>
                                    </div>
                                    {resultsType == "diff" &&
                                        <div>
                                            <div class="level_inputs_title">Точки, които да бъдат спечелени от отбора при преминаване на нивото:</div>
                                            <div>
                                                {gPoints ? gPoints : "-"}
                                            </div>
                                        </div>
                                    }
                                    {resultsType == "diff" &&
                                        <div>
                                            <div class="level_inputs_title">Точки, които да бъдат спечелени/отнети от играча при съвпадение/разминаване с груповия отговор:</div>
                                            <div>
                                                {iPoints ? iPoints : "-"}
                                            </div>
                                        </div>
                                    }
                                    {hasPlanning &&
                                        <div>
                                            <div class="level_inputs_title">Сложност:</div>
                                            <div>
                                                {complexity ? complexity : "-"}
                                            </div>
                                        </div>
                                    }
                                    {hasPlanning && 
                                            <div>
                                            <div class="level_inputs_title">Цвят:</div>
                                            <div>
                                                {color ? color : "-"}
                                            </div>
                                        </div>
                                    }
                                    {hasPlanning && 
                                            <div>
                                            <div class="level_inputs_title">Краен срок (в проценти от общото игрово време):</div>
                                            <div>
                                                {deadline ? deadline : "-"}
                                            </div>
                                        </div>
                                    }
                                    {timebox &&
                                        <div>
                                            <div class="level_inputs_title">Време, за което трябва да се реши нивото (в секунди):</div>
                                            <div>
                                                {timebox}
                                            </div>
                                        </div>
                                    }
                                    {hasPlanning && deadline > 0 &&
                                            <div>
                                            <div class="level_inputs_title">Точки, които да бъдат отнети, ако нивото не бъде завършено навреме:</div>
                                            <div>
                                                {deadlinePenalty ? deadlinePenalty : "-"}
                                            </div>
                                        </div>
                                    }
                                    {hasPlanning && allowReplanning &&
                                            <div>
                                            <div class="level_inputs_title">Кога да стане активно това ниво (в проценти от общото игрово време):</div>
                                            <div>
                                                {activation ? activation : "-"}
                                            </div>
                                        </div>
                                    }
                                    {hasPlanning && commitPenalty > 0 &&
                                            <div>
                                            <div class="level_inputs_title">Точки, които да бъдат отнети, ако нивото се планира, но не се изиграе:</div>
                                            <div>
                                                {commitPenalty ? commitPenalty : "-"}
                                            </div>
                                        </div>
                                    }
                                    {description &&
                                        <div class="level_instruction">
                                            <div class="level_inputs_title">Описание:</div>
                                            <div>
                                                {description}
                                            </div>
                                        </div>
                                    }                                    
                                    {individualType != "none" &&
                                    <div>
                                        {individualInstruction &&
                                        <div class="level_instruction">
                                            <div class="level_inputs_title">Инструкции за индивидуалната задача:</div>
                                            <div>
                                                {individualInstruction}
                                            </div>
                                        </div>
                                        }
                                        {individualType == "texts" &&
                                        <div>
                                            <div class="level_inputs_title">Индивидуални текстове, които ще бъдат разпределени между играчите:</div>
                                            <div key={this.props.id}>
                                            {individualInputs}
                                            </div>
                                        </div>
                                        }
                                        {individualType == "singleoption" &&
                                        <div>
                                            <div class="level_inputs_title">Възможни отговори на задачата, която играчът трябва да реши:</div>
                                            <div key={this.props.id}>
                                            {individualInputs}
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    }
                                    {groupType == "singleoption" &&
                                        <div>
                                            {groupInstruction &&
                                                <div class="level_instruction">
                                                    <div class="level_inputs_title">Инструкции за груповата задача:</div>
                                                    <div>
                                                        {groupInstruction}
                                                    </div>
                                                </div>
                                            }
                                            <div class="level_inputs_title">Възможни отговори на задачата, която групата трябва да реши с пълен консенсус:</div>
                                            {
                                                groupQuestionAnswers.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).sort((a, b) => b.getIn(['IsTrue'], null) - a.getIn(['IsTrue'], null)).map((val, idx)=> {
                                                const answerId = val.getIn(['Id'], null);
                                                const answerValue = val.getIn(['Value'], null);
                                                const answerPoints = val.getIn(['Points'], null);
                                                const answerPointsClass = (answerPoints > 0) ? "positive_points" : "negative_points"; 
                                                return (<div class="answer_detail">
                                                            {answerId == markAnswerId &&
                                                                <div class="answer_title"><b>Посочен отговор ({idx + 1}): </b></div>
                                                            }
                                                            {answerId != markAnswerId &&
                                                                <div class="answer_title">Oтговор {idx + 1}: </div>
                                                            }
                                                            <div class="answer_value">{answerValue}</div>
                                                            {resultsType != "no" && answerPoints &&
                                                                <div class="answer_points"><span class={answerPointsClass}>({answerPoints} точки)</span></div>
                                                            }
                                                        </div>
                                                    )
                                                
                                                })
                                            }
                                        </div>
                                    }
                                    {groupType == "list" && !this.props.isPlayedLevel &&
                                        <div>
                                            {groupInstruction &&
                                                <div class="level_instruction">
                                                    <div class="level_inputs_title">Инструкции за груповата задача:</div>
                                                    <div>
                                                        {groupInstruction}
                                                    </div>
                                                </div>
                                            }
                                            <div class="level_inputs_title">Списък, който групата трябва да подреди:</div>
                                            {
                                                items.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx)=> {
                                                const itemValue = val.getIn(['Value'], null);
                                                const itemColor = val.getIn(['Color'], null);
                                                const itemToBeRank = val.getIn(['ToBeRank'], null);
                                                return (<div class="list_detail" style={{backgroundColor: itemColor}}>
                                                            <div class="list_rank">{idx + 1}. </div>
                                                            <div class="list_value">{itemValue}</div>
                                                            <div class="list_attr">{(itemToBeRank) ? itemToBeRank : "-"}</div>
                                                        </div>
                                                    )                                                
                                                })
                                            }
                                        </div>
                                    }
                                    {roleLevels &&
                                    <div>
                                        <div>
                                            <div class="level_inputs_title">Роли със специфично поведение в нивото:</div>
                                            {
                                                roleLevels.map((val, idx)=> {
                                                return (
                                                    <RoleLevel roleLevel={val} hasIndividual={hasIndividual} hasGroup={hasGroup} />
                                                )
                                                })
                                            }
                                        </div>
                                    </div>
                                    }

                                    {groupType == "list" && this.props.isPlayedLevel &&
                                        <div>
                                            {groupInstruction &&
                                                <div class="level_instruction">
                                                    <div class="level_inputs_title">Инструкции за груповата задача:</div>
                                                    <div>
                                                        {groupInstruction}
                                                    </div>
                                                </div>
                                            }
                                            <div class="items_title">Групова подредба на списъка:</div>
                                            {
                                                orderedItems.sort((a, b) => a.getIn(['FinalRank'], null) - b.getIn(['FinalRank'], null)).map((val, idx)=> {
                                                const itemValue = val.getIn(['Value'], null);
                                                const itemColor = val.getIn(['Color'], null);
                                                const itemToBeRank = val.getIn(['ToBeRank'], null);
                                                const itemFinalRank = val.getIn(['FinalRank'], null);
                                                var rePosition = itemFinalRank - itemToBeRank;
                                                var rePositionClass = (rePosition == 0) ? "reposition_correct" : "reposition_wrong";
                                                var rePositionLabel = (rePosition >= 0) ? "+" + rePosition + " позиции" : rePosition + " позиции";
                                                var itemClasses = [];
                                                if (this.props.id % 2 == 0) {
                                                    if (idx == 0) {
                                                        itemClasses = ["item_detail", "item_color_2", "item_color_2_top"];
                                                    } else {
                                                        itemClasses = ["item_detail", "item_color_2"];
                                                    }                                               
                                                } else {
                                                    if (idx == 0) {
                                                        itemClasses = ["item_detail", "item_color_1", "item_color_1_top"];
                                                    } else {
                                                        itemClasses = ["item_detail", "item_color_1"];
                                                    }
                                                }
                                                return (<div class={itemClasses.join(' ')} style={{backgroundColor: itemColor}}>
                                                            {itemValue} <span class="item_detail_changes">(<span class={rePositionClass}>{rePositionLabel}</span>)</span>
                                                        </div>
                                                    )                                                
                                                })
                                            }
                                        </div>
                                    }
                                    {givenAnswers &&
                                        <div>
                                            <div class="level_inputs_title">Индивидуални отговори:</div>
                                            <div class="table">
                                                <TableContainer>
                                                <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Отговор</TableCell>
                                                        <TableCell align="center">Брой посочвания</TableCell>
                                                        <TableCell align="center">Посочили играчи</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {givenAnswers.sort((a, b) => b.getIn(['Count'], null) - a.getIn(['Count'], null)).map((val, idy)=> {
                                                    var value = val.getIn(["Value"], null);
                                                    var nicknames = val.getIn(["Nicknames"], null);
                                                    var count = val.getIn(["Count"], null);
                                                    return (
                                                        <TableRow>
                                                        <TableCell align="center">{value}</TableCell>
                                                        <TableCell align="center">{count}</TableCell>
                                                        <TableCell align="center">{nicknames}</TableCell>
                                                        </TableRow>
                                                    )})
                                                }     
                                                </TableBody>
                                                </Table>
                                            </TableContainer>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </AccordionItemPanel>
                    </div>
                    </StyledLevel>
                </AccordionItem>
            </Accordion>
        )
    }
}

Level.propTypes = {
    gameId: PropTypes.string,
    id: PropTypes.string,
    sequence: PropTypes.string,
    level: PropTypes.object,
    markAnswerId: PropTypes.string,
    penalty: PropTypes.string,
    hasPlanning: PropTypes.bool,
    allowReplanning: PropTypes.bool,
    givenAnswers: PropTypes.object,
    orderedItems: PropTypes.object,
    isCommited: PropTypes.bool,
    isDeadlineMissed: PropTypes.bool,
    isTimeboxOver: PropTypes.bool,
    isPlayedLevel: PropTypes.bool,
    isDone: PropTypes.bool,
  }
  
  const mapStateToProps = (state) => {
    return {
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Level)