import { put, call } from 'redux-saga/effects'
import { readTeamByPublicKey, readTeamByAccessKey } from '../../server/api'
import * as types from '../actions/types'


export function* readTeamByPublicKeySaga({ payload }) {
  try {
    const team = yield call(readTeamByPublicKey, payload)
    yield put({ type: types.READ_TEAMBY_PUBLICKEY_RESPONSE, team })    
  } catch (error) {
    yield put({ type: types.READ_TEAMBY_PUBLICKEY_ERROR, error })
  }
}

export function* readTeamByAccessKeySaga({ payload }) {
  try {
    const team = yield call(readTeamByAccessKey, payload)
    yield put({ type: types.READ_TEAMBY_ACCESSKEY_RESPONSE, team })    
  } catch (error) {
    yield put({ type: types.READ_TEAMBY_ACCESSKEY_ERROR, error })
  }
}

