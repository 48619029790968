import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTitle from '../../components/ui/title'
import StandardSection from '../../components/ui/section'
import Content from '../../components/ui/content'
import SectionTitle from '../../components/ui/subtitle'

import Subscriptions from '../../components/pages/stats/subscriptions'
import Income from '../../components/pages/stats/income'

export class StatsPage extends Component {

render() {
    return (
      <div>
        <PageTitle title="Статистики"/>
        <StandardSection bgr="#FFF">
          <Content>
            <SectionTitle text="Абонаменти"/>
            <Subscriptions />
          </Content>
        </StandardSection>
        <StandardSection bgr="#e8e8e8">
          <Content>
            <SectionTitle text="Приходи"/>
            <Income />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

StatsPage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsPage)
