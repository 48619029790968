import * as types from '../actions/types'
import initial from './initial'
import { setSubscription, setSubscriptionCount, setSubscriptionIncome, setError } from '../selectors/subscription'

export default function (state = initial.subscription, action) {
  switch (action.type) {
    case types.READ_SUBSCRIPTION_RESPONSE:
    case types.UPDATE_SUBSCRIPTION_RESPONSE:
      return setSubscription(state, action)
    case types.READ_SUBSCRIPTION_COUNT_RESPONSE:
      return setSubscriptionCount(state, action)
    case types.READ_SUBSCRIPTION_INCOME_RESPONSE:
      return setSubscriptionIncome(state, action)
    case types.READ_SUBSCRIPTION_ERROR:
    case types.UPDATE_SUBSCRIPTION_ERROR:
      return setError(state, action.error)
    default:
      return state
  }
}
