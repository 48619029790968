import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageTitle from '../components/ui/title'
import StandardSection from '../components/ui/section'
import Content from '../components/ui/content'
import PlanForm from '../components/pages/profile/plan'

export class RegisterPage extends Component {

render() {
    return (
      <div>
        <PageTitle title="Регистрация на акаунт"/>
        <StandardSection bgr="#FFF">
          <Content>
            <PlanForm isCreateProfile={true}/>
          </Content>
        </StandardSection>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

RegisterPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)
