import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageTitle from '../components/ui/title'
import Team from '../components/pages/team/team'

export class TeamPage extends Component {

render() {
    return (
      <div>
        <Team/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

TeamPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamPage)
