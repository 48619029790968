import styled from 'styled-components'

const StyledEditRole = styled.section`
.addroleform .checkbox {
  width: 95%;
  justify-content: start;
}


.rolestable {
  padding-bottom: 50px;
  width: 90%;
  margin: 0 auto;
}

.addrolebtns {
  padding-bottom: 50px;
  padding-top: 20px;
}

.deleterolebtn {
  padding-top: 10px;
}


`

export default StyledEditRole
