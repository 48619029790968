import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledAnalytics from './analytics.styled'
import queryString from 'query-string'
import PageTitle from '../../ui/title'

import { readReport } from '../../../state/actions/report'
import { getReport } from '../../../state/selectors/report'


export class Report extends Component {

  state = {
    time: "",
    stress: "",
    isChanged: false,
  }
 
  constructor(props) {
    super()
    
    const values = queryString.parse(window.location.search);
    this.key = "";
    if (values.key != null && values.key != "") {
      this.key = values.key;
    }
    if (this.key != "") {
      props.fetchReport(this.key);
    }

    this.texts = new Map();
    this.texts.set(1, {
      title: "AMBITION",
      left: "Too chill",
      leftDesc: "We aim too low, I expect that we are more ambitious.",
      center: "As expected",
      right: "Too strained",
      rightDesc: "We aim too high, I’m afraid of burnout. I’d  expect we were more on the work-life balance side."
    });
    this.texts.set(2, {
      title: "RESULTS",
      left: "Too mediocore",
      leftDesc: "The achieved results are lower than expected.",
      center: "As expected",
      right: "Too superfluous",
      rightDesc: "The achieved results are higher than expected.",
    });
    this.texts.set(3, {
      title: "FOCUS",
      left: "Too loose",
      leftDesc: "Lack of  meeting goals, agenda or/and inappropriate use of time and lack of focus.",
      center: "As expected",
      right: "Too stiff",
      rightDesc: "Too much focus on meeting goals and agenda or efficient time usage prevented us from being flexible enough to cover smaller but valuable “nearby” topics."
    });
  }
  

  render() {
    const { report } = this.props;
    var subject = report.getIn(['Report', 'Event', 'Subject'], null);
    var organization = report.getIn(['Report', 'Event', 'Organization'], null);
    var duration = report.getIn(['Report', 'Event', 'Duration'], null);
    var allParticipants = report.getIn(['Report', 'Event', 'Stats', "AllParticipants"], null);
    var respondedParticipants = report.getIn(['Report', 'Event', 'Stats', "RespondedParticipants"], null);
    var responseRate = report.getIn(['Report', 'Event', 'Stats', "ResponseRate"], null);
    var terribleEmotions = report.getIn(['Report', 'Event', 'Stats', "TerribleEmotions"], null);
    var badEmotions = report.getIn(['Report', 'Event', 'Stats', "BadEmotions"], null);
    var okEmotions = report.getIn(['Report', 'Event', 'Stats', "OkEmotions"], null);
    var goodEmotions = report.getIn(['Report', 'Event', 'Stats', "GoodEmotions"], null);
    var greatEmotions = report.getIn(['Report', 'Event', 'Stats', "GreatEmotions"], null);
    var observations = report.getIn(['Report', 'Event', "Observations"], []);
    return (
        <StyledAnalytics theme={process.theme}>
        <div class="analytics">
          {this.key == "" &&
          <div>Възникна грешка. Моля опитайте да отворите линка от имейла, който сте получили автоматично.</div>
          }
          {this.key != "" && report == null &&
          <div>Формата за обратна връзка се зарежда в момента.</div>
          }
          {report != null &&
          
          <div class="all">
            <div class="header">
              <div class="organization">{organization}</div>
              <div class="subject">{subject} {duration}</div>
            </div>
            <div class="main">
              <div class="summary">
                <div class="summarytitle">Feedback Summary</div>
                <div class="responserate">
                  <div class="rrtitle">Response Rate:</div>
                  <div class="rrvalue"><span>{respondedParticipants}</span> / {allParticipants} - <span>{responseRate}%</span></div>
                </div>
              </div>
              <div class="emotions">
                <div class="emotionstitle">How do you feel about your meeting?</div>
                <div class="emotionsrow">
                  <div class="emotion">
                    <div class={(terribleEmotions > 0) ? "emoicon emoiconselected" : "emoicon"}>😩</div>
                    <div class={(terribleEmotions) ? "emolabel emolabelselected" : "emolabel"}>Terrible ({terribleEmotions})</div>
                  </div>
                  <div class="emotion">
                    <div class={(badEmotions > 0) ? "emoicon emoiconselected" : "emoicon"}>🙁</div>
                    <div class={(badEmotions > 0) ? "emolabel emolabelselected" : "emolabel"}>Bad ({badEmotions})</div>
                  </div>
                  <div class="emotion">
                    <div class={(okEmotions > 0) ? "emoicon emoiconselected" : "emoicon"}>😐</div>
                    <div class={(okEmotions > 0) ? "emolabel emolabelselected" : "emolabel"}>OK ({okEmotions})</div>
                  </div>
                  <div class="emotion ">
                    <div class={(goodEmotions > 0) ? "emoicon emoiconselected" : "emoicon"}>🙂</div>
                    <div class={(goodEmotions > 0) ? "emolabel emolabelselected" : "emolabel"}>Good ({goodEmotions})</div>
                  </div>
                  <div class="emotion">
                    <div class={(greatEmotions > 0) ? "emoicon emoiconselected" : "emoicon"}>😄</div>
                    <div class={(greatEmotions > 0) ? "emolabel emolabelselected" : "emolabel"}>Great ({greatEmotions})</div>
                  </div>
                </div>                
              </div>
              <div class="observations">
                <div class="otitle">Observations summary:</div>
                {observations.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((observation) => {
                  var id = observation.getIn(['Id']);
                  var leftCount = observation.getIn(['LeftCount']);
                  var middleCount = observation.getIn(['MiddleCount']);
                  var rightCount = observation.getIn(['RightCount']);
                  var observationsCount = leftCount + rightCount;
                  var text = this.texts.get(id);
                  var leftCSS = "block";
                  var centerCSS = "block";
                  var rightCSS = "block";
                  if (leftCount > 0) {
                    leftCSS += " selected";
                  }
                  if (rightCount > 0) {
                    rightCSS += " selected";
                  }
                  return (
                    <div class="behavior">
                      <div class="title"><span>{text.title} - {observationsCount} observation findings reported</span></div>
                      <div class="row rowbuttons">
                        <div class={leftCSS}>
                          <div class="blockTitle">{text.left} ({leftCount})</div>
                        </div>
                        <div class={centerCSS}>
                          <div class="blockTitle">{text.center} ({middleCount})</div>
                        </div>
                        <div class={rightCSS}>
                          <div class="blockTitle">{text.right} ({rightCount})</div>
                        </div>
                      </div>
                    </div>
                  );}
                )}
              </div>
              <div class="recommendations">
                <div class="orecommendations">Recommendations:</div> 
                <div class="recommendation">
                    <span>Time Management</span> - Goal Setting | <a href="/game?id=1935">Learn more</a>
                </div>
              </div>
              <div class="underline">
                  <div>Your feedback is required to improve team's health and performance.</div>
                  <div>The feedback will be anonymous.</div>
              </div> 
            </div>
            <div class="powered">
              Powered by <span>agify.me</span>.
            </div>
          </div>
          }
          
        </div>
        </StyledAnalytics>
    )
  }
}

Report.propTypes = {
}

const mapStateToProps = (state) => {
  return {
    report: getReport(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchReport: (key) => dispatch(readReport(key)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Report)
