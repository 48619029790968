import React, { Component } from 'react'
import { connect } from 'react-redux'
import history from '../../../history'

import StyledPlayedGames from './playedgames.styled'
import StandardButton from '../../ui/button'
import FixedButton from '../../ui/fixedbutton'

import {  getProfile } from '../../../state/selectors/profile'
import {  getPlayedGames } from '../../../state/selectors/playedgame'
import { readPlayedGames } from '../../../state/actions/playedgame'

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import {
  isMobile,
} from "react-device-detect";

function sortPlayedGames(a, b, prop, desc) {
  var a_prop = a.get(prop);
  var b_prop = b.get(prop);
  var reverse = 1;
  if (desc) {
    reverse = -1;
  }
  if (a_prop < b_prop) {
    return reverse * -1;
  }
  if (a_prop > b_prop) {
    return reverse * 1;
  }
  return 0;
}
export class PlayedGames extends Component {
  constructor(props) {
    super()
    const { profile } = props
    const profileId = profile.getIn(['Profile', 'Id'], null);
    props.fetchPlayedGames(profileId);
    this.state = {
      sortProp: "CreationDate",
      desc: true,
      rowsPerPage: 5,
      page: 0,
    }
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  onSortingChanged(prop) {
    if (this.state.desc) {
      this.setState({
        desc: false, 
        sortProp: prop,
      });
    } else {
      this.setState({
        desc: true,
        sortProp: prop,
      });
    }
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  goBack = () => {
    history.go(-1);
  }

  render() {

      const { playedGames } = this.props
      const playedGamesList = playedGames.getIn(['playedGames','LightPlayedGames', 'PlayedGames']);
      return (
        <StyledPlayedGames>
         <div id="playedgamelist">
          {(!playedGamesList || playedGamesList.size == 0) &&
            <div class="message">
              Все още нямате регистрирани разигравания.
            </div>
          }
          {(playedGamesList && playedGamesList.size > 0) ?
          <div>
             
            <div class="table">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                      {!isMobile &&
                        <TableCell align="center">
                          <TableSortLabel {...this.state.sortProp == "CreationDate" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("CreationDate")}>Дата</TableSortLabel>
                        </TableCell>
                      }
                      <TableCell align="center">
                        <TableSortLabel {...this.state.sortProp == "TeamMark" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("TeamMark")}>Маркер на отбора</TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel {...this.state.sortProp == "TeamName" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("TeamName")}>Име на отбора</TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel {...this.state.sortProp == "Score" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("Score")}>Резултат</TableSortLabel>
                      </TableCell>
                      {!isMobile &&
                        <TableCell align="center">
                          <TableSortLabel {...this.state.sortProp == "PlayTime" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("PlayTime")}>Игрово време</TableSortLabel>
                        </TableCell>
                      }
                      <TableCell align="center">Действие</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {playedGamesList ?
                      playedGamesList.sort((a, b) => sortPlayedGames(a, b, this.state.sortProp, this.state.desc)).slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((playedGame) => {
                      var playedGameId = playedGame.get('Id');
                      var creationDate = new Date(Date.parse(playedGame.get('CreationDate')));
                      var teamMark = (playedGame.get('TeamMark')) ? playedGame.get('TeamMark') : "-";
                      var teamName = (playedGame.get('TeamName')) ? playedGame.get('TeamName') : "-";
                      return (
                        <TableRow>
                          {!isMobile && <TableCell align="center">{creationDate.toLocaleString()}</TableCell>}
                          <TableCell align="center">{teamMark}</TableCell>
                          <TableCell align="center">{teamName}</TableCell>
                          <TableCell align="center">{(playedGame.get('Score') == "-1") ? "-" : playedGame.get('Score')}</TableCell>
                          {!isMobile && <TableCell align="center">{playedGame.get('PlayTime')} сек.</TableCell>}
                          <TableCell align="center">
                            <FixedButton>
                              <StandardButton text="Разгледайте изиграната игра" width="100%" href={'/playedgame?id=' + playedGameId} />
                            </FixedButton>
                          </TableCell>
                        </TableRow>
                      );
                    }) : '-'
                }           
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination 
                      rowsPerPageOptions={[5, 10, 25]}
                      count={playedGamesList.size}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            </div>
            <div class="back">
              <FixedButton>
                <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
              </FixedButton>
             </div>
          </div>
            : ''}
        </div>
      </StyledPlayedGames>
    )
  }
}

PlayedGames.propTypes = {
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    playedGames: getPlayedGames(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchPlayedGames: (profileId) => dispatch(readPlayedGames(profileId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayedGames)
