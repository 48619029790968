import styled from 'styled-components'

const StyledRegister = styled.div`
  
  #register {
    margin-bottom: 0px;
  }

  .subtitle {
    margin-top: 40px;
  }

  form {
    margin-bottom: 50px;
  }

  form .inputs {
    width: 80%;
  }


  a {
    font-size: 1.1em;
  }

  .links {
    text-align: center;
  }

  .recover a {
    color: #a62324;
    font-size: 1em;
    padding-top: 20px;
  }

  .invoice {
    margin-top: 20px;
  }

  .errorMessage {
    font-size: 1.5em;
  }
  
`

export default StyledRegister
