import React, { Component } from 'react'
import { connect } from 'react-redux'
import Demo from '../../components/pages/demo/demo'

export class DemoPage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <Demo />
    )
  }
}

DemoPage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DemoPage)
