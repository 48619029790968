import { fromJS, Map } from 'immutable'


export function getFeedback(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['feedback'], null)
}

export function setFeedback(state, newState) {
  return state.merge(fromJS(newState.feedback))
}

export function resetFeedback(state) {
  return new Map();
}

export function setError(state, error) {
  return state.merge({
    Error: fromJS(error)
  })
}

