import { put, call } from 'redux-saga/effects'
import { createPlayCode, readPlayCode, readPlayCodes, readLastPlayCode, addPlayCodeTeam, deletePlayCodeTeam, addPlayCodeTeamPlayer, deletePlayCodeTeamPlayer } from '../../server/api'
import * as types from '../actions/types'

export function* createPlayCodeSaga({ payload }) {
  try {
    const playCode = yield call(createPlayCode, payload)
    yield put({ type: types.CREATE_PLAYCODE_RESPONSE, playCode })    
  } catch (error) {
    yield put({ type: types.CREATE_PLAYCODE_ERROR, error })
  }
}

export function* readPlayCodeSaga({ payload }) {
  try {
    const playCode = yield call(readPlayCode, payload)
    yield put({ type: types.READ_PLAYCODE_RESPONSE, playCode })    
  } catch (error) {
    yield put({ type: types.READ_PLAYCODE_ERROR, error })
  }
}

export function* readLastPlayCodeSaga({ payload }) {
  try {
    const playCode = yield call(readLastPlayCode, payload)
    yield put({ type: types.READ_LAST_PLAYCODE_RESPONSE, playCode })    
  } catch (error) {
    yield put({ type: types.READ_LAST_PLAYCODE_ERROR, error })
  }
}

export function* addPlayCodeTeamSaga({ payload }) {
  try {
    const playCode = yield call(addPlayCodeTeam, payload)
    yield put({ type: types.ADD_PLAYCODETEAM_RESPONSE, playCode })    
  } catch (error) {
    yield put({ type: types.ADD_PLAYCODETEAM_ERROR, error })
  }
}

export function* deletePlayCodeTeamSaga({ payload }) {
  try {
    const playCode = yield call(deletePlayCodeTeam, payload)
    yield put({ type: types.DELETE_PLAYCODETEAM_RESPONSE, playCode })    
  } catch (error) {
    yield put({ type: types.DELETE_PLAYCODETEAM_ERROR, error })
  }
}

export function* readPlayCodesSaga({ payload }) {
  try {
    const playCodes = yield call(readPlayCodes, payload)
    yield put({ type: types.READ_PLAYCODES_RESPONSE, playCodes })    
  } catch (error) {
    yield put({ type: types.READ_PLAYCODES_ERROR, error })
  }
}

