import styled from 'styled-components'

const StyledCreatePlayCode = styled.section`
.back {
    padding-top: 10px;
}

.game {
    font-size: 1.2em;
    text-align: center;
    padding-bottom: 40px;
}


.confpositions {
    @media (min-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.confposition {
    
    @media (min-width: 1200px) {
        width: 400px;
        padding: 0 20px;
    }
}

.confimg {
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 20px;

    @media (min-width: 1200px) {
        width: 100%;
    }
}

.parameterstitle {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 1.2em;
  font-weight: bold;
  color: 000;
}

.details {
    margin-bottom: 40px;
}

`

export default StyledCreatePlayCode
