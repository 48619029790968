import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledDemo from './demo.styled'
import queryString from 'query-string';

import Tablet from '../../ui/tablet'
import Player from '../../ui/player'

export class Demo extends Component {
  
  constructor(props) {
    super()
  }

  componentWillMount() {
    let url =  window.location.href;
    const parsed = queryString.parseUrl(url);
    // Check for access key
    this.bgrcolor = (parsed.query.bgrcolor) ? "#" + parsed.query.bgrcolor : "#ffffff";
    this.accesskey = (parsed.query.accesskey) ? parsed.query.accesskey : "-";
    this.isDemo = this.accesskey == "-";
    // Check if demo
    this.setState({
      bgrcolor: this.bgrcolor,
    })
  }

  render() {
      
      return (
        <StyledDemo bgrcolor={this.state.bgrcolor}>
            <Tablet>
              <Player allowFullScreen isDemo={this.isDemo} accessKey={this.accesskey} />
            </Tablet>
        </StyledDemo>
    )
  }
}

Demo.propTypes = {
  isGoBack: PropTypes.bool,
  profile: PropTypes.object,
  playCode: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Demo)
