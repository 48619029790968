import { put, call } from 'redux-saga/effects'
import { readReport } from '../../server/api'
import * as types from '../actions/types'


export function* readReportSaga({ payload }) {
  try {
    const report = yield call(readReport, payload)
    yield put({ type: types.GET_REPORT_RESPONSE, report })    
  } catch (error) {
    yield put({ type: types.GET_REPORT_ERROR, error })
  }
}

