import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import history from '../../../history'
import { Map } from 'immutable'

import StyledEditGame from './game.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'
import Theme from '../../ui/theme'

import {  getProfile } from '../../../state/selectors/profile'
import {  getGame } from '../../../state/selectors/game'

import { createGame, updateGame, readGame, resetGame, addGameType, deleteGameType, addGameTopic, deleteGameTopic } from '../../../state/actions/game'

import Tablet from '../../ui/tablet'
import Player from '../../ui/player'
import EditLevel from './level'
import EditRole from './role'

import queryString from 'query-string'
import { _ } from 'core-js';

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


export class EditGame extends Component {
  
  constructor(props) {
    super()

    // reset game
    props.fetchResetGame();

    const values = queryString.parse(window.location.search);
    var gameId = "";
    if (values.gameId != null && values.gameId != "") {
      gameId = values.gameId;
    } else if (values.gameid != null && values.gameid != "") {
      gameId = values.gameid;
    } else if (values.id != null && values.id != "") {
      gameId = values.id;
    }
    if (gameId != "") {
      this.state = {
        isUpdate: true,
        isChanged: false,
        newLevels: [],
        newRoles: [],
        hasPlanning: false,
        hasPostPlayActivity: false,
        allowReplanning: false,
        gameId: gameId,
        addType: false,
        addTopic: false,
        theme: "",
        lang: "",
      }
      // fetch game data
      props.fetchReadGame(gameId);
    } else {
      this.state = {
        isUpdate: false,
        isChanged: false,
        newLevels: [],
        newRoles: [],
        hasPlanning: false,
        hasPostPlayActivity: false,
        allowReplanning: false,
        gameId: gameId,
        addType: false,
        addTopic: false,
        theme: "",
        lang: "",
      }
    }

    this.clearChanges = this.clearChanges.bind(this);
    this.deleteNewLevel = this.deleteNewLevel.bind(this);
    this.addNewLevel = this.addNewLevel.bind(this);
    this.updateLevel = this.updateLevel.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submitGameDetails = this.submitGameDetails.bind(this);
    // Types
    this.addNewType = this.addNewType.bind(this);
    this.submitAddType = this.submitAddType.bind(this);
    this.closeAddType = this.closeAddType.bind(this);
    this.deleteType = this.deleteType.bind(this);
    // Topics
    this.addNewTopic = this.addNewTopic.bind(this);
    this.submitAddTopic = this.submitAddTopic.bind(this);
    this.closeAddTopic = this.closeAddTopic.bind(this);
    this.deleteTopic = this.deleteTopic.bind(this); 
    // Roles
    this.addNewRole = this.addNewRole.bind(this);  
    this.deleteNewRole = this.deleteNewRole.bind(this); 
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    
   this.setState({
      [ id ]: value,
      isChanged: true
    });
  }
  
  clearChanges = () => {
    this.setState(state => ({
      isChanged: false
    }));
  }

  submitGameDetails = e => {
    e.preventDefault()    
    const { profile, game } = this.props
    let gameAuthor = this.state.author;
    if (!gameAuthor) {
      gameAuthor = (game.getIn(['Game', 'Author'], null) == null) ? "" : game.getIn(['Game', 'Author'], null);
      if (!gameAuthor) {
        gameAuthor = profile.getIn(['Profile', 'FirstName'], null) + " " + profile.getIn(['Profile', 'SecondName'], null);
      }
    }
    let gameOrganization = this.state.organization;
    if (!gameOrganization) {
      gameOrganization = (game.getIn(['Game', 'Organization'], null) == null) ? "" : game.getIn(['Game', 'Organization'], null);
      if (!gameOrganization) {
        gameOrganization = profile.getIn(['Profile', 'Organization'], null);
      }
    }    
    if (!this.state.isUpdate) {      
      const profileId = profile.getIn(['Profile', 'Id'], null);
      let theme = this.state.theme;
      if (!theme) {
        theme = "0";
      }
      this.props.fetchCreateGame(profileId, this.state.title, this.state.description, this.state.subject, this.state.complexity, this.state.individualInstruction, this.state.groupInstruction, gameAuthor, gameOrganization, this.state.access, theme, this.state.introduction, this.state.hasPlanning, this.state.allowReplanning, this.state.platformType, this.state.lang, this.state.hasPostPlayActivity, this.state.postPlayActivityInstruction);
    } else {
      const { game } = this.props
      const gameId = game.getIn(['Game', 'Id'], null);
      this.props.fetchUpdateGame(gameId, this.state.title, this.state.description, this.state.subject, this.state.complexity, this.state.individualInstruction, this.state.groupInstruction, gameAuthor, gameOrganization, this.state.access, this.state.theme, this.state.introduction, this.state.hasPlanning, this.state.allowReplanning, this.state.platformType, this.state.lang, this.state.hasPostPlayActivity, this.state.postPlayActivityInstruction);
    }
    this.setState({
      isChanged: false,
      isUpdate: true,
    })
  }

  deleteNewLevel(id, sequence) {
    var array = [...this.state.newLevels]; // make a separate copy of the array
    array.splice(sequence, 1);
    this.setState({newLevels: array, updateLevel: true});
  }

  addNewLevel = (e) => {
    this.setState((prevState) => ({
      newLevels: [...prevState.newLevels, {}],
    }));
  }

  updateLevel() {
    this.setState({updateLevel: true});
  }

  goBack = () => {
    history.go(-1);
  }

  // Topics
  addNewTopic = (e) => {
    this.setState(() => ({
      addTopic: true,
    }));
  }

  submitAddTopic  = e => {
    e.preventDefault();
    const { game } = this.props;
    const gameId = game.getIn(['Game', 'Id'], null);
    this.props.fetchAddGameTopic(gameId, this.state.topicName);
    this.setState(() => ({
      addTopic: false,
    }));
  }

  closeAddTopic = (e) => {
    this.setState(() => ({
      addTopic: false,
    }));
  }

  deleteTopic = (id) => {
    this.props.fetchDeleteGameTopic(id);
  }

  // Types
  addNewType = (e) => {
    this.setState(() => ({
      addType: true,
    }));
  }

  submitAddType  = e => {
    e.preventDefault();
    const { game } = this.props;
    const gameId = game.getIn(['Game', 'Id'], null);
    this.props.fetchAddGameType(gameId, this.state.typeName);
    this.setState(() => ({
      addType: false,
    }));
  }

  closeAddType = (e) => {
    this.setState(() => ({
      addType: false,
    }));
  }

  deleteType = (id) => {
    this.props.fetchDeleteGameType(id);
  }

  // Roles
  addNewRole = (e) => {
    this.setState((prevState) => ({
      newRoles: [...prevState.newRoles, {}],
    }));
  }

  deleteNewRole(id, sequence) {
    var array = [...this.state.newRoles]; // make a separate copy of the array
    array.splice(sequence, 1);
    this.setState({newRoles: array});
  }

  render() {
      const { game, profile } = this.props;
      const gameId = game.getIn(['Game', 'Id'], null);
      let resetingGame = this.state.gameId != "" && this.state.gameId != gameId;
      let { newLevels, newRoles } = this.state;
      const lang = (game.getIn(['Game', 'Lang'], null) == null) ? "" : game.getIn(['Game', 'Lang'], null);
      if (this.state.lang == "") {
        this.state.lang = lang;
      }
      const title = (game.getIn(['Game', 'Title'], null) == null) ? "" : game.getIn(['Game', 'Title'], null);
      const description = (game.getIn(['Game', 'GameDescription'], null) == null) ? "" : game.getIn(['Game', 'GameDescription'], null);
      const subject = (game.getIn(['Game', 'Subject'], null) == null) ? "" : game.getIn(['Game', 'Subject'], null);
      const complexity = (game.getIn(['Game', 'Complexity'], null) == null) ? "" : game.getIn(['Game', 'Complexity'], null);
      const introduction = (game.getIn(['Game', 'Introduction'], null) == null) ? "" : game.getIn(['Game', 'Introduction'], null);
      const individualInstruction = (game.getIn(['Game', 'IndividualInstruction'], null) == null) ? "" : game.getIn(['Game', 'IndividualInstruction'], null);
      const groupInstruction = (game.getIn(['Game', 'GroupInstruction'], null) == null) ? "" : game.getIn(['Game', 'GroupInstruction'], null);
      const access = (game.getIn(['Game', 'Access'], null) == null) ? "" : game.getIn(['Game', 'Access'], null);
      var types = (game.getIn(['Game', 'Types'], null) == null) ? "" : game.getIn(['Game', 'Types'], null);
      var topics = (game.getIn(['Game', 'Topics'], null) == null) ? "" : game.getIn(['Game', 'Topics'], null);
      var roles = (game.getIn(['Game', 'Roles'], null) == null) ? "" : game.getIn(['Game', 'Roles'], null);
      var hasPostPlayActivity = (game.getIn(['Game', 'HasPostPlayActivity'], null) == null) ? false : game.getIn(['Game', 'HasPostPlayActivity'], null);
      if (!this.state.isChanged) {
        this.state.hasPostPlayActivity = hasPostPlayActivity;
      }
      const postPlayActivityInstruction = (game.getIn(['Game', 'PostPlayActivityInstruction'], null) == null) ? "" : game.getIn(['Game', 'PostPlayActivityInstruction'], null);
      var hasPlanning = (game.getIn(['Game', 'HasPlanning'], null) == null) ? false : game.getIn(['Game', 'HasPlanning'], null);
      var allowReplanning = (game.getIn(['Game', 'AllowReplanning'], null) == null) ? false : game.getIn(['Game', 'AllowReplanning'], null);
      if (!this.state.isChanged) {
        this.state.hasPlanning = hasPlanning;
      }
      if (!this.state.isChanged) {
        this.state.allowReplanning = allowReplanning;
      }
      const platformType = (game.getIn(['Game', 'PlatformType'], null) == null) ? "" : game.getIn(['Game', 'PlatformType'], null);
      if (this.state.platformType == "") {
        this.state.platformType = platformType;
      }
      let author = (game.getIn(['Game', 'Author'], null) == null) ? "" : game.getIn(['Game', 'Author'], null);
      if (!author) {
        author = profile.getIn(['Profile', 'FirstName'], null) + " " + profile.getIn(['Profile', 'SecondName'], null);
      }
      let organization = (game.getIn(['Game', 'Organization'], null) == null) ? "" : game.getIn(['Game', 'Organization'], null);
      if (!organization) {
        organization = profile.getIn(['Profile', 'Organization'], null);
      }
      let theme = (game.getIn(['Game', 'Theme'], null) == null) ? "0" : game.getIn(['Game', 'Theme'], null) + "";
      if (theme != "0" && this.state.theme == "") {
        this.state.theme = theme;
      }
      const gameProfileId = (game.getIn(['Game', 'Profile', 'Id'], null) == null) ? null : game.getIn(['Game', 'Profile', 'Id'], null);
      let levels = (game.getIn(['Game', 'Levels', 'Level'], null) == null) ? "" : game.getIn(['Game', 'Levels', 'Level'], null);
      if (!levels) {
        levels = new Map();
      }
      const profileId = profile.getIn(['Profile', 'Id'], null);
      const canPublish = profile.getIn(['Profile', 'CanPublish'], null);
      let notOwned = (this.state.isUpdate && profileId != gameProfileId);
      let unity = "";
      if (this.state.isUpdate && !this.state.isChanged) {
        unity = <Tablet><Player isHub gameId={gameId} date={new Date()} /></Tablet>
      }
      if (this.state.updateLevel) {
        unity = ""
        this.state.updateLevel = false;
      }
      return (
        <StyledEditGame>
         {resetingGame &&
          <div class="resetinggame">
            Играта се зарежда в момента. Моля, изчакайте.
          </div>
         }
         {!resetingGame &&
         <div id="editgame">
          <div class="details">
            <form onSubmit={this.submitGameDetails}>
              <h2>Моля въведете детайли на играта:</h2>
              <div class="inputs">
                <div class="row">
                    <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: this.state.lang} : {})} required id='lang' onChange={this.handleInputChange}>
                        <option value="" disabled selected>Изберете език на играта*</option>
                        <option value="Български">Български</option>
                        <option value="English">English</option>
                    </select>
                </div>
                <div class="row">
                  <input {...notOwned ? {disabled:"true"} : {}} id='title' {...(!this.state.isChanged ? {value: title} : {})} required placeholder="Заглавие*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <textarea {...notOwned ? {disabled:"true"} : {}}  id='description' {...(!this.state.isChanged ? {value: description} : {})} required placeholder="Кратко описание*" onChange={this.handleInputChange}></textarea>
                </div>
                <div class="row">
                  <input {...notOwned ? {disabled:"true"} : {}}  id='subject' {...(!this.state.isChanged ? {value: subject} : {})} required placeholder={process.theme.subject + "*"} onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                    <textarea {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: individualInstruction} : {})} id='individualInstruction' placeholder="Инструкции по подразбиране за индивидуалните задачи" onChange={this.handleInputChange}></textarea>
                </div>
                <div class="row">
                    <textarea {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: groupInstruction} : {})} id='groupInstruction' placeholder="Инструкции по подразбиране за груповите задачи" onChange={this.handleInputChange}></textarea>
                </div>
                <div class="row">
                  <textarea rows="7" {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: introduction} : {})} id='introduction' placeholder="Въведение в началото на играта" onChange={this.handleInputChange}></textarea>
                </div>
                <div class="row">
                  <div class="checkbox">
                    <input type="checkbox" {...(this.state.hasPostPlayActivity ? {checked: "checked"} : {})} id="hasPostPlayActivity" name="hasPostPlayActivity" onChange={this.handleInputChange}/>
                    <label for="hasPostPlayActivity">Ще има активност след края на играта</label>
                  </div>
                </div>
                {this.state.hasPostPlayActivity &&
                  <div class="row">
                      <textarea {...notOwned ? {disabled:"true"} : {}} required {...(!this.state.isChanged ? {value: postPlayActivityInstruction} : {})} id='postPlayActivityInstruction' placeholder="Инструкции за активността след края на играта*" onChange={this.handleInputChange}></textarea>
                  </div>
                }
                <div class="row">
                  <div class="checkbox">
                    <input type="checkbox" {...(this.state.hasPlanning ? {checked: "checked"} : {})} id="hasPlanning" name="hasPlanning" onChange={this.handleInputChange}/>
                    <label for="hasPlanning">В играта ще има етап на планиране</label>
                  </div>
                </div>
                {this.state.hasPlanning &&
                  <div class="row">
                    <div class="checkbox">
                      <input type="checkbox" {...(this.state.allowReplanning ? {checked: "checked"} : {})} id="allowReplanning" name="allowReplanning" onChange={this.handleInputChange}/>
                      <label for="allowReplanning">Да бъде позволено репланиране</label>
                    </div>
                  </div>
                }
                <div class="row">
                  <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: complexity} : {})} id='complexity' required onChange={this.handleInputChange}>
                    <option value="" disabled selected>Изберете трудност*</option>
                    <option value="1">Много лесно</option>
                    <option value="2">Лесно</option>
                    <option value="3">Трудно</option>
                    <option value="4">Много трудно</option>
                  </select>
                </div>
                <div class="row">
                  <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: theme} : {})} id='theme' required onChange={this.handleInputChange}>
                    <option value="" disabled>Изберете дали да има задължителна тема</option>
                    <option value="0" selected>Темата ще се избира при генериране на кода за игра</option>
                    {process.theme.themes}
                  </select>
                </div>
                {this.state.theme && this.state.theme != "" && this.state.theme != "0" && this.state.theme.length > 0 &&
                  <div class="row">
                    <Theme id={this.state.theme} />
                  </div>
                }
                <div class="row">
                  <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: platformType} : {})} id='platformType' required onChange={this.handleInputChange}>
                    <option value="" disabled selected>Изберете средата, в която ще може да се използва играта*</option>
                    <option value="all">На общо или на отделни устройства</option>
                    <option value="physical">На общо устройство</option>
                    <option value="virtual">На отделни устройства</option>
                  </select>
                </div>  
                <div class="row">
                  <select {...notOwned ? {disabled:"true"} : {}}  {...(!this.state.isChanged ? {value: access} : {})} id='access' required onChange={this.handleInputChange}>
                    <option value="" disabled selected>Изберете достъп до играта*</option>
                    <option value="1">Достъп само чрез моя акаунт</option>
                    <option value="2">Достъп с код на играта</option>
                    {canPublish &&
                      <option value="3">Свободен достъп</option>
                    }
                  </select>
                </div>   
                <div class="row">
                  <input {...notOwned ? {disabled:"true"} : {}} id='author' {...(!this.state.isChanged ? {value: author} : {})} required placeholder="Автор*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input {...notOwned ? {disabled:"true"} : {}} id='organization' {...(!this.state.isChanged ? {value: organization} : {})} required placeholder="Организация*" onChange={this.handleInputChange}/>
                </div>
              </div>
              <div class='required'>* задължително поле</div>
              <div class='buttons'>
                  <div class="btn_edit">
                    <FixedButton>
                      <StandardButton disabled={notOwned || (this.state.isUpdate && !this.state.isChanged)} isSubmit text={(!this.state.isUpdate) ? "Създайте" : "Актуализирайте"} width="100%"/>
                    </FixedButton>
                  </div>
                  {this.state.isUpdate &&
                    <div class="btn_cancel">
                      <FixedButton>
                        <StandardButton disabled={!this.state.isChanged} click={this.clearChanges} text="Анулирайте промените" width="100%" type="cancel"/>
                      </FixedButton>
                    </div>
                  }
                  {this.state.isUpdate &&
                    <div class="btn_delete">
                      <FixedButton>
                        <StandardButton disabled={notOwned} href={'/delete?gameId=' + gameId}  text="Изтрийте" width="100%" type="delete"/>
                      </FixedButton>
                    </div>
                  }            
                 <div class="btn_back">
                    <FixedButton>
                      <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                    </FixedButton>
                  </div>
              </div>
            </form>
          </div>

          {/** TOPICS */}
          {this.state.isUpdate &&
            <div class="parameters">
              <div class="parameterstitle"><h2>Теми на играта:</h2></div>
              {topics && topics.size > 0 &&
              <div class={["table", "parameterstable"].join(' ')}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Име</TableCell>
                        <TableCell align="center">Действие</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {topics.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((topic) => {
                      return (
                      <TableRow>
                        <TableCell align="center">{topic.getIn(['Name'], "")}</TableCell>
                        <TableCell align="center">
                          <StandardButton text="Изтрийте" width="100%" type="delete" click={() => this.deleteTopic(topic.getIn(['Id'], ""))} />
                        </TableCell>
                      </TableRow>
                      );}
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              }
              {this.state.addTopic && 
                <div class="addparameterform">
                  <form onSubmit={this.submitAddTopic}>
                    <div class="inputs">
                      <div class="row">
                        <input id='topicName' name="topicName" required placeholder="Име на темата*" onChange={this.handleInputChange}/>
                      </div>
                      <div class="addparameterbtns">
                        <div class="addparameterbtn">
                          <FixedButton>
                            <StandardButton isSubmit text="Добавете" width="100%"/>
                          </FixedButton>
                        </div>
                        <div class="cancelparameterbtn">
                            <FixedButton>
                              <StandardButton click={this.closeAddTopic} text="Отказ" width="100%" type="cancel"/>
                            </FixedButton>
                          </div>
                      </div>
                    </div>              
                  </form>
                </div>
              }
              {!this.state.addTopic && 
                <div class="addparameter">
                  {this.state.isUpdate &&
                    <StandardButton disabled={notOwned} click={this.addNewTopic} text="Добавете нова тема" width="100%"/>
                  }
                </div>
              }
            </div>
          }


          {this.state.isUpdate &&
            <div class="parameters">
              <div class="parameterstitle"><h2>Типове на играта:</h2></div>
              {types && types.size > 0 &&
              <div class={["table", "parameterstable"].join(' ')}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Име</TableCell>
                        <TableCell align="center">Действие</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {types.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((type) => {
                      return (
                      <TableRow>
                        <TableCell align="center">{type.getIn(['Name'], "")}</TableCell>
                        <TableCell align="center">
                          <StandardButton text="Изтрийте" width="100%" type="delete" click={() => this.deleteType(type.getIn(['Id'], ""))} />
                        </TableCell>
                      </TableRow>
                      );}
                      )}

                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              }
              {this.state.addType && 
                <div class="addparameterform">
                  <form onSubmit={this.submitAddType}>
                    <div class="inputs">
                      <div class="row">
                        <input id='typeName' name="typeName" required placeholder="Тип на играта*" onChange={this.handleInputChange}/>
                      </div>
                      <div class="addparameterbtns">
                        <div class="addparameterbtn">
                          <FixedButton>
                            <StandardButton isSubmit text="Добавете" width="100%"/>
                          </FixedButton>
                        </div>
                        <div class="cancelparameterbtn">
                            <FixedButton>
                              <StandardButton click={this.closeAddType} text="Отказ" width="100%" type="cancel"/>
                            </FixedButton>
                          </div>
                      </div>
                    </div>              
                  </form>
                </div>
              }
              {!this.state.addType && 
                <div class="addparameter">
                  {this.state.isUpdate &&
                    <StandardButton disabled={notOwned} click={this.addNewType} text="Добавете нов тип" width="100%"/>
                  }
                </div>
              }
            </div>
          }

          {this.state.isUpdate &&
            <div class="roles">
              <div class="rolestitle"><h2>Роли в играта:</h2></div>
              {roles &&
                roles.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx)=> {
                  return (
                    <EditRole notOwned={notOwned} game={game} role={val} id={idx} sequence={idx} deleteNewRole={this.deleteNewRole}/>
                  )
                })
              }
              {
                newRoles.map((val, idx)=> {
                  var rolesSize = (roles.size) ? 0 : roles.size;
                  return (
                    <EditRole notOwned={notOwned} game={game} id={idx + rolesSize} sequence={idx} deleteNewRole={this.deleteNewRole} />
                  )
                })
              }
              <div class="addrole">
                {this.state.isUpdate &&
                  <StandardButton disabled={notOwned} click={this.addNewRole} text="Добавете нова роля" width="100%"/>
                }
              </div>
            </div>            
          }
          
          {this.state.isUpdate &&
            <div class="levels">
              <div class="levelstitle"><h2>Нива на играта:</h2></div>
              {
                levels.sort((a, b) => a.getIn(['SequenceNumber'], null) - b.getIn(['SequenceNumber'], null)).map((val, idx)=> {
                  return (
                    <EditLevel roles={roles} updateLevel={this.updateLevel} notOwned={notOwned} gameId={gameId} level={val} id={idx} sequence={idx} hasPlanning={this.state.hasPlanning} allowReplanning={this.state.allowReplanning} />
                  )
                })
              }
              {
                newLevels.map((val, idx)=> {
                  return (
                    <EditLevel roles={roles} updateLevel={this.updateLevel} gameId={gameId} id={idx + levels.size} sequence={idx} deleteNewLevel={this.deleteNewLevel} hasPlanning={this.state.hasPlanning} allowReplanning={this.state.allowReplanning}/>
                  )
                })
              }
              <div class="addlevel">
                {this.state.isUpdate &&
                <FixedButton>
                  <StandardButton disabled={notOwned} click={this.addNewLevel} text="Добавете ново ниво" width="100%"/>
                </FixedButton>
                }
              </div>
              {unity != "" && levels.size > 0 &&
                <div id="player">
                  {unity}
                </div>
              }
            </div>
          }
          </div>
        }
      </StyledEditGame>
    )
  }
}

EditGame.propTypes = {
  game: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    game: getGame(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchCreateGame: (profileId, title, description, subject, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction, hasPlanning, allowReplanning, platformType, lang, hasPostPlayActivity, postPlayActivityInstruction) => dispatch(createGame(profileId, title, description, subject, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction, hasPlanning, allowReplanning, platformType, lang, hasPostPlayActivity, postPlayActivityInstruction)),
    fetchReadGame: (gameId) => dispatch(readGame(gameId)),
    fetchUpdateGame: (gameId, title, description, subject, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction, hasPlanning, allowReplanning, platformType, lang, hasPostPlayActivity, postPlayActivityInstruction) => dispatch(updateGame(gameId, title, description, subject, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction, hasPlanning, allowReplanning, platformType, lang, hasPostPlayActivity, postPlayActivityInstruction)),
    fetchResetGame: () => dispatch(resetGame()),
    fetchAddGameType: (gameId, name) => dispatch(addGameType(gameId, name)),
    fetchDeleteGameType: (id) => dispatch(deleteGameType(id)),
    fetchAddGameTopic: (gameId, name) => dispatch(addGameTopic(gameId, name)),
    fetchDeleteGameTopic: (id) => dispatch(deleteGameTopic(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGame)
