import { fromJS, Map } from 'immutable'


export function getAdmin(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['admin'], null)
}

export function setAdmin(state, newState) {
  if (newState.admin.Error) {
    localStorage.setItem("role", "");
  } else {
    localStorage.setItem("role", "Administrator");
  }
  return state.merge(fromJS(newState.admin))
}

export function setError(state, error) {
  localStorage.setItem("role", "");
  return state.merge({
    ApiError: fromJS(error)
  })
}
