import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import PageTitle from '../../components/ui/title'
import StandardSection from '../../components/ui/section'
import Content from '../../components/ui/content'
import SectionTitle from '../../components/ui/subtitle'
import Subscription from '../../components/pages/profile/subscription'
import Profile from '../../components/pages/profile/profile'
import Invoice from '../../components/pages/profile/invoice'
import Payment from '../../components/pages/profile/payment'

import {  getProfile } from '../../state/selectors/profile'

export class ProfilePage extends Component {

constructor(props) {
  super()
}

render() {
    const { profile } = this.props
    const subscription = profile.getIn(['Profile', 'Subscription'], null);
    return (
      <div>
        <PageTitle title="Моят акаунт"/>
        <StandardSection bgr="#fff">
          <Content>
            <SectionTitle text="Информация за вашия текущ абонамент"/>
            <Subscription />
          </Content>
        </StandardSection>
        {subscription &&  
          <StandardSection bgr="#e8e8e8">
            <Content>
              <SectionTitle text="Информация за вашите методи за плащане и достъп до вашите фактури"/>
              <Payment />
            </Content>
          </StandardSection>
        }
        {subscription &&        
        <StandardSection bgr="#fff">
          <Content>
            <SectionTitle text="Информация за вашето фактуриране"/>
            <Invoice />
          </Content>
        </StandardSection>
        }
        
        <StandardSection bgr="#e8e8e8">
          <Content>
            <SectionTitle text="Вашата лична информация"/>
            <Profile />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

Profile.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage)
