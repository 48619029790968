import { fromJS, Map } from 'immutable'


export function getSubscription(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['subscription'], null)
}

export function setSubscription(state, newState) {
  if (newState.subscription.Error) {
    localStorage.setItem("role", "");
  } else {
    localStorage.setItem("role", "Subscription");
  }
  return state.merge(fromJS(newState.subscription))
}

export function setSubscriptionCount(state, newState) {
  return state.merge(fromJS(newState.subscriptionCount))
}

export function setSubscriptionIncome(state, newState) {
  return state.merge(fromJS(newState.subscriptionIncome))
}

export function getSubscriptionCount(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['subscriptionCount'], null)
}

export function getSubscriptionIncome(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['subscriptionIncome'], null)
}

export function setError(state, error) {
  localStorage.setItem("role", "");
  return state.merge({
    ApiError: fromJS(error)
  })
}

export function isSubscriptionApiError(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['subscription', 'ApiError'], null) !== null
}

export function isSubscriptionError(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['subscription', 'Error'], null) !== null
}