
import * as types from './types'

export function readTeamByPublicKey(publicKey) {
  return {
    type: types.READ_TEAMBY_PUBLICKEY_REQUEST,
    payload: { publicKey }
  }
}

export function readTeamByAccessKey(accessKey) {
  return {
    type: types.READ_TEAMBY_ACCESSKEY_REQUEST,
    payload: { accessKey }
  }
}