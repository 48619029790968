
import * as types from '../actions/types'
import initial from './initial'
import { setPlayedGame, setPlayedGameError } from '../selectors/playedgame'

export default function (state = initial.playedGame, action) {
  switch (action.type) {
    case types.READ_PLAYEDGAME_RESPONSE:
    case types.CREATE_PLAYEDGAME_RESPONSE:
      return setPlayedGame(state, action)
    case types.READ_PLAYEDGAME_ERROR:
    case types.CREATE_PLAYEDGAME_ERROR:
      return setPlayedGameError(state, action.error)
    default:
      return state
  }
}
