import React from 'react'
import StyledPlayerStats from './playerstats.styled'

class SectionTitle extends React.Component {
  
  render() {
    
  return (
    <StyledPlayerStats>
      <div class="player">
        {this.props.isVirtual && <div class="playername">{this.props.name}</div>}
        <div class="playerscorelabel">Резултат:</div>
        <div class="playerscore">{this.props.score}</div>
        <div class="param">Точки: <span>{this.props.points}</span></div>
        <div class="param">Съвпадения: <span>{this.props.answersMatch}</span></div>        
        <div class="param">Разлики: <span>{this.props.answersUnmatch}</span></div>
      </div>
    </StyledPlayerStats>
    )
  }
}
export default SectionTitle
