import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledMonitor from './monitor.styled'
import queryString from 'query-string'

import PageTitle from '../../ui/title'
import StandardSection from '../../ui/section'
import SubTitle from '../../ui/subtitle'
import Level from '../game/level'
import StandardButton from '../../ui/button'

import socketIOClient from "socket.io-client";

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {  getGame } from '../../../state/selectors/game'
import { readGameByAccessKey, resetGame } from '../../../state/actions/game'

const internalSocketURL = "https://socket.agify.me/socket.io/";
const externalSocketURL = "https://socket.agify.me/";
const socketURL = externalSocketURL;

function msToTime(duration) {
  if (!duration) {
    return "-";
  }
  var minutes = Math.floor((duration / (1000 * 60)) % 60);
  var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  var seconds = Math.floor((duration / 1000) % 60),
  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;
  return hours + ":" + minutes + ":" + seconds;
}

export class Monitor extends Component {
 
  state = {
    accessKey: -1,
    isConnected: false,
  }

  componentDidMount() {
    let url =  window.location.href;
    if (this.props.accessKey) {
      this.accessKey = this.props.accessKey;
    } else {
      const parsed = queryString.parseUrl(url);
      // Check for access key
      this.accessKey = (parsed.query.id) ? parsed.query.id : -1;
      if (this.accessKey == -1) {
        this.accessKey = (parsed.query.id) ? parsed.query.id : -1;
      }
    }
    
    this.initialize();
  }

  constructor(props) {
    super()
    // Socket
    this.initialize = this.initialize.bind(this);
    this.onSocketConnect = this.onSocketConnect.bind(this);
    this.onSocketDisconnect = this.onSocketDisconnect.bind(this);
    this.onSocketConnectError = this.onSocketConnectError.bind(this);
    this.onSocketError = this.onSocketError.bind(this);
    this.onSetState = this.onSetState.bind(this);
    this.onHelpAddressed = this.onHelpAddressed.bind(this);
    this.onPausePlayer = this.onPausePlayer.bind(this);
  }

  initialize = () => {
    // Reset game
    this.props.fetchResetGame();
    // Read game
    this.props.fetchReadGameByAccessKey(this.accessKey);
    // Initialize socket
    this.socket = socketIOClient(socketURL, {transports: ["websocket"], upgrade: false});
    this.socket.on("connect", this.onSocketConnect);
    this.socket.on('disconnect', this.onSocketDisconnect);
    this.socket.on('connect_error', this.onSocketConnectError);
    this.socket.on('error', this.onSocketError);
    this.socket.on("SetState", this.onSetState);
  }

  onSocketConnect = () => {
    console.log("[Socket] Socket connected.");
    this.socket.emit('RegisterTrainer', this.accessKey);
    this.setState({
      isConnected: true,
    });
  }

  onSocketDisconnect = () => {
    console.log("[Socket] Socket disconnected.");
  }

  onSocketConnectError = (error) => {
    console.log("[Socket] Socket error: " + error);
  }

  onSocketError = (error) => {
    console.log("[Socket] Socket connect error: " + error);
  }

  onSetState = (state) => {
    console.log("[Socket] Set state.");
    var stateAsJSON = JSON.parse(state);
    this.setState({
      state: stateAsJSON,
    });
  }

  onHelpAddressed = () => {
    this.state.state.helpRequested = false;
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  onPausePlayer = (id) => {
    this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
      if (player.id == id) {
        player.isActive = !player.isActive;
      }
    });
    this.socket.emit('SetState', JSON.stringify(this.state.state));
  }

  render() {
      const { game } = this.props;
      const gameId = game.getIn(['Game', 'Id'], null);
      const postPlayActivityInstruction = game.getIn(['Game', 'PostPlayActivityInstruction'], null);
      let levels = (game.getIn(['Game', 'Levels', 'Level'], null) == null) ? null : game.getIn(['Game', 'Levels', 'Level'], null);
      var phaseText = "-";
      var subPhaseText = "-";
      var currLevel = "";
      var numberOfLevels = 0;
      var numberOfPlayedLevels = 0;
      var numberOfDoneLevels = 0;
      var emphasize = false;
      if (this.state.state) {
        emphasize = this.state.state.helpRequested;
        if (this.state.state.phase == "Start") {
          phaseText = <div>Играчите се намират на <b>стартовия екран</b> на играта.</div>
        } else if (this.state.state.phase == "Play") {
          phaseText = <div>Играчите се намират в <b>основната игра</b>.</div>
          if (this.state.state.play.phase == "initialemotions") {
            subPhaseText = <div>Играчите посочват <b>началаното си настроение</b>.</div>
          } else if (this.state.state.play.phase == "teamname") {
            subPhaseText = <div>Играчите избират <b>името на своя екип</b>.</div>
          } else if (this.state.state.play.phase == "introduction") {
            subPhaseText = <div>Играчите се <b>запознават с инструкциите</b>.</div>
          } else if (this.state.state.play.phase == "play") {
            subPhaseText = <div>Играчите в момента <b>играят</b>.</div>
            if (levels) {
              levels.map((level)=> {
                numberOfLevels++;
                var levelId = level.getIn(['Id'], null);
                if (levelId == this.state.state.play.currentLevelId) {
                  currLevel = level;
                }
              });
            }
            this.state.state.play.levels.map((level) => {
              if (level.isPlayed) {
                numberOfPlayedLevels++;
              }
              if (level.isDone) {
                numberOfDoneLevels++;
              }
            })
          } else if (this.state.state.play.phase == "postplayactivity") {
            subPhaseText = <div>Играчите работят по <b>дейността след играта</b>.</div>
          } else if (this.state.state.play.phase == "finalemotions") {
            subPhaseText = <div>Играчите посочват <b>финалното си настроение</b>.</div>
          } else if (this.state.state.play.phase == "planning") {
            subPhaseText = <div>Играчите <b>планират</b>.</div>
          }          
        } else if (this.state.state.phase == "IsLoadingEnd" || this.state.state.phase == "End") {
          phaseText = <div>Играчите се намират на <b>финалния екран</b> на играта.</div>
        }
      }
      var numberOfLevelsLeft = numberOfLevels - numberOfDoneLevels;
      var timeLeft = 0
      if (this.state.state && this.state.state.phase == "Play") {
        var totalTimeInSeconds = this.state.state.minutes * 60;
        var totalPlayedTime = 0;
          this.state.state.play.levels.map((level) => {
            totalPlayedTime += level.totalPlayedTime;
          }
        );
      timeLeft = totalTimeInSeconds - totalPlayedTime - this.state.state.play.totalPlanningTime;
      }
      var individualType;
      var groupType;
      if (currLevel) {
        individualType = currLevel.getIn(['IndividualType'], "");
        groupType = currLevel.getIn(['GroupType'], "");
      }
      var bgrColor = (!emphasize) ? "#fff" : "#f9bbc3"
      return (
        <StyledMonitor theme={process.theme} bgrColor={bgrColor}>
          <div class="emphasize">
            {this.props.teamPublicKey && this.state.state &&
              <div>
                {emphasize && 
                <div class="teamActions">
                  <button onClick={this.onHelpAddressed}><img src={"/images/monitor/help_request_btn.png"} /></button>
                </div>
                }
                <div class="embededTeamName">Отбор <span>{this.state.state.team.mark}</span></div>
                <div class="embededTeamLink">Публичен линк на екипа: <a href={process.theme.teamURL + "?id=" + this.props.teamPublicKey} target="_blank">{process.theme.teamURL}?id={this.props.teamPublicKey}</a></div>
              </div>
            }
            {!this.state.isConnected &&
            <div>
              {!this.props.accessKey &&
                <PageTitle title={"Мониторинг на отбор"}/>
              }
              <StandardSection bgr="#FFF">
                <div class="note">
                  Информацията за отбора се зарежда. Моля, изчакайте.
                </div>            
              </StandardSection>
            </div>
            }
            {this.state.isConnected && !this.state.state &&
            <div>
              {!this.props.accessKey &&
                <PageTitle title={"Мониторинг на отбор"}/>
              }
              <StandardSection bgr="#FFF">
                <div class="note">
                  Няма информация за този отбор. Все още няма регистрирани играчи.
                </div>            
              </StandardSection>
            </div>
            }
            {this.state.state &&
            <div>
              {!this.props.accessKey &&
                <PageTitle title={"Отбор " + this.state.state.team.mark}/>
              }
              <StandardSection bgr="#FFF">
                <SubTitle text="Списък с играчи"/>
                <div class={["table", "players"].join(' ')}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Играч</TableCell>
                          <TableCell align="center">Роля</TableCell>
                          <TableCell align="center">Онлайн</TableCell>                        
                          <TableCell align="center">Статус</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                        return (
                        <TableRow>
                          <TableCell align="center">{player.nickname}</TableCell>
                          <TableCell align="center">{(player.role.Name != "") ? player.role.Name : "-"}</TableCell>
                          <TableCell align="center">{(player.isOnline) ? <span class='green'>Онлайн</span> : <span class='red'>Офлайн</span>}</TableCell>
                          <TableCell align="center">
                            <div>{(player.isActive) ? <span class='green'>Активен</span> : <span class='red'>На пауза</span>}</div>
                            <div class="pause"><StandardButton click={() => this.onPausePlayer(player.id)} text={(player.isActive) ? "Паузирайте" : "Активирайте"} width="100%"/></div>
                          </TableCell>
                        </TableRow>
                        );}
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
              </div>
              </StandardSection>
              <StandardSection bgr="#E8EBE2">
                <SubTitle text="Текуща фаза на играта"/>
                <div>
                  <div class="gameId" target="_blank">Пълна информация за играта: <a href={"/game?id=" + gameId}>{gameId}</a></div>
                  <div class="phase">{phaseText}</div>
                  
                  {/** START PHASE */}
                  {this.state.state.phase == "Start" &&
                    <div class={["table","players"].join(' ')}>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Играч</TableCell>
                              <TableCell align="center">Натиснал ли е Play бутона?</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                                if (player.isActive && player.isOnline) {
                                  return (
                                    <TableRow>
                                      <TableCell align="center">{player.nickname}</TableCell>
                                      <TableCell align="center">{(player.hasAgreedToStartPlay) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                    </TableRow>
                                  );
                                } else {
                                  return (null);
                                }
                              })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  }
                  {/** PLAY PHASE */}
                  {this.state.state.phase == "Play" &&
                  <div>
                    <div class="subphase">{subPhaseText}</div>
                    
                    {/** INITIAL EMOTIONS */}
                    {this.state.state.play.phase == "initialemotions" &&
                      <div class={["table","players"].join(' ')}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Играч</TableCell>
                                <TableCell align="center">Избрал ли е емоция?</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                                  if (player.isActive && player.isOnline) {
                                    return (
                                      <TableRow>
                                        <TableCell align="center">{player.nickname}</TableCell>
                                        <TableCell align="center">{(player.initialEmotion >= 0) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                      </TableRow>
                                    );
                                  } else {
                                    return (null);
                                  }
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    }

                    {/** TEAM NAME */}
                    {this.state.state.play.phase == "teamname" &&
                      <div class={["table","players"].join(' ')}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Играч</TableCell>
                                <TableCell align="center">Съгласил ли се е с екипното име?</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                                  if (player.isActive && player.isOnline) {
                                    return (
                                      <TableRow>
                                        <TableCell align="center">{player.nickname}</TableCell>
                                        <TableCell align="center">{(player.hasAgreedOnTeamName) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                      </TableRow>
                                    );
                                  } else {
                                    return (null);
                                  }
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    }

                    {/** INTRODUCTION */}
                    {this.state.state.play.phase == "introduction" &&
                      <div class={["table","players"].join(' ')}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Играч</TableCell>
                                <TableCell align="center">Готов ли е с инструкциите?</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                                  if (player.isActive && player.isOnline) {
                                    return (
                                      <TableRow>
                                        <TableCell align="center">{player.nickname}</TableCell>
                                        <TableCell align="center">{(player.hasAgreedOnIntroduction) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                      </TableRow>
                                    );
                                  } else {
                                    return (null);
                                  }
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    }

                    {/** PLANNING */}
                    {this.state.state.play.phase == "planning" &&
                      <div class={["table","players"].join(' ')}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Играч</TableCell>
                                <TableCell align="center">Готов ли е с планирането?</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                                  if (player.isActive && player.isOnline) {
                                    return (
                                      <TableRow>
                                        <TableCell align="center">{player.nickname}</TableCell>
                                        <TableCell align="center">{(player.hasAgreedOnBacklog) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                      </TableRow>
                                    );
                                  } else {
                                    return (null);
                                  }
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    }

                    {/** PLAY */}
                    {this.state.state.play.phase == "play" && currLevel &&
                      <div>
                        <div class="levelInfo">Информация за нивото, което се играе в момента:</div>
                        <Level level={currLevel} id={numberOfPlayedLevels - 1} preExpanded/>
                        <div class={["table","players", "gameStats"].join(' ')}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Играч</TableCell>
                                  {individualType == "singleoption" &&
                                    <TableCell align="center">Готов ли е с индивидуалната задача?</TableCell>
                                  }
                                  {groupType != "none" &&
                                    <TableCell align="center">Готов ли е с груповата задача?</TableCell>
                                  }
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                                    if (player.isActive && player.isOnline) {
                                      var hasIndividualAnswer = false;
                                      var hasGroupAnswer = false;
                                      player.individualLevels.map((individualLevel) => {
                                        if (individualLevel.id == this.state.state.play.currentLevelId) {
                                          hasIndividualAnswer = (individualLevel.answer == "") ? false : true;
                                        }
                                      })
                                      player.groupLevels.map((groupLevel) => {
                                        if (groupLevel.id == this.state.state.play.currentLevelId) {
                                          if (groupType == "singleoption") {
                                            hasGroupAnswer = (groupLevel.answer == "") ? false : true;
                                          } else {
                                            hasGroupAnswer = groupLevel.hasAgreedOnGroupAnswer;
                                          }
                                        }
                                      })

                                      return (
                                        <TableRow>
                                          <TableCell align="center">{player.nickname}</TableCell>
                                          {individualType == "singleoption" &&
                                            <TableCell align="center">{(hasIndividualAnswer) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                          }
                                          {groupType != "none" &&
                                            <TableCell align="center">{(hasGroupAnswer) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                          }
                                        </TableRow>
                                      );
                                    } else {
                                      return (null);
                                    }
                                  })
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                        <div class="details">
                          <div class="detail">
                            <div class="param">Оставащ брой нива:</div>
                            <div class="value">{numberOfLevelsLeft}</div>
                          </div>
                          <div class="detail">
                            <div class="param">Приблизително оставащо време при последната актуализация:</div>
                            <div class="value">{msToTime(timeLeft * 1000)}</div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  }

                  {/** POST PLAY ACTIVITY */}
                  {this.state.state.play.phase == "postplayactivity" &&
                      <div>
                        <div class="levelInfo">Информация за дейността, което се провежда в момента:</div>
                        <div class="postPlay">
                          {postPlayActivityInstruction}
                        </div>
                        <div class={["table","players"].join(' ')}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Играч</TableCell>
                                  <TableCell align="center">Готов ли е с дейността?</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                                    if (player.isActive && player.isOnline) {
                                    
                                      return (
                                        <TableRow>
                                          <TableCell align="center">{player.nickname}</TableCell>
                                          <TableCell align="center">{(player.hasAgreedOnPostPlayText) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                          
                                        </TableRow>
                                      );
                                    } else {
                                      return (null);
                                    }
                                  })
                                }
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                  }

                  {/** FINAL EMOTIONS */}
                  {this.state.state.play.phase == "finalemotions" &&
                      <div class={["table","players"].join(' ')}>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Играч</TableCell>
                                <TableCell align="center">Избрал ли е емоция?</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.state.players.sort((a, b) => a.nickname - b.nickname).map((player) => {
                                  if (player.isActive && player.isOnline) {
                                    return (
                                      <TableRow>
                                        <TableCell align="center">{player.nickname}</TableCell>
                                        <TableCell align="center">{(player.finalEmotion >= 0) ? <span class='green'>Да</span> : <span class='red'>Не</span>}</TableCell>
                                      </TableRow>
                                    );
                                  } else {
                                    return (null);
                                  }
                                })
                              }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    }


                </div>
              </StandardSection>
            </div>
            }
        </div>
        </StyledMonitor>
      )
  }
}

Monitor.propTypes = {
  game: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    game: getGame(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchReadGameByAccessKey: (accessKey) => dispatch(readGameByAccessKey(accessKey)), 
    fetchResetGame: () => dispatch(resetGame())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Monitor)
