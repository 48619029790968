import { fromJS, Map } from 'immutable'

export function getPlayedGame(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['playedGame'], null)
}

export function setPlayedGame(state, newState) {
  return state.merge(fromJS(newState.playedGame))
}

export function setPlayedGameError(state, error) {
  return state.merge({
    PlayedGameError: fromJS(error)
  })
}

export function getPlayedGames(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['playedGames'], null)
}

export function setPlayedGames(state, newState) {
  return state.merge(fromJS({playedGames: newState.playedGames}))
}

export function setPlayedGamesError(state, error) {
  return state.merge({
    PlayedGamesError: fromJS(error)
  })
}

export function getDetailedPlayedGames(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['detailedPlayedGames'], null)
}

export function setDetailedPlayedGames(state, newState) {
  return state.merge(fromJS({detailedPlayedGames: newState.detailedPlayedGames}))
}

export function setDetailedPlayedGamesError(state, error) {
  return state.merge({
    DetailedPlayedGamesError: fromJS(error)
  })
}

export function resetDetailedPlayedGames(state) {
  return new Map();
}