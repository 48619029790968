import { combineReducers } from 'redux'
import * as types from '../state/actions/types'

import subscription from '../state/reducers/subscription'
import subscriptionCount from '../state/reducers/subscription'
import subscriptionIncome from '../state/reducers/subscription'
import profile from '../state/reducers/profile'
import game from '../state/reducers/game'
import admin from '../state/reducers/admin'
import team from '../state/reducers/team'
import games from '../state/reducers/games'
import playCode from '../state/reducers/playcode'
import playCodes from '../state/reducers/playcodes'
import playedGames from '../state/reducers/playedgames'
import playedGame from '../state/reducers/playedgame'
import detailedPlayedGames from '../state/reducers/detailedplayedgames'
import feedback from '../state/reducers/feedback'
import report from '../state/reducers/report'


const appReducer = combineReducers({
  subscription,
  profile,
  game,
  games,
  admin,
  subscriptionCount,
  subscriptionIncome,
  playCode,
  playCodes,
  playedGames,
  playedGame,
  detailedPlayedGames,
  team,
  feedback,
  report,
})

const rootReducer = (state, action) => {
  if (action.type === types.AUTH_LOGOUT) {
    state = undefined
    localStorage.removeItem("token");    
    localStorage.removeItem("profileEmail");
    localStorage.removeItem("profilePassword");
    localStorage.removeItem("subscriptionId");
    localStorage.removeItem("activationCode");    
    localStorage.removeItem("adminUsername");
    localStorage.removeItem("adminPassword");
  }
  return appReducer(state, action)
}


export default rootReducer
