import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledProfiles from './profiles.styled'

import {  getSubscription } from '../../../state/selectors/subscription'

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

function sorProfiles(a, b, prop, desc) {
  var a_prop = a.get(prop);
  var b_prop = b.get(prop);
  var reverse = 1;
  if (desc) {
    reverse = -1;
  }
  if (a_prop < b_prop) {
    return reverse * -1;
  }
  if (a_prop > b_prop) {
    return reverse * 1;
  }
  return 0;
}

export class Subscriptions extends Component {
  
  constructor(props) {
    super()
    this.state = {
      sortProp: "PlayCodesCount",
      desc: true,
    }
    this.onSortingChanged = this.onSortingChanged.bind(this);
  }

  onSortingChanged(prop) {
    if (this.state.desc) {
      this.setState({
        desc: false, 
        sortProp: prop,
      });
    } else {
      this.setState({
        desc: true,
        sortProp: prop,
      });
    }
  }


  render() {
      const { subscription } = this.props
      const profilesList = subscription.getIn(['Subscription', 'Profiles', 'Profiles']);
      return (
        <StyledProfiles>
         <div id="profiles">
            <div>
            {(!profilesList || profilesList.size == 0) &&
              <div class="message">
                Нямате асоциирани акаунти.
              </div>
            }
            {(profilesList && profilesList.size > 0) &&
              <div> 
                <div class="table">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"><TableSortLabel {...this.state.sortProp == "FirstName" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("FirstName")}>Име</TableSortLabel></TableCell>
                        <TableCell align="center"><TableSortLabel {...this.state.sortProp == "PlayCodesCount" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("PlayCodesCount")}>Брой генерирани кодове</TableSortLabel></TableCell>
                        <TableCell align="center"><TableSortLabel {...this.state.sortProp == "LastPlayCodeDate" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("LastPlayCodeDate")}>Дата на последно генериран код</TableSortLabel></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    {profilesList ?
                          profilesList.sort((a, b) => sorProfiles(a, b, this.state.sortProp, this.state.desc)).map((profile) => {
                          var lastPlayCodeDate = (profile.get('LastPlayCodeDate') != null) ? new Date(Date.parse(profile.get('LastPlayCodeDate'))).toLocaleString() : "-";
                          return (
                            <TableRow>
                              <TableCell align="center">{profile.get('FirstName') + " " + profile.get('SecondName')}</TableCell>
                              <TableCell align="center">{profile.get('PlayCodesCount')}</TableCell>
                              <TableCell align="center">{lastPlayCodeDate}</TableCell>
                            </TableRow>
                          );
                        }) : '-'
                    }           
                    </TableBody>
                  </Table>
                </TableContainer>
                </div>
              </div>
            }
            </div>
          </div>
      </StyledProfiles>
    )
  }
}

Subscriptions.propTypes = {
  subscription: PropTypes.object,
}
 
const mapStateToProps = (state) => {
  return {
    subscription: getSubscription(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions)
