import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { slide as Menu } from 'react-burger-menu'
import StyledNavigation from './navigation.styled.js'
import storage from '../../state/storage'
import { logOut } from '../../state/actions/login'
import { hasSubscription } from '../../state/selectors/login.js';

export class LoginNavigation extends Component {
  state = {
    menuOpen: false,
  }

  closeMenu = (e) => {
    if (e.target.id == "logout") {
      this.submitLogout();
    } else {
      e.preventDefault();
      this.setState({ menuOpen: false })
    }
  }

  handleStateChange = (state) => {
    this.setState({ menuOpen: state.isOpen })
  }

  submitLogout() {
    storage().dispatch(logOut());
  }

  render() {
    return (
       <StyledNavigation theme={process.theme}>
         <div id="menu-desktop">
          <ul>            
            <li><Link to="/">Моите кодове за игра</Link></li>
            <li><Link to="/games">Всички игри</Link></li>
            {this.props.hasSubscription && 
              <li><Link to="/mygames">Моите игри</Link></li>
            }
            <li><Link to="/playedgames">История на моите разигравания</Link></li>
            <li><Link to="/profile">Моят акаунт</Link></li>
            <li><a id="logout" href="/" onClick={this.submitLogout}>Изход</a></li>
          </ul>
        </div>
        <div id="menu-mobile" onClick={this.closeMenu}>
          <Menu width={'100%'} isOpen={this.state.menuOpen}
                onStateChange={(state) => this.handleStateChange(state)}>
            <Link to="/">Моите кодове за игра</Link>
            <Link to="/games">Всички игри</Link>
            {this.props.hasSubscription && 
              <Link to="/mygames">Моите игри</Link>
            }
            <Link to="/playedgames">История на моите разигравания</Link>
            <Link to="/profile">Моят акаунт</Link>
            <a id="logout" href="">Изход</a>
          </Menu>
        </div>
        </StyledNavigation>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    hasSubscription: hasSubscription(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

LoginNavigation.propTyes = {
  hasSubscription: PropTypes.bool,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginNavigation)