import * as types from '../actions/types'
import initial from './initial'
import { setAdmin, setError } from '../selectors/admin'

export default function (state = initial.profile, action) {
  switch (action.type) {
    case types.READ_ADMIN_RESPONSE:
      return setAdmin(state, action)
    case types.READ_ADMIN_ERROR:
      return setError(state, action.error)
    default:
      return state
  }
}
