import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import StyledProfile from './profile.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import axios from 'axios'

import {  getProfile } from '../../../state/selectors/profile'

export class Payment extends Component {
  
  constructor(props) {
    super();
    this.createPortalSession = this.createPortalSession.bind(this);
  }

  submitForm = e => {
    e.preventDefault();
    this.createPortalSession();
  }

  createPortalSession = () => {
    const { profile } = this.props
    const profileId = profile.getIn(['Profile', 'Id'], null);
    const API_ENDPOINT = `${process.env.REACT_APP_SERVER}/stripe/portal`
    let token = localStorage.getItem('token');
    const headers = {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    };
    var body = `id=${profileId}&`;
    axios.post(API_ENDPOINT, body, { headers })
    .then(response => {
      this.onPortalSessionOk(response.data);
    }).catch(error => {
      this.onPortalSessionFailure(error);
    })
  }

  onPortalSessionOk = (data) => {        
    window.location.href = data.PortalData.Url;
  }

  onPortalSessionFailure = (error) => {
    alert("onPortalSessionFailure: error=" + error);
  }

  render() {

      return (
        <StyledProfile theme={process.theme}>
         <div id="profile">
          <div>
            <form onSubmit={this.submitForm}>
                <div class="inputs">
                   <div class="buttons">
                      <div class="btn_edit">
                        <FixedButton>
                          <StandardButton isSubmit text="Разгледайте своите методи за плащане / фактури" width="100%"/>
                        </FixedButton>
                      </div>
                  </div>
                </div>              
              </form>
          </div>
        </div>
      </StyledProfile>
    )
  }
}

Payment.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Payment)
