import styled from 'styled-components'

const StyledPlans = styled.div`

.column, .radios {
    width: 100%;      
    margin: 0 auto 20px auto;
}

.radios {
    margin-top: 20px;
}

.freelabel {
    color: #ef526c;
    font-weight: bold;
    width: 50%;
    margin: 0 auto;
}

.monthlypaidlabel {
    color: #26b1d0;
    font-weight: bold;
    width: 50%;
    margin: 0 auto;
}

.grayout {
    opacity: .5;
}

.yearlypaidlabel {
    color: #000;
    font-weight: bold;
    width: 50%;
    margin: 0 auto;
}

.sub {
    text-align: center;
    border-radius: 30px;
    padding-top: 20px;
    padding-bottom: 0px; 
}

.btn {
    text-align: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    padding-bottom: 40px;
    padding-top: 20px; 
}

.notes {
    padding: 0px 40px 30px 40px;
    font-size: 0.8em;
}

.btn button {
    width: 80% !important;
    margin: 0 auto;
}

.btn a {
    width: 80% !important;
    margin: 0 auto;
}

.sub span {
    font-size: 1.2em;
    font-weight: bold;
}

.freenoaccount {
    background-color: #734072;
    color: #fff;
}

.promotion {
    color: #ef526c;
    font-size: 1em;
    text-decoration: line-through;    
}

.price {
    text-align: center;
    font-size: 1.2em;
    padding-top: 20px;
    padding-bottom: 20px;
}

.price span {
    font-size: 1.8em;
    font-weight: bold;
}

.freewithaccount {
    background-color: #ef526c;
    color: #fff;
}

.paid {
    padding-top: 20px;
}

.paidaccount {
    background-color: #26b1d0;
    color: #fff;
}

.paidaccountyearly {
    background-color: #fce054;
    color: #000;
    
}

.title {
    padding: 20px;
    font-size: 1.5em;
    font-weight: bold;
}

.line {
    font-size: 1.1em;
    padding: 10px 20px;
}

@media (min-width: 1200px) {

    .column, .radio {
        width: 300px;       
        margin: 0 10px 0px 0px;
    }

    .pricetable, .radios {
        display: flex;
        justify-content: center;
        align-items: stretch;
    }

    .height {
        height: 728px;
    }

    .price {
        font-size: 1.5em;
        padding-top: 0px;
    }

    .price span {
        font-size: 2em;
    }

    .promotion {
        padding-top: 20px;
    }
}
  
`

export default StyledPlans
