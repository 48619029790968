import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logInAsSubscription, logInAsProfile, logInAsAdmin } from '../state/actions/login'
import { isSubscriptionApiError, isProfileApiError, isSubscriptionError, isProfileError } from '../state/selectors/login'
import PageTitle from '../components/ui/title'
import LoginForm from '../components/pages/login/form'
import StandardSection from '../components/ui/section'
import Content from '../components/ui/content'

export class LoginPage extends Component {

render() {
    return (
      <div>
        <PageTitle title="Вход"/>
        <StandardSection bgr="#FFF">
          <Content>
            <LoginForm
                  submitLoginAsSubscription={this.props.submitLoginAsSubscription}
                  submitLoginAsProfile={this.props.submitLoginAsProfile}
                  submitLoginAsAdmin={this.props.submitLoginAsAdmin}
                  isAuthenticated={this.props.isAuthenticated}
                  isAuthError={this.props.isAuthError}
                  isApiError={this.props.isApiError}/>
          </Content>
        </StandardSection>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthError: isSubscriptionError(state) || isProfileError(state),
    isApiError: isSubscriptionApiError(state) || isProfileApiError(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    submitLoginAsSubscription: (subscriptionId, activationCode) => dispatch(logInAsSubscription(subscriptionId, activationCode)),
    submitLoginAsProfile: (email, password) => dispatch(logInAsProfile(email, password)),
    submitLoginAsAdmin: (username, password) => dispatch(logInAsAdmin(username, password)),
  }
}

LoginPage.propTyes = {
  isAuthError: PropTypes.bool,
  isApiError: PropTypes.bool,
  submitLoginAsSubscription: PropTypes.func,
  submitLoginAsProfile: PropTypes.func,
  submitLoginAsAdmin: PropTypes.func,
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
