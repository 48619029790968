import PropTypes from 'prop-types'
import React, { Component}  from 'react'
import { connect } from 'react-redux'
import StyledDashboard from './dashboard.styled'
import queryString from 'query-string'
import PageTitle from '../../ui/title'
import Monitor from './monitor'
import {  getPlayCode } from '../../../state/selectors/playcode'
import { readPlayCode } from '../../../state/actions/playcode'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

export class Dashboard extends Component {
  
  constructor(props) {
    super();

    this.playCode = "";
    const values = queryString.parse(window.location.search);
    if (values.playCode != null && values.playCode != "") {
      this.playCode = values.playCode;
      props.fetchReadPlayCode(values.playCode);
    } else if (values.playcode != null && values.playcode != "") {
      this.playCode = values.playcode;
      props.fetchReadPlayCode(values.playcode);
    }
  }

  render() {
   const { playCode } = this.props;
   var teams = playCode.getIn(['PlayCode', 'Teams'], null);
   let percentage = 99.5;
   if (teams) {
    percentage = 99 / teams.size;    
   }
   console.log();
   var id = playCode.getIn(['PlayCode', 'Id'], null);  
   var hasPlayedGames = playCode.getIn(['PlayCode', 'HasPlayedGames'], null); 
   return (
      <StyledDashboard theme={process.theme} width={percentage + "%"}>
      <PageTitle title={"Работен панел"}/>
        {!teams &&
        <div class="playedgamesloading">
          Информацията за отборите се зарежда в момента.
        </div>
        }
        {teams && teams.size == 0 && 
        <div class="noplayedgames">
          Все още няма регистриране отбори.
        </div>
        }
        {teams && teams.size > 0 &&
        <div>
          <div class="comparison">
            <div class="playedgames">
              {teams.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((team) => {
                var accessKey = team.getIn(['AccessKey'], null);
                var teamPublicKey = team.getIn(['PublicKey'], null);
                return (
                  <div class="playedgame">
                    <Monitor accessKey={accessKey} teamPublicKey={teamPublicKey}/>
                  </div>
                );
              })
              }    
            </div>
          </div>
          {hasPlayedGames &&
          <div class="results">
            <FixedButton>
              <StandardButton href={"/compareplayedgames?playCode=" + id}  text="Резултати" width="100%"/>
            </FixedButton>
          </div>
          }
        </div>
      }
      </StyledDashboard>
    )
  }
}

Dashboard.propTypes = {
  playCode: PropTypes.object,
}


const mapStateToProps = (state) => {
  return {
    playCode: getPlayCode(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchReadPlayCode: (id) => dispatch(readPlayCode(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
