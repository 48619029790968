import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledProfile from './profile.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'
import PlanForm from './plan'
import { Redirect } from 'react-router-dom'

import {  getProfile } from '../../../state/selectors/profile'
import { cancelProfileSubscription, reactivateProfileSubscription } from '../../../state/actions/profile'


export class Subscription extends Component {
  
  constructor(props) {
    super();

    this.cancelProfileSubscription = this.cancelProfileSubscription.bind(this); 
  }

  cancelProfileSubscription = () => {
    const { profile } = this.props;
    const id = profile.getIn(['Profile', 'Id'], null);
    this.props.fetchCancelProfileSubscription(id);    
  }

  reactivateProfileSubscription = () => {
    const { profile } = this.props;
    const id = profile.getIn(['Profile', 'Id'], null);
    this.props.fetchReactivateProfileSubscription(id);    
  }

  render() {
      const { profile } = this.props
      const subscription = profile.getIn(['Profile', 'Subscription'], null);
      var planType; 
      var planTitle;
      var isPaused;
      var isCanceled;
      var subscriptionRepurchaseDone = profile.getIn(['Profile', 'SubscriptionRepurchaseDone'], false);
      var subscriptionUpgradeDone = profile.getIn(['Profile', 'SubscriptionUpgradeDone'], false);; 
      if (!subscription) {
        planType = "0";
        planTitle = "Безплатен достъп с акаунт";
      } else {
        var subscriptionType = subscription.getIn(['Type'], null);
        isPaused = subscription.getIn(['IsPaused'], null);
        isCanceled = subscription.getIn(['IsCanceled'], null);
        planType = (subscriptionType == "monthly") ? "1" : "2";
        planTitle = (subscriptionType == "monthly") ? "Месечен платен абонамент" : "Годишен платен абонамент";
      }
      const expirationDate = (subscription) ? new Date(Date.parse(subscription.getIn(['ExpirationDate'], null))) : Date.now();
      return (
        <StyledProfile theme={process.theme}>
        {(subscriptionRepurchaseDone || subscriptionUpgradeDone) &&
            <Redirect to="/pending" />
        }
         <div id="profile">
            <div>
              <div class="details_section">                  
                <div class="detail_item">
                    <div class="detail_attribute">Тип на абонамента: </div>
                    <div class="detail_value">{planTitle}</div>
                </div>
                {planType != "0" &&
                  <div class="detail_item">
                      <div class="detail_attribute">Платен достъп до: </div>
                      <div class="detail_value">{expirationDate.toLocaleString()}</div>
                  </div>
                }
                {planType != "0" &&
                  <div class="detail_item">
                      <div class="detail_attribute">Статус на абонамента: </div>
                      <div class="detail_value">{(isCanceled) ? <span class="deactive">Канселиран</span> : <span class="active">Активен</span>}</div>
                  </div>
                }
                {planType != "0" && !isCanceled &&
                  <div class="detail_item">
                    <div class="detail_attribute">Статус на автоматичното плащане: </div>
                    <div class="detail_value">{(isPaused) ? <span class="deactive">Паузиран</span> : <span class="active">Позволен</span>}</div>
                  </div>
                }                    
                {planType != "0" && !isPaused && !isCanceled &&
                  <div class="detail_button">
                    <FixedButton>
                      <StandardButton click={this.cancelProfileSubscription} text="Канселирайте своя абонамент*" type="delete"  width="100%"/>
                    </FixedButton>
                    <div class="cancel">*Канселирането на абонамента ще преустанови неговото автоматично плащане. Вие ще продължите да имате достъп до платформата за периода, за който абонаментът Ви е предплатен. След това ще трябва да си поръчате нов абоанмент, за да продължите да ползвате платформата.</div>
                  </div>
                }
                {isPaused && !isCanceled &&
                  <div class="detail_button">
                    <FixedButton>
                      <StandardButton click={this.reactivateProfileSubscription} text="Реактивирайте своя абонамент*" width="100%"/>
                    </FixedButton>
                    <div class="reactivate">*Реактивирането на Вашия абонамент ще позволи неговото автоматично плащане. Съответно следващото автоматично плащане ще се случи, след като изтече периода, за който абонаментът Ви е предплатен.</div>
                  </div>
                }
              </div>
              <PlanForm isCreateProfile={false} isRepurchase={isCanceled}/>
            </div>
          </div>
      </StyledProfile>
    )
  }
}

Subscription.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchCancelProfileSubscription: (profilеId) => dispatch(cancelProfileSubscription(profilеId)),
    fetchReactivateProfileSubscription: (profilеId) => dispatch(reactivateProfileSubscription(profilеId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
