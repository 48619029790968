import * as types from '../actions/types'
import initial from './initial'
import { setGame, setError, resetGame, deleteGame } from '../selectors/game'

export default function (state = initial.game, action) {
  switch (action.type) {
    case types.CREATE_GAME_RESPONSE:
      return setGame(state, action)
    case types.READ_GAME_RESPONSE:
    case types.READ_GAME_BY_ACCESS_KEY_RESPONSE:
      return setGame(state, action)
    case types.UPDATE_GAME_RESPONSE:
      return setGame(state, action)
    case types.DELETE_LEVEL_RESPONSE:
      return setGame(state, action)
    case types.DELETE_GAME_RESPONSE:
    case types.DUPLICATE_GAME_RESPONSE:
      return setGame(state, action)
    case types.ADD_LEVEL_RESPONSE:
      return setGame(state, action)
    case types.UPDATE_LEVEL_RESPONSE:
        return setGame(state, action)
    case types.ADD_GAME_TOPIC_RESPONSE:
    case types.ADD_GAME_TYPE_RESPONSE:
    case types.ADD_GAME_ROLE_RESPONSE:
    case types.UPDATE_GAME_ROLE_RESPONSE:
    case types.ADD_GAME_ROLE_LEVEL_RESPONSE:
    case types.UPDATE_GAME_ROLE_LEVEL_RESPONSE:
    case types.DELETE_GAME_ROLE_LEVEL_RESPONSE:
        return setGame(state, action)
    case types.DELETE_GAME_TOPIC_RESPONSE:
    case types.DELETE_GAME_TYPE_RESPONSE:
    case types.DELETE_GAME_ROLE_RESPONSE:
        return setGame(state, action)
    case types.CREATE_GAME_ERROR:
      return setError(state, action.error)
    case types.READ_GAME_ERROR:
    case types.READ_GAME_BY_ACCESS_KEY_ERROR:
      return setError(state, action.error)
    case types.UPDATE_GAME_ERROR:
    case types.DUPLICATE_GAME_ERROR:
      return setError(state, action.error)
    case types.DELETE_LEVEL_ERROR:
      return setError(state, action.error)
    case types.ADD_LEVEL_ERROR:
      return setError(state, action.error)
    case types.UPDATE_LEVEL_ERROR:
      return setError(state, action.error)
    case types.RESET_GAME:
      return resetGame(state)
    default:
      return state
  }
}
