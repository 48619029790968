import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledProfile from './profile.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import {  getProfile } from '../../../state/selectors/profile'

export class Success extends Component {
  
  constructor(props) {
    super();
  }


  render() {

      return (
        <StyledProfile theme={process.theme}>
         <div id="profile">
            <div class="congrats">Вие успешно платихте своята абонамент към <a href="agify.me">agify.me</a>. Вече може да учете, да играете и да се забавлявате на воля с Вашите екипи на нашата страхотна платформа! :)</div>
            <div class="getinvoice">Ако сте заявили издаване на фактура - ще я получите в отделен мейл съвсем скоро! Също така бихте могли да я достъпите и <a href="/profile">през своя профил</a>.</div>
            <div class="allgamesbtn">
              <FixedButton>
                <StandardButton href="/games" text="Разгледайте всичките игри" width="100%"/>
              </FixedButton>
            </div>
            <div class="allgamesbtn">
              <FixedButton>
                <StandardButton href="/profile" text="Достъпете профила си" width="100%"/>
              </FixedButton>
            </div>
          </div>
      </StyledProfile>
    )
  }
}

Success.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Success)
