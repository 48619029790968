import * as types from './types'

export function updateSubscription(subscriptionId, organization, name, email, phone) {
  return {
    type: types.UPDATE_SUBSCRIPTION_REQUEST,
    payload: {subscriptionId, organization, name, email, phone}
  }
}

export function readSubscriptionCount() {
  return {
    type: types.READ_SUBSCRIPTION_COUNT_REQUEST,
    payload: {}
  }
}

export function readSubscriptionIncome() {
  return {
    type: types.READ_SUBSCRIPTION_INCOME_REQUEST,
    payload: {}
  }
}

export function createProfileSubscription(profileId, subscriptionId, activationCode) {
  return {
    type: types.CREATE_PROFILE_SUBSCRIPTION_REQUEST,
    payload: { profileId, subscriptionId, activationCode }
  }
}