import * as types from './types'

export function createGame(profileId, title, description, subject, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction, hasPlanning, allowReplanning, platformType, lang, hasPostPlayActivity, postPlayActivityInstruction) {
  return {
    type: types.CREATE_GAME_REQUEST,
    payload: { profileId, title, description, subject, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction, hasPlanning, allowReplanning, platformType, lang, hasPostPlayActivity, postPlayActivityInstruction}
  }
}

export function updateGame(gameId, title, description, subject, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction, hasPlanning, allowReplanning, platformType, lang, hasPostPlayActivity, postPlayActivityInstruction) {
  return {
    type: types.UPDATE_GAME_REQUEST,
    payload: { gameId, title, description, subject, complexity, individualInstruction, groupInstruction, author, organization, access, theme, introduction, hasPlanning, allowReplanning, platformType, lang, hasPostPlayActivity, postPlayActivityInstruction}
  }
}

export function readGame(gameId) {
  return {
    type: types.READ_GAME_REQUEST,
    payload: { gameId }
  }
}

export function readGameByAccessKey(accessKey) {
  return {
    type: types.READ_GAME_BY_ACCESS_KEY_REQUEST,
    payload: { accessKey }
  }
}

export function deleteGame(gameId) {
  return {
    type: types.DELETE_GAME_REQUEST,
    payload: { gameId }
  }
}

export function duplicateGame(gameId) {
  return {
    type: types.DUPLICATE_GAME_REQUEST,
    payload: { gameId }
  }
}

export function addGameType(gameId, name) {
  return {
    type: types.ADD_GAME_TYPE_REQUEST,
    payload: { gameId, name}
  }
}

export function deleteGameType(id) {
  return {
    type: types.DELETE_GAME_TYPE_REQUEST,
    payload: { id }
  }
}

export function addGameTopic(gameId, name) {
  return {
    type: types.ADD_GAME_TOPIC_REQUEST,
    payload: { gameId, name}
  }
}

export function deleteGameTopic(id) {
  return {
    type: types.DELETE_GAME_TOPIC_REQUEST,
    payload: { id }
  }
}

export function addGameRole(gameId, name, description, openPlanning, closePlanning, reorderTasks, selectTasks, toDoList, tasksInfo, estimateTasks, togglePlay, introduction, doPlay, toggleDefaultPlay) {
  return {
    type: types.ADD_GAME_ROLE_REQUEST,
    payload: { gameId, name, description, openPlanning, closePlanning, reorderTasks, selectTasks, toDoList, tasksInfo, estimateTasks, togglePlay, introduction, doPlay, toggleDefaultPlay}
  }
}

export function updateGameRole(gameRoleId, name, description, openPlanning, closePlanning, reorderTasks, selectTasks, toDoList, tasksInfo, estimateTasks, togglePlay, introduction, doPlay, toggleDefaultPlay) {
  return {
    type: types.UPDATE_GAME_ROLE_REQUEST,
    payload: { gameRoleId, name, description, openPlanning, closePlanning, reorderTasks, selectTasks, toDoList, tasksInfo, estimateTasks, togglePlay, introduction, doPlay, toggleDefaultPlay}
  }
}

export function deleteGameRole(id) {
  return {
    type: types.DELETE_GAME_ROLE_REQUEST,
    payload: { id }
  }
}

export function resetGame() {
  return {
    type: types.RESET_GAME
  }
}

export function addLevel(gameId, resultsType, gPoints, iPoints, complexity, description, individualInstruction, individualType, groupInstruction, groupType, color, deadline, deadlinePenalty, activation, timebox, commitPenalty, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, iAnswer1, iPoints1, iAnswer2, iPoints2, iAnswer3, iPoints3, iAnswer4, iPoints4, iAnswer5, iPoints5, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5, lValue1, lValue2, lValue3, lValue4, lValue5, lValue6, lValue7, lValue8, lValue9, lValue10, lValue11, lValue12, lValue13, lValue14, lValue15, lValue16, lValue17, lValue18, lValue19, lValue21, lValue22, lValue23, lValue24, lValue25, lValue26, lValue27, lValue28, lValue29, lValue30, lValue20, lColor1, lColor2, lColor3, lColor4, lColor5, lColor6, lColor7, lColor8, lColor9, lColor10, lColor11, lColor12, lColor13, lColor14, lColor15, lColor16, lColor17, lColor18, lColor19, lColor20, lColor21, lColor22, lColor23, lColor24, lColor25, lColor26, lColor27, lColor28, lColor29, lColor30, lRankToBe1, lRankToBe2, lRankToBe3, lRankToBe4, lRankToBe5, lRankToBe6, lRankToBe7, lRankToBe8, lRankToBe9, lRankToBe10, lRankToBe11, lRankToBe12, lRankToBe13, lRankToBe14, lRankToBe15, lRankToBe16, lRankToBe17, lRankToBe18, lRankToBe19, lRankToBe20, lRankToBe21, lRankToBe22, lRankToBe23, lRankToBe24, lRankToBe25, lRankToBe26, lRankToBe27, lRankToBe28, lRankToBe29, lRankToBe30) {
  return {
    type: types.ADD_LEVEL_REQUEST,
    payload: { gameId, resultsType, gPoints, iPoints, complexity, description, individualInstruction, individualType, groupInstruction, groupType, color, deadline, deadlinePenalty, activation, timebox, commitPenalty, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, iAnswer1, iPoints1, iAnswer2, iPoints2, iAnswer3, iPoints3, iAnswer4, iPoints4, iAnswer5, iPoints5, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5, lValue1, lValue2, lValue3, lValue4, lValue5, lValue6, lValue7, lValue8, lValue9, lValue10, lValue11, lValue12, lValue13, lValue14, lValue15, lValue16, lValue17, lValue18, lValue19, lValue20, lValue21, lValue22, lValue23, lValue24, lValue25, lValue26, lValue27, lValue28, lValue29, lValue30, lColor1, lColor2, lColor3, lColor4, lColor5, lColor6, lColor7, lColor8, lColor9, lColor10, lColor11, lColor12, lColor13, lColor14, lColor15, lColor16, lColor17, lColor18, lColor19, lColor20, lColor21, lColor22, lColor23, lColor24, lColor25, lColor26, lColor27, lColor28, lColor29, lColor30, lRankToBe1, lRankToBe2, lRankToBe3, lRankToBe4, lRankToBe5, lRankToBe6, lRankToBe7, lRankToBe8, lRankToBe9, lRankToBe10, lRankToBe11, lRankToBe12, lRankToBe13, lRankToBe14, lRankToBe15, lRankToBe16, lRankToBe17, lRankToBe18, lRankToBe19, lRankToBe20, lRankToBe21, lRankToBe22, lRankToBe23, lRankToBe24, lRankToBe25, lRankToBe26, lRankToBe27, lRankToBe28, lRankToBe29, lRankToBe30 }
  }
}

export function updateLevel(levelId, resultsType, gPoints, iPoints, complexity, description, individualInstruction, individualType, groupInstruction, groupType, color, deadline, deadlinePenalty, activation, timebox, commitPenalty, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, iAnswer1, iPoints1, iAnswer2, iPoints2, iAnswer3, iPoints3, iAnswer4, iPoints4, iAnswer5, iPoints5, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5, lValue1, lValue2, lValue3, lValue4, lValue5, lValue6, lValue7, lValue8, lValue9, lValue10, lValue11, lValue12, lValue13, lValue14, lValue15, lValue16, lValue17, lValue18, lValue19, lValue20, lValue21, lValue22, lValue23, lValue24, lValue25, lValue26, lValue27, lValue28, lValue29, lValue30, lColor1, lColor2, lColor3, lColor4, lColor5, lColor6, lColor7, lColor8, lColor9, lColor10, lColor11, lColor12, lColor13, lColor14, lColor15, lColor16, lColor17, lColor18, lColor19, lColor20, lColor21, lColor22, lColor23, lColor24, lColor25, lColor26, lColor27, lColor28, lColor29, lColor30, lRankToBe1, lRankToBe2, lRankToBe3, lRankToBe4, lRankToBe5, lRankToBe6, lRankToBe7, lRankToBe8, lRankToBe9, lRankToBe10, lRankToBe11, lRankToBe12, lRankToBe13, lRankToBe14, lRankToBe15, lRankToBe16, lRankToBe17, lRankToBe18, lRankToBe19, lRankToBe20, lRankToBe21, lRankToBe22, lRankToBe23, lRankToBe24, lRankToBe25, lRankToBe26, lRankToBe27, lRankToBe28, lRankToBe29, lRankToBe30) {
  return {
    type: types.UPDATE_LEVEL_REQUEST,
    payload: { levelId, resultsType, gPoints, iPoints, complexity, description, individualInstruction, individualType, groupInstruction, groupType, color, deadline, deadlinePenalty, activation, timebox, commitPenalty, iText1, iText2, iText3, iText4, iText5, iText6, iText7, iText8, iText9, iText10, iText11, iText12, iText13, iText14, iText15, iText16, iText17, iText18, iText19, iText20, iText21, iText22, iText23, iText24, iAnswer1, iPoints1, iAnswer2, iPoints2, iAnswer3, iPoints3, iAnswer4, iPoints4, iAnswer5, iPoints5, gAnswer1, gPoints1, gAnswer2, gPoints2, gAnswer3, gPoints3, gAnswer4, gPoints4, gAnswer5, gPoints5, lValue1, lValue2, lValue3, lValue4, lValue5, lValue6, lValue7, lValue8, lValue9, lValue10, lValue11, lValue12, lValue13, lValue14, lValue15, lValue16, lValue17, lValue18, lValue19, lValue20, lValue21, lValue22, lValue23, lValue24, lValue25, lValue26, lValue27, lValue28, lValue29, lValue30, lColor1, lColor2, lColor3, lColor4, lColor5, lColor6, lColor7, lColor8, lColor9, lColor10, lColor11, lColor12, lColor13, lColor14, lColor15, lColor16, lColor17, lColor18, lColor19, lColor20, lColor21, lColor22, lColor23, lColor24, lColor25, lColor26, lColor27, lColor28, lColor29, lColor30, lRankToBe1, lRankToBe2, lRankToBe3, lRankToBe4, lRankToBe5, lRankToBe6, lRankToBe7, lRankToBe8, lRankToBe9, lRankToBe10, lRankToBe11, lRankToBe12, lRankToBe13, lRankToBe14, lRankToBe15, lRankToBe16, lRankToBe17, lRankToBe18, lRankToBe19, lRankToBe20, lRankToBe21, lRankToBe22, lRankToBe23, lRankToBe24, lRankToBe25, lRankToBe26, lRankToBe27, lRankToBe28, lRankToBe29, lRankToBe30 }
  }
}

export function deleteLevel(levelId) {
  return {
    type: types.DELETE_LEVEL_REQUEST,
    payload: { levelId }
  }
}

export function readGames() {
  return {
    type: types.READ_GAMES_REQUEST
  }
}

export function addGameRoleLevel(levelId, roleId, individualOn, individualInstruction, groupOn, groupInstruction, introduction) {
  return {
    type: types.ADD_GAME_ROLE_LEVEL_REQUEST,
    payload: { levelId, roleId, individualOn, individualInstruction, groupOn, groupInstruction, introduction }
  }
}

export function updateGameRoleLevel(gameRoleLevelId, individualOn, individualInstruction, groupOn, groupInstruction, introduction) {
  return {
    type: types.UPDATE_GAME_ROLE_LEVEL_REQUEST,
    payload: { gameRoleLevelId, individualOn, individualInstruction, groupOn, groupInstruction, introduction }
  }
}

export function deleteGameRoleLevel(gameRoleLevelId) {
  return {
    type: types.DELETE_GAME_ROLE_LEVEL_REQUEST,
    payload: { gameRoleLevelId }
  }
}