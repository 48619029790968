import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledAnalytics from './analytics.styled'
import queryString from 'query-string'

import { sendEmotionFeedback, sendFullFeedback, resetFeedback } from '../../../state/actions/feedback'
import {  getFeedback } from '../../../state/selectors/feedback'

import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

export class Feedback extends Component {
 
  state = {
    isSubmitted: false,
    isError: false,
  }

  constructor(props) {
    super()
    
    this.texts = new Map();
    this.texts.set(1, {
      title: "AMBITION:",
      left: "Too chill",
      leftDesc: "We aim too low, I expect that we are more ambitious.",
      center: "As expected",
      right: "Too strained",
      rightDesc: "We aim too high, I’m afraid of burnout. I’d  expect we were more on the work-life balance side."
    });
    this.texts.set(2, {
      title: "RESULTS:",
      left: "Too mediocore",
      leftDesc: "The achieved results are lower than expected.",
      center: "As expected",
      right: "Too superfluous",
      rightDesc: "The achieved results are higher than expected.",
    });
    this.texts.set(3, {
      title: "FOCUS:",
      left: "Too loose",
      leftDesc: "Lack of  meeting goals, agenda or/and inappropriate use of time and lack of focus.",
      center: "As expected",
      right: "Too stiff",
      rightDesc: "Too much focus on meeting goals and agenda or efficient time usage prevented us from being flexible enough to cover smaller but valuable “nearby” topics."
    });
  }

  componentDidMount() {
    this.props.resetFeedback();
    const values = queryString.parse(window.location.search);
    this.key = "";
    if (values.key != null && values.key != "") {
      this.key = values.key;
    }
    this.emotion = "";
    if (values.emotion != null && values.emotion != "") {
      this.emotion = values.emotion;
    }
    if (this.key != "" && this.emotion != "") {
      this.setState({
        isError: false,
      })
    } else {
      this.setState({
        isError: true,
      })
    }
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id

    this.setState({
      [ id ]: value,
    });
  }

  submitFeedback = e => {
    e.preventDefault();
    this.props.resetFeedback();
    var behaviors = new Map();
    for (var i=1; i<=20; i++) {
      if (this.state["behavior"+i] == null && this.state["behavior" + i] == undefined) {
        break;
      }
      behaviors.set("behavior"+i, this.state["behavior"+i]);
    }
    this.props.sendFullFeedback(this.key, this.state.other, behaviors);
    this.setState({
      isSubmitted: true,
    })
  }

  changeItem = (id, value) => {
    this.setState({
      [ id ]: value,
    });
  } 

  changeEmotion = (value) => {
    this.emotion = value;
    this.props.sendEmotionFeedback(this.key, this.emotion);
    this.setState({
      emotion: value
    });
  } 
   

  render() {
    const { feedback } = this.props;    
    var key = feedback.getIn(['Feedback', 'Key'], null);
    if (key != null) {
      this.state.isLoading = false;
    }
    var organization = feedback.getIn(['Feedback', 'Organization'], null);
    var subject = feedback.getIn(['Feedback', 'Subject'], null);
    var duration = feedback.getIn(['Feedback', 'Duration'], null);
    var other = feedback.getIn(['Feedback', 'Other'], null);
    var behaviors = feedback.getIn(['Feedback', 'Behaviors'], null);
    return (
        <StyledAnalytics theme={process.theme}>
        <div class="analytics">
          <div class="all">
            <div class="header">
              <div class="organization">{organization}</div>
              <div class="subject">{subject} {duration}</div>
            </div>
            <div class="main">
            {this.state.isError && 
            <div>
              <div class="message">
                <img src="/images/feedback/error.png" alt='Something went wrong. Please try again later!' />
                <div class="messagetitle">Something went wrong. Please try again later :(</div>
                <div class="messagedesc"><a href="mailto:stavros@agify.me">Or contact us</a></div>
              </div>
            </div>
            }
            {this.state.isLoading && !this.state.isError &&
            <div class="message">
              <img src="/images/feedback/loading.png" alt='Your feedback was recieved!' />
            </div>
            }
            {!this.state.isLoading && !this.state.isSubmitted &&
            <div> 
              <div class="more">Please tell us more:</div>
              <div class="emotions">
                <div class="emotionstitle">How do you feel about your meeting?</div>
                <div class="emotionsrow">
                  <div onClick={() => this.changeEmotion(2)} class="emotion">
                    <div class={(this.emotion == 2) ? "emoicon emoiconselected pointer" : "emoicon pointer"}>😊</div>
                    <div class={(this.emotion == 2) ? "emolabel emolabelselected pointer" : "emolabel pointer"}>Great</div>
                  </div>
                  <div onClick={() => this.changeEmotion(1)}  class="emotion">
                    <div class={(this.emotion == 1) ? "emoicon emoiconselected pointer" : "emoicon pointer"}>🙂</div>
                    <div class={(this.emotion == 1) ? "emolabel emolabelselected pointer" : "emolabel pointer"}>Good</div>
                  </div>
                  <div onClick={() => this.changeEmotion(0)}  class="emotion">
                    <div class={(this.emotion == 0) ? "emoicon emoiconselected pointer" : "emoicon pointer"}>😐</div>
                    <div class={(this.emotion == 0) ? "emolabel emolabelselected pointer" : "emolabel pointer"}>OK</div>
                  </div>
                  <div onClick={() => this.changeEmotion(-1)} class="emotion ">
                    <div class={(this.emotion == -1) ? "emoicon emoiconselected pointer" : "emoicon pointer"}>🙁</div>
                    <div class={(this.emotion == -1) ? "emolabel emolabelselected pointer" : "emolabel pointer"}>Bad</div>
                  </div>
                  <div onClick={() => this.changeEmotion(-2)} class="emotion">
                    <div class={(this.emotion == -2) ? "emoicon emoiconselected pointer" : "emoicon pointer"}></div>
                    <div class={(this.emotion == -2) ? "emolabel emolabelselected pointer" : "emolabel pointer"}>Terrible</div>
                  </div>
                </div>                
              </div>
              <form onSubmit={this.submitFeedback}>
                <div class="formtitle">What did you observe during this meeting?</div>
                <div class="formdesc">(in relation to your expectations and understanding of how it should be)</div>
                <div class="inputs">
                  {behaviors.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((behavior) => {
                  var id = behavior.getIn(['Id']);
                  var text = this.texts.get(id);
                  var leftCSS = "block";
                  var centerCSS = "block";
                  var rightCSS = "block";
                  var value = this.state["behavior" + id];
                  if (value == undefined || value == null) {
                    var score = behavior.getIn(['Score']);
                    if (score == null || score == undefined) {
                      this.state["behavior" + id] = 0;
                    } else {
                      this.state["behavior" + id] = score;
                    }
                    value = this.state["behavior" + id];
                  }
                  var centerTick = "";
                  var leftTick = "";
                  var rightTick = "";
                  if (value == 0) {
                    centerCSS += " selected";
                    centerTick = <div class="tick">✔</div>;
                  } else if (value == -1) {
                    leftCSS += " selected";
                    leftTick = <div class="tick">✔</div>;
                  } else {
                    rightCSS += " selected";
                    rightTick = <div class="tick">✔</div>;
                  }
                  return (
                    <div class="behavior">
                      <div class="title">In terms of <span>{text.title}</span></div>
                      <div class="mobileblockdesctop">
                        {text.leftDesc}
                      </div>
                      <div class="row rowbuttons">
                        <a class={leftCSS} onClick={() => this.changeItem("behavior" + id, -1)}>
                          <div class="blockTitle">{leftTick}{text.left}</div>
                        </a>
                        <a class={centerCSS} onClick={() => this.changeItem("behavior" + id, 0)}>
                          <div class="blockTitle">{centerTick}{text.center}</div>
                        </a>
                        <a class={rightCSS} onClick={() => this.changeItem("behavior" + id, 1)}>
                          <div class="blockTitle">{rightTick}{text.right}</div>
                        </a>
                      </div>
                      <div class="mobileblockdescbottom">
                        {text.rightDesc}
                      </div>
                      <div class="row desktopdescs">
                        <div class="blockdesc">
                          {text.leftDesc}
                        </div>
                        <div class="blockdesc"></div>
                        <div class="blockdesc">
                        {text.rightDesc}
                        </div>
                      </div>
                    </div>
                  );}
                )}
                </div>
                <div class="behavior">
                  <div class="title">Please share some other observations:</div>
                  <div class="row">
                      <textarea rows="7" id='other' placeholder="Share your observations here..." onChange={this.handleInputChange}>{other}</textarea>
                  </div>
                </div>
                <div class='submit'>
                  <FixedButton>
                    <StandardButton isSubmit text="Submit" width="100%" bgr="#000"/>
                  </FixedButton>
                </div>  
                </form>
              </div>
              }
              {!this.state.isLoading && this.state.isSubmitted &&
              <div class="message">
                <img src="/images/feedback/recieved.png" alt='Your feedback was recieved!' />
                <div class="messagetitle">Your feedback was recieved! :)</div>
                <div class="messagedesc">An anonymized summary of all responses will be sent to you within 24h.</div>
              </div>
              }
              {!this.state.isError && !this.state.isLoading &&
              <div class="underline">
                  <div>Your feedback is required to improve team's health and performance.</div>
                  {!this.state.isSubmitted &&
                  <div>An anonymized summary of all responses will be sent to you within 24h.</div>
                  }
              </div>
              }
              </div>              
              <div class="powered">
                Powered by <span>agify.me</span>.
              </div>
          </div>
        </div>
        </StyledAnalytics>
    )
  }
}

Feedback.propTypes = {
}

const mapStateToProps = (state) => {
  return {
    feedback: getFeedback(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    resetFeedback: (key, emotion) => dispatch(resetFeedback(key, emotion)),
    sendEmotionFeedback: (key, emotion) => dispatch(sendEmotionFeedback(key, emotion)),
    sendFullFeedback: (key, other, behaviors) => dispatch(sendFullFeedback(key, other, behaviors)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
