import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledTeam from './team.styled'
import queryString from 'query-string'

import {  getTeam } from '../../../state/selectors/team'

import { readTeamByPublicKey } from '../../../state/actions/team'

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import PageTitle from '../../ui/title'
import Ranking from '../../ui/ranking'
import StandardSection from '../../ui/section'
import SubTitle from '../../ui/subtitle'
import PlayedGame from '../playedgame/playedgame'
import StandardButton from '../../ui/button'
import FixedButton from '../../ui/fixedbutton'

export class Team extends Component {
 
  constructor(props) {
    super()
    
    const values = queryString.parse(window.location.search);
    this.publicKey = "";
    if (values.id != null && values.id != "") {
      this.publicKey = values.id;
    } else if (values.Id != null && values.Id != "") {
      this.publicKey = values.Id;
    }
    if (this.publicKey != "") {
      props.fetchReadTeamByPublicKey(this.publicKey);
    }
    this.refresh = this.refresh.bind(this);
    this.sortTeams = this.sortTeams.bind(this);
  }


  refresh = () => {
    if (this.publicKey != "") {
      this.props.fetchReadTeamByPublicKey(this.publicKey);
    }
  }

  sortTeams = (a, b) => {
    var a_prop = a.getIn(['Place'], 0);
    var b_prop = b.getIn(['Place'], 0);
    if (a_prop > b_prop) {
      return -1;
    }
    if (a_prop < b_prop) {
      return 1;
    }
    return 0; 
  }

  render() {
    const { team } = this.props;
    var teamAccessKey = team.getIn(['PlayCodeTeam','AccessKey'], null);
    var name = team.getIn(['PlayCodeTeam', 'Name'], null);
    var players = team.getIn(['PlayCodeTeam', 'Players'], null);
    var playedGame = team.getIn(['PlayCodeTeam', 'PlayedGame'], null);
    var concurrentTeams = team.getIn(['PlayCodeTeam', 'ConcurrentTeams'], null);
    var allTeams = team.getIn(['PlayCodeTeam', 'AllTeams'], null);
    var showRanking = team.getIn(['PlayCodeTeam', 'ShowRanking'], null);
      return (
        <StyledTeam theme={process.theme}>
          {!team &&
          <div>
            <PageTitle title={"Информация за вашия отбор"}/>
            <StandardSection bgr="#FFF">
              <div class="note">
                Информацията за отбора се зарежда. Моля, изчакайте.
              </div>            
            </StandardSection>
          </div>
          }
          {team && name &&
            <PageTitle title={"Отбор " + name}/>
          }
          {team && !name &&
            <PageTitle title={"Информация за вашия отбор"}/>
          }
          {playedGame && concurrentTeams && showRanking &&
            <div>
              <Ranking bgColor="#E8EBE2" title="Класация в текущата игра" teams={concurrentTeams} refreshCallback={this.refresh} />
            </div>
          }
          {team && players && !playedGame &&
          <div>
            <StandardSection bgr="#FFF">
              <SubTitle text="Линк за достъп до играта"/>
              <div class="accessLink"><a href={process.theme.playerURL + "?key=" + teamAccessKey} target="_blank">{process.theme.playerURL}?key={teamAccessKey}</a></div>
              <div  class={["table", "players"].join(' ')}>
                <div class="playersTitle">Регистрирани играчи</div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Играч</TableCell>
                        <TableCell align="center">Роля</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {players.sort((a, b) => a.getIn(['Nickname'], null) - b.getIn(['Nickname'], null)).map((player) => {
                      return (
                      <TableRow>
                        <TableCell align="center">{player.getIn(['Nickname'], "")}</TableCell>
                        <TableCell align="center">{player.getIn(['Role', 'Name'], "-")}</TableCell>
                      </TableRow>
                      );}
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div class="refresh">
                  <FixedButton>
                    <StandardButton click={this.refresh} text="Опресни информацията" width="100%"/>
                  </FixedButton>
                </div>
            </div>
            </StandardSection>
          </div>
          }
          {playedGame &&
          <StandardSection bgr="#fff">
            <SubTitle text="Детайли на отборната ви игра"/>
            <PlayedGame detailedPlayedGame={playedGame} noTitles={true}/>
          </StandardSection>
          }
          {playedGame && allTeams && showRanking &&
            <div>
              <Ranking bgColor="#E8EBE2" title="Класация за всички времена" teams={allTeams} refreshCallback={this.refresh} />
            </div>
          }
        </StyledTeam>
    )
  }
}

Team.propTypes = {
}

const mapStateToProps = (state) => {
  return {
    team: getTeam(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchReadTeamByPublicKey: (publicKey) => dispatch(readTeamByPublicKey(publicKey)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Team)
