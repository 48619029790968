
import * as types from '../actions/types'
import initial from './initial'
import { setPlayedGames, setPlayedGamesError } from '../selectors/playedgame'

export default function (state = initial.playedGames, action) {
  switch (action.type) {
    case types.READ_PLAYEDGAMES_RESPONSE:
      return setPlayedGames(state, action)
    case types.READ_PLAYEDGAMES_ERROR:
      return setPlayedGamesError(state, action.error)
    default:
      return state
  }
}
