import styled from 'styled-components'

const StyledAnalytics = styled.section`
.analytics {
    background-color: #eeeeee;
    width: 100%;
}

.all {
    width: 100%;
}

.header {
    font-size: 1em;
    padding: 30px 0px;
    font-weight: bold;
    text-align: center;
    padding: 30px;
}

.organization {
    padding-bottom: 20px;
}

.main {
    width: 100%;
    background-color: #fafafa;
    border-radius: 20px;
    text-align: center;
    padding: 20px 0px;
}

.more {
    font-size: 1.4em;
    font-weight: bold;
}

.emotions {
    padding-top: 20px;
}

.emotionstitle {
    font-size: 0.9em;
    font-weight: bold;
}

.emoiconselected {
    filter: grayscale(0%) !important;
}

.emotionsrow {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emoicon {
    filter: grayscale(100%);
    font-size: 1.8em; 
}

.emolabel {
    margin-top: -5px;
    font-size: 0.8em;
    color: #5e5e5e;
}

.emolabelselected {
    font-weight: bold;
    color: #000;
}

.emotion {
    margin-bottom: 10px;
    margin: 0px 5px;
}

form {
    padding-top: 20px;
}

.formtitle {
    font-size: 0.9em;
    padding: 0 20px;
    font-weight: bold;
}

.formdesc {
    font-size: 0.9em;
    padding: 0 20px;
}

.desktopdescs {
    display: none !important;
}

form .inputs {
    width: 80% !important;
    padding-top: 30px;
}

.block {
    color: #000;
    display: block;
    padding: 10px;
}

.blockdesc {
    width: 230px;
    font-size: 0.9em;
}

.mobileblockdesctop {
    font-size: 0.8em;
    padding-bottom: 10px;
}

.mobileblockdescbottom {
    font-size: 0.8em;
    padding-top: 10px;
}

form a:hover {
    text-decoration: none;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.rowbuttons {
    border-radius: 25px;
    background-color: #f0f0f0;
}

.blockTitle {
    font-size: 0.9em;
}

.behavior {
    margin-bottom: 20px;
}

.row {
    width: 100%;
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.selected {
    position: relative;
    border: 1px solid #000;
    border-radius: 25px;
    font-weight: bold;
    background-color: white;
}

.title {
    font-size: 0.9em;
    padding-bottom: 10px;
}

.title span {
    font-weight: bold;    
}

button {
    margin-top: -20px;
}

.underline {
    width: 90%;
    margin: 30px auto 0 auto;
    border-top: 1px solid #b6b6b6;
    font-size: 0.9em;
    padding-top: 20px;
}

.powered {
    padding: 20px;
    text-align: center;
}

.powered span {
    font-weight: bold;
}

.summarytitle {
    padding-top: 20px;
    font-size: 1.5em;
    padding-bottom: 20px;
}

.responserate {
    margin-bottom: 20px;
}

.rrtitle, .otitle, .orecommendations {
    font-size: 0.9em;
    font-weight: bold;
    padding-bottom: 30px;
}

.rrvalue {
    width: 80%;
    margin: 0 auto;
    background-color: #f0f0f0;
    padding: 10px 0px;
    border-radius: 5px;
    font-size: 1em;
}

.rrvalue span {
    font-size: 2.5em;
    font-weight: bold;
}

.observations {
    margin-top: 30px;
}

.recommendations {
    margin-top: 30px;
}

.recommendation {
    font-size: 0.9em;
}

.recommendation span {
    font-weight: bold;
}

.recommendation a {
    font-weight: bold;
    color: #2670d9;
}

.pointer {
    cursor: pointer;
}

.tick {
    position: absolute;
    font-size: 15px;
    top: -7px;
    right: 0px;
    background-color: #000;
    padding: 2px 7px;
    border-radius: 15px;
    color: #fff;
}

textarea {
    border-color: #fff;
    background-color: #f0f0f0;
}

.message img {
    width: 100%;
}

.messagetitle {
    font-size: 1.2em;
    padding: 0px 10px;
    font-weight: bold;
    margin-top: 20px;
}

.messagedesc {
    margin-top: 10px;
    padding: 0px 20px;
}

.message a {
    color: #000;
    text-decoration: underline;
    text-weight: bold;
} 

@media (min-width: 1200px) {

    .all {
        width: 80%;
        margin: 0 auto;
    }

    .header {
        font-size: 1.4em;
        display: flex;
    }
    
    .organization {
        width: 20%;
        text-align: left;
        padding-bottom: 0px;
    }
    
    .subject {
        width: 80%;
        text-align: right;
    }

    .main {
        padding: 50px 0px;
    }

    .more {
        font-size: 2em;
    }

    .emotions {
        padding-top: 40px;
    }

    .emotionstitle {
        font-size: 1.4em;
    }

    .emoicon {
        font-size: 3.5em; 
    }

    .emolabel {
        margin-top: -10px;
        font-size: 1.2em;
    }

    form {
        padding-top: 50px;
    }

    form .inputs {
        width: 100% !important;
    }

    .formtitle {
        font-size: 1.4em;
    }

    .formdesc {
        font-size: 1.2em;
    }

    .blockTitle {
        font-size: 1.1em;
    }

    .row {
        display: flex;
        width: 690px;
        margin: 0 auto;
        align-items: stretch;
        margin-bottom: 25px;
    }

    .block {
        width: 230px;
    }

    .emotion {
        margin-bottom: 30px;
        margin: 0px 10px;
    }

    .title {
        font-size: 1.2em;
        padding-bottom: 20px;
    }

    .mobileblockdesctop, .mobileblockdescbottom {
        display: none;
    }

    button {
        margin-top: 0px;
    }

    .underline {
        margin: 50px auto 0 auto;
        font-size: 1.2em;
        padding-top: 40px;
    }

    .powered {
        padding: 40px;
    }

    .desktopdescs {
        display: flex !important;
    }

    .rrvalue {
        width: 350px;        
        font-size: 1.5em;
    }

    .summarytitle {
        font-size: 2.5em;
        padding-bottom: 40px;
    }

    .rrtitle, .otitle, .orecommendations {
        font-weight: bold;
        padding-bottom: 30px;
    }

    .observations {
        margin-top: 50px;
    }

    .recommendations {
        margin-top: 50px;
    }
    
    .recommendation {
        font-size: 1.2em;
    }

    .message img {
        width: 350px;
    }
    
    .messagetitle {
        font-size: 1.7em;
    }
    
    .messagedesc {
        margin-top: 10px;
    }
}
`

export default StyledAnalytics
