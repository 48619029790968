import { put, call } from 'redux-saga/effects'
import { readSubscription, updateSubscription, readSubscriptionCount, readSubscriptionIncome } from '../../server/api'
import * as types from '../actions/types'

export function* readSubscriptionSaga({ payload }) {
  try {
    const subscription = yield call(readSubscription, payload)
    yield put({ type: types.READ_SUBSCRIPTION_RESPONSE, subscription })    
  } catch (error) {
    yield put({ type: types.READ_SUBSCRIPTION_ERROR, error })
  }
}

export function* updateSubscriptionSaga({ payload }) {
  try {
    const subscription = yield call(updateSubscription, payload)
    yield put({ type: types.UPDATE_SUBSCRIPTION_RESPONSE, subscription })    
  } catch (error) {
    yield put({ type: types.UPDATE_SUBSCRIPTION_ERROR, error })
  }
}

export function* readSubscriptionCountSaga(payload) {
  try {
    const subscriptionCount = yield call(readSubscriptionCount, payload)
    yield put({ type: types.READ_SUBSCRIPTION_COUNT_RESPONSE, subscriptionCount })
  } catch (error) {
    yield put({ type: types.READ_SUBSCRIPTION_COUNT_ERROR, error })
  }
}

export function* readSubscriptionIncomeSaga(payload) {
  try {
    const subscriptionIncome = yield call(readSubscriptionIncome, payload)
    yield put({ type: types.READ_SUBSCRIPTION_INCOME_RESPONSE, subscriptionIncome })
  } catch (error) {
    yield put({ type: types.READ_SUBSCRIPTION_INCOME_ERROR, error })
  }
}
