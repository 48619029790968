import { fromJS, Map } from 'immutable'

const SERVER = process.env.REACT_APP_SERVER

export async function readSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/subscription/${payload.subscriptionId}`
  const token = btoa(`${payload.subscriptionId}:${payload.activationCode}`)
  localStorage.setItem('token', token)
  localStorage.setItem('subscriptionId', payload.subscriptionId)
  localStorage.setItem('activationCode', payload.activationCode)

  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function updateSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/subscription/${payload.subscriptionId}`
  var body = ``;
  body += (payload.organization) ? `organization=${payload.organization}&` : ``;
  body += (payload.name) ? `name=${payload.name}&` : ``;
  body += (payload.email) ? `email=${payload.email.trim()}&` : ``;
  body += (payload.phone) ? `phone=${payload.phone}&` : ``;
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function createProfile(payload) {
  const API_ENDPOINT = `${SERVER}/profile`
  var body = `firstName=${payload.firstName}&secondName=${payload.secondName}&email=${payload.email.trim()}&password=${payload.password}&`
  body += (payload.organization) ? `organization=${payload.organization}&` : ``;
  body += (payload.subscribe) ? `subscribe=${payload.subscribe}&` : ``;
  body += (payload.audience) ? `audience=${payload.audience}&` : ``;
  body += (payload.activationCode) ? `activationCode=${payload.activationCode}&` : ``;
  body += (payload.subscriptionId) ? `subscriptionId=${payload.subscriptionId}&` : ``;
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readProfile(payload) {
  const API_ENDPOINT = `${SERVER}/profile/email/${payload.email.trim()}`
  const token = btoa(`${payload.email.trim()}:${payload.password}`)
  localStorage.setItem('token', token)
  localStorage.setItem('profileEmail', payload.email.trim())
  localStorage.setItem('profilePassword', payload.password)
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readTeamByPublicKey(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/team/${payload.publicKey}`
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readTeamByAccessKey(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/team/accesskey/${payload.accessKey}`
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}


export async function updateProfile(payload) {
  const API_ENDPOINT = `${SERVER}/profile/${payload.profileId}`
  var body = ``;
  body += (payload.firstName) ? `firstName=${payload.firstName}&` : ``;
  body += (payload.secondName) ? `secondName=${payload.secondName}&` : ``;
  body += (payload.email) ? `email=${payload.email.trim()}&` : ``;
  body += (payload.organization) ? `organization=${payload.organization}&` : ``;
  body += (payload.password) ? `password=${payload.password}&` : ``;  
  body += (payload.issueInvoice != null) ? `issueInvoice=${payload.issueInvoice}&` : ``;
  body += (payload.organizationId) ? `organizationId=${payload.organizationId}&` : ``;
  body += (payload.organizationName) ? `organizationName=${payload.organizationName}&` : ``;
  body += (payload.organizationCountry) ? `organizationCountry=${payload.organizationCountry}&` : ``;
  body += (payload.organizationTown) ? `organizationTown=${payload.organizationTown}&` : ``;
  body += (payload.organizationAddress) ? `organizationAddress=${payload.organizationAddress}&` : ``;
  body += (payload.organizationContactPerson) ? `organizationContactPerson=${payload.organizationContactPerson}&` : ``;
  body += (payload.organizationNote) ? `organizationNote=${payload.organizationNote}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()  
  if (payload.email) {
    token = btoa(`${payload.email.trim()}:${payload.password}`)
    localStorage.setItem('token', token)
    localStorage.setItem('profileEmail', payload.email.trim())
    localStorage.setItem('profilePassword', payload.password)
  }  
  return await fetchedData
}

export async function cancelProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/stripe/subscription/cancel`
  var body = ``;
  body += (payload.profileId) ? `profileId=${payload.profileId}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function reactivateProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/stripe/subscription/reactivate`
  var body = ``;
  body += (payload.profileId) ? `profileId=${payload.profileId}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function upgradeProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/stripe/subscription/upgrade`
  var body = ``;
  body += (payload.profileId != null) ? `profileId=${payload.profileId}&` : ``;
  body += (payload.priceRef != null) ? `priceRef=${payload.priceRef}&` : ``;
  body += (payload.planType != null) ? `planType=${payload.planType}&` : ``;
  body += (payload.issueInvoice != null) ? `issueInvoice=${payload.issueInvoice}&` : ``;
  body += (payload.organizationId != null) ? `organizationId=${payload.organizationId}&` : ``;
  body += (payload.organizationName != null) ? `organizationName=${payload.organizationName}&` : ``;
  body += (payload.organizationCountry != null) ? `organizationCountry=${payload.organizationCountry}&` : ``;
  body += (payload.organizationTown != null) ? `organizationTown=${payload.organizationTown}&` : ``;
  body += (payload.organizationAddress != null) ? `organizationAddress=${payload.organizationAddress}&` : ``;
  body += (payload.organizationContactPerson != null) ? `organizationContactPerson=${payload.organizationContactPerson}&` : ``;
  body += (payload.organizationNote != null) ? `organizationNote=${payload.organizationNote}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function repurchaseProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/stripe/subscription/repurchase`
  var body = ``;
  body += (payload.profileId != null) ? `profileId=${payload.profileId}&` : ``;
  body += (payload.priceRef != null) ? `priceRef=${payload.priceRef}&` : ``;
  body += (payload.planType != null) ? `planType=${payload.planType}&` : ``;
  body += (payload.issueInvoice != null) ? `issueInvoice=${payload.issueInvoice}&` : ``;
  body += (payload.organizationId != null) ? `organizationId=${payload.organizationId}&` : ``;
  body += (payload.organizationName != null) ? `organizationName=${payload.organizationName}&` : ``;
  body += (payload.organizationCountry != null) ? `organizationCountry=${payload.organizationCountry}&` : ``;
  body += (payload.organizationTown != null) ? `organizationTown=${payload.organizationTown}&` : ``;
  body += (payload.organizationAddress != null) ? `organizationAddress=${payload.organizationAddress}&` : ``;
  body += (payload.organizationContactPerson != null) ? `organizationContactPerson=${payload.organizationContactPerson}&` : ``;
  body += (payload.organizationNote != null) ? `organizationNote=${payload.organizationNote}&` : ``;
  let token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readAdmin(payload) {
  const API_ENDPOINT = `${SERVER}/administrator/${payload.username}`
  const token = btoa(`${payload.username}:${payload.password}`)
  localStorage.setItem('token', token)
  localStorage.setItem('adminUsername', payload.username)
  localStorage.setItem('adminPassword', payload.password)
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function createGame(payload) {
  const API_ENDPOINT = `${SERVER}/game`
  var body = `profileId=${payload.profileId}&title=${payload.title}&description=${payload.description}&subject=${payload.subject}&access=${payload.access}&theme=${payload.theme}&complexity=${payload.complexity}&hasPlanning=${payload.hasPlanning}&platformType=${payload.platformType}&lang=${payload.lang}&`
  body += (payload.individualInstruction) ? `individualInstruction=${payload.individualInstruction}&` : ``;
  body += (payload.groupInstruction) ? `groupInstruction=${payload.groupInstruction}&` : ``;
  body += (payload.introduction) ? `introduction=${payload.introduction}&` : ``;
  body += (payload.author) ? `author=${payload.author}&` : ``;
  body += (payload.organization) ? `organization=${payload.organization}&` : ``;
  body += (payload.allowReplanning != null) ? `allowReplanning=${payload.allowReplanning}&` : ``;
  body += (payload.hasPostPlayActivity != null) ? `hasPostPlayActivity=${payload.hasPostPlayActivity}&` : ``;
  body += (payload.postPlayActivityInstruction) ? `postPlayActivityInstruction=${payload.postPlayActivityInstruction}&` : ``;
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readProfileGames(payload) {
  const API_ENDPOINT = `${SERVER}/profile/${payload.profileId}/games`;
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function createProfileSubscription(payload) {
  const API_ENDPOINT = `${SERVER}/profile/${payload.profileId}/subscription`
  const body = `subscriptionId=${payload.subscriptionId}&activationCode=${payload.activationCode}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readGame(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}`
  const token = localStorage.getItem('token');
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readGameByAccessKey(payload) {
  const API_ENDPOINT = `${SERVER}/game/accesskey/${payload.accessKey}`
  const token = localStorage.getItem('token');
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deleteGame(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function duplicateGame(payload) {
  const API_ENDPOINT = `${SERVER}/game/duplicate/${payload.gameId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function updateGame(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}`
  var body = ``;
  body += (payload.title) ? `title=${payload.title}&` : ``;
  body += (payload.description) ? `description=${payload.description}&` : ``;
  body += (payload.subject) ? `subject=${payload.subject}&` : ``;
  body += (payload.author) ? `author=${payload.author}&` : ``;
  body += (payload.organization) ? `organization=${payload.organization}&` : ``;
  body += (payload.access) ? `access=${payload.access}&` : ``;
  body += (payload.theme) ? `theme=${payload.theme}&` : ``;
  body += (payload.complexity) ? `complexity=${payload.complexity}&` : ``;
  body += (payload.individualInstruction) ? `individualInstruction=${payload.individualInstruction}&` : ``;
  body += (payload.groupInstruction) ? `groupInstruction=${payload.groupInstruction}&` : ``;
  body += (payload.introduction) ? `introduction=${payload.introduction}&` : ``;
  body += (payload.hasPlanning != null) ? `hasPlanning=${payload.hasPlanning}&` : ``;
  body += (payload.allowReplanning != null) ? `allowReplanning=${payload.allowReplanning}&` : ``;
  body += (payload.platformType) ? `platformType=${payload.platformType}&` : ``;
  body += (payload.lang) ? `lang=${payload.lang}&` : ``;
  body += (payload.hasPostPlayActivity != null) ? `hasPostPlayActivity=${payload.hasPostPlayActivity}&` : ``;
  body += (payload.postPlayActivityInstruction) ? `postPlayActivityInstruction=${payload.postPlayActivityInstruction}&` : ``;
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function addLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}/level`
  var body = ``;
  body += (payload.resultsType != null) ? `resultsType=${payload.resultsType}&` : ``;
  body += (payload.gPoints != null) ? `gPoints=${payload.gPoints}&` : ``;
  body += (payload.iPoints != null) ? `iPoints=${payload.iPoints}&` : ``;
  body += (payload.complexity != null) ? `complexity=${payload.complexity}&` : ``;
  body += (payload.color != null) ? `color=${payload.color}&` : ``;
  body += (payload.deadline != null) ? `deadline=${payload.deadline}&` : ``;
  body += (payload.deadlinePenalty != null) ? `deadlinePenalty=${payload.deadlinePenalty}&` : ``;
  body += (payload.activation != null) ? `activation=${payload.activation}&` : ``;
  body += (payload.timebox != null) ? `timebox=${payload.timebox}&` : ``;
  body += (payload.commitPenalty != null) ? `commitPenalty=${payload.commitPenalty}&` : ``;
  body += (payload.description != null) ? `description=${payload.description}&` : ``;
  body += (payload.individualInstruction != null) ? `individualInstruction=${payload.individualInstruction}&` : ``;
  body += (payload.individualType != null) ? `individualType=${payload.individualType}&` : ``;
  body += (payload.groupInstruction != null) ? `groupInstruction=${payload.groupInstruction}&` : ``;
  body += (payload.groupType != null) ? `groupType=${payload.groupType}&` : ``;
  body += (payload.iText1 != null) ? `iText1=${payload.iText1}&` : ``;
  body += (payload.iText2 != null) ? `iText2=${payload.iText2}&` : ``;
  body += (payload.iText3 != null) ? `iText3=${payload.iText3}&` : ``;
  body += (payload.iText4 != null) ? `iText4=${payload.iText4}&` : ``;
  body += (payload.iText5 != null) ? `iText5=${payload.iText5}&` : ``;
  body += (payload.iText6 != null) ? `iText6=${payload.iText6}&` : ``;
  body += (payload.iText7 != null) ? `iText7=${payload.iText7}&` : ``;
  body += (payload.iText8 != null) ? `iText8=${payload.iText8}&` : ``;
  body += (payload.iText9 != null) ? `iText9=${payload.iText9}&` : ``;
  body += (payload.iText10 != null) ? `iText10=${payload.iText10}&` : ``;  
  body += (payload.iText11 != null) ? `iText11=${payload.iText11}&` : ``;
  body += (payload.iText12 != null) ? `iText12=${payload.iText12}&` : ``;
  body += (payload.iText13 != null) ? `iText13=${payload.iText13}&` : ``;
  body += (payload.iText14 != null) ? `iText14=${payload.iText14}&` : ``;
  body += (payload.iText15 != null) ? `iText15=${payload.iText15}&` : ``;
  body += (payload.iText16 != null) ? `iText16=${payload.iText16}&` : ``;
  body += (payload.iText17 != null) ? `iText17=${payload.iText17}&` : ``;
  body += (payload.iText18 != null) ? `iText18=${payload.iText18}&` : ``;
  body += (payload.iText19 != null) ? `iText19=${payload.iText19}&` : ``;
  body += (payload.iText20 != null) ? `iText20=${payload.iText20}&` : ``;
  body += (payload.iText21 != null) ? `iText21=${payload.iText21}&` : ``;
  body += (payload.iText22 != null) ? `iText22=${payload.iText22}&` : ``;
  body += (payload.iText23 != null) ? `iText23=${payload.iText23}&` : ``;
  body += (payload.iText24 != null) ? `iText24=${payload.iText24}&` : ``; 
  body += (payload.iAnswer1 != null) ? `iAnswer1=${payload.iAnswer1}&` : ``;
  body += (payload.iPoints1 != null) ? `iPoints1=${payload.iPoints1}&` : ``;
  body += (payload.iAnswer2 != null) ? `iAnswer2=${payload.iAnswer2}&` : ``;
  body += (payload.iPoints2 != null) ? `iPoints2=${payload.iPoints2}&` : ``;
  body += (payload.iAnswer3 != null) ? `iAnswer3=${payload.iAnswer3}&` : ``;
  body += (payload.iPoints3 != null) ? `iPoints3=${payload.iPoints3}&` : ``;
  body += (payload.iAnswer4 != null) ? `iAnswer4=${payload.iAnswer4}&` : ``;
  body += (payload.iPoints4 != null) ? `iPoints4=${payload.iPoints4}&` : ``;
  body += (payload.iAnswer5 != null) ? `iAnswer5=${payload.iAnswer5}&` : ``;
  body += (payload.iPoints5 != null) ? `iPoints5=${payload.iPoints5}&` : ``;
  body += (payload.gAnswer1 != null) ? `gAnswer1=${payload.gAnswer1}&` : ``;
  body += (payload.gPoints1 != null) ? `gPoints1=${payload.gPoints1}&` : ``;
  body += (payload.gAnswer2 != null) ? `gAnswer2=${payload.gAnswer2}&` : ``;
  body += (payload.gPoints2 != null) ? `gPoints2=${payload.gPoints2}&` : ``;
  body += (payload.gAnswer3 != null) ? `gAnswer3=${payload.gAnswer3}&` : ``;
  body += (payload.gPoints3 != null) ? `gPoints3=${payload.gPoints3}&` : ``;
  body += (payload.gAnswer4 != null) ? `gAnswer4=${payload.gAnswer4}&` : ``;
  body += (payload.gPoints4 != null) ? `gPoints4=${payload.gPoints4}&` : ``;
  body += (payload.gAnswer5 != null) ? `gAnswer5=${payload.gAnswer5}&` : ``;
  body += (payload.gPoints5 != null) ? `gPoints5=${payload.gPoints5}&` : ``;
  body += (payload.isSelectable != null) ? `isSelectable=${payload.isSelectable}&` : ``;
  body += (payload.allowedSelections != null) ? `allowedSelections=${payload.allowedSelections}&` : ``;
  body += (payload.lValue1 != null) ? `lValue1=${payload.lValue1}&` : ``;
  body += (payload.lValue2 != null) ? `lValue2=${payload.lValue2}&` : ``;
  body += (payload.lValue3 != null) ? `lValue3=${payload.lValue3}&` : ``;
  body += (payload.lValue4 != null) ? `lValue4=${payload.lValue4}&` : ``;
  body += (payload.lValue5 != null) ? `lValue5=${payload.lValue5}&` : ``;
  body += (payload.lValue6 != null) ? `lValue6=${payload.lValue6}&` : ``;
  body += (payload.lValue7 != null) ? `lValue7=${payload.lValue7}&` : ``;
  body += (payload.lValue8 != null) ? `lValue8=${payload.lValue8}&` : ``;
  body += (payload.lValue9 != null) ? `lValue9=${payload.lValue9}&` : ``;
  body += (payload.lValue10 != null) ? `lValue10=${payload.lValue10}&` : ``;
  body += (payload.lValue11 != null) ? `lValue11=${payload.lValue11}&` : ``;
  body += (payload.lValue12 != null) ? `lValue12=${payload.lValue12}&` : ``;
  body += (payload.lValue13 != null) ? `lValue13=${payload.lValue13}&` : ``;
  body += (payload.lValue14 != null) ? `lValue14=${payload.lValue14}&` : ``;
  body += (payload.lValue15 != null) ? `lValue15=${payload.lValue15}&` : ``;
  body += (payload.lValue16 != null) ? `lValue16=${payload.lValue16}&` : ``;
  body += (payload.lValue17 != null) ? `lValue17=${payload.lValue17}&` : ``;
  body += (payload.lValue18 != null) ? `lValue18=${payload.lValue18}&` : ``;
  body += (payload.lValue19 != null) ? `lValue19=${payload.lValue19}&` : ``;
  body += (payload.lValue20 != null) ? `lValue20=${payload.lValue20}&` : ``;  
  body += (payload.lValue21 != null) ? `lValue21=${payload.lValue21}&` : ``;
  body += (payload.lValue22 != null) ? `lValue22=${payload.lValue22}&` : ``;
  body += (payload.lValue23 != null) ? `lValue23=${payload.lValue23}&` : ``;
  body += (payload.lValue24 != null) ? `lValue24=${payload.lValue24}&` : ``;
  body += (payload.lValue25 != null) ? `lValue25=${payload.lValue25}&` : ``;
  body += (payload.lValue26 != null) ? `lValue26=${payload.lValue26}&` : ``;
  body += (payload.lValue27 != null) ? `lValue27=${payload.lValue27}&` : ``;
  body += (payload.lValue28 != null) ? `lValue28=${payload.lValue28}&` : ``;
  body += (payload.lValue29 != null) ? `lValue29=${payload.lValue29}&` : ``;
  body += (payload.lValue30 != null) ? `lValue30=${payload.lValue30}&` : ``;
  body += (payload.lColor1 != null) ? `lColor1=${payload.lColor1}&` : ``;
  body += (payload.lColor2 != null) ? `lColor2=${payload.lColor2}&` : ``;
  body += (payload.lColor3 != null) ? `lColor3=${payload.lColor3}&` : ``;
  body += (payload.lColor4 != null) ? `lColor4=${payload.lColor4}&` : ``;
  body += (payload.lColor5 != null) ? `lColor5=${payload.lColor5}&` : ``;
  body += (payload.lColor6 != null) ? `lColor6=${payload.lColor6}&` : ``;
  body += (payload.lColor7 != null) ? `lColor7=${payload.lColor7}&` : ``;
  body += (payload.lColor8 != null) ? `lColor8=${payload.lColor8}&` : ``;
  body += (payload.lColor9 != null) ? `lColor9=${payload.lColor9}&` : ``;
  body += (payload.lColor10 != null) ? `lColor10=${payload.lColor10}&` : ``;
  body += (payload.lColor11 != null) ? `lColor11=${payload.lColor11}&` : ``;
  body += (payload.lColor12 != null) ? `lColor12=${payload.lColor12}&` : ``;
  body += (payload.lColor13 != null) ? `lColor13=${payload.lColor13}&` : ``;
  body += (payload.lColor14 != null) ? `lColor14=${payload.lColor14}&` : ``;
  body += (payload.lColor15 != null) ? `lColor15=${payload.lColor15}&` : ``;
  body += (payload.lColor16 != null) ? `lColor16=${payload.lColor16}&` : ``;
  body += (payload.lColor17 != null) ? `lColor17=${payload.lColor17}&` : ``;
  body += (payload.lColor18 != null) ? `lColor18=${payload.lColor18}&` : ``;
  body += (payload.lColor19 != null) ? `lColor19=${payload.lColor19}&` : ``;
  body += (payload.lColor20 != null) ? `lColor20=${payload.lColor20}&` : ``; 
  body += (payload.lColor21 != null) ? `lColor21=${payload.lColor21}&` : ``;
  body += (payload.lColor22 != null) ? `lColor22=${payload.lColor22}&` : ``;
  body += (payload.lColor23 != null) ? `lColor23=${payload.lColor23}&` : ``;
  body += (payload.lColor24 != null) ? `lColor24=${payload.lColor24}&` : ``;
  body += (payload.lColor25 != null) ? `lColor25=${payload.lColor25}&` : ``;
  body += (payload.lColor26 != null) ? `lColor26=${payload.lColor26}&` : ``;
  body += (payload.lColor27 != null) ? `lColor27=${payload.lColor27}&` : ``;
  body += (payload.lColor28 != null) ? `lColor28=${payload.lColor28}&` : ``;
  body += (payload.lColor29 != null) ? `lColor29=${payload.lColor29}&` : ``;
  body += (payload.lColor30 != null) ? `lColor30=${payload.lColor30}&` : ``;
  body += (payload.lRankToBe1 != null) ? `lRankToBe1=${payload.lRankToBe1}&` : ``;
  body += (payload.lRankToBe2 != null) ? `lRankToBe2=${payload.lRankToBe2}&` : ``;
  body += (payload.lRankToBe3 != null) ? `lRankToBe3=${payload.lRankToBe3}&` : ``;
  body += (payload.lRankToBe4 != null) ? `lRankToBe4=${payload.lRankToBe4}&` : ``;
  body += (payload.lRankToBe5 != null) ? `lRankToBe5=${payload.lRankToBe5}&` : ``;
  body += (payload.lRankToBe6 != null) ? `lRankToBe6=${payload.lRankToBe6}&` : ``;
  body += (payload.lRankToBe7 != null) ? `lRankToBe7=${payload.lRankToBe7}&` : ``;
  body += (payload.lRankToBe8 != null) ? `lRankToBe8=${payload.lRankToBe8}&` : ``;
  body += (payload.lRankToBe9 != null) ? `lRankToBe9=${payload.lRankToBe9}&` : ``;
  body += (payload.lRankToBe10 != null) ? `lRankToBe10=${payload.lRankToBe10}&` : ``;
  body += (payload.lRankToBe11 != null) ? `lRankToBe11=${payload.lRankToBe11}&` : ``;
  body += (payload.lRankToBe12 != null) ? `lRankToBe12=${payload.lRankToBe12}&` : ``;
  body += (payload.lRankToBe13 != null) ? `lRankToBe13=${payload.lRankToBe13}&` : ``;
  body += (payload.lRankToBe14 != null) ? `lRankToBe14=${payload.lRankToBe14}&` : ``;
  body += (payload.lRankToBe15 != null) ? `lRankToBe15=${payload.lRankToBe15}&` : ``;
  body += (payload.lRankToBe16 != null) ? `lRankToBe16=${payload.lRankToBe16}&` : ``;
  body += (payload.lRankToBe17 != null) ? `lRankToBe17=${payload.lRankToBe17}&` : ``;
  body += (payload.lRankToBe18 != null) ? `lRankToBe18=${payload.lRankToBe18}&` : ``;
  body += (payload.lRankToBe19 != null) ? `lRankToBe19=${payload.lRankToBe19}&` : ``;
  body += (payload.lRankToBe20 != null) ? `lRankToBe20=${payload.lRankToBe20}&` : ``; 
  body += (payload.lRankToBe21 != null) ? `lRankToBe21=${payload.lRankToBe21}&` : ``;
  body += (payload.lRankToBe22 != null) ? `lRankToBe22=${payload.lRankToBe22}&` : ``;
  body += (payload.lRankToBe23 != null) ? `lRankToBe23=${payload.lRankToBe23}&` : ``;
  body += (payload.lRankToBe24 != null) ? `lRankToBe24=${payload.lRankToBe24}&` : ``;
  body += (payload.lRankToBe25 != null) ? `lRankToBe25=${payload.lRankToBe25}&` : ``;
  body += (payload.lRankToBe26 != null) ? `lRankToBe26=${payload.lRankToBe26}&` : ``;
  body += (payload.lRankToBe27 != null) ? `lRankToBe27=${payload.lRankToBe27}&` : ``;
  body += (payload.lRankToBe28 != null) ? `lRankToBe28=${payload.lRankToBe28}&` : ``;
  body += (payload.lRankToBe29 != null) ? `lRankToBe29=${payload.lRankToBe29}&` : ``;
  body += (payload.lRankToBe30 != null) ? `lRankToBe30=${payload.lRankToBe30}&` : ``;
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function updateLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/level/${payload.levelId}`
  var body = ``;  
  body += (payload.resultsType != null) ? `resultsType=${payload.resultsType}&` : ``;
  body += (payload.gPoints != null) ? `gPoints=${payload.gPoints}&` : ``;
  body += (payload.iPoints != null) ? `iPoints=${payload.iPoints}&` : ``;
  body += (payload.complexity) ? `complexity=${payload.complexity}&` : ``;
  body += (payload.color != null) ? `color=${payload.color}&` : ``;
  body += (payload.deadline != null) ? `deadline=${payload.deadline}&` : ``;
  body += (payload.deadlinePenalty != null) ? `deadlinePenalty=${payload.deadlinePenalty}&` : ``;
  body += (payload.activation != null) ? `activation=${payload.activation}&` : ``;
  body += (payload.timebox != null) ? `timebox=${payload.timebox}&` : ``;
  body += (payload.commitPenalty != null) ? `commitPenalty=${payload.commitPenalty}&` : ``;
  body += (payload.description) ? `description=${payload.description}&` : ``;
  body += (payload.individualInstruction) ? `individualInstruction=${payload.individualInstruction}&` : ``;
  body += (payload.individualType != null) ? `individualType=${payload.individualType}&` : ``;
  body += (payload.groupInstruction) ? `groupInstruction=${payload.groupInstruction}&` : ``;
  body += (payload.groupType != null) ? `groupType=${payload.groupType}&` : ``;
  body += (payload.iText1 != null) ? `iText1=${payload.iText1}&` : ``;
  body += (payload.iText2 != null) ? `iText2=${payload.iText2}&` : ``;
  body += (payload.iText3 != null) ? `iText3=${payload.iText3}&` : ``;
  body += (payload.iText4 != null) ? `iText4=${payload.iText4}&` : ``;
  body += (payload.iText5 != null) ? `iText5=${payload.iText5}&` : ``;
  body += (payload.iText6 != null) ? `iText6=${payload.iText6}&` : ``;
  body += (payload.iText7 != null) ? `iText7=${payload.iText7}&` : ``;
  body += (payload.iText8 != null) ? `iText8=${payload.iText8}&` : ``;
  body += (payload.iText9 != null) ? `iText9=${payload.iText9}&` : ``;
  body += (payload.iText10 != null) ? `iText10=${payload.iText10}&` : ``;
  body += (payload.iText11 != null) ? `iText11=${payload.iText11}&` : ``;
  body += (payload.iText12 != null) ? `iText12=${payload.iText12}&` : ``;
  body += (payload.iText13 != null) ? `iText13=${payload.iText13}&` : ``;
  body += (payload.iText14 != null) ? `iText14=${payload.iText14}&` : ``;
  body += (payload.iText15 != null) ? `iText15=${payload.iText15}&` : ``;
  body += (payload.iText16 != null) ? `iText16=${payload.iText16}&` : ``;
  body += (payload.iText17 != null) ? `iText17=${payload.iText17}&` : ``;
  body += (payload.iText18 != null) ? `iText18=${payload.iText18}&` : ``;
  body += (payload.iText19 != null) ? `iText19=${payload.iText19}&` : ``;
  body += (payload.iText20 != null) ? `iText20=${payload.iText20}&` : ``;
  body += (payload.iText21 != null) ? `iText21=${payload.iText21}&` : ``;
  body += (payload.iText22 != null) ? `iText22=${payload.iText22}&` : ``;
  body += (payload.iText23 != null) ? `iText23=${payload.iText23}&` : ``;
  body += (payload.iText24 != null) ? `iText24=${payload.iText24}&` : ``;
  body += (payload.iAnswer1 != null) ? `iAnswer1=${payload.iAnswer1}&` : ``;
  body += (payload.iPoints1 != null) ? `iPoints1=${payload.iPoints1}&` : ``;
  body += (payload.iAnswer2 != null) ? `iAnswer2=${payload.iAnswer2}&` : ``;
  body += (payload.iPoints2 != null) ? `iPoints2=${payload.iPoints2}&` : ``;
  body += (payload.iAnswer3 != null) ? `iAnswer3=${payload.iAnswer3}&` : ``;
  body += (payload.iPoints3 != null) ? `iPoints3=${payload.iPoints3}&` : ``;
  body += (payload.iAnswer4 != null) ? `iAnswer4=${payload.iAnswer4}&` : ``;
  body += (payload.iPoints4 != null) ? `iPoints4=${payload.iPoints4}&` : ``;
  body += (payload.iAnswer5 != null) ? `iAnswer5=${payload.iAnswer5}&` : ``;
  body += (payload.iPoints5 != null) ? `iPoints5=${payload.iPoints5}&` : ``;
  body += (payload.gAnswer1 != null) ? `gAnswer1=${payload.gAnswer1}&` : ``;
  body += (payload.gPoints1 != null) ? `gPoints1=${payload.gPoints1}&` : ``;
  body += (payload.gAnswer2 != null) ? `gAnswer2=${payload.gAnswer2}&` : ``;
  body += (payload.gPoints2 != null) ? `gPoints2=${payload.gPoints2}&` : ``;
  body += (payload.gAnswer3 != null) ? `gAnswer3=${payload.gAnswer3}&` : ``;
  body += (payload.gPoints3 != null) ? `gPoints3=${payload.gPoints3}&` : ``;
  body += (payload.gAnswer4 != null) ? `gAnswer4=${payload.gAnswer4}&` : ``;
  body += (payload.gPoints4 != null) ? `gPoints4=${payload.gPoints4}&` : ``;
  body += (payload.gAnswer5 != null) ? `gAnswer5=${payload.gAnswer5}&` : ``;
  body += (payload.gPoints5 != null) ? `gPoints5=${payload.gPoints5}&` : ``;
  body += (payload.isSelectable != null) ? `isSelectable=${payload.isSelectable}&` : ``;
  body += (payload.allowedSelections != null) ? `allowedSelections=${payload.allowedSelections}&` : ``;
  body += (payload.lValue1 != null) ? `lValue1=${payload.lValue1}&` : ``;
  body += (payload.lValue2 != null) ? `lValue2=${payload.lValue2}&` : ``;
  body += (payload.lValue3 != null) ? `lValue3=${payload.lValue3}&` : ``;
  body += (payload.lValue4 != null) ? `lValue4=${payload.lValue4}&` : ``;
  body += (payload.lValue5 != null) ? `lValue5=${payload.lValue5}&` : ``;
  body += (payload.lValue6 != null) ? `lValue6=${payload.lValue6}&` : ``;
  body += (payload.lValue7 != null) ? `lValue7=${payload.lValue7}&` : ``;
  body += (payload.lValue8 != null) ? `lValue8=${payload.lValue8}&` : ``;
  body += (payload.lValue9 != null) ? `lValue9=${payload.lValue9}&` : ``;
  body += (payload.lValue10 != null) ? `lValue10=${payload.lValue10}&` : ``;
  body += (payload.lValue11 != null) ? `lValue11=${payload.lValue11}&` : ``;
  body += (payload.lValue12 != null) ? `lValue12=${payload.lValue12}&` : ``;
  body += (payload.lValue13 != null) ? `lValue13=${payload.lValue13}&` : ``;
  body += (payload.lValue14 != null) ? `lValue14=${payload.lValue14}&` : ``;
  body += (payload.lValue15 != null) ? `lValue15=${payload.lValue15}&` : ``;
  body += (payload.lValue16 != null) ? `lValue16=${payload.lValue16}&` : ``;
  body += (payload.lValue17 != null) ? `lValue17=${payload.lValue17}&` : ``;
  body += (payload.lValue18 != null) ? `lValue18=${payload.lValue18}&` : ``;
  body += (payload.lValue19 != null) ? `lValue19=${payload.lValue19}&` : ``;
  body += (payload.lValue20 != null) ? `lValue20=${payload.lValue20}&` : ``;  
  body += (payload.lValue21 != null) ? `lValue21=${payload.lValue21}&` : ``;
  body += (payload.lValue22 != null) ? `lValue22=${payload.lValue22}&` : ``;
  body += (payload.lValue23 != null) ? `lValue23=${payload.lValue23}&` : ``;
  body += (payload.lValue24 != null) ? `lValue24=${payload.lValue24}&` : ``;
  body += (payload.lValue25 != null) ? `lValue25=${payload.lValue25}&` : ``;
  body += (payload.lValue26 != null) ? `lValue26=${payload.lValue26}&` : ``;
  body += (payload.lValue27 != null) ? `lValue27=${payload.lValue27}&` : ``;
  body += (payload.lValue28 != null) ? `lValue28=${payload.lValue28}&` : ``;
  body += (payload.lValue29 != null) ? `lValue29=${payload.lValue29}&` : ``;
  body += (payload.lValue30 != null) ? `lValue30=${payload.lValue30}&` : ``;
  body += (payload.lColor1 != null) ? `lColor1=${payload.lColor1}&` : ``;
  body += (payload.lColor2 != null) ? `lColor2=${payload.lColor2}&` : ``;
  body += (payload.lColor3 != null) ? `lColor3=${payload.lColor3}&` : ``;
  body += (payload.lColor4 != null) ? `lColor4=${payload.lColor4}&` : ``;
  body += (payload.lColor5 != null) ? `lColor5=${payload.lColor5}&` : ``;
  body += (payload.lColor6 != null) ? `lColor6=${payload.lColor6}&` : ``;
  body += (payload.lColor7 != null) ? `lColor7=${payload.lColor7}&` : ``;
  body += (payload.lColor8 != null) ? `lColor8=${payload.lColor8}&` : ``;
  body += (payload.lColor9 != null) ? `lColor9=${payload.lColor9}&` : ``;
  body += (payload.lColor10 != null) ? `lColor10=${payload.lColor10}&` : ``;
  body += (payload.lColor11 != null) ? `lColor11=${payload.lColor11}&` : ``;
  body += (payload.lColor12 != null) ? `lColor12=${payload.lColor12}&` : ``;
  body += (payload.lColor13 != null) ? `lColor13=${payload.lColor13}&` : ``;
  body += (payload.lColor14 != null) ? `lColor14=${payload.lColor14}&` : ``;
  body += (payload.lColor15 != null) ? `lColor15=${payload.lColor15}&` : ``;
  body += (payload.lColor16 != null) ? `lColor16=${payload.lColor16}&` : ``;
  body += (payload.lColor17 != null) ? `lColor17=${payload.lColor17}&` : ``;
  body += (payload.lColor18 != null) ? `lColor18=${payload.lColor18}&` : ``;
  body += (payload.lColor19 != null) ? `lColor19=${payload.lColor19}&` : ``;
  body += (payload.lColor20 != null) ? `lColor20=${payload.lColor20}&` : ``; 
  body += (payload.lColor21 != null) ? `lColor21=${payload.lColor21}&` : ``;
  body += (payload.lColor22 != null) ? `lColor22=${payload.lColor22}&` : ``;
  body += (payload.lColor23 != null) ? `lColor23=${payload.lColor23}&` : ``;
  body += (payload.lColor24 != null) ? `lColor24=${payload.lColor24}&` : ``;
  body += (payload.lColor25 != null) ? `lColor25=${payload.lColor25}&` : ``;
  body += (payload.lColor26 != null) ? `lColor26=${payload.lColor26}&` : ``;
  body += (payload.lColor27 != null) ? `lColor27=${payload.lColor27}&` : ``;
  body += (payload.lColor28 != null) ? `lColor28=${payload.lColor28}&` : ``;
  body += (payload.lColor29 != null) ? `lColor29=${payload.lColor29}&` : ``;
  body += (payload.lColor30 != null) ? `lColor30=${payload.lColor30}&` : ``;
  body += (payload.lRankToBe1 != null) ? `lRankToBe1=${payload.lRankToBe1}&` : ``;
  body += (payload.lRankToBe2 != null) ? `lRankToBe2=${payload.lRankToBe2}&` : ``;
  body += (payload.lRankToBe3 != null) ? `lRankToBe3=${payload.lRankToBe3}&` : ``;
  body += (payload.lRankToBe4 != null) ? `lRankToBe4=${payload.lRankToBe4}&` : ``;
  body += (payload.lRankToBe5 != null) ? `lRankToBe5=${payload.lRankToBe5}&` : ``;
  body += (payload.lRankToBe6 != null) ? `lRankToBe6=${payload.lRankToBe6}&` : ``;
  body += (payload.lRankToBe7 != null) ? `lRankToBe7=${payload.lRankToBe7}&` : ``;
  body += (payload.lRankToBe8 != null) ? `lRankToBe8=${payload.lRankToBe8}&` : ``;
  body += (payload.lRankToBe9 != null) ? `lRankToBe9=${payload.lRankToBe9}&` : ``;
  body += (payload.lRankToBe10 != null) ? `lRankToBe10=${payload.lRankToBe10}&` : ``;
  body += (payload.lRankToBe11 != null) ? `lRankToBe11=${payload.lRankToBe11}&` : ``;
  body += (payload.lRankToBe12 != null) ? `lRankToBe12=${payload.lRankToBe12}&` : ``;
  body += (payload.lRankToBe13 != null) ? `lRankToBe13=${payload.lRankToBe13}&` : ``;
  body += (payload.lRankToBe14 != null) ? `lRankToBe14=${payload.lRankToBe14}&` : ``;
  body += (payload.lRankToBe15 != null) ? `lRankToBe15=${payload.lRankToBe15}&` : ``;
  body += (payload.lRankToBe16 != null) ? `lRankToBe16=${payload.lRankToBe16}&` : ``;
  body += (payload.lRankToBe17 != null) ? `lRankToBe17=${payload.lRankToBe17}&` : ``;
  body += (payload.lRankToBe18 != null) ? `lRankToBe18=${payload.lRankToBe18}&` : ``;
  body += (payload.lRankToBe19 != null) ? `lRankToBe19=${payload.lRankToBe19}&` : ``;
  body += (payload.lRankToBe20 != null) ? `lRankToBe20=${payload.lRankToBe20}&` : ``; 
  body += (payload.lRankToBe21 != null) ? `lRankToBe21=${payload.lRankToBe21}&` : ``;
  body += (payload.lRankToBe22 != null) ? `lRankToBe22=${payload.lRankToBe22}&` : ``;
  body += (payload.lRankToBe23 != null) ? `lRankToBe23=${payload.lRankToBe23}&` : ``;
  body += (payload.lRankToBe24 != null) ? `lRankToBe24=${payload.lRankToBe24}&` : ``;
  body += (payload.lRankToBe25 != null) ? `lRankToBe25=${payload.lRankToBe25}&` : ``;
  body += (payload.lRankToBe26 != null) ? `lRankToBe26=${payload.lRankToBe26}&` : ``;
  body += (payload.lRankToBe27 != null) ? `lRankToBe27=${payload.lRankToBe27}&` : ``;
  body += (payload.lRankToBe28 != null) ? `lRankToBe28=${payload.lRankToBe28}&` : ``;
  body += (payload.lRankToBe29 != null) ? `lRankToBe29=${payload.lRankToBe29}&` : ``;
  body += (payload.lRankToBe30 != null) ? `lRankToBe30=${payload.lRankToBe30}&` : ``;
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deleteLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/level/${payload.levelId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function addGameType(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}/type`
  var body = ``;
  body += (payload.name != null) ? `name=${payload.name}&` : ``;
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deleteGameType(payload) {
  const API_ENDPOINT = `${SERVER}/game/type/${payload.id}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function addGameTopic(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}/topic`
  var body = ``;
  body += (payload.name != null) ? `name=${payload.name}&` : ``;
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deleteGameTopic(payload) {
  const API_ENDPOINT = `${SERVER}/game/topic/${payload.id}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function addGameRole(payload) {
  const API_ENDPOINT = `${SERVER}/game/${payload.gameId}/role`
  var body = ``;
  body += (payload.name != null) ? `name=${payload.name}&` : ``;
  body += (payload.description != null) ? `description=${payload.description}&` : ``;
  body += (payload.openPlanning != null) ? `openPlanning=${payload.openPlanning}&` : ``;
  body += (payload.closePlanning != null) ? `closePlanning=${payload.closePlanning}&` : ``;
  body += (payload.reorderTasks != null) ? `reorderTasks=${payload.reorderTasks}&` : ``;
  body += (payload.selectTasks != null) ? `selectTasks=${payload.selectTasks}&` : ``;
  body += (payload.toDoList != null) ? `toDoList=${payload.toDoList}&` : ``;
  body += (payload.tasksInfo != null) ? `tasksInfo=${payload.tasksInfo}&` : ``;
  body += (payload.estimateTasks != null) ? `estimateTasks=${payload.estimateTasks}&` : ``;
  body += (payload.togglePlay != null) ? `togglePlay=${payload.togglePlay}&` : ``;
  body += (payload.introduction != null) ? `introduction=${payload.introduction}&` : ``;  
  body += (payload.doPlay != null) ? `doPlay=${payload.doPlay}&` : ``;  
  body += (payload.toggleDefaultPlay != null) ? `toggleDefaultPlay=${payload.toggleDefaultPlay}&` : ``;  
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function updateGameRole(payload) {
  const API_ENDPOINT = `${SERVER}/game/role/${payload.gameRoleId}`
  var body = ``;
  body += (payload.name != null) ? `name=${payload.name}&` : ``;
  body += (payload.description != null) ? `description=${payload.description}&` : ``;
  body += (payload.openPlanning != null) ? `openPlanning=${payload.openPlanning}&` : ``;
  body += (payload.closePlanning != null) ? `closePlanning=${payload.closePlanning}&` : ``;
  body += (payload.reorderTasks != null) ? `reorderTasks=${payload.reorderTasks}&` : ``;
  body += (payload.selectTasks != null) ? `selectTasks=${payload.selectTasks}&` : ``;
  body += (payload.toDoList != null) ? `toDoList=${payload.toDoList}&` : ``;
  body += (payload.tasksInfo != null) ? `tasksInfo=${payload.tasksInfo}&` : ``;
  body += (payload.estimateTasks != null) ? `estimateTasks=${payload.estimateTasks}&` : ``;
  body += (payload.togglePlay != null) ? `togglePlay=${payload.togglePlay}&` : ``;
  body += (payload.introduction != null) ? `introduction=${payload.introduction}&` : ``;  
  body += (payload.doPlay != null) ? `doPlay=${payload.doPlay}&` : ``;  
  body += (payload.toggleDefaultPlay != null) ? `toggleDefaultPlay=${payload.toggleDefaultPlay}&` : ``;  
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deleteGameRole(payload) {
  const API_ENDPOINT = `${SERVER}/game/role/${payload.id}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readGames(payload) {
  const API_ENDPOINT = `${SERVER}/game/all`
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function forgottenPassword(payload) {
  const API_ENDPOINT = `${SERVER}/email/forgottenPassword`
  var body = `email=${payload.trim()}`;
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readSubscriptionCount(payload) {
  const API_ENDPOINT = `${SERVER}/subscription/stats/count`
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readSubscriptionIncome(payload) {
  const API_ENDPOINT = `${SERVER}/subscription/stats/income`
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function createPlayCode(payload) {
  const API_ENDPOINT = `${SERVER}/playcode`
  var body = `profileId=${payload.profileId}&gameId=${payload.gameId}&minutes=${payload.minutes}&theme=${payload.theme}&isVirtual=${payload.isVirtual}&`
  body += (payload.subscriptionId) ? `subscriptionId=${payload.subscriptionId}&` : ""
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readPlayCode(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/${payload.id}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readPlayCodes(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/profile/${payload.profileId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readLastPlayCode(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/profile/${payload.profileId}/last`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function addPlayCodeTeam(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/accesskey/${payload.accessKey}/team`
  const body = `mark=${payload.teamMark}&profileId=${payload.profileId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deletePlayCodeTeam(payload) {
  const API_ENDPOINT = `${SERVER}/playcode/team/${payload.id}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function createPlayedGame(payload) {
  const API_ENDPOINT = `${SERVER}/playedgame/store`
  var body = ``;
  body += (payload.accessKey != null) ? `accessKey=${payload.accessKey}&` : ``;
  body += (payload.score != null) ? `score=${payload.score}&` : ``;
  body += (payload.teamName != null) ? `teamName=${payload.teamName}&` : ``;
  body += (payload.playTime != null) ? `playTime=${payload.playTime}&` : ``;
  body += (payload.state != null) ? `state=${payload.state}&` : ``;
  body += (payload.isVirtual != null) ? `isVirtual=${payload.isVirtual}&` : ``;
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readPlayedGame(payload) {
  const API_ENDPOINT = `${SERVER}/playedgame/${payload.id}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readPlayedGames(payload) {
  const API_ENDPOINT = `${SERVER}/playedgame/profile/${payload.profileId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readDetailedPlayedGames(payload) {
  const API_ENDPOINT = `${SERVER}/playedgame/playcode/${payload.playCode}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function addGameRoleLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/role/${payload.roleId}/level/${payload.levelId}`
  alert(API_ENDPOINT);
  var body = ``;
  body += (payload.individualOn != null) ? `individualOn=${payload.individualOn}&` : ``;
  body += (payload.individualInstruction != null) ? `individualInstruction=${payload.individualInstruction}&` : ``;
  body += (payload.groupOn != null) ? `groupOn=${payload.groupOn}&` : ``;
  body += (payload.groupInstruction != null) ? `groupInstruction=${payload.groupInstruction}&` : ``; 
  body += (payload.introduction != null) ? `introduction=${payload.introduction}&` : ``; 
  body = body.split("+").join(encodeURIComponent("+"));
  alert(body);
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function updateGameRoleLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/rolelevel/${payload.gameRoleLevelId}`
  var body = ``;
  body += (payload.individualOn != null) ? `individualOn=${payload.individualOn}&` : ``;
  body += (payload.individualInstruction != null) ? `individualInstruction=${payload.individualInstruction}&` : ``;
  body += (payload.groupOn != null) ? `groupOn=${payload.groupOn}&` : ``;
  body += (payload.groupInstruction != null) ? `groupInstruction=${payload.groupInstruction}&` : ``; 
  body += (payload.introduction != null) ? `introduction=${payload.introduction}&` : ``; 
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })

  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function deleteGameRoleLevel(payload) {
  const API_ENDPOINT = `${SERVER}/game/rolelevel/${payload.gameRoleLevelId}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'DELETE',
    headers: {
      'Authorization': `Basic ${token}`,
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function sendEmotionFeedback(payload) {
  const API_ENDPOINT = `${SERVER}/feedback/emotion`
  var body = ``;
  body += (payload.key != null) ? `key=${payload.key}&` : ``;
  body += (payload.emotion != null) ? `emotion=${payload.emotion}&` : ``;
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function sendFullFeedback(payload) {
  const API_ENDPOINT = `${SERVER}/feedback/full`
  var body = ``;
  body += (payload.key != null) ? `key=${payload.key}&` : ``;
  body += (payload.other != null) ? `other=${payload.other}&` : ``;
  payload.behaviors.forEach((value, key) => {
    body += `${key}=${value}&`;
  })
  body = body.split("+").join(encodeURIComponent("+"));
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    },
    body: body
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}

export async function readReport(payload) {
  const API_ENDPOINT = `${SERVER}/report/${payload.key}`
  const token = localStorage.getItem('token')
  let fetchedData = await fetch(API_ENDPOINT, {
    method: 'GET',
    headers: {
      'Authorization': `Basic ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      'accept': 'application/json'
    }
  })
  fetchedData = await fetchedData.json()
  return await fetchedData
}