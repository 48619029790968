import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'

import StyledEditRole from './role.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import { addGameRole, updateGameRole, deleteGameRole } from '../../../state/actions/game'

import Content from '../../ui/content'

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
  } from 'react-accessible-accordion';

  

export class EditRole extends Component {
  
    constructor(props) {
      super()

      this.state = {
        isUpdate: false,
        isChanged: false,
        isDeleted: false,
        isAdded: false,
      }
      
      this.submitAddRole = this.submitAddRole.bind(this);
      this.closeAddRole = this.closeAddRole.bind(this);
      this.deleteRole = this.deleteRole.bind(this);
    }

    handleInputChange = (event) => {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const id = target.id
        this.setState({
          [ id ]: value,
          isChanged: true,
        });
    }    

    addNewRole = (e) => {
        this.setState(() => ({    
            
        }));
    }

    submitAddRole  = e => {
        e.preventDefault();
        const { game, role } = this.props;
        if (this.state.isUpdate) {
            const gameRoleId = role.getIn(['Id'], null);  
            this.props.fetchUpdateGameRole(gameRoleId, this.state.name, this.state.description, this.state.openPlanning, this.state.closePlanning, this.state.reorderTasks, this.state.selectTasks, this.state.toDoList, this.state.tasksInfo, this.state.estimateTaks, this.state.togglePlay, this.state.introduction, this.state.doPlay, this.state.toggleDefaultPlay);
            this.setState({
                isChanged: false,
                isDeleted: false,
                isAdded: false,
            });    
        } else {
            const gameId = game.getIn(['Game', 'Id'], null);
            this.props.fetchAddGameRole(gameId, this.state.name, this.state.description, this.state.openPlanning, this.state.closePlanning, this.state.reorderTasks, this.state.selectTasks, this.state.toDoList, this.state.tasksInfo, this.state.estimateTaks, this.state.togglePlay, this.state.introduction, this.state.doPlay, this.state.toggleDefaultPlay);
            this.props.deleteNewRole(this.props.sequence);
        }
        
    }

    submitDeleteExistingRole = e => {
        e.preventDefault();
        let { role } = this.props;
        let roleId = role.getIn(['Id'], null);
        this.props.fetchDeleteGameRole(roleId);
    }

    submitDeleteNewRole = e => {
        e.preventDefault()
        this.props.deleteNewRole(this.props.sequence);
    }

    closeAddRole = (e) => {
        this.setState(() => ({
            addRole: false,
        }));
    }

    deleteRole = (id) => {
    this.props.fetchDeleteGameRole(id);
    }

    clearChanges = () => {
        this.setState(state => ({
          isChanged: false,
          openPlanning: null,
          closePlanning: null,
          reorderTasks: null,
          selectTasks: null,
          toDoList: null,
          tasksInfo: null,
          estimateTaks: null,
          togglePlay: null,
          introduction: null,
          doPlay: null,
          toggleDefaultPlay: null,
        }));
      }

    render() {
        let {game, role, id, notOwned} = this.props;
        if (role != null && role != undefined) {
            this.state.isUpdate = true;
        }
        const name = (this.state.isUpdate) ? role.getIn(['Name'], null) : "";
        if (this.state.name == null) {
            this.state.name = name;
        }
        const description = (this.state.isUpdate) ? role.getIn(['Description'], null) : "";
        if (this.state.description == null) {
            this.state.description = description;
        }
        const toDoList = (this.state.isUpdate) ? role.getIn(['ToDoList'], null) : false;
        if (this.state.toDoList == null) {
            this.state.toDoList = toDoList;
        }
        const openPlanning = (this.state.isUpdate) ? role.getIn(['OpenPlanning'], null) : false;
        if (this.state.openPlanning == null) {
            this.state.openPlanning = openPlanning;
        }
        const closePlanning = (this.state.isUpdate) ? role.getIn(['OpenPlanning'], null) : false;
        if (this.state.closePlanning == null) {
            this.state.closePlanning = closePlanning;
        }
        const tasksInfo = (this.state.isUpdate) ? role.getIn(['TasksInfo'], null) : false;
        if (this.state.tasksInfo == null) {
            this.state.tasksInfo = tasksInfo;
        }
        const reorderTasks = (this.state.isUpdate) ? role.getIn(['ReorderTasks'], null) : false;
        if (this.state.reorderTasks == null) {
            this.state.reorderTasks = reorderTasks;
        }
        const selectTasks = (this.state.isUpdate) ? role.getIn(['SelectTasks'], null) : false;
        if (this.state.selectTasks == null) {
            this.state.selectTasks = selectTasks;
        }
        const estimateTaks = (this.state.isUpdate) ? role.getIn(['EstimateTasks'], null) : false;
        if (this.state.estimateTaks == null) {
            this.state.estimateTaks = estimateTaks;
        }
        const doPlay = (this.state.isUpdate) ? role.getIn(['DoPlay'], null) : false;
        if (this.state.doPlay == null) {
            this.state.doPlay = doPlay;
        }
        const togglePlay = (this.state.isUpdate) ? role.getIn(['TogglePlay'], null) : false;
        if (this.state.togglePlay == null) {
            this.state.togglePlay = togglePlay;
        }
        const toggleDefaultPlay = (this.state.isUpdate) ? role.getIn(['ToggleDefaultPlay'], null) : false;
        if (this.state.toggleDefaultPlay == null) {
            this.state.toggleDefaultPlay = toggleDefaultPlay;
        }
        
        const introduction = (this.state.isUpdate) ? role.getIn(['Introduction'], "") : null;
        if (this.state.introduction == null) {
            this.state.introduction = introduction;
        }
        let expandedId = (this.state.isUpdate) ? -1 : this.props.id;
        return (
        <Accordion allowZeroExpanded allowMultipleExpanded preExpanded={[expandedId]}>
            <AccordionItem uuid={"e" + this.props.id}>
            <StyledEditRole>
                <div class={(this.props.id % 2 == 0) ? "accordion__heading_1" : "accordion__heading_2"}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                    {"Роля " + name}{(this.state.isChanged || this.state.isDeleted || this.state.isAdded) && "*"} <span class="level_desc"></span>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <Content>
                    <div class="addroleform">
                        <form onSubmit={this.submitAddRole}>
                        <AccordionItemPanel>
                        <div class="inputs">
                            <div class="row">
                                <input id='name' name="name" value={this.state.name} required placeholder="Име на ролята*" onChange={this.handleInputChange}/>
                            </div>
                            <div class="row">
                                <input id='description' value={this.state.description}  {...(!this.state.isChanged ? {value: description} : {})} required placeholder="Описание на ролята*" onChange={this.handleInputChange}/>
                            </div>
                            <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.toDoList ? {checked: "checked"} : {})} id="toDoList" name="toDoList" onChange={this.handleInputChange}/>
                                    <label for="toDoList">Да може да достъпва списъка със задачи.</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.openPlanning ? {checked: "checked"} : {})} id="openPlanning" name="openPlanning" onChange={this.handleInputChange}/>
                                    <label for="openPlanning">Да може да инициира процеса на планиране.</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.closePlanning ? {checked: "checked"} : {})} id="closePlanning" name="closePlanning" onChange={this.handleInputChange}/>
                                    <label for="closePlanning">Да може да прекратява процеса на планиране.</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.tasksInfo ? {checked: "checked"} : {})} id="tasksInfo" name="tasksInfo" onChange={this.handleInputChange}/>
                                    <label for="tasksInfo">Да достъпва допълнителна информация за задачите.</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.reorderTasks ? {checked: "checked"} : {})} id="reorderTasks" name="reorderTasks" onChange={this.handleInputChange}/>
                                    <label for="reorderTasks">Да може да променя подредбата на задачите.</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.selectTasks ? {checked: "checked"} : {})} id="selectTasks" name="selectTasks" onChange={this.handleInputChange}/>
                                    <label for="selectTasks">Да може да избира задачите, по които да се работи.</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.estimateTaks ? {checked: "checked"} : {})} id="estimateTaks" name="estimateTaks" onChange={this.handleInputChange}/>
                                    <label for="estimateTaks">Да може да оценява сложността на задачите.</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.doPlay ? {checked: "checked"} : {})} id="doPlay" name="doPlay" onChange={this.handleInputChange}/>
                                    <label for="doPlay">Да участва в решаването на задачи.</label>
                                </div>
                            </div>
                            {this.state.doPlay &&
                                <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.togglePlay ? {checked: "checked"} : {})} id="togglePlay" name="togglePlay" onChange={this.handleInputChange}/>
                                    <label for="togglePlay">Да може да се изключва от решаването на задачи.</label>
                                </div>
                            </div>
                            }
                            {this.state.doPlay && this.state.togglePlay &&
                                <div class="row">
                                <div class="checkbox">
                                    <input type="checkbox" {...(this.state.toggleDefaultPlay ? {checked: "checked"} : {})} id="toggleDefaultPlay" name="toggleDefaultPlay" onChange={this.handleInputChange}/>
                                    <label for="toggleDefaultPlay">Първоначално да бъде изключен от решаването на задачите.</label>
                                </div>
                            </div>
                            }
                            
                            <div class="row">
                                <textarea rows="7" value={this.state.introduction} {...notOwned ? {disabled:"true"} : {}}  id='introduction' placeholder="Въведение в началото на играта за тази роля" onChange={this.handleInputChange}></textarea>
                            </div>
                            <div class="addrolebtns">
                                <div class="addrolebtn">
                                    <FixedButton>
                                        <StandardButton disabled={notOwned || (!this.state.isChanged && !this.state.isDeleted && !this.state.isAdded)} isSubmit text={(!this.state.isUpdate) ? "Добавете" : "Актуализирайте"} width="100%" />
                                    </FixedButton>
                                </div>
                                <div class="cancelrolebtn">
                                    <FixedButton>
                                        <StandardButton disabled={notOwned || !this.state.isChanged} click={this.clearChanges} text="Анулирайте промените" width="100%" type="cancel"/>
                                    </FixedButton>
                                </div>
                                <div class="deleterolebtn">
                                    <FixedButton>
                                        <StandardButton disabled={notOwned} click={(this.state.isUpdate) ? this.submitDeleteExistingRole : this.submitDeleteNewRole} text="Изтрийте ролята" width="100%" type="delete"/>
                                    </FixedButton>
                                </div>
                            </div>
                            </div>    
                            </AccordionItemPanel>          
                        </form>
                    </div>
                </Content>
                </div>
            </StyledEditRole>
            </AccordionItem>
        </Accordion>
        )
    }
}


EditRole.propTypes = {
    idx: PropTypes.number,
    game: PropTypes.object,
    level: PropTypes.object,
  }
  
  const mapStateToProps = (state) => {
    return {
    }
  }
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
        fetchAddGameRole: (gameId, name, description, openPlanning, closePlanning, reorderTasks, selectTasks, toDoList, tasksInfo, estimateTasks, togglePlay, introduction, doPlay, toggleDefaultPlay) => dispatch(addGameRole(gameId, name, description, openPlanning, closePlanning, reorderTasks, selectTasks, toDoList, tasksInfo, estimateTasks, togglePlay, introduction, doPlay, toggleDefaultPlay)),
        fetchUpdateGameRole: (gameRoleId, name, description, openPlanning, closePlanning, reorderTasks, selectTasks, toDoList, tasksInfo, estimateTasks, togglePlay, introduction, doPlay, toggleDefaultPlay) => dispatch(updateGameRole(gameRoleId, name, description, openPlanning, closePlanning, reorderTasks, selectTasks, toDoList, tasksInfo, estimateTasks, togglePlay, introduction, doPlay, toggleDefaultPlay)),
        fetchDeleteGameRole: (id) => dispatch(deleteGameRole(id)),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(EditRole)