
import * as types from './types'

export function resetFeedback() {
  return {
    type: types.RESET_FEEDBACK,
    payload: { }
  }
}

export function sendEmotionFeedback(key, emotion) {
  return {
    type: types.SEND_EMOTION_FEEDBACK_REQUEST,
    payload: { key, emotion }
  }
}

export function sendFullFeedback(key, other, behaviors) {
  return {
    type: types.SEND_FULL_FEEDBACK_REQUEST,
    payload: { key, other, behaviors }
  }
}
