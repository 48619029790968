import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledProfile from './profile.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import { updateProfile } from '../../../state/actions/profile'
import {  getProfile } from '../../../state/selectors/profile'

export class Invoice extends Component {
  constructor(props) {
    super()
    this.state = {
      isUpdate: false,
    }
    this.startUpdate = this.startUpdate.bind(this);
  }

  startUpdate = () => {
    this.setState(state => ({
      isUpdate: true
    }));
  }

  stopUpdate = () => {
    this.setState(state => ({
      isUpdate: false
    }));
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    
   this.setState({
      [ id ]: value,
      isChanged: true
    });
  }

  submitProfileDetails = e => {
    e.preventDefault();

    const { profile } = this.props
    const profilеId = profile.getIn(['Profile', 'Id'], null);
    let email = profile.getIn(['Profile', 'Email'], null);
    if (this.state.email) {
      email = this.state.email;
    }
    this.props.fetchUpdateProfile(profilеId, null, null, null, null, null, this.state.issueInvoice, this.state.organizationId, this.state.organizationName, this.state.organizationCountry, this.state.organizationTown, this.state.organizationAddress, this.state.organizationContactPerson, this.state.organizationNote);
    
    this.setState(state => ({
      isUpdate: false,
      issueInvoice: null,
      organizationId: null,
      organizationName: null,
      organizationCountry: null,
      organizationTown: null,
      organizationAddress: null,
      organizationContactPerson: null,
      organizationNote: null,
    }));

  }

  render() {
      const { profile } = this.props
      const issueInvoice = profile.getIn(['Profile', 'IssueInvoice'], null);
      if (!this.state.isUpdate || this.state.issueInvoice == null) {
        this.state.issueInvoice = issueInvoice;
      }
      const organizationId = profile.getIn(['Profile', 'OrganizationId'], null);
      if (!this.state.isUpdate || this.state.organizationId == null) {
        this.state.organizationId = organizationId;
      }
      const organizationName = profile.getIn(['Profile', 'OrganizationName'], null);
      if (!this.state.isUpdate || this.state.organizationName == null) {
        this.state.organizationName = organizationName;
      }
      const organizationCountry = profile.getIn(['Profile', 'OrganizationCountry'], null);
      if (!this.state.isUpdate || this.state.organizationCountry == null) {
        this.state.organizationCountry = organizationCountry;
      }
      const organizationTown = profile.getIn(['Profile', 'OrganizationTown'], null);
      if (!this.state.isUpdate || this.state.organizationTown == null) {
        this.state.organizationTown = organizationTown;
      }
      const organizationAddress = profile.getIn(['Profile', 'OrganizationAddress'], null);
      if (!this.state.isUpdate || this.state.organizationAddress == null) {
        this.state.organizationAddress = organizationAddress;
      }
      const organizationContactPerson = profile.getIn(['Profile', 'OrganizationContactPerson'], null);
      if (!this.state.isUpdate || this.state.organizationContactPerson == null) {
        this.state.organizationContactPerson = organizationContactPerson;
      }
      const organizationNote = profile.getIn(['Profile', 'OrganizationNote'], null);
      if (!this.state.isUpdate || this.state.organizationNote == null) {
        this.state.organizationNote = organizationNote;
      }
      return (
        <StyledProfile theme={process.theme}>
         <div id="profile">
          <div>
            {!this.state.isUpdate &&
            <div>
              <div class="details_section">
                <div class="detail_item">
                    <div class="detail_attribute">Да ми бъде издавана фактура: </div>
                    <div class="detail_value">{issueInvoice == "1" ? "Да" : "Не"}</div>
                </div>
                {issueInvoice == "1" &&
                  <div>
                    <div class="detail_item">
                        <div class="detail_attribute">ЕИК: </div>
                        <div class="detail_value">{organizationId ? organizationId : "-"}</div>
                    </div>
                    <div class="detail_item">
                        <div class="detail_attribute">Име на организацията: </div>
                        <div class="detail_value">{organizationName ? organizationName : "-"}</div>
                    </div>
                    <div class="detail_item">
                        <div class="detail_attribute">Държава: </div>
                        <div class="detail_value">{organizationCountry ? organizationCountry : "-"}</div>
                    </div>
                    <div class="detail_item">
                        <div class="detail_attribute">Населено място: </div>
                        <div class="detail_value">{organizationTown ? organizationTown : "-"}</div>
                    </div>
                    <div class="detail_item">
                        <div class="detail_attribute">Адрес: </div>
                        <div class="detail_value">{organizationAddress ? organizationAddress : "-"}</div>
                    </div>
                    <div class="detail_item">
                        <div class="detail_attribute">МОЛ: </div>
                        <div class="detail_value">{organizationContactPerson ? organizationContactPerson : "-"}</div>
                    </div>
                    <div class="detail_item">
                        <div class="detail_attribute">Бележка, която да се добави към фактурата: </div>
                        <div class="detail_value">{organizationNote ? organizationNote : "-"}</div>
                    </div>
                  </div>
                }
              </div>
              <div class="detail_button">
                <FixedButton>
                  <StandardButton click={this.startUpdate} text="Актуализирайте" width="100%"/>
                </FixedButton>
              </div>
            </div>
            }

            {this.state.isUpdate &&
              <form onSubmit={this.submitProfileDetails}>
                <h2>Моля въведете детайли на акаунта си:</h2>
                <div class="inputs">
                  <div class="row">
                    <div class="checkbox">
                      <input type="checkbox" {...(this.state.issueInvoice ? {checked: "checked"} : {})} id="issueInvoice" onChange={this.handleInputChange}/>
                      <label for="issueInvoice">Искам да ми бъдaт издавани фактури</label>
                    </div>
                  </div>
                  {this.state.issueInvoice && 
                  <div>
                    <div class="row">
                      <input value={this.state.organizationId} id='organizationId' required placeholder="ЕИК*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input value={this.state.organizationName} id='organizationName' required placeholder="Име на организацията**" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input value={this.state.organizationCountry} id='organizationCountry' required placeholder="Държава*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input value={this.state.organizationTown} id='organizationTown' required placeholder="Населено място*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input value={this.state.organizationAddress} id='organizationAddress' required placeholder="Адрес*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input value={this.state.organizationContactPerson} id='organizationContactPerson' required placeholder="МОЛ*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input value={this.state.organizationNote} id='organizationNote' placeholder="Бележка, която да се добави към фактурата" onChange={this.handleInputChange}/>
                    </div>
                  </div>  
                  }       
                  <div class="buttons">
                      <div class="btn_edit">
                        <FixedButton>
                          <StandardButton disabled={this.state.isUpdate && !this.state.isChanged} isSubmit text="Актуализирайте" width="100%"/>
                        </FixedButton>
                      </div>            
                      <div class="btn_back">
                        <FixedButton>
                          <StandardButton click={this.stopUpdate} text="Откажете актуализацията" width="100%" type="back"/>
                        </FixedButton>
                      </div>
                  </div>
                </div>              
              </form>
              }
          </div>
        </div>
      </StyledProfile>
    )
  }
}

Invoice.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchUpdateProfile: (profilеId, firstName, secondName, email, organization, password, issueInvoice, organizationId, organizationName, organizationCountry, organizationTown, organizationAddress, organizationContactPerson, organizationNote) => dispatch(updateProfile(profilеId, firstName, secondName, email, organization, password, issueInvoice, organizationId, organizationName, organizationCountry, organizationTown, organizationAddress, organizationContactPerson, organizationNote))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
