
import * as types from './types'

export function readPlayedGame(id) {
  return {
    type: types.READ_PLAYEDGAME_REQUEST,
    payload: { id }
  }
}

export function resetPlayedGame() {
  return {
    type: types.RESET_PLAYEDGAME,
    payload: { }
  }
}

export function readPlayedGames(profileId) {
  return {
    type: types.READ_PLAYEDGAMES_REQUEST,
    payload: { profileId }
  }
}

export function readDetailedPlayedGames(playCode) {
  return {
    type: types.READ_DETAILEDPLAYEDGAMES_REQUEST,
    payload: { playCode }
  }
}

export function resetDetailedPlayedGames() {
  return {
    type: types.RESET_DETAILEDPLAYEDGAMES,
    payload: {  }
  }
}

export function createPlayedGame(accessKey, score, teamName, playTime, state, isVirtual) {
  return {
    type: types.CREATE_PLAYEDGAME_REQUEST,
    payload: { accessKey, score, teamName, playTime, state, isVirtual }
  }
}



