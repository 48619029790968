import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageTitle from '../components/ui/title'
import Success from '../components/pages/profile/success'
import StandardSection from '../components/ui/section'
import Content from '../components/ui/content'

export class SuccessPage extends Component {

render() {
    return (
      <div>
        <PageTitle title="Успешно плащане на абонамент"/>
        <StandardSection bgr="#FFF">
          <Content>
            <Success />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

SuccessPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPage)
