import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import StyledProfile from './profile.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import {  getProfile } from '../../../state/selectors/profile'

export class Pending extends Component {
  
  constructor(props) {
    super();
  }


  render() {

      return (
        <StyledProfile theme={process.theme}>
         <div id="profile">
            <div class="pending">Актуализирането на Вашия абонамент е в процес на обработка. Може да го следите във <a href="/profile">Вашия профил</a>. Ако не видите актуализацията до 24 часа, моля да ни пишете на <a href="mailto:stavros@agify.me">stavros@agify.me</a> или да ни се обадите на <a href="tel:+359893691583">+359893691583</a>.</div>
            <div class="allgamesbtn">
              <FixedButton>
                <StandardButton href="/" text="Начало" width="100%"/>
              </FixedButton>
            </div>
          </div>
      </StyledProfile>
    )
  }
}

Pending.propTypes = {
  profile: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Pending)
