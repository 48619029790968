import { Map } from 'immutable'

export function getRole(state) {
  const immutableState = Map(state)
  var role = "";
  if (immutableState.getIn(['admin', 'Administrator'], null) !== null) {
    role = "Administrator";
  }
  if (immutableState.getIn(['subscription', 'Subscription'], null) !== null) {
    role = "Subscription";
  }
  if (immutableState.getIn(['profile', 'Profile'], null) !== null) {
    role = "Profile";
  }
  return role;
}

export function isAuthenticated(state) {
  const immutableState = Map(state);
  return immutableState.getIn(['subscription', 'Subscription'], null) !== null || immutableState.getIn(['profile', 'Profile'], null) !== null || immutableState.getIn(['admin', 'Administrator'], null) !== null
}

export function getSubscriptionId(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['subscription', 'Subscription', 'Id'], null)
}

export function getProfileId(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile', 'Profile', 'Id'], null)
}

export function hasSubscription(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile', 'Profile', "Subscription"], null)
}

export function isSubscriptionError(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['subscription', 'Error'], null) !== null
}

export function isProfileError(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile', 'Error'], null) !== null
}

export function isSubscriptionApiError(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['subscription', 'ApiError'], null) !== null
}

export function isProfileApiError(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['profile', 'ApiError'], null) !== null
}