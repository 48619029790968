import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageTitle from '../components/ui/title'
import Pending from '../components/pages/profile/pending'
import StandardSection from '../components/ui/section'
import Content from '../components/ui/content'

export class PendingPage extends Component {

render() {
    return (
      <div>
        <PageTitle title="Актуализирането на абонамента е в процес на обработка"/>
        <StandardSection bgr="#FFF">
          <Content>
            <Pending />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

PendingPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingPage)
