import styled from 'styled-components'

const StyledGame = styled.section`

.morale {
  background-color: #fff;
  padding: 50px 0px 80px 0;
}

.noDetailsStats {
  font-size: 1.2em;
  text-align: center;
  font-weight: bold;
  color: ${props => props.theme.alternativeColor};
  margin-top: 50px;
}

.moraletitle {
  text-align: center;
  padding: 15px 0 25px 0;
  font-size: 1.5em;
  font-weight: bold;
  color: ${props => props.theme.defaultColor};
}

.emotionshearts {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 50px;
}

.emotionheart img {
  width: 70px;
  margin-left: 10px;
}

.emotionsTitle {
  font-weight: bold;
}

.emotions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.emotion {
  margin-left: 10px;
}

.emotion img {
  margin: 0 auto;
  width: 70px;
  display: block;
}

.emotionNickname {
  text-align: center;
  font-size: 0.7em;
  font-weight: bold;
  margin-top: 5px;
}

.teamname {
  background-color: #fff;
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  padding: 40px 0px;
}

.percentage {
  color: #fff important!
}

.nogame {
  font-size: 1.2em;
  text-align: center;
  padding: 70px;
}

.playedlevels {
  color: #fff !important;
  background-color: ${props => props.theme.alternativeColor};
  padding-top: 20px;
  padding-bottom: 50px;
}

.playedlevels .number {
  font-size: 5em !important;
  color: #fff !important;
}

.red {
  color: #a62324 !important;
}

.green {
  color: green !important;
}

.playedlevels .label {
  margin-top: -30px;
  font-weight: bold;
}

.points {
  padding-top: 50px;
  padding-bottom: 50px;
}

.playedtime {
  background-color: #e8ebe2;
  padding-top: 50px;
  padding-bottom: 50px;
}

.game_info {
  background-color: #e8ebe2 !important;
}

#gamedetails {
  font-size: 1.20em;
  padding-bottom: 50px;
  
  #info {
    background-color: #fff;
    padding: 70px;
    text-align: center;

    .free .value {
      font-weight: bold;
      color: #3a953c;
    }
  }

  .detail {
    margin: 15px auto;

    @media (min-width: 1200px) {
      display: flex;
      justify-content: center;
    }
  }

  .title {
    font-weight: bold;
    color: ${props => props.theme.defaultColor};

    @media (min-width: 1200px) {
      text-align: right;
      padding-right: 20px;
    }
  }

  .value {
    @media (min-width: 1200px) {
      text-align: left;
    }
  }

  .demo {
    padding-bottom: 30px;
  }

  .buttons {
    padding-top: 50px;
  }

  .generate {
    padding-bottom: 4px;
  }
}

.players {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 100px;
}

.playerstitle, .answerstitle {
  padding: 15px 0 25px 0;
  font-size: 1.5em;
  font-weight: bold;
  color: ${props => props.theme.defaultColor};
}

.playersrow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.answers {
  background-color: #e8ebe2;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.table {
  width: 80%;
  margin: 0 auto;
}

.playersstats {
  margin-top: 50px;
}


.postplay {
  background: #e8ebe2;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 100px;
}

.postplaytitle {
  padding: 15px 0 25px 0;
  font-size: 1.5em;
  font-weight: bold;
  color: ${props => props.theme.defaultColor};
}
`

export default StyledGame
