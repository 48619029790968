import PropTypes from 'prop-types'
import React, { Component}  from 'react'
import { connect } from 'react-redux'
import StyledComparePlayedGames from './compareplayedgames.styled'
import PlayedGame from './playedgame'
import queryString from 'query-string'
import history from '../../../history'
import StandardButton from '../../ui/button'
import SubTitle from '../../ui/subtitle'
import FixedButton from '../../ui/fixedbutton'
import Ranking from '../../ui/ranking'
import {  getDetailedPlayedGames } from '../../../state/selectors/playedgame'
import {  getProfile } from '../../../state/selectors/profile'
import { readDetailedPlayedGames, resetDetailedPlayedGames } from '../../../state/actions/playedgame'

export class ComparePlayedGames extends Component {
  
  constructor(props) {
    super();

    props.fetchResetDetailedPlayedGames();
    this.playCode = "";
    const values = queryString.parse(window.location.search);
    if (values.playCode != null && values.playCode != "") {
      this.playCode = values.playCode;
      props.fetchDetailedPlayedGames(values.playCode);
    } else if (values.playcode != null && values.playcode != "") {
      this.playCode = values.playcode;
      props.fetchDetailedPlayedGames(values.playcode);
    }

    this.refresh = this.refresh.bind(this);
  }

  goBack = () => {
    history.go(-1);
  }

  refresh = () => {
    if (this.playCode != "") {
      this.props.fetchDetailedPlayedGames(this.playCode);
    }
  }

  render() {
   const { detailedPlayedGames, profile } = this.props
   const profilеId = profile.getIn(['Profile', 'Id'], null);
   const playedGamesList = detailedPlayedGames.getIn(['detailedPlayedGames','PlayedGames', 'PlayedGames']);
   const concurrentTeams = detailedPlayedGames.getIn(['detailedPlayedGames','PlayedGames', 'ConcurrentTeams']);
   const allTeams = detailedPlayedGames.getIn(['detailedPlayedGames','PlayedGames', 'AllTeams']);
   var showRanking = detailedPlayedGames.getIn(['detailedPlayedGames','PlayedGames', 'ShowRanking'], null);
   let percentage = 99.5;
   return (
      <StyledComparePlayedGames theme={process.theme} width={percentage + "%"}>
      {!playedGamesList &&
       <div class="playedgamesloading">
        Резултатите се зареждат.
       </div>
      }
      {playedGamesList && playedGamesList.size == 0 && 
        <div class="noplayedgames">
          Все още няма резултати.
        </div>
      }
      {concurrentTeams && showRanking &&
      <div>
        <Ranking bgColor="#E8EBE2" title="Класация в текущата игра" teams={concurrentTeams} refreshCallback={this.refresh} />
      </div>
      }
      {playedGamesList && playedGamesList.size > 0 &&
        <div class="comparison">
          <SubTitle text="Сравнение на резултатите на отборите"/>
          <div class="playedgames">
            {playedGamesList.map((playedGame) => {
              return (
                <div class="playedgame">
                  <PlayedGame detailedPlayedGame={playedGame}/>
                </div>
              );
            })
            }    
          </div>
        </div>
      }
      {allTeams && showRanking &&
        <div>
          <Ranking bgColor="#E8EBE2" title="Класация за всички времена" teams={allTeams} refreshCallback={this.refresh} />
        </div>
      }
      {profilеId &&
      <div class="buttons">
            <div class="back">
              <FixedButton>
                <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
              </FixedButton>
            </div>
          </div>
      }
      </StyledComparePlayedGames>
    )
  }
}

ComparePlayedGames.propTypes = {
  detailedPlayedGames: PropTypes.object,
  profile: PropTypes.object,
}


const mapStateToProps = (state) => {
  return {
    detailedPlayedGames: getDetailedPlayedGames(state),
    profile: getProfile(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchDetailedPlayedGames: (playCode) => dispatch(readDetailedPlayedGames(playCode)),
    fetchResetDetailedPlayedGames: () => dispatch(resetDetailedPlayedGames()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparePlayedGames)
