import React, { Component, PureComponent}  from 'react'
import { connect } from 'react-redux'
import { readSubscriptionIncome } from '../../../state/actions/subscription'
import {  getSubscriptionCount, getSubscriptionIncome } from '../../../state/selectors/subscription'
import StyledStats from './stats.styled'
import {
  LineChart,
  CartesianGrid,
  XAxis,
  Line,
  Legend
} from "recharts";


export class AdminDashboardPage extends Component {
  constructor(props) {
    super()
    props.fetchSubscriptionIncome()
  }

  render() {
        const { subscriptionIncome, subscriptionCount } = this.props
        const incomeTotal = subscriptionIncome.getIn(['SubscriptionIncomeData', 'Total'], 0);
        const incomeAvarage = subscriptionIncome.getIn(['SubscriptionIncomeData', 'Avarage'], 0);
        const subscriptionIncomePerMonthList = subscriptionCount.getIn(['SubscriptionIncomeData', 'SubscriptionIncomePerMonthsData', 'SubscriptionIncomePerMonthData'], null);
        const dataIncome = [];
        if (subscriptionIncomePerMonthList) {
          subscriptionIncomePerMonthList.sort((a, b) => a.get("Date").localeCompare(b.get("Date"))).map((subscriptionsPerMonth) => {
                let obj = { };
                obj.name = subscriptionsPerMonth.get("Date");
                obj.income = subscriptionsPerMonth.get("Income");
                dataIncome.push(obj);
          });
        }
        return (
        <StyledStats>
         <div>
          <div class="count">
            <div class="data">
              <div class="item">              
                <div class="number">{incomeTotal} лв.</div>
                <div class="label">от всички абонаменти</div>
              </div>
              <div class="item">
                <div class="number">{incomeAvarage} лв.</div>
                <div class="label">средно на абонамент</div>
              </div>
            </div>
            <div class="chart">
              <LineChart width={1200} height={400} data={dataIncome} margin={{ top: 50, right: 50, left: 10, bottom: 0 }} >
                <XAxis dataKey="name" />
                <CartesianGrid stroke="#f5f5f5" />
                <Line isAnimationActive={false} type="monotone" name="Приходи от абонаменти на месец" dataKey="income" stroke="#a62324" yAxisId={1} label={<CustomizedLabel stroke="#a62324" />}  />
                <Legend wrapperStyle={{paddingTop: "30px"}}/>
              </LineChart>
            </div>
          </div>
        </div>
      </StyledStats>
    )
  }
}

class CustomizedLabel extends PureComponent {
  render() {
    const {
      x, y, stroke, value,
    } = this.props;

    return <text x={x} y={y} dy={-15} fill={stroke} fontSize={15} textAnchor="middle">{value}</text>;
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptionCount: getSubscriptionCount(state),
    subscriptionIncome: getSubscriptionIncome(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchSubscriptionIncome: () => dispatch(readSubscriptionIncome())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboardPage)
