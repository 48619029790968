import styled from 'styled-components'

const StyledTeam = styled.section`

.results {
    text-align: center;
    padding-top: 50px;
}

.temptitle {
    font-size: 2.5em;
    color: #139ec1;
    font-weight: bold;
    padding: 0px 0 30px 0;
}

.finaltitle {
    font-size: 2.5em;
    color: #fec233;
    font-weight: bold;
    padding: 0px 0 30px 0;
}

.teams {
    padding: 0px;
}

.team {
    padding-top: 20px;
    padding-bottom: 30px;
    width: 80%;
    margin: 0 auto;

    @media (min-width: 1200px) {
        display: flex;
        align-items: center;
        justify-content: left;
        width: 800px;
        margin: 0 auto;
    }
}

.teamname {
    font-size: 1.8em;
    padding-left: 20px;
    padding-top: 20px;

    @media (min-width: 1200px) {
        width: 500px;        
        text-align:left;
        padding-top: 0px;
    }
}

.certificate {
    font-size: 0.7em;
}

.certificate a {
    color: 000;
}

.teamplace {
    background-image: url("/images/results/blueplace.png");
    background-repeat: no-repeat;
    background-size: 100px 100px;
    background-position: center;
    width: 100px;
    height: 100px;
    color: #fff;
    font-size: 2.2em;
    font-weight: bold;
    margin: 0 auto;
    width: 100px;
    text-align: center;
}

.teamplacenumber {
    font-size: 1.2em;
    padding-top: 20px;
}

.firstplace {
    background-image:  url("/images/results/redplace.png");
    background-repeat: no-repeat;
    background-size: 100px 100px;
    background-position: center;
    width: 100px;
    height: 100px;
    color: #fff;
    font-size: 2em;
    font-weight: bold;
    margin: 0 auto;
    width: 100px;
}

.teampoints {
    color: #139ec1;
    font-weight: bold;
    font-size: 2.6em;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (min-width: 1200px) {
        width: 200px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
}

.teamtime {
    font-size: 0.4em;
    color: #000;
    font-weight: normal;
}

.pointslabel {
    font-size: 0.4em;
}

.totalpoints {
    font-size: 1.2em;
    padding: 20px 0 40px 0;
}

.totalpointskey {
    font-size: 1.2em;
    font-size: 1.5em;
    padding-bottom: 10px;
}

.totalpointsvalue {
    font-size: 4.5em;
    font-weight: bold;
    color: #a62324;
}

.totalpointslabel {
  font-size: 0.4em;
}

.totalpointsvaluewinner {
    background-image: url("/images/results/finalresults.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 600px 290px;
    height: 200px;
    margin-top: 10px;
    padding-top: 70px;
    padding-bottom: 20px;
    font-size: 3em;
    font-weight: bold;
    color: 000;
}

.winner {
    background-image: url("/images/results/finalresults.png");
    background-repeat: no-repeat;
    background-size: 800px 200px;
    background-position: center top;
    background-size: 400px 200px;
    height: 200px;
    padding: 20px 0px;
    margin-bottom: 20px;
    padding-top: 70px;

    @media (min-width: 1200px) {
        background-image: url("/images/results/firstplace.png");
        background-repeat: no-repeat;
        background-size: 800px 200px;
        background-position: center;
        height: 200px;
        padding: 20px 0px;
    }
}

.winnerplace {
    font-size: 4.8em;
    font-weight: bold;
    color: #139ec1;
    padding-left: 0px;
    margin-top: -30px;
    padding-bottom: 0px;

    @media (min-width: 1200px) {
        padding-left: 210px;
    }
}

.winnerdesc { 
    margin-top: 70px;
    font-size: 1.7em;

    @media (min-width: 1200px) {
        padding-left: 80px;
        margin-top: -30px;
    }
}

.winnerpoints {
    font-size: 1.5em;
}
`

export default StyledTeam
