import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PageTitle from '../components/ui/title'
import Cancel from '../components/pages/profile/cancel'
import StandardSection from '../components/ui/section'
import Content from '../components/ui/content'

export class CancelPage extends Component {

render() {
    return (
      <div>
        <PageTitle title="Плащането на абонамент е прекратено"/>
        <StandardSection bgr="#FFF">
          <Content>
            <Cancel />
          </Content>
        </StandardSection>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

CancelPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CancelPage)
