import * as types from '../actions/types'
import initial from './initial'
import { setFeedback, resetFeedback, setError } from '../selectors/feedback'

export default function (state = initial.feedback, action) {
  switch (action.type) {
    case types.RESET_FEEDBACK:
      return resetFeedback(state);
    case types.SEND_EMOTION_FEEDBACK_RESPONSE:
    case types.SEND_FULL_FEEDBACK_RESPONSE:
      return setFeedback(state, action)
    case types.SEND_EMOTION_FEEDBACK_ERROR:
    case types.SEND_FULL_FEEDBACK_ERROR:
      return setError(state, action.error)
    default:
      return state
  }
}
