import React, { Component } from 'react'
import { connect } from 'react-redux'
import Monitor from '../components/pages/monitor/monitor'

export class MonitorPage extends Component {

render() {
    return (
      <div>
        <Monitor/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

MonitorPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MonitorPage)
