import { put, call } from 'redux-saga/effects'
import { sendEmotionFeedback, sendFullFeedback } from '../../server/api'
import * as types from '../actions/types'


export function* sendEmotionFeedbackSaga({ payload }) {
  try {
    const feedback = yield call(sendEmotionFeedback, payload)
    yield put({ type: types.SEND_EMOTION_FEEDBACK_RESPONSE, feedback })    
  } catch (error) {
    yield put({ type: types.SEND_EMOTION_FEEDBACK_ERROR, error })
  }
}

export function* sendFullFeedbackSaga({ payload }) {
  try {
    const feedback = yield call(sendFullFeedback, payload)
    yield put({ type: types.SEND_FULL_FEEDBACK_RESPONSE, feedback })    
  } catch (error) {
    yield put({ type: types.SEND_FULL_FEEDBACK_ERROR, error })
  }
}

