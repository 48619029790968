import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import StyledPlayCodes from './playcodes.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import { getPlayCodes } from '../../../state/selectors/playcode';
import {  getProfile } from '../../../state/selectors/profile'
import { readPlayCodes } from '../../../state/actions/playcode'

import Chip from '@material-ui/core/Chip';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import {
  isMobile,
} from "react-device-detect";

function sortPlayCodes(a, b, prop, desc) {
  var a_prop = a.get(prop);
  var b_prop = b.get(prop);
  var reverse = 1;
  if (desc) {
    reverse = -1;
  }
  if (a_prop < b_prop) {
    return reverse * -1;
  }
  if (a_prop > b_prop) {
    return reverse * 1;
  }
  return 0;
}

function filterPlayCodes(a, props, values) {
  if (props.length > 0) {
    var isValid = 1;
    props.map((prop, index) => {
      if (a.get(prop) != values[index]) {
        isValid = 0;
      }
    });
    return isValid;
  } else {
    return 1;
  }
}

function isSelected(prop, value, props, values) {
  if (props.length > 0) {
    var isSelected = false;
    props.map((filterProp, index) => {
      if (filterProp == prop && values[index] == value) {
        isSelected = true;
      }
    });
    return isSelected;
  } else {
    return false;
  }
}

export class PlayCodes extends Component {
  constructor(props) {
    super()
    const { profile } = props
    const profileId = profile.getIn(['Profile', 'Id'], null);
    props.fetchPlayCodes(profileId);
    this.state = {
      sortProp: "CreationDate",
      desc: true,      
      filterProp: "",
      filterValue: "",
      filterProps: ["IsExpired"],
      filterValues: [false],
      rowsPerPage: 5,
      page: 0,
    }
    this.onSortingChanged = this.onSortingChanged.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  onSortingChanged(prop) {
    if (this.state.desc) {
      this.setState({
        desc: false, 
        sortProp: prop,
      });
    } else {
      this.setState({
        desc: true,
        sortProp: prop,
      });
    }
  }

  onFilter(prop, value) {
    var props = [...this.state.filterProps];
    var values = [...this.state.filterValues];
    var isApplied = false;
    var appliedIndex = -1;
    props.map((filterProp, index) => {
      if (filterProp == prop && values[index] == value) {
        isApplied = true;
        appliedIndex = index;
      }
    });
    if (isApplied) {
      props.splice(appliedIndex, 1);
      values.splice(appliedIndex, 1);
    } else {
      props.push(prop);
      values.push(value);
    }
    this.setState({
      filterProps: props,
      filterValues: values,
    });
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  render() {

      const { playCodes } = this.props
      const playCodesList = playCodes.getIn(['playCodes','PlayCodes', 'PlayCode']);
      return (
        <StyledPlayCodes>
         <div id="playcodelist">
          {(!playCodesList || playCodesList.size == 0) &&
            <div class="message">
              Нямате генерирани кодове за игра.
            </div>
          }
          {(playCodesList && playCodesList.size > 0) ?
          <div>
            <div class="chips">
            <Chip
              onClick={() => this.onFilter("IsExpired", false)}
              label={isSelected("IsExpired", false, this.state.filterProps, this.state.filterValues) ? "Покажи всички генерирани кодове" : "Покажи само активните кодове"}
              className={isSelected("IsExpired", false, this.state.filterProps, this.state.filterValues) ? "chip" : "chip_selected"}
            />
            </div> 
            <div class="table">
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                      <TableCell align="center">
                        <TableSortLabel {...this.state.sortProp == "GameId" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("GameId")}>Номер на игра</TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel {...this.state.sortProp == "GameTitle" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("GameTitle")}>Заглавие на играта</TableSortLabel>
                      </TableCell>
                      {!isMobile &&
                        <TableCell align="center">
                          <TableSortLabel {...this.state.sortProp == "Status" && {active:"true"}} direction={this.state.desc ? "desc" : "asc" } onClick={() => this.onSortingChanged("Status")}>Статус</TableSortLabel>
                        </TableCell>
                      }
                      <TableCell align="center" >Действие</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {playCodesList ?
                      playCodesList.sort((a, b) => sortPlayCodes(a, b, this.state.sortProp, this.state.desc)).filter(a => filterPlayCodes(a, this.state.filterProps, this.state.filterValues)).slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((playCode) => {
                      var status = (playCode.get('IsExpired')) ? "Изтекъл" : "Активен";
                      const title = (playCode.get('GameTitle')) ? playCode.get('GameTitle') : "-";
                      return (
                        <TableRow>
                          <TableCell align="center">
                          {playCode.get('GameId') ?
                            <Link to={'/game?id=' + playCode.get('GameId')}>{playCode.get('GameId')}</Link> :
                            "-"
                          }
                          </TableCell>
                          {!isMobile &&
                            <TableCell align="center">{title}</TableCell>
                          }
                          {!isMobile &&
                            <TableCell class={playCode.get('IsExpired') ? "inactive" : "active"} align="center">{status}</TableCell>
                          }
                          <TableCell align="center" className="table_buttons">
                            {!playCode.get('IsExpired') &&
                              <FixedButton>
                                <StandardButton text="Разгледайте кода за игра"  width="100%" href={'/playcode?id=' + playCode.get('Id')} />
                              </FixedButton>
                            }
                            {playCode.get('IsExpired') &&
                              <FixedButton>
                                <StandardButton href={"/compareplayedgames?playCode=" + playCode.get('Id')}  text="Резултати" width="100%"/>
                              </FixedButton>
                            }
                          </TableCell>
                        </TableRow>
                      );
                    }) : '-'
                }           
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination 
                      rowsPerPageOptions={[5, 10, 25]}
                      count={playCodesList.filter(a => filterPlayCodes(a, this.state.filterProps, this.state.filterValues)).size}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            </div>
          </div>
            : ''}
          <div class="generate">
            <FixedButton>
              <StandardButton href="/games" text="Генерирайте нов код за игра" width="100%"/>
            </FixedButton>
          </div>
        </div>
      </StyledPlayCodes>
    )
  }
}

PlayCodes.propTypes = {
  games: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    playCodes: getPlayCodes(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchPlayCodes: (profileId) => dispatch(readPlayCodes(profileId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayCodes)
