import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { persistStore } from 'redux-persist'
import history from './history'
import Favicon from 'react-favicon';

import App from './app'
import { Provider } from 'react-redux'
import storage from './state/storage'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { bgBG } from '@material-ui/core/locale';

const theme = createMuiTheme({}, bgBG);

const store = storage()

//persistStore(store)

require('./index.css');

const domain = window.location.hostname;
process.theme = {
  name: "agify.me",    
  url: "https://play.agify.me",
  defaultColor: "#a62324",
  alternativeColor: "#000",
  headerBgrColor: "#a62324",
  headerColor: "#fff",    
  footerBgrColor: "#000",
  titleBgrColor: "#e8ebe2",
  titleColor: "#a62324", 
  subTitleColor: "#000",
  detailColor: "#a62324",   
  font: '"Bitter", serif',
  fontBold: '"Bitter", serif',
  buttonDefaultBgrColor: "#000",
  buttonDefaultColor: "#fff",
  buttonBackBgrColor: "#e8ebe2",
  buttonBackColor: "#000",
  buttonCancelBgrColor: "#e8ebe2",
  buttonCancelColor: "#000",
  buttonDeleteBgrColor: "#000",
  buttonDeleteColor: "#fff",    
  buttonSubscribeBgrColor: "#a62324",
  buttonSubscribeColor: "#fff",  
  buttonGenerateBgrColor: "#a62324",
  buttonGenerateColor: "#fff", 
  title: "Хъбът на agify.me",
  favicon: "https://play.agify.me/images/favicon.ico",
  address: "ул. Розова долина 5, Лозенец, гр. София",
  email: "stavros@agify.me",
  phone: "+359 893 691 583",
  subject: "Ключова дума",
  playerURL: "https://play.agify.me/play",
  teamURL: "https://play.agify.me/team",
  monitorURL: "https://play.agify.me/monitor",
  dashboardURL: "https://play.agify.me/dashboard",
  publicgames: false,
  publicgame: false,
  themes: [
    <option value="8">Полицай и престъпници</option>,
    <option value="9">Екип реди пъзел</option>,
    <option value="10">Инцидент в океана</option>,
    <option value="11">Скайдайвинг</option>,
    <option value="12">Облаци от 'лоши' мисли</option>,
    <option value="13">'Сърдити' колеги</option>,
    <option value="14">Протест</option>,
    <option value="15">Инцидент на луната</option>,
    <option value="16">Спасяване на хора на отдалечен остров</option>,
    <option value="17">Слаклайн над пропаст</option>,
    <option value="18">Пикселизирани хора в сладкарница</option>,
    <option value="19">'Спорна топка' в ръгбито</option>,
    <option value="20">Музиканти в метрото</option>,
    <option value="21">Презентация в офиса</option>,
    <option value="22">Щафетно бягане</option>,
    <option value="23">Циркови акробати</option>,
    <option value="24">Сливащ се диалог</option>,
    <option value="25">Объркан разговор</option>,
    <option value="26">Отдалечено редене на танграм</option>,
    <option value="27">Екип реди сърце</option>,
    <option value="28">Екип реди думата 'team'</option>,
    <option value="29">Екип реди 'лампа' с ръце</option>,
    <option value="30">Състезатели с тандем колело са първи на дълъг път</option>,
    <option value="31">Екип сглобява часовник</option>,
    <option value="32">Платноходка следва маршрута си и стига своята цел</option>,
    <option value="33">Регулировчици и натоварен трафик</option>,
    <option value="34">Екип спасява паднал алпинист</option>,
    <option value="35">Пожарникари гасят пожар</option>,
    <option value="36">'Слон' в стаятата</option>,
    <option value="37">'Разстроен' поглед в парка</option>,
    <option value="38">Хора преместват паднало дърво</option>,
    <option value="39">Помирени войници</option>,
    <option value="40">Скарани деца рисуват обща дъга</option>,
    <option value="41">Хора се карат в игра на карти</option>
  ]
}

ReactDOM.render((
  <Provider store={store}>
    <BrowserRouter history={history}>
      <ThemeProvider theme={theme}>
        <Favicon url={process.theme.favicon} />
          <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
), document.getElementById('root'))
