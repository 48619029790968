import * as types from '../actions/types'
import initial from './initial'
import { setTeam, setError } from '../selectors/team'

export default function (state = initial.team, action) {
  switch (action.type) {
    case types.READ_TEAMBY_PUBLICKEY_RESPONSE:
    case types.READ_TEAMBY_ACCESSKEY_RESPONSE:
      return setTeam(state, action)
    case types.READ_TEAMBY_PUBLICKEY_ERROR:
    case types.READ_TEAMBY_ACCESSKEY_ERROR:
      return setError(state, action.error)
    default:
      return state
  }
}
