import React, { Component } from 'react'
import { connect } from 'react-redux'
import Report from '../components/pages/analytics/report'

export class ReportPage extends Component {

render() {
    return (
      <div>
        <Report />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

ReportPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportPage)
