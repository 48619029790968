import React from 'react'
import StyledButton from './button.styled'
import { Link } from 'react-router-dom'

class StandardButton extends React.Component {
  
  render() {
  let bgr = process.theme.buttonDefaultBgrColor;
  let color = process.theme.buttonDefaultColor;
  if (this.props.type == "back") {
    bgr = process.theme.buttonBackBgrColor;
    color = process.theme.buttonBackColor;
  } else if (this.props.type == "cancel") {
    bgr = process.theme.buttonCancelBgrColor;
    color = process.theme.buttonCancelColor;
  } else if (this.props.type == "delete") {
    bgr = process.theme.buttonDeleteBgrColor;
    color = process.theme.buttonDeleteColor;
  } else if (this.props.type == "subscribe") {
    bgr = process.theme.buttonSubscribeBgrColor;
    color = process.theme.buttonSubscribeColor;
  } else if (this.props.type == "generate") {
    bgr = process.theme.buttonGenerateBgrColor;
    color = process.theme.buttonGenerateColor;
  }
  if (this.props.bgr) {
    bgr = this.props.bgr;
  }
  if (this.props.color) {
    color = this.props.color;
  }
  
  let width = (this.props.width) ? this.props.width : "300px";
  let disabled = (this.props.disabled) ? true : false;
  disabled = (this.props.isDisabled) ? this.props.isDisabled : disabled;
  let bgrColor = (disabled) ? "rgba(100, 100, 100, 0.3)" : bgr;
  let isSubmit =  (this.props.isSubmit) ? true : false;
  let isLink = (this.props.isLink) ? true : false;
  let isDownload = (this.props.isDownload) ? true : false;
  
  return (
    <StyledButton theme={process.theme}>
      <div class="button">
        {this.props.click && <button onClick={this.props.click} disabled={disabled} style={{ background: bgr, color: color, width: width, backgroundColor: bgrColor }}>{this.props.text}</button>}
        {!this.props.click && !isLink && !isSubmit && <Link to={this.props.href} class="link"><button disabled={disabled} style={{ background: bgr, color: color, width: width, backgroundColor: bgrColor }}>{this.props.text}</button></Link>}
        {!this.props.click && isLink && !isSubmit && !isDownload && <a href={this.props.href} target="_blank" style={{ background: bgr, color: color, width: width, backgroundColor: bgrColor }}>{this.props.text}</a>}
        {!this.props.click && isLink && !isSubmit && isDownload && <a href={this.props.href} download style={{ background: bgr, color: color, width: width, backgroundColor: bgrColor }}>{this.props.text}</a>}
        {!this.props.click && isSubmit && <button type="submit" disabled={disabled} style={{ background: bgr, color: color, width: width, backgroundColor: bgrColor }}>{this.props.text}</button>}
      </div>
    </StyledButton>
    )
  }
}
export default StandardButton
