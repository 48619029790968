import styled from 'styled-components'

const StyledNavigation = styled.nav`

.generate a {
  color: #fff !important;
  background-color: 000;
  padding: 10px;
  border-radius: 25px;
}

 width: 100%;
 padding-top: 20px;
 font-family: ${props => props.theme.font};
 overflow: hidden;

 .dropheader {
  background-color: #a62324;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-weight: bold;
}
 
 @media (min-width: 1200px) {
    width: 100%;
 }

 #menu-mobile {
   display: block;

   @media (min-width: 1200px) {
    display: none;
   }

   /* Position and sizing of burger button */
    .bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      right: 5%;
      top: 46px;
    }
    
    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #fff;
    }
    
    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: #a90000;
    }
    
    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      top: 15px !important;
      right: 20px !important;
      width: 44px;
      height: 44px;
    }
    
    /* Color/shape of close button cross */
    .bm-cross {
      background: #000;
    }
    
    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
      position: fixed;
      height: 100%;
    }
    
    /* General sidebar styles */
    .bm-menu {
      background: 000;
      padding: 0px;
      font-size: 1.15em;
      width: 100%;
      overflow: hidden !important;
    }
    
    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: #373a47;
    }
    
    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      padding: 0px;
      width: 100%;
      overflow: auto;
    }
    
    /* Individual item */
    .bm-item {
      display: inline-block;
      color: #fff;
      width: 100%;
      text-align: center;
      padding: 15px 0;
      border-bottom: 1px solid rgba(253, 253, 254, 0.5); 
    }
    
    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.9);
    }
   
 }

 #menu-desktop {
   display: none; 

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      li {
        a {
          margin-right: 10px;
          }
        }
    }

    @supports (-ms-ime-align:auto) {
      li {
        a {
          margin-right: 10px;
          }
        }
    }
    
    @media (min-width: 1200px) {
      display: block;

      ul {
        list-style-type: none;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: nowrap;
        padding-left: 0;
        margin-bottom: 10px;
    
        li {
          a {
            color: ${props => props.theme.headerColor};
            text-decoration: none;
            cursor: pointer;
            :hover {
              opacity: 0.7;
            }
          }

          
          {/*
          &:last-child {
            position: relative;
            top: -5px;
          }
          */}
        }
      }

      .dropbtn {
        font-size: 16px;  
        border: none;
        outline: none;
        background-color: #fff;
        font-family: inherit;
        margin: 0;
        padding: 0;
      }
      
      .dropdowncnt {
        padding: 10px 0;
        display: none;
        position: absolute;
        min-width: 160px;
      }
      
      .dropdowncnt a {
        color: ${props => props.theme.headerColor};    
        background-color: #f9f9f9;
        padding: 14px 16px;
        text-decoration: none;
        display: block;
        text-align: left;
      }
      
      .dropdowncnt a:hover {
        opacity: 1 !important;
        background-color: #ddd;
      }
      
      .dropdownnav:hover .dropdowncnt {
        display: block;
      }
    
      .dropbtn:hover {
        opacity: 0.7;
      }
    }

    #menu-item-games {
      color: #fff;
      background-color: 000;
      padding: 5px 10px;
      border-radius: 25px;
    }
  }
`

export default StyledNavigation
