import styled from 'styled-components'

const StyledLevel = styled.section`
.rolelevelinfo {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;  
}

.rolelevel_detail {
    width: 250px;
    text-align: center;
    padding: 0 5px;
}
`

export default StyledLevel
