import { fromJS, Map } from 'immutable'


export function getTeam(state) {
  const immutableState = Map(state)
  return immutableState.getIn(['team'], null)
}

export function setTeam(state, newState) {
  return state.merge(fromJS(newState.team))
}

export function resetTeam(state) {
  return new Map();
}

export function setError(state, error) {
  return state.merge({
    Error: fromJS(error)
  })
}

