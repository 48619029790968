import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import history from '../../../history'
import parse from 'html-react-parser';

import StyledGame from './game.styled'
import StandardButton from '../../ui/button'
import FixedButton from '../../ui/fixedbutton'

import {  getGame } from '../../../state/selectors/game'
import {  getProfile } from '../../../state/selectors/profile'
import { readGame, resetGame } from '../../../state/actions/game'

import Tablet from '../../ui/tablet'
import Player from '../../ui/player'
import Theme from '../../ui/theme'

import Role from './role'
import Level from './level'

import queryString from 'query-string'

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


export class Game extends Component {
  
  constructor(props) {
    super()

    props.fetchResetGame();

    const values = queryString.parse(window.location.search);
    if (values.gameId != null && values.gameId != "") {
      props.fetchReadGame(values.gameId);
      this.state = {
        gameId: values.gameId,
      }
    } else if (values.gameid != null && values.gameid != "") {
      props.fetchReadGame(values.gameid);
      this.state = {
        gameId: values.gameid,
      }
    } else if (values.id != null && values.id != "") {
      props.fetchReadGame(values.id);
      this.state = {
        gameId: values.id,
      }
    }
  }
  
  goBack = () => {
    history.go(-1);
  }

  render() {
      const { game, profile } = this.props;
      const gameId = game.getIn(['Game', 'Id'], null);
      let resetingGame = this.state.gameId != "" && this.state.gameId != gameId;
      const title = (game.getIn(['Game', 'Title'], null) == null) ? "" : game.getIn(['Game', 'Title'], null);
      const description = (game.getIn(['Game', 'GameDescription'], null) == null) ? "" : game.getIn(['Game', 'GameDescription'], null);
      const subject = (game.getIn(['Game', 'Subject'], null) == null) ? "" : game.getIn(['Game', 'Subject'], null);
      const grade = (game.getIn(['Game', 'Grade'], null) == null) ? "" : game.getIn(['Game', 'Grade'], null);
      const complexity = (game.getIn(['Game', 'Complexity'], null) == null) ? "" : game.getIn(['Game', 'Complexity'], null);
      const individualInstruction = (game.getIn(['Game', 'IndividualInstruction'], null) == null) ? "-" : game.getIn(['Game', 'IndividualInstruction'], null);
      const groupInstruction = (game.getIn(['Game', 'GroupInstruction'], null) == null) ? "-" : game.getIn(['Game', 'GroupInstruction'], null);
      const introduction = (game.getIn(['Game', 'Introduction'], null) == null) ? "-" : game.getIn(['Game', 'Introduction'], null);
      const hasPlanning = (game.getIn(['Game', 'HasPlanning'], null) == null) ? false : game.getIn(['Game', 'HasPlanning'], null);
      const allowReplanning = (game.getIn(['Game', 'AllowReplanning'], null) == null) ? "false" : game.getIn(['Game', 'AllowReplanning'], null);
      const author = (game.getIn(['Game', 'Author'], null) == null) ? "" : game.getIn(['Game', 'Author'], null);
      const isFree = (game.getIn(['Game', 'IsFree'], null) == null) ? "" : game.getIn(['Game', 'IsFree'], null);
      const organization = (game.getIn(['Game', 'Organization'], null) == null) ? "" : game.getIn(['Game', 'Organization'], null);
      const platformType = (game.getIn(['Game', 'PlatformType'], null) == null) ? "" : game.getIn(['Game', 'PlatformType'], null);
      const theme = (game.getIn(['Game', 'Theme'], null) == null) ? "0" : game.getIn(['Game', 'Theme'], null);
      const hasPostPlayActivity = (game.getIn(['Game', 'HasPostPlayActivity'], null) == null) ? false : game.getIn(['Game', 'HasPostPlayActivity'], null);
      const postPlayActivityInstruction = (game.getIn(['Game', 'PostPlayActivityInstruction'], null) == null) ? "" : game.getIn(['Game', 'PostPlayActivityInstruction'], null);
      var platforms = "";
      if (platformType == "all") {
        platforms = "На общо или на отделни устройства";
      } else if (platformType == "physical") {
        platforms = "На общо устройство";
      } else {
        platforms = "На отделни устройства";
      }
      let levels = (game.getIn(['Game', 'Levels', 'Level'], null) == null) ? "" : game.getIn(['Game', 'Levels', 'Level'], null);
      if (!levels) {
        levels = new Map();
      }
      var complexityLabel = "";
      switch (complexity) {
          case "1": {
            complexityLabel = "Много лесно";
            break;
          }
          case "2": {
            complexityLabel = "Лесно";
            break;
          } 
          case "3": {
            complexityLabel = "Трудно";
            break;
          } 
          case "4": {
            complexityLabel = "Много трудно";
            break;
          } 
      }
      const roles = (game.getIn(['Game', 'Roles'], null) == null) ? "" : game.getIn(['Game', 'Roles'], null);
      
      // Topics
      const topics = (game.getIn(['Game', 'Topics'], null) == null) ? "" : game.getIn(['Game', 'Topics'], null);
      var topicNames = [];
      if (topics) {
        topics.map((topic) => {
          topicNames.push(
            <div>{topic.getIn(['Name'], "")}</div>
          )
        })
      } else {
        topicNames = "-";
      }

      // Types
      const types = (game.getIn(['Game', 'Types'], null) == null) ? "" : game.getIn(['Game', 'Types'], null);
      var typeNames = [];
      if (types) {
        types.map((type) => {
          typeNames.push(
            <div>{type.getIn(['Name'], "")}</div>
          )
        })
      } else {
        typeNames = "-";
      }

      const gameProfileId = game.getIn(['Game', 'Profile', 'Id'], null);
      const profileId = profile.getIn(['Profile', 'Id'], null);
      const subscription = profile.getIn(['Profile', 'Subscription'], null);
      var isActive = (subscription) ? subscription.getIn(["IsActive"]) : false;
      
      return (
        <StyledGame theme={process.theme}>
        {gameId == null && 
          <div class="nogame">
            Играта се зарежда в момента. Моля, изчакайте.
          </div>
        }
        {resetingGame && gameId != null &&
          <div class="resetinggame">
            Играта се зарежда в момента. Моля, изчакайте.
          </div>
        }
        {!resetingGame && gameId != null &&
          <div id="gamedetails">
            <div>
              <div id="info">
                <div class="detail">
                    <div class="title">Номер на играта: </div>
                    <div class="value"><b>{gameId}</b></div>
                </div>
                <div class="detail">
                    <div class="title">Теми: </div>
                    <div class="value">{topicNames}</div>
                </div>
                <div class="detail">
                    <div class="title">Тип: </div>
                    <div class="value">{typeNames}</div>
                </div>
                <div class="detail">
                    <div class="title">Заглавие: </div>
                    <div class="value">{title}</div>
                </div>
                <div class="detail">
                    <div class="title">Кратко описание: </div>
                    <div class="value">{description}</div>
                </div>
                <div class="detail">
                    <div class="title">{process.theme.subject}:</div>
                    <div class="value">{subject}</div>
                </div>
                <div class="detail">
                    <div class="title">Ниво на сложност: </div>
                    <div class="value">{complexityLabel}</div>
                </div>
                <div class="detail">
                      <div class="title">Среда, в която ще може да се използва играта: </div>
                      <div class="value">{platforms}</div>
                </div> 
                <div class="detail">
                    <div class="title">Ще има активност след края на играта: </div>
                    <div class="value">{hasPostPlayActivity ? "Да" : "Не"}</div>
                </div>
                {hasPostPlayActivity &&
                  <div class="detail">
                      <div class="title">Инструкции за активността след края на играта: </div>
                      <div class="value">{postPlayActivityInstruction}</div>
                  </div>
                }
                <div class="detail">
                    <div class="title">В играта има етап на планиране: </div>
                    <div class="value">{hasPlanning ? "Да" : "Не"}</div>
                </div>
                {hasPlanning &&
                  <div class="detail">
                      <div class="title">В играта ще бъде позволено репланиране: </div>
                      <div class="value">{allowReplanning == "true" ? "Да" : "Не"}</div>
                  </div>
                }
                <div class="detail">
                    <div class="title">Инструкции по подразбиране за индивидуалните задачи: </div>
                    <div class="value">{individualInstruction}</div>
                </div>
                <div class="detail">
                      <div class="title">Инструкции по подразбиране за груповите задачи: </div>
                      <div class="value">{groupInstruction}</div>
                </div>
                <div class="detail">
                    <div class="title">Въведение в началото на играта: </div>
                    <div class="value">{introduction}</div>
                </div>
                {theme != "0" &&
                  <div class="detail">
                      <div class="title">Графична тема на играта: </div>
                      <div class="value">
                        <div class="theme">
                          <Theme id={theme} />
                        </div>
                      </div>
                  </div>
                }
              </div>
            </div>

            {roles && roles.size > 0 &&
              <div>
                <div class="levelstitle"><h4>Роли в играта:</h4></div>
                {
                  roles.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx)=> {
                    return (
                      <div class="level_component">
                        <Role game={game} role={val} id={idx} sequence={idx} />
                      </div>
                    )
                  })
                }
              </div>
            }

            {(subscription != null || isFree) &&
              <div class="levels">
                <div class="levelstitle"><h4>Нива на играта:</h4></div>
                {
                  levels.sort((a, b) => a.getIn(['SequenceNumber'], null) - b.getIn(['SequenceNumber'], null)).map((val, idx)=> {
                    return (
                      <div class="level_component">
                        <Level gameId={gameId} level={val} id={idx} sequence={idx} hasPlanning={hasPlanning} allowReplanning={allowReplanning}/>
                      </div>
                    )
                  })
                }
              </div>
            }
            
            {(subscription != null || isFree) &&
            <div class="demo">
              <div class="player">
                <Tablet>
                  <Player isHub gameId={gameId} />
                </Tablet>
              </div>
            </div>
            }

            <div class="buttons">
              {profileId != null && gameProfileId != null && gameProfileId == profileId &&
                <div class="edit">
                <FixedButton>
                  <StandardButton text="Актуализирайте" width="100%" href={'/editgame?id=' + gameId} />
                </FixedButton>
              </div>
              }
              {profileId != null && subscription == null && !isFree &&
              <div class="subscribe">
                <div class="invite">Искате да достъпите цялата информация за играта, както и да я тествате и играете?</div>
                <div>
                  <FixedButton>
                    <StandardButton href="/profile#plans" text="Купете си абонамент" width="100%" type="subscribe"/>
                  </FixedButton>
                </div>
              </div>
              }
              {profileId != null && (subscription != null || isFree) &&
                <div class="generate">
                  <FixedButton>
                    <StandardButton isDisabled={!(isActive || isFree)} text="Генерирайте код за игра" width="100%" href={'/createplaycode?gameId=' + gameId} type="generate" />
                  </FixedButton>
                </div>
              }
              {profileId != null && subscription != null &&
                <div class="duplicate">
                  <FixedButton>
                    <StandardButton text="Дублирайте" width="100%" href={'/duplicate?gameId=' + gameId} />
                  </FixedButton>
                </div>
              }
              {profileId != null &&
                <div class="back">
                  <FixedButton>
                    <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                  </FixedButton>
                </div>
              }
            </div>
          </div>
        }
      </StyledGame>
    )
  }
}

Game.propTypes = {
  game: PropTypes.object,
  profile: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    game: getGame(state),
    profile: getProfile(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
   fetchReadGame: (gameId) => dispatch(readGame(gameId)),   
   fetchResetGame: () => dispatch(resetGame())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Game)
