import PropTypes from 'prop-types'
import React, { Component, PureComponent}  from 'react'
import { connect } from 'react-redux'
import history from '../../../history'

import StyledPlayedGame from './playedgame.styled'
import StandardButton from '../../ui/button'
import FixedButton from '../../ui/fixedbutton'

import {  getProfile } from '../../../state/selectors/profile'
import {  getPlayedGame } from '../../../state/selectors/playedgame'
import { readPlayedGame, resetPlayedGame } from '../../../state/actions/playedgame'
import StyledStats from '../stats/stats.styled'
import Level from '../game/level'
import PlayerStats from './playerstats'

import {
  LineChart,
  CartesianGrid,
  XAxis,
  Line,
  Legend, 
  ResponsiveContainer
} from "recharts";

import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import queryString from 'query-string'
import { Step } from '@material-ui/core';


export class PlayedGame extends Component {
  
  constructor(props) {
    super()

    if (!props.degailedPlayedGame) {
      const values = queryString.parse(window.location.search);
      if (values.id != null && values.id != "") {
        props.fetchReadPlayedGame(values.id);
      }
    }
    this.state = {
      rowsPerPage: 5,
      page: 0,
    }

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };
  
  goBack = () => {
    history.go(-1);
  }

  render() {
      const { profile, playedGame, detailedPlayedGame, noTitles } = this.props;
      const profileId = profile.getIn(['Profile', 'Id'], null);
      const showTitles = (noTitles) ? false : true;
      const refinedPlayedGame = (playedGame.getIn(['PlayedGame'], null)) ? playedGame.getIn(['PlayedGame'], null) : playedGame;
      const actualPlayedGame = (detailedPlayedGame) ? detailedPlayedGame : refinedPlayedGame;
      const playedGameId = actualPlayedGame.getIn(['Id'], null);
      const gameId = actualPlayedGame.getIn(['GameId'], null);
      const hasDetailedStats = actualPlayedGame.getIn(['HasDetailedStats'], false);
      const teamName = (actualPlayedGame.getIn(['TeamName'], null)) ? actualPlayedGame.getIn(['TeamName'], null) : "-";
      const hasGroupResults = actualPlayedGame.getIn(['HasGroupResults'], false);
      const score = actualPlayedGame.getIn(['Score'], null);
      const playedTime = actualPlayedGame.getIn(['PlayTime'], null);
      const playedLevelsStats = actualPlayedGame.getIn(['PlayedLevels', 'StatsPerLevels'], null);
      const dataStatsPerLevels = [];
      if (playedLevelsStats) {
        playedLevelsStats.map((playedLevelData) => {
              let obj = { };
              obj.levelId = playedLevelData.get("LevelId");
              obj.label = playedLevelData.get("Label");
              obj.points = playedLevelData.get("Points");
              obj.playedTime = playedLevelData.get("PlayedTime");
              dataStatsPerLevels.push(obj);
        });
      }
      const playedLevelsCoverage = actualPlayedGame.getIn(['PlayedLevelsCoverage'], "-");
      const playersStats = actualPlayedGame.getIn(['Players', 'StatsPerPlayers'], null);
      const isVirtual = actualPlayedGame.getIn(['IsVirtual'], false);
      const initialEmotions = actualPlayedGame.getIn(['InitialEmotions'], false);
      const finalEmotions = actualPlayedGame.getIn(['FinalEmotions'], false);
      var hearts = [];
      for (var i = 0; i < 6; i++) {
        hearts.push(i);
      }
      let hasIndividualResults = actualPlayedGame.getIn(['HasIndividualResults'], false);
      const points = actualPlayedGame.getIn(['Points'], null);
      const avgPoints = actualPlayedGame.getIn(['AvaragePointsPerLevel'], "-");
      const hasPlanning = actualPlayedGame.getIn(['HasPlanning'], false);
      const planningTime = actualPlayedGame.getIn(['PlanningTime'], 0);
      const planningTimePercentage = actualPlayedGame.getIn(['PlanningTimePercentage'], 0);
      const playPoints = actualPlayedGame.getIn(['PlayPoints'], 0);
      const penaltiesPoints = actualPlayedGame.getIn(['Penalties'], false);
      const rewardsPoints = actualPlayedGame.getIn(['Rewards'], false);
      const answerStats = actualPlayedGame.getIn(['Answers', 'StatsPerAnswer'], null);
      const hasPostPlayActivity = actualPlayedGame.getIn(['HasPostPlayActivity'], false);
      const postPlayActivityInstruction = actualPlayedGame.getIn(['PostPlayActivityInstruction'], "");
      const postPlayText = actualPlayedGame.getIn(['PostPlayText'], "");
      const avgPlayedTime = (dataStatsPerLevels.length > 0) ? Math.round((playedTime - planningTime) / dataStatsPerLevels.length) : "-";
      return (
        <StyledPlayedGame theme={process.theme}>
        <div class="playedgame">
        {playedGameId == null && 
          <div class="nogame">
            Достъпът до тази игра е ограничен.
          </div>
        }
        {playedGameId != null &&
        <div id="gamedetails">
          {showTitles &&
          <div class="teamname">
            Отбор {teamName}
          </div>
          }
          <StyledStats theme={process.theme}>
          <div class="playedlevels">
            <div class="count">
              <div class="data">
                {hasDetailedStats &&
                <div class="item">              
                  <div class="number">{dataStatsPerLevels.length}<span class="percentage"> ({playedLevelsCoverage}%)</span></div>
                  <div class="label">изиграни нива</div>
                </div>
                }
                {hasGroupResults &&
                  <div class="item">              
                    <div class="number">{score}</div>
                    <div class="label">резултат</div>
                  </div>
                }
              </div>
            </div>
          </div>
          </StyledStats>
          {playersStats &&
          <div className="morale">
            <div class="moraletitle">Отборно настроение</div>
            <div className="emotionshearts">
            {
                hearts.map((val, idy)=> {
                  var step = Math.floor(100 / 6);
                  var image = "";
                  if (finalEmotions > initialEmotions && initialEmotions <= (val + 1) * step && finalEmotions > (val + 1) * step) {
                    image = "emotion_final";
                  } else if (initialEmotions > (val + 1) * step && finalEmotions > (val + 1) * step) {
                    image = "emotion_start";
                  } else if (initialEmotions > (val + 1) * step && finalEmotions <= (val + 1) * step) {
                    image = "emotion_default";
                  }
                  if (image != "") {
                    return (
                      <div className="emotionheart">
                        <img src={"/images/results/" + image + ".png"} />
                      </div>
                    )
                  } else {
                    return (
                      null
                    )
                  }
                  
                })
              }
              
            </div>
            <div className="emotions">
              <div className="emotionsTitle">Начални емоции:</div>
              {
                  playersStats.map((val, idy)=> {
                  var nickname = val.getIn(["Nickname"], null);
                  var initialEmotion = val.getIn(["InitialEmotion"], null);
                  var name = (isVirtual) ? <div className="emotionNickname">{nickname}</div> : null;
                  var icon = (initialEmotion == 1) ? <div><img src={"/images/results/happy.png"} /></div> : <div><img src={"/images/results/sad.png"} /></div>
                  if (initialEmotion != null && initialEmotion >= 0) {
                    return (
                      <div className="emotion">
                        {initialEmotion >= 0 && icon}
                        {name}
                      </div>
                    )
                  } else {
                    return (
                      null
                    )
                  }
                })
              }
            </div>
            <div className="emotions">
              <div className="emotionsTitle">Финални емоции:</div>
              {
                  playersStats.map((val, idy)=> {
                  var nickname = val.getIn(["Nickname"], null);
                  var finalEmotion = val.getIn(["FinalEmotion"], null);
                  var name = (isVirtual) ? <div className="emotionNickname">{nickname}</div> : null;
                  var icon = (finalEmotion == 1) ? <div><img src={"/images/results/happy.png"} /></div> : <div><img src={"/images/results/sad.png"} /></div>
                  if (finalEmotion != null && finalEmotion >= 0) {
                    return (
                      <div className="emotion">
                        {icon}
                        {name}
                      </div>
                    )
                  } else {
                    return (
                      null
                    )
                  }
                })
              }
            </div>
          </div>
          }
            <StyledStats theme={process.theme}>
            <div class="playedtime">
              <div class="count">
                <div class="data">
                  <div class="item">              
                    <div class="number">{playedTime}<span class="percentage"> сек.</span></div>
                    <div class="label">общо игрово време</div>
                  </div>
                  {hasDetailedStats &&
                  <div class="item">
                    <div class="number">{avgPlayedTime}<span class="percentage"> сек.</span></div>
                    <div class="label">ср. игрово време на ниво</div>
                  </div>
                  }
                  {hasPlanning &&
                    <div class="item">
                      <div class="number">{planningTime}<span class="percentage"> сек. ({planningTimePercentage}%)</span></div>
                      <div class="label">време за планиране</div>
                    </div>
                  }
                </div>
                {hasDetailedStats &&
                <div class="chart">
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={dataStatsPerLevels} margin={{ top: 50, right: 50, left: 10, bottom: 0 }} >
                      <XAxis dataKey="label" />
                      <CartesianGrid stroke="#f5f5f5" />
                      <Line isAnimationActive={false} type="monotone" name="Игрово време на ниво (в сек.)" dataKey="playedTime" stroke={process.theme.defaultColor} yAxisId={1} label={<CustomizedLabel stroke={process.theme.defaultColor} />}  />
                      <Legend wrapperStyle={{paddingTop: "30px"}}/>
                    </LineChart>
                  </ResponsiveContainer>
                </div>
                }
              </div>
            </div>

            {hasGroupResults && hasDetailedStats &&
              <div class="points">
                <div class="count">
                  <div class="data">
                    <div class="item">              
                      <div class="number">{points}</div>
                      <div class="label">общ брой точки</div>
                    </div>
                    <div class="item">
                      <div class="number">{avgPoints}</div>
                      <div class="label">ср. брой точки на ниво</div>
                    </div>
                    {hasPlanning &&
                      <div class="item">              
                        <div class="number">{playPoints}</div>
                        <div class="label">точки от игра</div>
                      </div>
                    }
                    {hasPlanning &&
                      <div class="item">
                        <div class="number green">{rewardsPoints}</div>
                        <div class="label">бонус точки</div>
                      </div>
                    }
                    {hasPlanning &&
                      <div class="item">              
                        <div class="number red">{penaltiesPoints}</div>
                        <div class="label">отнети точки</div>
                      </div>
                    }
                  </div>
                  <div class="chart">
                    <ResponsiveContainer width="100%" height={400}>
                      <LineChart data={dataStatsPerLevels} margin={{ top: 50, right: 50, left: 10, bottom: 0 }} >
                        <XAxis dataKey="label" />
                        <CartesianGrid stroke="#f5f5f5" />
                        <Line isAnimationActive={false} type="monotone" name="Точки на ниво" dataKey="points" stroke={process.theme.defaultColor} yAxisId={1} label={<CustomizedLabel stroke={process.theme.defaultColor} />}  />
                        <Legend wrapperStyle={{paddingTop: "30px"}}/>
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            }
            </StyledStats> 

            <div>
            {playedLevelsStats &&
                playedLevelsStats.map((val, idx)=> {
                var details = val.getIn(["Details"], null);
                var answerId = val.getIn(["AnswerId"], null);
                var givenAnswers = val.getIn(["Answers", "StatsPerAnswer"], null);
                var isMaxPoints = val.getIn(["IsMaxPoints"], false);
                var orderedItems = val.getIn(["Items"], []);
                var penalty = details.getIn(["DeadlinePenalty"], 0);                
                var distribution = val.getIn(["Answers", "Distribution"], null);
                var isCommited = val.getIn(["IsCommited"], false);
                var isDeadlineMissed = val.getIn(["IsDeadlineMissed"], false);
                var isPlayedLevel = val.getIn(["IsPlayedLevel"], false);
                var isTimeboxOver = val.getIn(["IsTimeboxOver"], false);
                var isDone = val.getIn(["IsDone"], false);
                return (
                  <div class="level_component">
                    <Level distribution={distribution} isPlayedLevel={true} hasIndividualResults={hasIndividualResults} hasGroupResults={hasGroupResults} gameId={gameId} markAnswerId={answerId} level={details} id={idx} hasPlanning={hasPlanning} givenAnswers={givenAnswers} isMaxPoints={isMaxPoints} orderedItems={orderedItems} penalty={penalty} isCommited={isCommited} isDeadlineMissed={isDeadlineMissed} isTimeboxOver={isTimeboxOver} isDone={isDone}/>
                  </div>
                )
              })
            }
            
            {hasPostPlayActivity &&
              <div class="postplay">
                <div class="postplaytitle">{postPlayActivityInstruction}</div>
                <div class="postplaytext">{postPlayText}</div>
              </div>
            }

            {playersStats != null && hasIndividualResults &&
            <div class="players">
              <div class="playerstitle">Индивидуално представяне</div>
              <div class="playersrow">
                  {
                    playersStats.map((val, idy)=> {
                    var nickname = val.getIn(["Nickname"], null);
                    var playerScore = val.getIn(["Score"], null);
                    var playerPoints = val.getIn(["Points"], null);
                    var answersMatch = val.getIn(["AnswersMatch"], null);
                    var answersUnmatch = val.getIn(["AnswersUnmatch"], null);
                    return (
                      <PlayerStats isVirtual={isVirtual} name={nickname} score={playerScore} points={playerPoints} answersMatch={answersMatch} answersUnmatch={answersUnmatch} />
                    )
                  })
                }
              </div>
              <div class="playersstats">
                <div class="detail">
                    <div class="title">Най-слаб индивидуален резултат: </div>
                    <div class="value"><b>{actualPlayedGame.getIn(['MinPlayerScore'], null)}</b></div>
                </div>
                <div class="detail">
                    <div class="title">Най-добър индивидуален резултат: </div>
                    <div class="value"><b>{actualPlayedGame.getIn(['MaxPlayerScore'], null)}</b></div>
                </div>
                <div class="detail">
                    <div class="title">Среден индивидуален резултат: </div>
                    <div class="value"><b>{actualPlayedGame.getIn(['AvgPlayerScore'], null)}</b></div>
                </div>
                <div class="detail">
                    <div class="title">Медиана на индивидуалните резултати: </div>
                    <div class="value"><b>{actualPlayedGame.getIn(['MedianPlayerScore'], null)}</b></div>
                </div>
                <div class="detail">
                    <div class="title">Играчи с по-слаб резултат от екипния резултат: </div>
                    <div class="value"><b>{actualPlayedGame.getIn(['LessTeamScore'], null)}</b></div>
                </div>
                <div class="detail">
                    <div class="title">Играчи с по-добър резултат от екипния резултат: </div>
                    <div class="value"><b>{actualPlayedGame.getIn(['MoreTeamScore'], null)}</b></div>
                </div>
              </div>
            </div>
          }

          {answerStats != null && answerStats.size > 0 &&
            <div class="answers">
              <div class="answerstitle">Статистики по индивидуалните отговори</div>
              <div class="table">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                        <TableCell align="center">Отговор</TableCell>
                        <TableCell align="center">Брой посочвания</TableCell>
                        <TableCell align="center">Посочили играчи</TableCell>                        
                        <TableCell align="center">Id на ниво</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {answerStats.sort((a, b) => b.getIn(['Count'], null) - a.getIn(['Count'], null)).slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((val, idy)=> {
                      var value = val.getIn(["Value"], null);
                      var nicknames = val.getIn(["Nicknames"], null);
                      var count = val.getIn(["Count"], null);
                      var levelId = val.getIn(["LevelId"], null);
                      return (
                        <TableRow>
                          <TableCell align="center">{value}</TableCell>
                          <TableCell align="center">{count}</TableCell>
                          <TableCell align="center">{nicknames}</TableCell>
                          <TableCell align="center">{levelId}</TableCell>
                        </TableRow>
                      )})
                  }     
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination 
                        rowsPerPageOptions={[5, 10, 25]}
                        count={answerStats.size}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
              </div>
            </div>
            }
            {!hasDetailedStats &&
              <div className="noDetailsStats">Детайлните статистики за тази игра не са достъпни.</div>
            }
            </div>
            {!this.props.detailedPlayedGame &&
              <div class="buttons">
                {profileId != null &&
                  <div class="back">
                    <FixedButton>
                      <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                    </FixedButton>
                  </div>
                }
              </div>
            }
            {this.props.detailedPlayedGame && showTitles &&
              <div class="teamname">
                Отбор {teamName}
              </div>
            }
          </div>
        }
        </div>
      </StyledPlayedGame>
    )
  }
}

PlayedGame.propTypes = {
  profile: PropTypes.object,
  playedGame: PropTypes.object,
  detailedPlayedGame: PropTypes.object,
  noTitles: PropTypes.bool,
}

class CustomizedLabel extends PureComponent {
  render() {
    const {
      x, y, stroke, value,
    } = this.props;

    return <text x={x} y={y} dy={-15} fill={stroke} fontSize={15} textAnchor="middle">{value}</text>;
  }
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    playedGame: getPlayedGame(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
   fetchReadPlayedGame: (id) => dispatch(readPlayedGame(id)),   
   fetchResetPlayedGame: () => dispatch(resetPlayedGame()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayedGame)
