import * as types from '../actions/types'
import initial from './initial'
import { setReport, setError } from '../selectors/report'

export default function (state = initial.report, action) {
  switch (action.type) {
    case types.GET_REPORT_RESPONSE:
      return setReport(state, action)
    case types.GET_REPORT_ERROR:
      return setError(state, action.error)
    default:
      return state
  }
}
