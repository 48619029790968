import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTitle from '../../components/ui/title'
import PlayedGame from '../../components/pages/playedgame/playedgame'

export class PlayedGamePage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <div>
        <PageTitle title="Преглед на разиграване"/>
          <PlayedGame />
      </div>
    )
  }
}

PlayedGamePage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayedGamePage)
