import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import history from '../../../history'

import StyledPlayCode from './playcode.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import PlayCodeTeam from './playcodeteam'

import {  getGame } from '../../../state/selectors/game'
import {  getProfile } from '../../../state/selectors/profile'
import {  getPlayCode } from '../../../state/selectors/playcode'
import { readGame } from '../../../state/actions/game'
import { readPlayCode, readLastPlayCode, addPlayCodeTeam, resetPlayCode } from '../../../state/actions/playcode'

import queryString from 'query-string'

var QRCode = require('qrcode.react');

function msToTime(duration) {
  if (!duration) {
    return "-";
  }
  var minutes = Math.floor((duration / (1000 * 60)) % 60);
  var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  var seconds = Math.floor((duration / 1000) % 60),
  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;
  return hours + ":" + minutes + ":" + seconds;
}

export class PlayCode extends Component {
  
  constructor(props) {
    super()

    props.fetchResetPlayCode();

    this.state = {
      isAddingTeam: false,
      isFetchingGame: false,
    }

    const { playCode, profile } = props;
    var urlId = "";
    if (window.location) {
      const values = queryString.parse(window.location.search);
      if (values.Id != null && values.Id != "") {
        urlId = values.Id;
      } else if (values.id != null && values.id != "") {
        urlId = values.id;
      }
    }
    var id = playCode.getIn(['PlayCode', 'Id'], null);
    
    if (urlId != "") {
      props.fetchReadPlayCode(urlId);
    } else if (id == null) {
      const profileId = profile.getIn(['Profile', 'Id'], null);
      props.fetchReadLastPlayCode(profileId);
    }

    this.print = this.print.bind(this);
  }
  
  componentDidMount() {
    this.interval = setInterval(() => this.setState({ timeLeft: msToTime() }), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  openAddTeamForm = () => {
    this.setState({
      isAddingTeam: true,
    })
  }

  closeAddTeamForm = () => {
    this.setState({
      isAddingTeam: false,
    })
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id
    this.setState({
      [ id ]: value,
      isChanged: true,
    });
  }

  submitAddTeam = e => {
    e.preventDefault();

    const { profile, playCode } = this.props;
    const profilеId = profile.getIn(['Profile', 'Id'], null);
    var accessKey = playCode.getIn(['PlayCode', 'AccessKey'], null);

    this.props.fetchAddPlayCodeTeam(profilеId, accessKey, this.state.teamMark);

    this.setState({
      isAddingTeam: false,
    })
  }

  print = () => {
    window.print();
  }

  goBack = () => {
    history.go(-1);
  }

  render() {
      const { playCode, isGoBack, game } = this.props;
      var id = playCode.getIn(['PlayCode', 'Id'], null);  
      var hasPlayCode = (id != null);  
      var gameId = playCode.getIn(['PlayCode', 'GameId'], null);
      var gameNotLoaded = gameId != null && gameId != game.getIn(['Game', 'Id'], null);
      if (gameNotLoaded && !this.state.isFetchingGame) { 
        // Fetch game
        this.state.isFetchingGame = true;
        this.props.fetchReadGame(gameId);
      }
      var minutes = playCode.getIn(['PlayCode', 'Minutes'], null);
      var theme = playCode.getIn(['PlayCode', 'Theme'], null);
      var accessKey = playCode.getIn(['PlayCode', 'AccessKey'], null);
      var encryptedCode = playCode.getIn(['PlayCode', 'QrCode'], null);
      var creationDate = new Date(Date.parse(playCode.getIn(['PlayCode', 'CreationDate'], null)));
      var expirationDate = new Date(Date.parse(creationDate));
      expirationDate.setDate(expirationDate.getDate() + 1);
      var timeLeft = expirationDate.getTime() - new Date().getTime();
      var isExpired = playCode.getIn(['PlayCode', 'IsExpired'], null); 
      var hasPlayedGames = playCode.getIn(['PlayCode', 'HasPlayedGames'], null); 
      var teams = playCode.getIn(['PlayCode', 'Teams'], null);
      const topics = (game.getIn(['Game', 'Topics'], null) == null) ? "" : game.getIn(['Game', 'Topics'], null);
      var topicNames = [];
      if (topics) {
        topics.map((topic) => {
          topicNames.push(
            <div>{topic.getIn(['Name'], "")}</div>
          )
        })
      } else {
        topicNames = "-";
      }
      const types = (game.getIn(['Game', 'Types'], null) == null) ? "" : game.getIn(['Game', 'Types'], null);
      var typeNames = [];
      if (types) {
        types.map((type) => {
          typeNames.push(
            <div>{type.getIn(['Name'], "")}</div>
          )
        })
      } else {
        typeNames = "-";
      } 
      const title = (game.getIn(['Game', 'Title'], null) == null) ? "" : game.getIn(['Game', 'Title'], null);
      const description = (game.getIn(['Game', 'GameDescription'], null) == null) ? "" : game.getIn(['Game', 'GameDescription'], null);
      const subject = (game.getIn(['Game', 'Subject'], null) == null) ? "" : game.getIn(['Game', 'Subject'], null);
      const individualInstruction = (game.getIn(['Game', 'IndividualInstruction'], null) == null) ? "-" : game.getIn(['Game', 'IndividualInstruction'], null);
      const groupInstruction = (game.getIn(['Game', 'GroupInstruction'], null) == null) ? "-" : game.getIn(['Game', 'GroupInstruction'], null);
      
      var themeName = "-";
      switch (theme) {
        case 1: {
          themeName = "Нефтопукльовците";
          break;
        }
        case 2: {
          themeName = "Прахопукльовците";
          break;
        }
        case 3: {
          themeName = "Бокльопукльовците";
          break;
        }
        case 4: {
          themeName = "Торопукльовците";
          break;
        }
        case 5: {
          themeName = "Лесопукльовците";
          break;
        }
        case 6: {
          themeName = "Прахопукитата";
          break;
        }
        case 7: {
          themeName = "Нефтопукитата";
          break;
        }
        case 8: {
          themeName = "Полицай с престъпници";
          break;
        }
        case 9: {
          themeName = "Екип реди пъзел";
          break;
        }
        case 10: {
          themeName = "Инцидент в океана";
          break;
        }
        case 11: {
          themeName = "Скайдайвинг";
          break;
        }
        case 12: {
          themeName = "Облаци от 'лоши' мисли";
          break;
        }
        case 13: {
          themeName = "'Сърдити' колеги";
          break;
        }
        case 14: {
          themeName = "Протест";
          break;
        }
        case 15: {
          themeName = "Инцидент на луната";
          break;
        }
        case 16: {
          themeName = "Спасяване на хора на отдалечен остров";
          break;
        }
        case 17: {
          themeName = "Слаклайн над пропаст";
          break;
        }
        case 18: {
          themeName = "Пикселизирани хора в сладкарница";
          break;
        }
        case 19: {
          themeName = "'Спорна топка' в ръгбито";
          break;
        }
        case 20: {
          themeName = "Музиканти в метрото";
          break;
        }
        case 21: {
          themeName = "Презентация в офиса";
          break;
        }
        case 22: {
          themeName = "Щафетно бягане";
          break;
        }
        case 23: {
          themeName = "Циркови акробати";
          break;
        }
        case 24: {
          themeName = "Сливащ се диалог";
          break;
        }
        case 25: {
          themeName = "Объркан разговор";
          break;
        }
        case 26: {
          themeName = "Отдалечено редене на танграм";
          break;
        }
        case 27: {
          themeName = "Екип реди сърце";
          break;
        }
        case 28: {
          themeName = "Екип реди думата 'team'";
          break;
        }
        case 29: {
          themeName = "Екип реди 'лампа' с ръце";
          break;
        }
        case 30: {
          themeName = "Състезатели с тандем колело са първи на дълъг път";
          break;
        }
        case 31: {
          themeName = "Екип сглобява часовник";
          break;
        }
        case 32: {
          themeName = "Платноходка следва маршрута си и стига своята цел";
          break;
        }
        case 33: {
          themeName = "Регулировчици и натоварен трафик";
          break;
        }
        case 34: {
          themeName = "Екип спасява паднал алпинист";
          break;
        }
        case 35: {
          themeName = "Пожарникари гасят пожар";
          break;
        }
        case 36: {
          themeName = "'Слон' в стаятата";
          break;
        }
        case 37: {
          themeName = "'Разстроен' поглед в парка";
          break;
        }
        case 38: {
          themeName = "Хора преместват паднало дърво";
          break;
        }
        case 39: {
          themeName = "Помирени войници";
          break;
        }
        case 40: {
          themeName = "Скарани деца рисуват обща дъга";
          break;
        }
        case 41: {
          themeName = "Хора се карат в игра на карти";
          break;
        }
      } 
      var isVirtual = playCode.getIn(['PlayCode', 'IsVirtual'], null) == "1";
      var type = (isVirtual) ? "Виртуална игра" : "Физическа игра"; 
      return (
        <StyledPlayCode theme={process.theme}>
          
         <div id="playcode">          
          
          {!hasPlayCode &&
          <div>
            <div class="message">Нямате активен код за игра.</div>
            <div class="add">
              <FixedButton>
                <StandardButton href="/games" text="Разгледайте списъка с игрите" width="100%"/>
              </FixedButton>
            </div>
          </div>
          }
          {hasPlayCode && isExpired &&
            <div class="message">Кодът за игра е изтекъл.</div>
          }
          {gameNotLoaded && 
            <div class="message">Кодът се зарежда.</div>
          }
          {!gameNotLoaded && hasPlayCode && !isExpired &&
            <div>

              <div class="logo">
                <img src={"/images/print_logo.png"} alt="Лого" />
              </div>


              {!isVirtual &&
                <div id="qrcode">
                  <div class="scan">Сканирайте следния код на таблетите, за да стартирате играта:</div>
                  <QRCode value={encryptedCode} size="500"/>
                </div>
              }

              {!isVirtual && 
                  <div class="manualcode">
                    <div class="enter">Или въведете ръчно следния код на таблетите:</div>
                    <div class="manual"><b>{accessKey}</b></div>
                  </div>
                  }
              <div class="details">
                <div id="manualcode">
                  {isVirtual && teams &&                    
                    <div class="detail dashboard">
                      <div class="param">Линк за мониторинг на всички екипи заедно:</div>
                      <div class="value"><a href={process.theme.dashboardURL + "?playCode=" + id} target="_blank">{process.theme.dashboardURL}?playCode={id}</a></div>
                    </div>
                  }
                  {isVirtual && teams &&
                    teams.sort((a, b) => a.getIn(['Id'], null) - b.getIn(['Id'], null)).map((val, idx)=> {
                      return (
                        <PlayCodeTeam team={val} idx={idx} game={game} />
                      )
                  }) 
                  }
                  {isVirtual && teams && !this.state.isAddingTeam &&
                    <div class="add">
                      <FixedButton>
                        <StandardButton click={this.openAddTeamForm} text="Добавете отбор" width="100%"/>
                      </FixedButton>
                    </div>
                  }
                  {isVirtual && teams && this.state.isAddingTeam &&
                    <div class="addTeam">
                      <form onSubmit={this.submitAddTeam}>
                        <div class="inputs">
                          <div class="row">
                            <input id='teamMark' name="teamMark" required placeholder="Маркер на отбора*" onChange={this.handleInputChange}/>
                          </div>
                          <div class="addbtns">
                            <div class="addbtn">
                              <FixedButton>
                                <StandardButton isSubmit text="Добавете" width="100%"/>
                              </FixedButton>
                            </div>
                            <div class="cancelbtn">
                                <FixedButton>
                                  <StandardButton click={this.closeAddTeamForm} text="Отказ" width="100%" type="cancel"/>
                                </FixedButton>
                              </div>
                          </div>
                        </div>              
                      </form>
                    </div>
                  }
                  {hasPlayedGames &&
                    <div class="results">
                      <FixedButton>
                        <StandardButton href={"/compareplayedgames?playCode=" + id}  text="Резултати" width="100%"/>
                      </FixedButton>
                    </div>
                  }         
                </div>
                <div class="detail">
                  <div class="param">Оставащо активно време:</div>
                  <div class="value">{msToTime(timeLeft)}</div>
                </div>
                <div class="detail">
                  <div class="param">Генериран на:</div>
                  <div class="value">{creationDate.toLocaleString()}</div>
                </div>
                <div class="detail">
                  <div class="param">Изтича на:</div>
                  <div class="value">{expirationDate.toLocaleString()}</div>
                </div>
                <div class="detail">
                  <div class="param">Номер на играта:</div>
                  <div class="value"><Link to={'/game?id=' + gameId}>{gameId}</Link></div>
                </div>
                <div class="detail">
                    <div class="param">{process.theme.subject} на играта:</div>
                    <div class="value">{subject}</div>
                </div>
                <div class="detail">
                    <div class="param">Теми на играта: </div>
                    <div class="value">{topicNames}</div>
                </div>
                <div class="detail">
                    <div class="param">Тип на играта: </div>
                    <div class="value">{typeNames}</div>
                </div>
                <div class="detail">
                    <div class="param">Заглавие на играта: </div>
                    <div class="value">{title}</div>
                </div>
                <div class="detail">
                    <div class="param">Кратко описание на играта: </div>
                    <div class="value">{description}</div>
                </div>                
                <div class="detail">
                    <div class="param">Инструкции по подразбиране за индивидуалните задачи: </div>
                    <div class="value">{individualInstruction}</div>
                </div>
                <div class="detail">
                    <div class="param">Инструкции по подразбиране за груповите задачи: </div>
                    <div class="value">{groupInstruction}</div>
                </div> 
                <div class="detail">
                  <div class="param">Графична тема на играта:</div>
                  <div class="value">{themeName}</div>
                </div>                
                <div class="detail">
                  <div class="param">Време за игра:</div>
                  <div class="value">{minutes} минути</div>
                </div>
                <div class="detail">
                  <div class="param">Тип на играта:</div>
                  <div class="value">{type}</div>
                </div>
                <div class="btns">
                  {!isVirtual &&
                    <div class="print">
                      <FixedButton>
                        <StandardButton click={this.print} text="Принтирайте кода" width="100%"/>
                      </FixedButton>
                    </div>
                  }
                  {isGoBack &&
                    <div class="back">
                      <FixedButton>
                        <StandardButton click={this.goBack} text="Назад" width="100%" type="back"/>
                      </FixedButton>
                    </div>
                  }
                </div>
              </div>
            </div>
          }
        </div>
      </StyledPlayCode>
    )
  }
}

PlayCode.propTypes = {
  isGoBack: PropTypes.bool,
  profile: PropTypes.object,
  playCode: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
    playCode: getPlayCode(state),
    game: getGame(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchResetPlayCode: () => dispatch(resetPlayCode()),
    fetchReadGame: (gameId) => dispatch(readGame(gameId)),
    fetchReadPlayCode: (id) => dispatch(readPlayCode(id)),
    fetchReadLastPlayCode: (profileId) => dispatch(readLastPlayCode(profileId)),
    fetchAddPlayCodeTeam: (profileId, accessKey, teamMark) => dispatch(addPlayCodeTeam(profileId, accessKey, teamMark)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayCode)
