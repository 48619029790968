import React, { Component } from 'react'
import { connect } from 'react-redux'
import NewPlay from '../components/pages/monitor/newplay'

export class NewPlayPage extends Component {

render() {
    return (
      <div>
        <NewPlay />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

NewPlayPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPlayPage)
