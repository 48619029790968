import { put, call } from 'redux-saga/effects'
import { readAdmin } from '../../server/api'
import * as types from '../actions/types'

export function* readAdminSaga({ payload }) {
  try {
    const admin = yield call(readAdmin, payload)
    yield put({ type: types.READ_ADMIN_RESPONSE, admin })    
  } catch (error) {
    yield put({ type: types.READ_ADMIN_ERROR, error })
  }
}