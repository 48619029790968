import styled from 'styled-components'

const StyledEditRole = styled.section`
.deletebtn {
  margin-top: 10px !important;
}

.row {
  align-items: flex-start;
  margin-bottom: 50px;
}

.row div {
  width: 250px !important;
  margin: 0px 10px;
}

form .inputs {
  width: 100% !important;
}

label {
  text-align: left;
}

select, textarea {
  width: 80% !important;
}
`

export default StyledEditRole
