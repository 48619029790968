import * as types from '../actions/types'
import initial from './initial'
import { setPlayCode, setPlayCodeError, resetPlayCode } from '../selectors/playcode'

export default function (state = initial.playCode, action) {
  switch (action.type) {
    case types.CREATE_PLAYCODE_RESPONSE:
    case types.READ_PLAYCODE_RESPONSE:
    case types.READ_LAST_PLAYCODE_RESPONSE:
    case types.ADD_PLAYCODETEAM_RESPONSE:
    case types.DELETE_PLAYCODETEAM_RESPONSE:
      return setPlayCode(state, action)
    case types.READ_PLAYCODES_RESPONSE:
    return setPlayCode(state, action)
    case types.RESET_PLAYCODE:
      return resetPlayCode(state)
    case types.CREATE_PLAYCODE_ERROR:
      return setPlayCodeError(state, action.error)
    default:
      return state
  }
}
