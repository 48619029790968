import React, { Component } from 'react'
import { connect } from 'react-redux'

import Player from '../../components/ui/player'

export class PlayPage extends Component {

constructor(props) {
  super()
}

render() {
    return (
      <div>
        <Player allowFullScreen />
      </div>
    )
  }
}

PlayPage.propTypes = {
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayPage)
