
import * as types from './types'

export function createPlayCode(subscriptionId, profileId, gameId, theme, minutes, isVirtual) {
  return {
    type: types.CREATE_PLAYCODE_REQUEST,
    payload: { subscriptionId, profileId, gameId, theme, minutes, isVirtual }
  }
}

export function readPlayCode(id) {
  return {
    type: types.READ_PLAYCODE_REQUEST,
    payload: { id }
  }
}

export function resetPlayCode() {
  return {
    type: types.RESET_PLAYCODE
  }
}

export function readPlayCodes(profileId) {
  return {
    type: types.READ_PLAYCODES_REQUEST,
    payload: { profileId }
  }
}

export function readLastPlayCode(profileId) {
  return {
    type: types.READ_LAST_PLAYCODE_REQUEST,
    payload: { profileId }
  }
}

export function addPlayCodeTeam(profileId, accessKey, teamMark) {
  return {
    type: types.ADD_PLAYCODETEAM_REQUEST,
    payload: { profileId, accessKey, teamMark }
  }
}

export function deletePlayCodeTeam(profileId, id) {
  return {
    type: types.DELETE_PLAYCODETEAM_REQUEST,
    payload: { profileId, id }
  }
}