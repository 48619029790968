import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import StyledPlayCode from './playcode.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'

import {  getProfile } from '../../../state/selectors/profile'

import { deletePlayCodeTeam } from '../../../state/actions/playcode'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';

export class PlayCodeTeam extends Component {
  
  constructor(props) {
    super()

    this.state = {
      isAddingPlayer: false,
    }
  }
  
  delete = () => {

    const { profile, team } = this.props;
    const profilеId = profile.getIn(['Profile', 'Id'], null);
    var teamId = team.getIn(['Id'], null);

    this.props.fetchDeletePlayCodeTeam(profilеId, teamId);
  }

  render() {
      
      const { team, game } = this.props;
      var id = team.getIn(['Id'], null);
      var mark = team.getIn(['Mark'], null);
      var playedGameId= team.getIn(['PlayedGameId'], null);
      var score= team.getIn(['Score'], null);
      var teamAccessKey = team.getIn(['AccessKey'], null);
      var teamPublicKey = team.getIn(['PublicKey'], null);
      var players = team.getIn(['Players'], null);
      let roles = (game != null) ? game.getIn(['Game', 'Roles'], null) : null;
      return (
        <Accordion allowZeroExpanded allowMultipleExpanded>
          <AccordionItem uuid={id}>
            <StyledPlayCode>
            <div class={(this.props.idx % 2 == 0) ? "accordion__heading_1" : "accordion__heading_2"}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Отбор <span>{mark}</span>
                </AccordionItemButton>
              </AccordionItemHeading>
                <AccordionItemPanel>
                <div>
                  <div class="detail">
                    <div class="param">Публичен линк на екипа:</div>
                    <div class="value"><a href={process.theme.teamURL + "?id=" + teamPublicKey} target="_blank">{process.theme.teamURL}?id={teamPublicKey}</a></div>
                  </div>
                </div>                
                <div>
                  <div class="detail">
                    <div class="param">Линк за мониторинг на екипа:</div>
                    <div class="value"><a href={process.theme.monitorURL + "?id=" + teamAccessKey} target="_blank">{process.theme.monitorURL}?id={teamAccessKey}</a></div>
                  </div>
                </div>
                {playedGameId && 
                  <div class="resultdetails">
                    {score >= 0 &&
                    <div class="detail">
                      <div class="param">Постигнат резултат:</div>
                      <div class="value">{score}</div>
                    </div>
                    }
                    <div class="detail">
                      <div class="param">Статистики за играта:</div>
                      <div class="value"><Link to={"/playedgame?id=" + playedGameId}>вижте тук</Link></div>
                    </div>
                  </div>
                }
                {!this.props.playedGameId &&
                  <div class="playerbtns">
                    <div class="delete">
                      <FixedButton>
                        <StandardButton click={this.delete} text="Изтрийте отбора" width="100%" type="delete"/>
                      </FixedButton>
                    </div>
                  </div>
                }
                </AccordionItemPanel>
              </div>
            </StyledPlayCode>
          </AccordionItem>
        </Accordion>
    )
  }
}

PlayCodeTeam.propTypes = {
  profile: PropTypes.object,
  team: PropTypes.object,
  game: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state),
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchDeletePlayCodeTeam: (profilеId, id) => dispatch(deletePlayCodeTeam(profilеId, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayCodeTeam)
