import React, { Component } from 'react'
import { connect } from 'react-redux'
import Feedback from '../components/pages/analytics/feedback'

export class FeedbackPage extends Component {

render() {
    return (
      <div>
        <Feedback />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

FeedbackPage.propTyes = {
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPage)
