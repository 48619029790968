import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import StyledPlan from './plan.styled'
import FixedButton from '../../ui/fixedbutton'
import StandardButton from '../../ui/button'
import Plans from '../plans/plans'
import axios from 'axios'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import queryString from 'query-string'

import {  getProfile } from '../../../state/selectors/profile'
import { createProfile, upgradeProfileSubscription, repurchaseProfileSubscription } from '../../../state/actions/profile'


//const stripePromise = loadStripe('pk_test_51J2gpiF8SJcbmAp9m83DSJjeExL37BMNrfthFflHpDHRBB2eGFlCA54gO6QrLeVF4OxIQAInqu9mI62mAwVUIVCd00XJLmYH9I');
const stripePromise = loadStripe('pk_live_51J2gpiF8SJcbmAp96gbfkh3h0N3PsOl3mKwFIPj3zpGaffLBbXUCxVK0pSeRO6W5ZcHgqQyEW9QCjNjCXCu010FY00JDcTfR2K');

const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};


// Live prices
const price_1_ref = "price_1KWnfGF8SJcbmAp9E3fUcfB4"; // Monthly subscription
const price_2_ref = "price_1KWndyF8SJcbmAp9F4Z6UDuS"; // Yearly subscription

// Test prices
//const price_1_ref = "price_1J2u2lF8SJcbmAp9ty7D1V46"; // Monthly subscription
//const price_2_ref = "price_1JUsvaF8SJcbmAp9ExxJV0op"; // Yearly subscription

export class PlanForm extends Component {

  state = {
    planType: "",
    stripe: "",
    passwordsDoNotMatch: false,
    planIsManuallySelected: false
  }

  constructor(props) {
    super();

    const values = queryString.parse(window.location.search);
    if (values.planType != null && values.planType != "") {
      this.state.planType = values.planType;
    }

    this.onPlanChanged = this.onPlanChanged.bind(this);
    this.createSession = this.createSession.bind(this);
    this.onSessionCreated = this.onSessionCreated.bind(this);
    this.onSessionFailure = this.onSessionFailure.bind(this);
  }

  async componentDidMount() {
    const stripe = await stripePromise;
    this.setState({
      stripe: stripe
    })
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const id = target.id

    this.setState({
      [ id ]: value,
      passwordsDoNotMatch: false,
    });
  }

  onPlanChanged = (id) => {
    this.setState({
      planType: id, 
      planIsManuallySelected: true,
    });
  }

  submitForm = e => {
    e.preventDefault();

    if (this.props.isCreateProfile) {
      if (this.state.password != this.state.confirmPassword) {
        // Password do not match
        this.setState({
          passwordsDoNotMatch: true,
        })
        return;
      }
  
      var planType = this.state.planType;
      if (planType === "") {
        return;
      } else if (planType === "0") {
        this.props.fetchCreateProfile(this.state.firstName, this.state.secondName, this.state.email, this.state.password, this.state.organization, this.state.subscriptionId, this.state.activationCode, this.state.subscribe);
      } else {
        this.createSession();
      }
    } else if (this.props.isRepurchase) {
      const { profile } = this.props;
      var profileId = (profile) ? profile.getIn(['Profile', 'Id'], null) : null;
      var priceRef = (this.state.planType == 1) ? price_1_ref : price_2_ref; 
      this.props.fetchRepurchaseProfileSubscription(profileId, priceRef, this.state.planType, this.state.issueInvoice, this.state.organizationId, this.state.organizationName, this.state.organizationCountry, this.state.organizationTown, this.state.organizationAddress, this.state.organizationContactPerson, this.state.organizationNote);
    } else {
      const { profile } = this.props;
      var hasSubscription = (profile) ? profile.getIn(['Profile', 'Subscription'], false) : false;      
      if (hasSubscription) {
        var profileId = (profile) ? profile.getIn(['Profile', 'Id'], null) : null;
        var priceRef = (this.state.planType == 1) ? price_1_ref : price_2_ref;
        var subscription = profile.getIn(['Profile', 'Subscription'], null);
        this.state.issueInvoice = (this.state.issueInvoice != null) ? this.state.issueInvoice : subscription.getIn(['IssueInvoice'], false);
        this.props.fetchUpgradeProfileSubscription(profileId, priceRef, this.state.planType, this.state.issueInvoice, this.state.organizationId, this.state.organizationName, this.state.organizationCountry, this.state.organizationTown, this.state.organizationAddress, this.state.organizationContactPerson, this.state.organizationNote);
      } else {
        this.createSession();
      }
    }
  }

  createSession = () => {
    const { profile } = this.props;
    const API_ENDPOINT = `${process.env.REACT_APP_SERVER}/stripe/session/start`    
    var priceRef = (this.state.planType == 1) ? price_1_ref : price_2_ref;
    var profileId = (profile != null) ? profile.getIn(['Profile', 'Id'], null) : null;
    var body = (profileId != null) ? `profileId=${profileId}&` : "";
    body += (this.state.firstName != null) ? `firstName=${this.state.firstName}&`: "";
    body += (this.state.secondName != null) ? `secondName=${this.state.secondName}&` : "";
    body += (this.state.email != null) ? `email=${this.state.email}&` : "";
    body += (this.state.password != null) ? `password=${this.state.password}&` : "";
    body += (priceRef != null) ? `priceRef=${priceRef}&` : "";
    body += (this.state.planType != null) ? `planType=${this.state.planType}&` : "";
    body += (this.state.issueInvoice != null) ? `issueInvoice=${this.state.issueInvoice}&` : "issueInvoice=0&";    
    body += (this.state.organization != null) ? `organization=${this.state.organization}&` : "";
    if (this.state.issueInvoice) {
      body += (this.state.organizationId != null) ? `organizationId=${this.state.organizationId}&`: "";
      body += (this.state.organizationName != null) ? `organizationName=${this.state.organizationName}&`: "";
      body += (this.state.organizationCountry != null) ? `organizationCountry=${this.state.organizationCountry}&`: "";
      body += (this.state.organizationTown != null) ? `organizationTown=${this.state.organizationTown}&`: "";
      body += (this.state.organizationAddress != null) ? `organizationAddress=${this.state.organizationAddress}&`: "";
      body += (this.state.organizationContactPerson != null) ? `organizationContactPerson=${this.state.organizationContactPerson}&`: "";
      body += (this.state.organizationNote != null) ? `organizationNote=${this.state.organizationNote}&`: "";
    }
    axios.post(API_ENDPOINT, body, { headers })
    .then(response => {
      if (this.state.email != null && this.state.password != null) {
        const token = btoa(`${this.state.email.trim()}:${this.state.password}`)
        localStorage.setItem('token', token)
        localStorage.setItem('profileEmail', this.state.email.trim())
        localStorage.setItem('profilePassword', this.state.password)
      }
      this.onSessionCreated(response.data);      
    }).catch(error => {
      this.onSessionFailure(error);
    })
  }

  onSessionCreated = (data) => {    
    this.state.stripe.redirectToCheckout({ sessionId: data.CheckoutSession.Id });
  }

  onSessionFailure = (error) => {
    alert("onFailure: error=" + error);
  }

  render() {
    const { profile, isCreateProfile } = this.props;
    var profileId = profile.getIn(['Profile', 'Id'], null);
    var errorCode = profile.getIn(['Error', 'Code'], null);
    var errorMessage = "";
    switch (errorCode) {
      case 11: 
        errorMessage = "Въвелите сте грешен абонаментен номер или код за активиране.";
        break;
      case 12:
        errorMessage = "Въведеният абонамент е неактивен.";
        break;
      case 51:
        errorMessage = "Вече съществува акаунт с въведения Email.";
        break;
      default:
        errorMessage = "Възникна грешка. Моля проверете въведените от Вас данни и опитайте отново.";
    }
    if (this.state.passwordsDoNotMatch) {
      errorCode = 0;
      errorMessage = "Въведените от Вас пароли не съвпадат.";
    }
    var profilePlanType = "0";
    if (!isCreateProfile) {
      const subscription = (profile != null) ? profile.getIn(['Profile', 'Subscription'], null) : null;
      if (subscription != null) {
        var subscriptionType = subscription.getIn(['Type'], null);
        profilePlanType = (subscriptionType == "monthly") ? "1" : "2";
        if (this.state.planType == "") { 
          this.state.planType = profilePlanType;
        }
      }
    }
    var readyToBuy = this.state.planType == 1 || this.state.planType == 2;
    if (!isCreateProfile) {
      if (profilePlanType == "0") {
        readyToBuy = this.state.planIsManuallySelected;
      } else if (!this.props.isRepurchase) {
        readyToBuy = this.state.planType != profilePlanType;
      } else {
        readyToBuy = this.state.planIsManuallySelected;
      }
      
    }
    if (!isCreateProfile) {
      const issueInvoice = profile.getIn(['Profile', 'IssueInvoice'], null);
      if (this.state.issueInvoice == null) {
        this.state.issueInvoice = issueInvoice;
      }
      const organizationId = profile.getIn(['Profile', 'OrganizationId'], null);
      if (this.state.organizationId == null) {
        this.state.organizationId = organizationId;
      }
      const organizationName = profile.getIn(['Profile', 'OrganizationName'], null);
      if (this.state.organizationName == null) {
        this.state.organizationName = organizationName;
      }
      const organizationCountry = profile.getIn(['Profile', 'OrganizationCountry'], null);
      if (this.state.organizationCountry == null) {
        this.state.organizationCountry = organizationCountry;
      }
      const organizationTown = profile.getIn(['Profile', 'OrganizationTown'], null);
      if (this.state.organizationTown == null) {
        this.state.organizationTown = organizationTown;
      }
      const organizationAddress = profile.getIn(['Profile', 'OrganizationAddress'], null);
      if (this.state.organizationAddress == null) {
        this.state.organizationAddress = organizationAddress;
      }
      const organizationContactPerson = profile.getIn(['Profile', 'OrganizationContactPerson'], null);
      if (this.state.organizationContactPerson == null) {
        this.state.organizationContactPerson = organizationContactPerson;
      }
      const organizationNote = profile.getIn(['Profile', 'OrganizationNote'], null);
      if (this.state.organizationNote == null) {
        this.state.organizationNote = organizationNote;
      }
    } else {
      if (this.state.issueInvoice == null) {
        this.state.issueInvoice = false;
      }
    }
    var formTitle = "";
    if (this.props.isRepurchase) {
      formTitle = "Реактивирайте своя абонамент с един от следните планове:";
    } else if (isCreateProfile) {
      formTitle = "Изберете абонаментен план:";
    } else if (profilePlanType == "0") {
      formTitle = "Отключете всички възможности на платформата с:";
    } else {
      formTitle = "Минете на по-добър абонаментен план:";
    }

    return (
      <StyledPlan>
        {profileId != null && isCreateProfile &&
          <Redirect to={'/'} />
        }
        <div id="register">
          <form onSubmit={this.submitForm}>
            <div class="inputs">
              {isCreateProfile &&
              <div>
                <h2>Въведете данни за акаунта:</h2> 
                <div class="row">
                  <input id='firstName' required placeholder="Име*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='secondName' required placeholder="Фамилия*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='email' required placeholder="Email*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='organization' placeholder="Организация" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='password' type="password" required placeholder="Парола*" onChange={this.handleInputChange}/>
                </div>
                <div class="row">
                  <input id='confirmPassword' type="password" required placeholder="Потвърдете паролата*" onChange={this.handleInputChange}/>
                </div>
              </div>
              }
              {(this.props.isRepurchase || (this.state.planIsManuallySelected && profilePlanType != "2") || this.state.planType != "2") &&
              <div>
                <h2 class="subtitle" id="plans">{formTitle}</h2>
                <Plans onPlanChanged={this.onPlanChanged} isUpgrade={!isCreateProfile} isRepurchase={this.props.isRepurchase} minPlan={profilePlanType} preselectedPlan={this.state.planType}/>
                {readyToBuy &&
                <div class="invoice">
                  <div class="row">
                    <div class="checkbox">
                      <input type="checkbox" {...(this.state.issueInvoice ? {checked: "checked"} : {})} id="issueInvoice" onChange={this.handleInputChange}/>
                      <label for="issueInvoice">Искам да ми бъдaт издавани фактури</label>
                    </div>
                  </div>
                  {this.state.issueInvoice && 
                  <div>
                    <h2 class="subtitle">Въведете данни за фактуриране:</h2>
                    <div class="row">
                      <input id='organizationId' value={this.state.organizationId} required placeholder="ЕИК*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input id='organizationName' value={this.state.organizationName} required placeholder="Име на организацията*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input id='organizationCountry' value={this.state.organizationCountry} required placeholder="Държава*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input id='organizationTown' value={this.state.organizationTown} required placeholder="Населено място*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input id='organizationAddress' value={this.state.organizationAddress} required placeholder="Адрес*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input id='organizationContactPerson' value={this.state.organizationContactPerson} required placeholder="МОЛ*" onChange={this.handleInputChange}/>
                    </div>
                    <div class="row">
                      <input id='organizationNote' value={this.state.organizationNote} placeholder="Бележка, която да се добави към фактурата" onChange={this.handleInputChange}/>
                    </div>
                  </div>
                  }
                </div>
                }
              </div>
            }
            </div>
            {(isCreateProfile || this.state.planType != "2") &&
              <div>
                <div class='required'>* задължително поле</div>
                {errorCode != null &&
                  <div class="error">
                    <div class="errorMessage">{errorMessage}</div>
                    {errorCode == 51 &&
                      <div class="recover"><Link to={"/forgottenPassword"}>Забравена парола?</Link></div>
                    } 
                  </div>
                }
              </div>
            }
            {this.state.planType != "" && this.state.planType == 0 &&
              <div class='submit'>
                <FixedButton>
                  <StandardButton isSubmit text="Регистрирайте се" width="100%"/>
                </FixedButton>
              </div>
            }            
            {readyToBuy &&
              <div class='submit'>
                <Elements stripe={stripePromise}>
                  <FixedButton>
                    <StandardButton isSubmit text="Купете" width="100%" bgr="#a62324" color="#fff"/>
                  </FixedButton> 
                </Elements>
              </div>
            }
          </form>             
      </div>
      </StyledPlan>
    )
  }
}

PlanForm.propTypes = {
}

const mapStateToProps = (state) => {
  return {
    profile: getProfile(state)
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    fetchCreateProfile: (firstName, secondName, email, password, organization, subscriptionId, activationCode, subscribe) => dispatch(createProfile(firstName, secondName, email, password, organization, subscriptionId, activationCode, subscribe)),
    fetchUpgradeProfileSubscription: (profileId, priceRef, planType, issueInvoice, organizationId, organizationName, organizationCountry, organizationTown, organizationAddress, organizationContactPerson, organizationNote) => dispatch(upgradeProfileSubscription(profileId, priceRef, planType, issueInvoice, organizationId, organizationName, organizationCountry, organizationTown, organizationAddress, organizationContactPerson, organizationNote)),
    fetchRepurchaseProfileSubscription: (profileId, priceRef, planType, issueInvoice, organizationId, organizationName, organizationCountry, organizationTown, organizationAddress, organizationContactPerson, organizationNote) => dispatch(repurchaseProfileSubscription(profileId, priceRef, planType, issueInvoice, organizationId, organizationName, organizationCountry, organizationTown, organizationAddress, organizationContactPerson, organizationNote)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanForm)
