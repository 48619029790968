import React, { Component } from 'react'
import { slide as Menu } from 'react-burger-menu'
import StyledNavigation from './navigation.styled.js'
import storage from '../../state/storage'
import { logOut } from '../../state/actions/login'

export class SubscriptionNavigation extends Component {
  state = {
    menuOpen: false,
  }

  closeMenu = (e) => {
    if (e.target.id == "logout") {
      this.submitLogout();
    } else {
      e.preventDefault();
      this.setState({ menuOpen: false })
    }
  }

  handleStateChange = (state) => {
    this.setState({ menuOpen: state.isOpen })
  }

  submitLogout() {
    storage().dispatch(logOut());
  }

  render() {
    return (
       <StyledNavigation theme={process.theme}>
         <div id="menu-desktop">
          <ul>
           <li><a id="logout" href="/" onClick={this.submitLogout}>Изход</a></li>
          </ul>
        </div>
        <div id="menu-mobile" onClick={this.closeMenu}>
          <Menu width={'100%'} isOpen={this.state.menuOpen}
                onStateChange={(state) => this.handleStateChange(state)}>
            <a id="logout" href="/">Изход</a>
          </Menu>
        </div>
        </StyledNavigation>
    )
  }
}

export default SubscriptionNavigation